import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import toast from 'react-hot-toast'

import AlertBox from '../../components/AlertBox'
import Loader from '../../components/Loader'
import { getTokenFromUrl } from '../../client/client'
import DOWNLOAD_REPORT_FILE from '../../gql/queries/downloadReportFile'
import Button from '../../components/buttons/Button'

function ExternalReportPage() {
  const { clientId } = useParams()
  const token = getTokenFromUrl()
  const [searchParams] = useSearchParams()
  const kind = searchParams.get('kind')
  const productionYears = searchParams.getAll('productionYears')

  const { data, loading } = useQuery(DOWNLOAD_REPORT_FILE, {
    variables: {
      clientId,
      productionYear: productionYears.map(Number),
      kind,
      orientation: 'portrait'
    },
    skip: !clientId || !token
  })

  const downloadPdf = async () => {
    try {
      const { downloadReportFile } = data
      const { fileName, pdfStringValue } = downloadReportFile

      const link = window.document.createElement('a')
      link.href = `data:application/pdf;base64,${pdfStringValue}`
      link.download = fileName
      link.click()
    } catch (error) {
      toast.error(`Error while downloading report.`)
    }
  }

  if (!data && loading) {
    return <Loader message="Fetching reports data...." variant="fetchData" />
  }

  if (!token || !data) {
    return <AlertBox message="Unauthorized" />
  }

  return (
    <>
      <div className="fixed top-0 left-0 z-10 flex h-full w-full flex-col items-center justify-center bg-white md:hidden">
        <p className="mb-2 text-gray-500">Your report is ready! Click below to download.</p>
        <Button onClick={() => downloadPdf('landscape')} label="Download" />
      </div>
      <div className="absolute top-0 left-0 right-0 bottom-0 hidden md:block">
        <embed
          className="h-full w-full"
          src={`data:application/pdf;base64,${data.downloadReportFile.pdfStringValue}`}
        />
      </div>
    </>
  )
}

export default ExternalReportPage
