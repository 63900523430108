import { gql } from '@apollo/client'

const GET_REPORTS = gql`
  query getReports($clientId: String!, $productionYears: [Float!]!) {
    getPriceFetchedOn
    getReports(clientId: $clientId, productionYears: $productionYears) {
      clientId
      id
      amountNotForSale
      bullsInWeight
      bullsSaleCount
      bullsSaleWeight
      calvingDate
      cowsCount
      crop {
        id
        name
        unit
        tradingMonths
        futuresName
      }
      dailyGain
      deathsCount
      description
      drylandAcres
      drylandYield
      headsCount
      heiferInWeight
      heiferRetainCount
      heiferSaleCount
      heiferSaleWeight
      inDate
      inWeight
      irrigatedAcres
      irrigatedYield
      isDryland
      isIrrigated
      kind
      livestockKind
      outWeight
      saleDate
      storageAvailable
      crop {
        id
        name
      }
      Storage {
        location
        quantity
      }
      productionYear {
        id
        name
      }
      profilesWithRevenue {
        id
        cropId
        description
        livestockKind
        totalAcres
        totalHeads
        totalQuantity
        basisAmount
        totalExpense
        totalIncome
        totalTargets
        breakeven
        targetPrice
        futureMonth {
          id
          month
          year
          price
          cropId
        }
        soldQuantity {
          subkind
          quantity
          amount
          basisAmount
          feeAmount
          futuresAmount
        }
      }
    }
  }
`

export default GET_REPORTS
