import { gql } from '@apollo/client'

const PROFILE_FRAGMENT = gql`
  fragment Profile on Profile {
    id
    name
    amountNotForSale
    bullsInWeight
    bullsSaleCount
    bullsSaleWeight
    calvingDate
    cowsCount
    cropId
    dailyGain
    deathsCount
    description
    drylandAcres
    drylandYield
    headsCount
    heiferInWeight
    heiferRetainCount
    heiferSaleCount
    heiferSaleWeight
    inDate
    inWeight
    irrigatedAcres
    irrigatedYield
    isDryland
    isIrrigated
    kind
    livestockKind
    outWeight
    saleDate
    storageAvailable
    productionYear {
      id
      name
    }
    crop {
      id
      name
      unit
      tradingMonths
      futuresName
    }
  }
`

export default PROFILE_FRAGMENT
