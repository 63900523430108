/* eslint-disable no-unused-vars */
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { groupBy, sortBy } from 'lodash'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import Button from '../../buttons/Button'
import SaleModal from '../../modals/SaleModal'
import DELETE_SALE from '../../../gql/mutations/deleteSale'
import DeleteModal from '../../modals/DeleteModal'
import EmptyState from '../../EmptyState'
import GET_CROPS from '../../../gql/queries/getCrops'
import GET_SALES_PROFILES from '../../../gql/queries/getSalesProfile'
import GET_SUMMARY from '../../../gql/queries/getSummary'
import PROFILES from '../../../gql/queries/Profiles'
import SubTitle from '../../typography/SubTitle'
import TableWrapper from '../../wrappers/TableWrapper'
import { renderMoney, renderPrice, renderQuantity, sortHandler } from '../../../utils/helper.js'
import SubTitleWrapper from '../../wrappers/SubTitleWrapper'
import ViewWrapper from '../../wrappers/ViewWrapper'
import downArrow from '../../../assets/downArrow.png'

const saleIntialValues = {
  amount: undefined,
  basisAmount: undefined,
  buyerId: '',
  clientId: undefined,
  deliveryEndDate: undefined,
  deliveryStartDate: undefined,
  feeAmount: undefined,
  feeMode: undefined,
  futuresAmount: undefined,
  futuresMonth: undefined,
  kind: undefined,
  notes: undefined,
  orderExpiryDate: undefined,
  profileId: '',
  quantity: undefined,
  delivered: undefined,
  referenceNumber: undefined,
  unloadNumber: undefined,
  saleDate: '',
  subkind: '',
  unitWeight: undefined,
  transportStatus: undefined,
  transportToLocation: undefined
}

function SalesView({ data, buyers }) {
  const { id, productionYearId } = useParams()
  const name = Number(productionYearId)

  const [currentSaleValues, setCurrentSaleValues] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const [profile, setProfile] = useState({})
  const [saleValues, setSaleValues] = useState(saleIntialValues)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [sortOrder, setSortOrder] = useState()
  const [sortKey, setSortKey] = useState()

  const { data: profilesData } = useQuery(PROFILES, {
    variables: {
      clientId: id,
      productionYearId: name
    },
    onCompleted: (profilesData) => {
      setProfile(profilesData?.profiles[0])
    }
  })

  const { data: cropsData } = useQuery(GET_CROPS)
  const [deleteSale] = useMutation(DELETE_SALE)
  const removeSale = async () => {
    await deleteSale({
      variables: {
        input: {
          id: saleValues.id
        }
      },
      refetchQueries: [
        {
          query: GET_SALES_PROFILES,
          variables: {
            clientId: id,
            productionYearId: name
          }
        },
        {
          query: GET_SUMMARY,
          variables: {
            clientId: id,
            productionYearId: Number(name)
          }
        }
      ]
    })
  }

  const handleSelect = (id) => {
    const selectedProfile = profilesData.profiles.find((profile) => profile.id === id)
    setProfile(selectedProfile)
    setSaleValues({ ...saleValues, profileId: selectedProfile?.id })
  }

  const groupedSales = groupBy(
    sortBy(data, (item) => item.profile?.crop?.name),
    (item) => item.profile?.crop?.name
  )

  const handleCloseSaleModal = () => {
    setIsOpen(false)
    setProfile(profilesData?.profiles[0])
    setSaleValues(saleIntialValues)
    setIsDeleteModalOpen(false)
  }

  return (
    <>
      <ViewWrapper>
        <SubTitleWrapper
          title={<SubTitle color="gray">Sales</SubTitle>}
          button={
            <Button
              type="button"
              color="info"
              label="Add Sale"
              onClick={() => {
                setIsOpen(true)
              }}
            />
          }
        />
        {data?.length > 0 ? (
          <div className="-my-2 min-w-full overflow-x-auto">
            <div className="inline-block min-w-full py-2 px-0.5 align-middle">
              <TableWrapper
                columns={[
                  <div
                    className="flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      setSortKey('buyer.name')
                      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                    }}
                    aria-hidden="true"
                  >
                    <span>Buyer</span>
                    {sortKey && sortKey === 'buyer.name' && (
                      <div
                        className="w-[7px]"
                        style={{
                          rotate: sortOrder === 'asc' ? '180deg' : '0deg'
                        }}
                      >
                        <img src={downArrow} alt="" height={7} width={7} />
                      </div>
                    )}
                  </div>,
                  <div
                    className="flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      setSortKey('profile.name')
                      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                    }}
                    aria-hidden="true"
                  >
                    <span>Commodity</span>
                    {sortKey && sortKey === 'profile.name' && (
                      <div
                        className="w-[7px]"
                        style={{
                          rotate: sortOrder === 'asc' ? '180deg' : '0deg'
                        }}
                      >
                        <img src={downArrow} alt="" height={7} width={7} />
                      </div>
                    )}
                  </div>,
                  <div
                    className="flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      setSortKey('subkind')
                      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                    }}
                    aria-hidden="true"
                  >
                    <span>Type</span>
                    {sortKey && sortKey === 'subkind' && (
                      <div
                        className="w-[7px]"
                        style={{
                          rotate: sortOrder === 'asc' ? '180deg' : '0deg'
                        }}
                      >
                        <img src={downArrow} alt="" height={7} width={7} />
                      </div>
                    )}
                  </div>,
                  <div
                    className="flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      setSortKey('futuresMonth')
                      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                    }}
                    aria-hidden="true"
                  >
                    <span>Fut. month</span>
                    {sortKey && sortKey === 'futuresMonth' && (
                      <div
                        className="w-[7px]"
                        style={{
                          rotate: sortOrder === 'asc' ? '180deg' : '0deg'
                        }}
                      >
                        <img src={downArrow} alt="" height={7} width={7} />
                      </div>
                    )}
                  </div>,
                  <div
                    className="flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      setSortKey('displayPrice')
                      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                    }}
                    aria-hidden="true"
                  >
                    <span>Price</span>
                    {sortKey && sortKey === 'displayPrice' && (
                      <div
                        className="w-[7px]"
                        style={{
                          rotate: sortOrder === 'asc' ? '180deg' : '0deg'
                        }}
                      >
                        <img src={downArrow} alt="" height={7} width={7} />
                      </div>
                    )}
                  </div>,
                  <div
                    className="flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      setSortKey('quantity')
                      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                    }}
                    aria-hidden="true"
                  >
                    <span>Quantity</span>
                    {sortKey && sortKey === 'quantity' && (
                      <div
                        className="w-[7px]"
                        style={{
                          rotate: sortOrder === 'asc' ? '180deg' : '0deg'
                        }}
                      >
                        <img src={downArrow} alt="" height={7} width={7} />
                      </div>
                    )}
                  </div>,
                  <div
                    className="flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      setSortKey('expectedRevenue')
                      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                    }}
                    aria-hidden="true"
                  >
                    <span>Expected Revenue</span>
                    {sortKey && sortKey === 'expectedRevenue' && (
                      <div
                        className="w-[14px]"
                        style={{
                          rotate: sortOrder === 'asc' ? '180deg' : '0deg'
                        }}
                      >
                        <img src={downArrow} alt="" height={7} width={7} />
                      </div>
                    )}
                  </div>,
                  <div
                    className="flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      setSortKey('deliveryStartDate')
                      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                    }}
                    aria-hidden="true"
                  >
                    <span>Delivery</span>
                    {sortKey && sortKey === 'deliveryStartDate' && (
                      <div
                        className="w-[7px]"
                        style={{
                          rotate: sortOrder === 'asc' ? '180deg' : '0deg'
                        }}
                      >
                        <img src={downArrow} alt="" height={7} width={7} />
                      </div>
                    )}
                  </div>,
                  <div
                    className="flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      setSortKey('delivered')
                      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                    }}
                    aria-hidden="true"
                  >
                    <span>Delivered</span>
                    {sortKey && sortKey === 'delivered' && (
                      <div
                        className="w-[7px]"
                        style={{
                          rotate: sortOrder === 'asc' ? '180deg' : '0deg'
                        }}
                      >
                        <img src={downArrow} alt="" height={7} width={7} />
                      </div>
                    )}
                  </div>,
                  <div
                    className="flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      setSortKey('saleDate')
                      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                    }}
                    aria-hidden="true"
                  >
                    <span>Sale Date</span>
                    {sortKey && sortKey === 'saleDate' && (
                      <div
                        className="w-[14px]"
                        style={{
                          rotate: sortOrder === 'asc' ? '180deg' : '0deg'
                        }}
                      >
                        <img src={downArrow} alt="" height={7} width={7} />
                      </div>
                    )}
                  </div>,
                  <div
                    className="flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      setSortKey('referenceNumber')
                      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                    }}
                    aria-hidden="true"
                  >
                    <span>Contract Number</span>
                    {sortKey && sortKey === 'referenceNumber' && (
                      <div
                        className="w-[14px]"
                        style={{
                          rotate: sortOrder === 'asc' ? '180deg' : '0deg'
                        }}
                      >
                        <img src={downArrow} alt="" height={7} width={7} />
                      </div>
                    )}
                  </div>,
                  'Actions'
                ]}
                rows={Object.values(groupedSales).flatMap((group) =>
                  sortHandler(group, sortOrder, sortKey)
                    ?.map((item) => {
                      const cropProfile = item.profile
                      const productionYear = cropProfile.productionYear.name
                      const crop = cropsData?.getCrops?.find(
                        (crop) => crop.id === item.profile.cropId
                      )

                      let { subkind } = item
                      if (subkind === 'CASH_SALE') {
                        subkind = 'CASH'
                      }
                      return [
                        item.buyer?.name,
                        `${cropProfile?.name} (${productionYear})`,
                        subkind,
                        item.futuresMonth,
                        item.subkind !== 'NPE' && renderPrice(item.displayPrice),
                        `${renderQuantity(item.quantity)} ${crop?.unit.toLowerCase() || ''}`,
                        item.subkind !== 'NPE' && renderMoney(item.expectedRevenue),
                        `${dayjs(item?.deliveryStartDate).format('MM/DD/YYYY')} -
                                    ${dayjs(item.deliveryEndDate).format('MM/DD/YYYY')}`,
                        item.delivered ? 'Yes' : 'No',
                        dayjs(item.saleDate).format('MM/DD/YYYY'),
                        item.referenceNumber,
                        <div>
                          <Button
                            color="action"
                            actionLabel="Edit"
                            onClick={() => {
                              setCurrentSaleValues({
                                saleId: item.id,
                                saleAmount: item?.quantity?.toLocaleString('en-US')
                              })

                              handleSelect(item.profile.id)
                              setSaleValues({
                                ...item,
                                profileId: item.profile.id,
                                buyerId: item.buyer.id,
                                saleDate: dayjs(item?.saleDate).format('MM/DD/YYYY'),
                                orderExpiryDate: dayjs(item?.orderExpiryDate).format('MM/DD/YYYY'),
                                deliveryStartDate: dayjs(item?.deliveryStartDate).format(
                                  'MM/DD/YYYY'
                                ),
                                deliveryEndDate: dayjs(item?.deliveryEndDate).format('MM/DD/YYYY')
                              })
                              setIsOpen(true)
                            }}
                          />
                          <Button
                            color="action"
                            actionLabel="Delete"
                            onClick={() => {
                              setSaleValues(item)
                              setIsDeleteModalOpen(true)
                            }}
                          />
                        </div>
                      ]
                    })
                    .concat([
                      group.reduce(
                        (asc, item, index, { length }) => {
                          const sumOfQuantity = asc[5] + item.quantity
                          const format = (value) =>
                            index === length - 1 ? renderQuantity(value) : value

                          return [
                            'Total',
                            `${item.profile?.name} (${item.profile?.productionYear?.name || ''})`,
                            '',
                            '',
                            '',
                            format(sumOfQuantity),
                            '',
                            '',
                            '',
                            '',
                            '',
                            ''
                          ]
                        },
                        ['Total', 0, 0, 0, 0, 0, 0]
                      )
                    ])
                )}
                rowClassName={(row) =>
                  // eslint-disable-next-line no-nested-ternary
                  row[0] === 'Total'
                    ? 'bg-[#f0f0f0]'
                    : row[8] === 'Yes' // delivered
                    ? 'bg-[#d7e8cd]'
                    : 'bg-white'
                }
              />
            </div>
          </div>
        ) : (
          <EmptyState header="No sale" message="Get started by adding a sale." disabled />
        )}
      </ViewWrapper>

      <SaleModal
        buyers={buyers}
        closeModal={handleCloseSaleModal}
        currentSaleValues={currentSaleValues}
        handleSelect={handleSelect}
        intialValues={saleValues}
        profile={profile}
        profiles={profilesData?.profiles}
        crops={cropsData?.getCrops}
        isOpen={isOpen}
        type={saleValues.id ? 'EDIT_SALE' : 'CREATE_SALE'}
        key={saleValues?.id}
      />

      <DeleteModal
        onClose={handleCloseSaleModal}
        isOpen={isDeleteModalOpen}
        description="Are you sure you want to delete this sale?"
        title={`${saleValues?.profile?.crop?.name || ''} (${productionYearId})`}
        onSubmit={removeSale}
      />
    </>
  )
}

export default SalesView
