import { gql } from '@apollo/client'

import USER_FRAGMENT from '../fragments/user'

const ACCEPT_INVITE = gql`
  mutation AcceptInvite($input: AcceptInviteInput!) {
    acceptInvite(input: $input) {
      user {
        ...User
      }
      accessToken
    }
  }
  ${USER_FRAGMENT}
`

export default ACCEPT_INVITE
