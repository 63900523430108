import { gql } from '@apollo/client'

const CREATE_PRODUCTION_YEAR = gql`
  mutation createProductionYear($input: CreateProductionYearInput!) {
    createProductionYear(input: $input) {
      name
      clientId
    }
  }
`

export default CREATE_PRODUCTION_YEAR
