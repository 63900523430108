/* eslint-disable no-unsafe-optional-chaining */
import React from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import AlertBox from '../../../components/AlertBox'
import EmptyState from '../../../components/EmptyState'
import ExpenseInput from '../../../components/inputs/ExpenseInput'
import GET_PROFILE_PROFIT_GOALS from '../../../gql/queries/getProfileProfitGoals'
import Header from '../../../components/Header'
import Loader from '../../../components/Loader'
import PROFILES from '../../../gql/queries/Profiles'
import SAVE_PROFILE_PROFIT_GOAL from '../../../gql/mutations/saveProfileProfitGoal'
import TableWrapper from '../../../components/wrappers/TableWrapper'
import Title from '../../../components/typography/Title'
import { convertNumberTwoDecimal, renderMoney, renderPrice } from '../../../utils/helper'

export default function GoalsPage() {
  const [saveProfileProfitGoal] = useMutation(SAVE_PROFILE_PROFIT_GOAL)
  const { id, productionYearId } = useParams()
  const name = Number(productionYearId)

  const { data, error, loading } = useQuery(PROFILES, {
    variables: {
      clientId: id,
      productionYearId: name
    }
  })

  const profileIds = data?.profiles?.map((item) => item.id)

  const { data: profitGoals, error: getProfileGoalsError } = useQuery(GET_PROFILE_PROFIT_GOALS, {
    variables: {
      profileIds
    },
    skip: !profileIds?.length
  })

  const cropProfiles = data?.profiles?.filter((item) => item?.kind === 'CROP')
  const livestockProfiles = data?.profiles?.filter((item) => item?.kind === 'LIVESTOCK')
  const getName = (profile) => {
    if (profile.kind === 'CROP') return `${profile.name} (${profile.productionYear.name})`
    if (profile.livestockKind === 'COW_CALF') {
      return profile?.description ? `Cow-Calf (${profile.description})` : `Cow-Calf`
    }
    if (profile.livestockKind === 'LIVE_CATTLE') {
      return profile?.description ? `Live-Cattle (${profile.description})` : `Live-Cattle`
    }
    if (profile.livestockKind === 'FEEDER_CATTLE') {
      return profile?.description ? `Feeder-Cattle (${profile.description})` : `Feeder-Cattle`
    }

    return null
  }

  const getProfitGoals = (profile, type) => {
    if (profitGoals?.getProfileProfitGoals?.length) {
      const target = profitGoals.getProfileProfitGoals.find((item) => item.profileId === profile.id)

      if (target) {
        if (profile.kind === 'LIVESTOCK') return target.livestockProfitTarget

        if (profile.kind === 'CROP') {
          if (type === 'DRYLAND') return target.drylandProfitTarget
          if (type === 'IRRIGATED') return target.irrigatedProfitTarget

          return null
        }
      }

      return null
    }

    return null
  }

  const handleProfitGoalChange = (event, profileId, type) => {
    const data = {}
    if (type === 'DRYLAND') {
      data.drylandProfitTarget = event
    } else if (type === 'IRRIGATED') {
      data.irrigatedProfitTarget = event
    } else if (type === 'LIVESTOCK') {
      data.livestockProfitTarget = event
    }

    toast.promise(
      saveProfileProfitGoal({
        variables: {
          input: {
            profileId,
            ...data
          }
        },
        refetchQueries: [GET_PROFILE_PROFIT_GOALS]
      }),
      {
        loading: 'Saving the profit target...',
        success: 'Profit target saved successfully.',
        error: 'Error while saving the profit target.'
      }
    )
  }

  const cropProfileColumns = [
    'Commodity',
    'Dryland profit target',
    'Irrigated profit target',
    'Breakeven',
    'Target Price'
  ]

  const livestockProfileColumns = ['Profile', 'Profit target', 'Breakeven', 'Target Price']

  const getCropWeightedAvg = (type) => {
    let totalDrylandAcres = 0
    let totalIrrigatedAcres = 0

    totalDrylandAcres = cropProfiles
      ?.map((profile) => {
        if (profile.isDryland) return profile.drylandAcres
        return 0
      })
      ?.reduce((acc, acres) => acc + acres)

    totalIrrigatedAcres = cropProfiles
      ?.map((profile) => {
        if (profile.isIrrigated) return profile.irrigatedAcres
        return 0
      })
      ?.reduce((acc, acres) => acc + acres)

    if (type === 'DRYLAND') {
      let drylandWeightage = 0

      cropProfiles?.forEach((profile) => {
        const target = profitGoals?.getProfileProfitGoals?.find(
          (item) => item.profileId === profile.id
        )
        if (profile.isDryland)
          drylandWeightage += profile.drylandAcres * target?.drylandProfitTarget || 0
      })

      return drylandWeightage ? convertNumberTwoDecimal(drylandWeightage / totalDrylandAcres) : 0
    }

    if (type === 'IRRIGATED') {
      let irrigatedWeightage = 0
      cropProfiles?.forEach((profile) => {
        const target = profitGoals?.getProfileProfitGoals?.find(
          (item) => item.profileId === profile.id
        )
        if (profile.isIrrigated)
          irrigatedWeightage += profile.irrigatedAcres * target?.irrigatedProfitTarget || 0
      })

      return irrigatedWeightage
        ? convertNumberTwoDecimal(irrigatedWeightage / totalIrrigatedAcres)
        : 0
    }

    if (type === 'TOTAL') {
      const totalAcres = totalDrylandAcres + totalIrrigatedAcres

      let totalWeightage = 0
      cropProfiles?.forEach((profile) => {
        const target = profitGoals?.getProfileProfitGoals?.find(
          (item) => item.profileId === profile.id
        )

        if (profile.isDryland)
          totalWeightage += profile.drylandAcres * target?.drylandProfitTarget || 0
        if (profile.isIrrigated)
          totalWeightage += profile.irrigatedAcres * target?.irrigatedProfitTarget || 0
      })

      return totalWeightage ? convertNumberTwoDecimal(totalWeightage / totalAcres) : 0
    }

    return 0
  }

  const getLivestockWeightedAvg = () => {
    let totalWeight = 0

    totalWeight = livestockProfiles
      ?.map((profile) => {
        if (profile.livestockKind === 'LIVE_CATTLE' || profile.livestockKind === 'FEEDER_CATTLE')
          return profile.outWeight
        return profile.bullsSaleWeight + profile.heiferSaleWeight
      })
      ?.reduce((acc, acres) => acc + acres)

    let totalWeightage = 0
    livestockProfiles?.forEach((profile) => {
      const target = profitGoals?.getProfileProfitGoals?.find(
        (item) => item.profileId === profile.id
      )

      if (profile.livestockKind === 'LIVE_CATTLE' || profile.livestockKind === 'FEEDER_CATTLE')
        totalWeightage += profile.outWeight * target?.livestockProfitTarget || 0

      if (profile.livestockKind === 'COW_CALF')
        totalWeightage +=
          (profile.bullsSaleWeight + profile.heiferSaleWeight) * target?.livestockProfitTarget || 0
    })

    return totalWeightage ? convertNumberTwoDecimal(totalWeightage / totalWeight) : 0
  }

  const renderTotal = (key, value, unit) => (
    <div className="flex flow-root">
      <div className="text-sm text-gray-400">{key}</div>
      <div>
        {value}&nbsp;
        <span className="text-sm text-gray-400">{unit}</span>
      </div>
    </div>
  )

  return (
    <>
      <Header>Goals</Header>
      {loading && <Loader message="Fetching data..." variant="fetchData" />}
      {error && <AlertBox message="Oops! Something went wrong while fetching data." />}
      {getProfileGoalsError && (
        <AlertBox message="Oops! Something went wrong while fetching data." />
      )}
      {cropProfiles?.length ? (
        <div className="mt-8 flex flex-col">
          <Title title="Crops" />
          <TableWrapper
            columns={cropProfileColumns}
            rows={cropProfiles
              .map((item) => [
                getName(item),
                item.isDryland && (
                  <ExpenseInput
                    prefix="$"
                    justify="end"
                    value={getProfitGoals(item, 'DRYLAND')}
                    onChange={(event) => handleProfitGoalChange(event, item.id, 'DRYLAND')}
                  />
                ),
                item.isIrrigated && (
                  <ExpenseInput
                    prefix="$"
                    justify="end"
                    value={getProfitGoals(item, 'IRRIGATED')}
                    onChange={(event) => handleProfitGoalChange(event, item.id, 'IRRIGATED')}
                  />
                ),
                renderPrice(item.breakeven),
                renderPrice(item.targetPrice)
              ])
              .concat([
                [
                  renderTotal(
                    'Total Weighted Avg',
                    renderMoney(getCropWeightedAvg('TOTAL')),
                    'per acre'
                  ),
                  renderTotal(
                    'Dryland Weighted Avg',
                    renderMoney(getCropWeightedAvg('DRYLAND')),
                    'per acre'
                  ),
                  renderTotal(
                    'Irrigated Weighted Avg',
                    renderMoney(getCropWeightedAvg('IRRIGATED')),
                    'per acre'
                  )
                ]
              ])}
          />
        </div>
      ) : null}
      {livestockProfiles?.length ? (
        <div className="mt-8 flex flex-col">
          <Title title="Livestock" />
          <TableWrapper
            columns={livestockProfileColumns}
            rows={livestockProfiles
              .map((item) => [
                getName(item),
                <ExpenseInput
                  prefix="$"
                  justify="end"
                  value={getProfitGoals(item)}
                  onChange={(event) => handleProfitGoalChange(event, item.id, 'LIVESTOCK')}
                />,
                renderPrice(item.breakeven),
                renderPrice(item.targetPrice)
              ])
              .concat([
                [
                  renderTotal(
                    'Total Weighted Avg',
                    renderMoney(getLivestockWeightedAvg()),
                    'per head'
                  ),
                  renderTotal('Weighted Avg', renderMoney(getLivestockWeightedAvg()), 'per head')
                ]
              ])}
          />
        </div>
      ) : null}
      {!cropProfiles?.length && !livestockProfiles?.length && (
        <EmptyState header="No commodities" message="Get started by adding commodity." disabled />
      )}
    </>
  )
}
