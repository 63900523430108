/* eslint-disable no-unsafe-optional-chaining */
import React from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'

import Header from '../../components/Header'
import ReportView from '../../components/views/report/ReportView'
import { useGetUser } from '../../services/queries/useGetUser'
import { useAuth } from '../../hooks/useAuth'
import { reportKind } from '../../constants/reportKind'
import roles from '../../constants/roles'

function ClientGeneratedMonthlyReportsPage() {
  const { id: clientId } = useParams()
  const { user } = useAuth() || {}
  const isClient = user?.kind === roles.CLIENT

  const { data, loading } = useGetUser(clientId, { skip: !clientId || (isClient && user) })
  const client = (isClient && user) || data?.getUser

  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>Client Generated 360 At A Glance</title>
      </Helmet>
      <Header>Client Generated 360 At A Glance</Header>
      <ReportView
        clientId={clientId}
        client={client}
        clientLoading={loading}
        onBack={() => navigate(-1)}
        kind={reportKind.Glance}
      />
    </>
  )
}

export default ClientGeneratedMonthlyReportsPage
