import React from 'react'
import { XIcon } from '@heroicons/react/solid'
import { toast, Toaster as ToastWrapper, ToastBar } from 'react-hot-toast'

export default function Toaster() {
  return (
    <ToastWrapper
      position="bottom-right"
      toastOptions={{
        duration: 1000,
        style: {
          backgroundColor: '#FbFbFb'
        },
        success: {
          style: {
            borderLeftWidth: 3,
            borderLeftColor: 'greenyellow'
          }
        },
        error: {
          style: {
            borderLeftWidth: 3,
            borderColor: 'red'
          }
        }
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              <div className="mx-1">{icon}</div>
              {t.type !== 'loading' && (
                <button
                  className="absolute top-2 right-2 h-4 w-4 border-none hover:cursor-pointer"
                  type="button"
                  onClick={() => toast.dismiss(t.id)}
                >
                  <XIcon />
                </button>
              )}
              <div className="my-4 mx-1">{message}</div>
            </>
          )}
        </ToastBar>
      )}
    </ToastWrapper>
  )
}
