import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import AlertBox from '../../../components/AlertBox'
import GET_INCOMES from '../../../gql/queries/getIncomes'
import GET_PRODUCTION_YEARS from '../../../gql/queries/getProductionYears'
import Header from '../../../components/Header'
import IncomeTable from '../../../components/tables/IncomeTable'
import Loader from '../../../components/Loader'
import PROFILES from '../../../gql/queries/Profiles'

export default function AdhocIncomePage() {
  const { id, productionYearId } = useParams()
  const name = Number(productionYearId)
  const [getProfileIncomesError, setGetProfileIncomesError] = useState('')

  const { data: productionYearDataItems } = useQuery(GET_PRODUCTION_YEARS, {
    variables: {
      clientId: id
    }
  })

  const productionYearData = productionYearDataItems?.getProductionYears?.find(
    (item) => item.name === Number(productionYearId)
  )

  const { data, error: getProfilesError } = useQuery(PROFILES, {
    variables: {
      clientId: id,
      productionYearId: name
    }
  })

  const {
    data: incomeData,
    error,
    loading
  } = useQuery(GET_INCOMES, {
    variables: {
      clientId: id
    }
  })

  const incomeItems = incomeData?.getIncomes?.map((item) => item)

  return (
    <>
      <Header>Ad-Hoc Income</Header>
      {loading && <Loader message="Fetching data..." variant="fetchData" />}
      {error && <AlertBox message="Oops! Something went wrong while fetching data." />}
      {getProfilesError && <AlertBox message="Oops! Something went wrong while fetching data." />}
      {getProfileIncomesError && (
        <AlertBox message="Oops! Something went wrong while fetching data." />
      )}
      {!loading && !error && incomeItems?.length && (
        <IncomeTable
          clientId={id}
          profiles={data?.profiles}
          incomes={incomeItems}
          productionYearId={productionYearData?.id}
          setGetProfileIncomesError={setGetProfileIncomesError}
        />
      )}
    </>
  )
}
