import { Dialog } from '@headlessui/react'
import React from 'react'

import BaseModal from '../modal/BaseModal'
import Button from '../buttons/Button'

function DeleteModal({ title, description, isOpen, onClose, onSubmit, children }) {
  return (
    <BaseModal isOpen={isOpen} closeModal={onClose}>
      <div className="sm:w-full sm:max-w-lg sm:p-4">
        <div>
          <div className="mt-3 text-center sm:mt-5">
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
              {title}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{description}</p>
            </div>
          </div>
          {children}
        </div>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <Button type="submit" color="neutral" label="No" onClick={onClose} />
          <Button
            type="button"
            color="danger"
            label="Yes"
            onClick={() => {
              onSubmit()
              onClose()
            }}
          />
        </div>
      </div>
    </BaseModal>
  )
}

export default DeleteModal
