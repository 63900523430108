import { gql } from '@apollo/client'

const GET_PRODUCTION_YEAR_INCOMES = gql`
  query getProductionYearIncomes($productionYearIds: [String!]!) {
    getProductionYearIncomes(productionYearIds: $productionYearIds) {
      id
      productionYearId
      incomeId
      income {
        clientId
        name
      }
      amount
    }
  }
`

export default GET_PRODUCTION_YEAR_INCOMES
