import classNames from 'classnames'
import React from 'react'

export default function SubTitle({ children, color = 'emerald' }) {
  return (
    <div
      className={classNames('mb-4 font-bold', {
        'text-emerald-500': color === 'emerald',
        'text-gray-900': color === 'gray'
      })}
    >
      {children}
    </div>
  )
}
