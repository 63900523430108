import { gql } from '@apollo/client'

const GET_PRODUCTION_YEARS = gql`
  query getProductionYears($clientId: String!) {
    getProductionYears(clientId: $clientId) {
      id
      name
      clientId
    }
  }
`

export default GET_PRODUCTION_YEARS
