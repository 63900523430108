import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import roles from '../../constants/roles'

import { CLIENT_ROUTES, USER_ROUTES } from '../../constants/routes'
import { useAuth } from '../../hooks/useAuth'

export default function RequireVisitor() {
  const auth = useAuth()

  if (!auth) {
    return <Outlet />
  }

  const userId = auth.user?.id

  return auth.user.kind === roles.SUPER_MANAGER || auth.user.kind === roles.MANAGER ? (
    <Navigate to={USER_ROUTES.HOME} />
  ) : (
    <Navigate to={`/clients/${userId}/${CLIENT_ROUTES.PROFILE}`} />
  )
}
