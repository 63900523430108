import { gql } from '@apollo/client'

const GET_CROPS = gql`
  query getCrops {
    getCrops {
      id
      name
      unit
      tradingMonths
      futuresName
    }
  }
`

export default GET_CROPS
