import { gql } from '@apollo/client'

import USER_FRAGMENT from '../fragments/user'

const SET_PASSWORD = gql`
  mutation setPassword($password: String!, $id: String) {
    setPassword(password: $password, id: $id) {
      ...User
    }
  }
  ${USER_FRAGMENT}
`

export default SET_PASSWORD
