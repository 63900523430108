import { gql } from '@apollo/client'

const GET_BUYERS = gql`
  query getBuyers($clientId: String!) {
    getBuyers(clientId: $clientId) {
      id
      name
      city
      state
    }
  }
`

export default GET_BUYERS
