/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useReducer, useEffect } from 'react'

import { filterReducer } from '../reducers/filterReducer'

const FilterContext = createContext()

const intialState = {
  allCashSalesData: [],
  filteredCashSalesData: [],
  filters: {
    buyer: 'any',
    commodity: 'any',
    type: 'any',
    futMonths: 'any',
    deliveryStartDate: 'any',
    deliveryEndDate: 'any',
    deliveredStatus: 'any',
    saleDate: 'any'
  }
}

function FilterContextProvider({ children }) {
  const [state, dispatch] = useReducer(filterReducer, intialState)

  // Update filter values
  const updateFilter = (event) => {
    const { value, name } = event.target
    return dispatch({ type: 'UPDATE_SELECTED_FILTER_VALUE', payload: { name, value } })
  }

  // Clear filter values
  const clearFilters = () => {
    dispatch({ type: 'CLEAR_FILTERS' })
  }

  useEffect(() => {
    dispatch({ type: 'UPDATE_FILTER_DATA' })
  }, [state.allCashSalesData, state.filters])

  return (
    <FilterContext.Provider value={{ ...state, dispatch, updateFilter, clearFilters }}>
      {children}
    </FilterContext.Provider>
  )
}

const useFilterContext = () => useContext(FilterContext)

export { useFilterContext, FilterContextProvider }
