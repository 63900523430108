import { gql } from '@apollo/client'

const UPDATE_PRODUCTION_YEAR = gql`
  mutation updateProductionYear($input: UpdateProductionYearInput!) {
    updateProductionYear(input: $input) {
      id
      name
      clientId
    }
  }
`

export default UPDATE_PRODUCTION_YEAR
