import React from 'react'

import Table from '../tables/Table'
import TableRow from '../tables/TableRow'
import TableDataCell from '../tables/TableDataCell'

function ReportTable({ title, columns = [], rows }) {
  return (
    <>
      {title && <h3 className="ml-1 mt-4 mb-2 text-lg font-bold text-gray-900">{title}</h3>}
      <Table columns={columns}>
        {rows.map((row, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow key={i}>
            {row.map((cell, j) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableDataCell type={j === 0 ? 'title' : 'value'} key={j}>
                {cell}
              </TableDataCell>
            ))}
          </TableRow>
        ))}
      </Table>
    </>
  )
}

export default ReportTable
