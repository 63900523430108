import dayjs from 'dayjs'
import React from 'react'

import ReportTitle from '../../reportPrimitives/ReportTitle'
import ReportTable from '../../reportPrimitives/ReportTable'
import { reportKind } from '../../../constants/reportKind'

const timezone = require('dayjs/plugin/timezone')
const advanced = require('dayjs/plugin/advancedFormat')

dayjs.extend(timezone)
dayjs.extend(advanced)

function SummaryView({ kind, variant, priceFetchedOn, client }) {
  return (
    <>
      <ReportTitle
        variant={variant}
        title={
          <>
            {kind === reportKind.Glance ? '360 AT A GLANCE FOR' : 'RISK MANAGEMENT REPORT FOR'} -{' '}
            {client?.fullName?.toUpperCase()}
          </>
        }
      />

      {kind !== reportKind.Glance && (
        <ReportTable
          variant={variant}
          rows={[
            ['Report Date', dayjs().format('MM/DD/YYYY hh:mma z')],
            ['Board prices as of', dayjs(priceFetchedOn).format('MM/DD/YYYY hh:mma z')]
          ]}
        />
      )}
    </>
  )
}

export default SummaryView
