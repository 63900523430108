import { gql } from '@apollo/client'

const SAVE_PRODUCTION_YEAR_INCOME = gql`
  mutation saveProductionYearIncome($input: ProductionYearIncomeInput!) {
    saveProductionYearIncome(input: $input) {
      id
      productionYearId
      incomeId
      amount
    }
  }
`

export default SAVE_PRODUCTION_YEAR_INCOME
