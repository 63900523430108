import { gql } from '@apollo/client'

const GET_ALL_PRODUCTION_YEARS = gql`
  query getAllProductionYears($adminId: String!) {
    getAllProductionYears(adminId: $adminId) {
      name
    }
  }
`

export default GET_ALL_PRODUCTION_YEARS
