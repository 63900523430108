import { gql } from '@apollo/client'

const GET_EXPENSES = gql`
  query getExpenses($clientId: String!) {
    getExpenses(clientId: $clientId) {
      id
      clientId
      name
      kind
      position
      grossExpenses {
        id
        productionYearId
        cost
      }
    }
  }
`

export default GET_EXPENSES
