import * as Sentry from '@sentry/react'
import { BrowserRouter } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { ApolloProvider } from '@apollo/client'
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist'
import { Helmet } from 'react-helmet'

import AppRouter from './components/global/AppRouter'
import Loader from './components/Loader'
import Toaster from './components/Toast/Toaster'
import client, { cache } from './client/client'
import { UserProvider } from './context/UserContext'

const SCHEMA_VERSION = '2.0'
const SCHEMA_VERSION_KEY = 'sunrise-schema-version'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV
})

const storage = new LocalStorageWrapper(localStorage)

const persistor = new CachePersistor({
  cache,
  storage,
  // 1024 * 1024 -> 1MB is default
  maxSize: 1024 * 1024 * 5
})

function ClientProvider({ children }) {
  const [apolloClient, setApolloClient] = useState(null)

  useEffect(() => {
    const schemaVersion = storage.getItem(SCHEMA_VERSION_KEY)

    if (schemaVersion === SCHEMA_VERSION) persistor.restore()
    else persistor.purge().then(() => storage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION))

    setApolloClient(client)

    return () => {}
  }, [])

  if (!apolloClient) {
    return <Loader variant="fetchData" />
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

function App() {
  return (
    <>
      <Helmet defaultTitle="Sunrise 360" titleTemplate="%s | Sunrise 360">
        <meta name="keywords" content="Agri-Tech, Farming, Consulting" />
      </Helmet>
      <ClientProvider>
        <UserProvider>
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
          <Toaster />
        </UserProvider>
      </ClientProvider>
    </>
  )
}

export default App
