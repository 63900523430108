import * as yup from 'yup'
import React from 'react'
import { Field, Form, Formik } from 'formik'
import { toast } from 'react-hot-toast'
import { useMutation } from '@apollo/client'

import BaseModal from '../modal/BaseModal'
import Button from '../buttons/Button'
import CREATE_INSURANCE_PRICING from '../../gql/mutations/createInsurancePricing'
import GET_INSURANCE_PRICINGS from '../../gql/queries/getInsurancePricings'
import SignedInput from '../SignedInput'
import UPDATE_INSURANCE_PRICING from '../../gql/mutations/updateInsurancePricing'
import years from '../../constants/years'

const validationSchema = yup.object({
  price: yup.number().required('Price is required'),
  cropId: yup.string().required('Crop is required'),
  productionYear: yup.number().required('Production year is required')
})

export default function AddUSDAPriceModal({ isOpen, closeModal, cropsData, mode = 'ADD', item }) {
  const initialValues = {
    cropId: cropsData.getCrops[0]?.id,
    productionYear: years[0],
    price: ''
  }

  const updatedInitialValues = {
    cropId: item?.crop?.id,
    productionYear: item?.productionYear,
    price: item?.price
  }

  const [createInsurancePricing] = useMutation(CREATE_INSURANCE_PRICING)
  const [updateInsurancePricing] = useMutation(UPDATE_INSURANCE_PRICING)

  const onSubmit = async (values) => {
    if (mode === 'ADD') {
      toast.promise(
        createInsurancePricing({
          variables: {
            input: {
              cropId: values?.cropId,
              price: values?.price,
              productionYear: parseInt(values?.productionYear, 10)
            }
          },
          onCompleted: () => closeModal(),
          refetchQueries: [GET_INSURANCE_PRICINGS]
        }),
        {
          loading: 'Adding the price...',
          success: 'Price created successfully.',
          error: 'Error while creating the price.'
        }
      )
    } else if (mode === 'EDIT') {
      toast.promise(
        updateInsurancePricing({
          variables: {
            input: {
              pricingId: item?.id,
              cropId: values?.cropId,
              price: values?.price,
              productionYear: parseInt(values?.productionYear, 10)
            }
          },
          onCompleted: () => closeModal(),
          refetchQueries: [GET_INSURANCE_PRICINGS]
        }),
        {
          loading: 'Updating the price...',
          success: 'Price updated successfully.',
          error: 'Error while updating the price.'
        }
      )
    }
  }

  return (
    <BaseModal isOpen={isOpen} closeModal={closeModal}>
      <div className="space-y-6">
        <Formik
          initialValues={mode === 'EDIT' ? updatedInitialValues : initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => onSubmit(values)}
        >
          {() => (
            <Form>
              <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      {`${mode === 'EDIT' ? 'Edit' : 'Add'}`} Price
                    </h3>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-12 sm:col-span-12">
                        <label htmlFor="cropId" className="block text-sm font-bold text-gray-700">
                          Commodity name
                        </label>
                        <Field
                          as="select"
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                          type="crop"
                          id="cropId"
                          name="cropId"
                        >
                          {cropsData.getCrops.map((item) => (
                            <option value={item?.id}>{item?.name}</option>
                          ))}
                        </Field>
                      </div>
                      <div className="col-span-12 sm:col-span-12">
                        <label
                          htmlFor="productionYear"
                          className="block text-sm font-bold text-gray-700"
                        >
                          Production Year
                        </label>
                        <Field
                          as="select"
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                          type="productionYear"
                          id="productionYear"
                          name="productionYear"
                        >
                          {years.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </Field>
                      </div>
                      <div className="col-span-12 sm:col-span-12">
                        <SignedInput label="Price" type="number" min="0" name="price" unit="$" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex justify-end gap-x-2">
                <Button type="button" color="neutral" label="Cancel" onClick={closeModal} />
                <Button type="submit" color="info" label="Save" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </BaseModal>
  )
}
