import classNames from 'classnames'
import React, { useCallback, useState } from 'react'
import { debounce } from 'lodash'
import { number } from 'yup'

const DELAY = 500

export default function ExpenseInput({ prefix, value, onChange, suffix, justify }) {
  const [valid, setValid] = useState(true)

  function handleBlur(event) {
    if (event.target.value === '') {
      debouncedSearch(null)
    }
  }

  function handleChange(event) {
    if (event.target.value === '') {
      debouncedSearch(null)
      return
    }

    const isValid = number().isValidSync(event.target.value)
    if (isValid) debouncedSearch(parseFloat(event.target.value))
    setValid(isValid)
  }

  const debouncedSearch = useCallback(
    debounce((value) => onChange(value), DELAY),
    []
  )

  return (
    <div className="flex flex-col">
      <div className={classNames('mt-1 flex flex-row', justify === 'end' ? 'justify-end' : '')}>
        {prefix && (
          <div className="flex h-8 w-fit items-center justify-center rounded rounded-r-none border border-gray-300 bg-gray-50 p-2">
            {prefix}
          </div>
        )}
        {!suffix ? (
          <input
            className="h-8 w-24 rounded rounded-l-none border border-gray-300 px-1 py-2 pr-1 text-left text-sm leading-none text-gray-500 outline-none"
            type="text"
            inputMode="numeric"
            pattern="\d*"
            min="0"
            defaultValue={value?.toFixed?.(2) || null}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        ) : (
          <>
            <input
              className="h-8 w-20 rounded rounded-l-none rounded-r-none border border-gray-300 px-1 py-2 pr-1 text-left text-sm leading-none text-gray-500 outline-none"
              type="text"
              inputMode="numeric"
              pattern="\d*"
              min="0"
              defaultValue={value?.toFixed?.(2) || null}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <div className="flex h-8 w-fit items-center justify-center rounded rounded-l-none border border-gray-300 bg-gray-50 p-2">
              {suffix}
            </div>
          </>
        )}
      </div>
      {!valid && <p className="mt-1 text-sm font-medium text-red-600">Enter a number</p>}
    </div>
  )
}
