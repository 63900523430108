import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import AlertBox from '../../../components/AlertBox'
import GET_EXPENSES from '../../../gql/queries/getExpenses'
import GET_PRODUCTION_YEARS from '../../../gql/queries/getProductionYears'
import Header from '../../../components/Header'
import Loader from '../../../components/Loader'
import PROFILES from '../../../gql/queries/Profiles'
import ScrollTable from '../../../components/tables/ScrollTable'

export default function GrossExpensesPage() {
  const [getProfileExpensesError, setGetProfileExpensesError] = useState('')
  const { id, productionYearId } = useParams()
  const name = Number(productionYearId)

  const { data: productionYearDataItems } = useQuery(GET_PRODUCTION_YEARS, {
    variables: {
      clientId: id
    }
  })

  const productionYearData = productionYearDataItems?.getProductionYears?.find(
    (item) => item.name === Number(productionYearId)
  )

  const { data, error: getProfilesError } = useQuery(PROFILES, {
    variables: {
      clientId: id,
      productionYearId: name
    }
  })
  const {
    data: expensesData,
    loading,
    error
  } = useQuery(GET_EXPENSES, {
    variables: {
      clientId: id
    }
  })
  const expenseItems = expensesData?.getExpenses?.filter((item) => item.kind === 'GROSS')

  const buttons = [
    { color: 'info', label: 'Manage expenses', title: 'Manage Custom Gross Expenses' }
  ]

  return (
    <>
      <Header>Gross Expenses</Header>
      {loading && <Loader message="Fetching data..." variant="fetchData" />}
      {error && <AlertBox message="Oops! Something went wrong while fetching data." />}
      {getProfilesError && <AlertBox message="Oops! Something went wrong while fetching data." />}
      {getProfileExpensesError && (
        <AlertBox message="Oops! Something went wrong while fetching data." />
      )}
      {!loading && !error && expenseItems?.length && (
        <ScrollTable
          buttons={buttons}
          profiles={data?.profiles}
          kind="GROSS"
          expenses={expenseItems}
          grossExpenses={expenseItems}
          setGetProfileExpensesError={setGetProfileExpensesError}
          productionYearId={productionYearData?.id}
          clientId={id}
        />
      )}
    </>
  )
}
