/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames'
import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'

export default function ScrollButton({ direction, onClick, disabled }) {
  const Icon = direction === 'left' ? ChevronLeftIcon : ChevronRightIcon
  return (
    <div
      role="button"
      className={classNames(
        'z-10 m-auto flex h-24 w-8 items-center justify-center rounded',
        direction === 'left' ? 'rounded-r-none' : 'rounded-l-none',
        'border border-gray-300 bg-gray-50',
        disabled ? 'cursor-not-allowed' : ''
      )}
      onClick={onClick}
      disabled={disabled}
      tabIndex={0}
    >
      <Icon className="h-6 w-6 text-gray-500" aria-hidden="true" />
    </div>
  )
}
