const counties = [
  {
    County: 'Autauga County',
    State: 'Alabama'
  },
  {
    County: 'Baldwin County',
    State: 'Alabama'
  },
  {
    County: 'Barbour County',
    State: 'Alabama'
  },
  {
    County: 'Bibb County',
    State: 'Alabama'
  },
  {
    County: 'Blount County',
    State: 'Alabama'
  },
  {
    County: 'Bullock County',
    State: 'Alabama'
  },
  {
    County: 'Butler County',
    State: 'Alabama'
  },
  {
    County: 'Calhoun County',
    State: 'Alabama'
  },
  {
    County: 'Chambers County',
    State: 'Alabama'
  },
  {
    County: 'Cherokee County',
    State: 'Alabama'
  },
  {
    County: 'Chilton County',
    State: 'Alabama'
  },
  {
    County: 'Choctaw County',
    State: 'Alabama'
  },
  {
    County: 'Clarke County',
    State: 'Alabama'
  },
  {
    County: 'Clay County',
    State: 'Alabama'
  },
  {
    County: 'Cleburne County',
    State: 'Alabama'
  },
  {
    County: 'Coffee County',
    State: 'Alabama'
  },
  {
    County: 'Colbert County',
    State: 'Alabama'
  },
  {
    County: 'Conecuh County',
    State: 'Alabama'
  },
  {
    County: 'Coosa County',
    State: 'Alabama'
  },
  {
    County: 'Covington County',
    State: 'Alabama'
  },
  {
    County: 'Crenshaw County',
    State: 'Alabama'
  },
  {
    County: 'Cullman County',
    State: 'Alabama'
  },
  {
    County: 'Dale County',
    State: 'Alabama'
  },
  {
    County: 'Dallas County',
    State: 'Alabama'
  },
  {
    County: 'DeKalb County',
    State: 'Alabama'
  },
  {
    County: 'Elmore County',
    State: 'Alabama'
  },
  {
    County: 'Escambia County',
    State: 'Alabama'
  },
  {
    County: 'Etowah County',
    State: 'Alabama'
  },
  {
    County: 'Fayette County',
    State: 'Alabama'
  },
  {
    County: 'Franklin County',
    State: 'Alabama'
  },
  {
    County: 'Geneva County',
    State: 'Alabama'
  },
  {
    County: 'Greene County',
    State: 'Alabama'
  },
  {
    County: 'Hale County',
    State: 'Alabama'
  },
  {
    County: 'Henry County',
    State: 'Alabama'
  },
  {
    County: 'Houston County',
    State: 'Alabama'
  },
  {
    County: 'Jackson County',
    State: 'Alabama'
  },
  {
    County: 'Jefferson County',
    State: 'Alabama'
  },
  {
    County: 'Lamar County',
    State: 'Alabama'
  },
  {
    County: 'Lauderdale County',
    State: 'Alabama'
  },
  {
    County: 'Lawrence County',
    State: 'Alabama'
  },
  {
    County: 'Lee County',
    State: 'Alabama'
  },
  {
    County: 'Limestone County',
    State: 'Alabama'
  },
  {
    County: 'Lowndes County',
    State: 'Alabama'
  },
  {
    County: 'Macon County',
    State: 'Alabama'
  },
  {
    County: 'Madison County',
    State: 'Alabama'
  },
  {
    County: 'Marengo County',
    State: 'Alabama'
  },
  {
    County: 'Marion County',
    State: 'Alabama'
  },
  {
    County: 'Marshall County',
    State: 'Alabama'
  },
  {
    County: 'Mobile County',
    State: 'Alabama'
  },
  {
    County: 'Monroe County',
    State: 'Alabama'
  },
  {
    County: 'Montgomery County',
    State: 'Alabama'
  },
  {
    County: 'Morgan County',
    State: 'Alabama'
  },
  {
    County: 'Perry County',
    State: 'Alabama'
  },
  {
    County: 'Pickens County',
    State: 'Alabama'
  },
  {
    County: 'Pike County',
    State: 'Alabama'
  },
  {
    County: 'Randolph County',
    State: 'Alabama'
  },
  {
    County: 'Russell County',
    State: 'Alabama'
  },
  {
    County: 'St. Clair County',
    State: 'Alabama'
  },
  {
    County: 'Shelby County',
    State: 'Alabama'
  },
  {
    County: 'Sumter County',
    State: 'Alabama'
  },
  {
    County: 'Talladega County',
    State: 'Alabama'
  },
  {
    County: 'Tallapoosa County',
    State: 'Alabama'
  },
  {
    County: 'Tuscaloosa County',
    State: 'Alabama'
  },
  {
    County: 'Walker County',
    State: 'Alabama'
  },
  {
    County: 'Washington County',
    State: 'Alabama'
  },
  {
    County: 'Wilcox County',
    State: 'Alabama'
  },
  {
    County: 'Winston County',
    State: 'Alabama'
  },
  {
    County: 'Aleutians East Borough',
    State: 'Alaska'
  },
  {
    County: 'Aleutians West Census Area',
    State: 'Alaska'
  },
  {
    County: 'Anchorage, Municipality of',
    State: 'Alaska'
  },
  {
    County: 'Bethel Census Area',
    State: 'Alaska'
  },
  {
    County: 'Bristol Bay Borough',
    State: 'Alaska'
  },
  {
    County: 'Denali Borough',
    State: 'Alaska'
  },
  {
    County: 'Dillingham Census Area',
    State: 'Alaska'
  },
  {
    County: 'Fairbanks North Star Borough',
    State: 'Alaska'
  },
  {
    County: 'Haines Borough',
    State: 'Alaska'
  },
  {
    County: 'Hoonah-Angoon Census Area',
    State: 'Alaska'
  },
  {
    County: 'Juneau, City and Borough',
    State: 'Alaska'
  },
  {
    County: 'Kenai Peninsula Borough',
    State: 'Alaska'
  },
  {
    County: 'Ketchikan Gateway Borough',
    State: 'Alaska'
  },
  {
    County: 'Kodiak Island Borough',
    State: 'Alaska'
  },
  {
    County: 'Lake and Peninsula Borough',
    State: 'Alaska'
  },
  {
    County: 'Matanuska-Susitna Borough',
    State: 'Alaska'
  },
  {
    County: 'Nome Census Area',
    State: 'Alaska'
  },
  {
    County: 'North Slope Borough',
    State: 'Alaska'
  },
  {
    County: 'Northwest Arctic Borough',
    State: 'Alaska'
  },
  {
    County: 'Petersburg Census Area',
    State: 'Alaska'
  },
  {
    County: 'Prince of Wales-Hyder Census Area',
    State: 'Alaska'
  },
  {
    County: 'Sitka, City and Borough of',
    State: 'Alaska'
  },
  {
    County: 'Skagway Borough, Municipality of',
    State: 'Alaska'
  },
  {
    County: 'Southeast Fairbanks Census Area',
    State: 'Alaska'
  },
  {
    County: 'Valdez-Cordova Census Area',
    State: 'Alaska'
  },
  {
    County: 'Wade Hampton Census Area',
    State: 'Alaska'
  },
  {
    County: 'Wrangell, City and Borough of',
    State: 'Alaska'
  },
  {
    County: 'Yakutat, City and Borough of',
    State: 'Alaska'
  },
  {
    County: 'Yukon-Koyukuk Census Area',
    State: 'Alaska'
  },
  {
    County: 'Apache County',
    State: 'Arizona'
  },
  {
    County: 'Cochise County',
    State: 'Arizona'
  },
  {
    County: 'Coconino County',
    State: 'Arizona'
  },
  {
    County: 'Gila County',
    State: 'Arizona'
  },
  {
    County: 'Graham County',
    State: 'Arizona'
  },
  {
    County: 'Greenlee County',
    State: 'Arizona'
  },
  {
    County: 'La Paz County',
    State: 'Arizona'
  },
  {
    County: 'Maricopa County',
    State: 'Arizona'
  },
  {
    County: 'Mohave County',
    State: 'Arizona'
  },
  {
    County: 'Navajo County',
    State: 'Arizona'
  },
  {
    County: 'Pima County',
    State: 'Arizona'
  },
  {
    County: 'Pinal County',
    State: 'Arizona'
  },
  {
    County: 'Santa Cruz County',
    State: 'Arizona'
  },
  {
    County: 'Yavapai County',
    State: 'Arizona'
  },
  {
    County: 'Yuma County',
    State: 'Arizona'
  },
  {
    County: 'Arkansas County',
    State: 'Arkansas'
  },
  {
    County: 'Ashley County',
    State: 'Arkansas'
  },
  {
    County: 'Baxter County',
    State: 'Arkansas'
  },
  {
    County: 'Benton County',
    State: 'Arkansas'
  },
  {
    County: 'Boone County',
    State: 'Arkansas'
  },
  {
    County: 'Bradley County',
    State: 'Arkansas'
  },
  {
    County: 'Calhoun County',
    State: 'Arkansas'
  },
  {
    County: 'Carroll County',
    State: 'Arkansas'
  },
  {
    County: 'Chicot County',
    State: 'Arkansas'
  },
  {
    County: 'Clark County',
    State: 'Arkansas'
  },
  {
    County: 'Clay County',
    State: 'Arkansas'
  },
  {
    County: 'Cleburne County',
    State: 'Arkansas'
  },
  {
    County: 'Cleveland County',
    State: 'Arkansas'
  },
  {
    County: 'Columbia County',
    State: 'Arkansas'
  },
  {
    County: 'Conway County',
    State: 'Arkansas'
  },
  {
    County: 'Craighead County',
    State: 'Arkansas'
  },
  {
    County: 'Crawford County',
    State: 'Arkansas'
  },
  {
    County: 'Crittenden County',
    State: 'Arkansas'
  },
  {
    County: 'Cross County',
    State: 'Arkansas'
  },
  {
    County: 'Dallas County',
    State: 'Arkansas'
  },
  {
    County: 'Desha County',
    State: 'Arkansas'
  },
  {
    County: 'Drew County',
    State: 'Arkansas'
  },
  {
    County: 'Faulkner County',
    State: 'Arkansas'
  },
  {
    County: 'Franklin County',
    State: 'Arkansas'
  },
  {
    County: 'Fulton County',
    State: 'Arkansas'
  },
  {
    County: 'Garland County',
    State: 'Arkansas'
  },
  {
    County: 'Grant County',
    State: 'Arkansas'
  },
  {
    County: 'Greene County',
    State: 'Arkansas'
  },
  {
    County: 'Hempstead County',
    State: 'Arkansas'
  },
  {
    County: 'Hot Spring County',
    State: 'Arkansas'
  },
  {
    County: 'Howard County',
    State: 'Arkansas'
  },
  {
    County: 'Independence County',
    State: 'Arkansas'
  },
  {
    County: 'Izard County',
    State: 'Arkansas'
  },
  {
    County: 'Jackson County',
    State: 'Arkansas'
  },
  {
    County: 'Jefferson County',
    State: 'Arkansas'
  },
  {
    County: 'Johnson County',
    State: 'Arkansas'
  },
  {
    County: 'Lafayette County',
    State: 'Arkansas'
  },
  {
    County: 'Lawrence County',
    State: 'Arkansas'
  },
  {
    County: 'Lee County',
    State: 'Arkansas'
  },
  {
    County: 'Lincoln County',
    State: 'Arkansas'
  },
  {
    County: 'Little River County',
    State: 'Arkansas'
  },
  {
    County: 'Logan County',
    State: 'Arkansas'
  },
  {
    County: 'Lonoke County',
    State: 'Arkansas'
  },
  {
    County: 'Madison County',
    State: 'Arkansas'
  },
  {
    County: 'Marion County',
    State: 'Arkansas'
  },
  {
    County: 'Miller County',
    State: 'Arkansas'
  },
  {
    County: 'Mississippi County',
    State: 'Arkansas'
  },
  {
    County: 'Monroe County',
    State: 'Arkansas'
  },
  {
    County: 'Montgomery County',
    State: 'Arkansas'
  },
  {
    County: 'Nevada County',
    State: 'Arkansas'
  },
  {
    County: 'Newton County',
    State: 'Arkansas'
  },
  {
    County: 'Ouachita County',
    State: 'Arkansas'
  },
  {
    County: 'Perry County',
    State: 'Arkansas'
  },
  {
    County: 'Phillips County',
    State: 'Arkansas'
  },
  {
    County: 'Pike County',
    State: 'Arkansas'
  },
  {
    County: 'Poinsett County',
    State: 'Arkansas'
  },
  {
    County: 'Polk County',
    State: 'Arkansas'
  },
  {
    County: 'Pope County',
    State: 'Arkansas'
  },
  {
    County: 'Prairie County',
    State: 'Arkansas'
  },
  {
    County: 'Pulaski County',
    State: 'Arkansas'
  },
  {
    County: 'Randolph County',
    State: 'Arkansas'
  },
  {
    County: 'St. Francis County',
    State: 'Arkansas'
  },
  {
    County: 'Saline County',
    State: 'Arkansas'
  },
  {
    County: 'Scott County',
    State: 'Arkansas'
  },
  {
    County: 'Searcy County',
    State: 'Arkansas'
  },
  {
    County: 'Sebastian County',
    State: 'Arkansas'
  },
  {
    County: 'Sevier County',
    State: 'Arkansas'
  },
  {
    County: 'Sharp County',
    State: 'Arkansas'
  },
  {
    County: 'Stone County',
    State: 'Arkansas'
  },
  {
    County: 'Union County',
    State: 'Arkansas'
  },
  {
    County: 'Van Buren County',
    State: 'Arkansas'
  },
  {
    County: 'Washington County',
    State: 'Arkansas'
  },
  {
    County: 'White County',
    State: 'Arkansas'
  },
  {
    County: 'Woodruff County',
    State: 'Arkansas'
  },
  {
    County: 'Yell County',
    State: 'Arkansas'
  },
  {
    County: 'Alameda County',
    State: 'California'
  },
  {
    County: 'Alpine County',
    State: 'California'
  },
  {
    County: 'Amador County',
    State: 'California'
  },
  {
    County: 'Butte County',
    State: 'California'
  },
  {
    County: 'Calaveras County',
    State: 'California'
  },
  {
    County: 'Colusa County',
    State: 'California'
  },
  {
    County: 'Contra Costa County',
    State: 'California'
  },
  {
    County: 'Del Norte County',
    State: 'California'
  },
  {
    County: 'El Dorado County',
    State: 'California'
  },
  {
    County: 'Fresno County',
    State: 'California'
  },
  {
    County: 'Glenn County',
    State: 'California'
  },
  {
    County: 'Humboldt County',
    State: 'California'
  },
  {
    County: 'Imperial County',
    State: 'California'
  },
  {
    County: 'Inyo County',
    State: 'California'
  },
  {
    County: 'Kern County',
    State: 'California'
  },
  {
    County: 'Kings County',
    State: 'California'
  },
  {
    County: 'Lake County',
    State: 'California'
  },
  {
    County: 'Lassen County',
    State: 'California'
  },
  {
    County: 'Los Angeles County',
    State: 'California'
  },
  {
    County: 'Madera County',
    State: 'California'
  },
  {
    County: 'Marin County',
    State: 'California'
  },
  {
    County: 'Mariposa County',
    State: 'California'
  },
  {
    County: 'Mendocino County',
    State: 'California'
  },
  {
    County: 'Merced County',
    State: 'California'
  },
  {
    County: 'Modoc County',
    State: 'California'
  },
  {
    County: 'Mono County',
    State: 'California'
  },
  {
    County: 'Monterey County',
    State: 'California'
  },
  {
    County: 'Napa County',
    State: 'California'
  },
  {
    County: 'Nevada County',
    State: 'California'
  },
  {
    County: 'Orange County',
    State: 'California'
  },
  {
    County: 'Placer County',
    State: 'California'
  },
  {
    County: 'Plumas County',
    State: 'California'
  },
  {
    County: 'Riverside County',
    State: 'California'
  },
  {
    County: 'Sacramento County',
    State: 'California'
  },
  {
    County: 'San Benito County',
    State: 'California'
  },
  {
    County: 'San Bernardino County',
    State: 'California'
  },
  {
    County: 'San Diego County',
    State: 'California'
  },
  {
    County: 'San Francisco, City and County of',
    State: 'California'
  },
  {
    County: 'San Joaquin County',
    State: 'California'
  },
  {
    County: 'San Luis Obispo County',
    State: 'California'
  },
  {
    County: 'San Mateo County',
    State: 'California'
  },
  {
    County: 'Santa Barbara County',
    State: 'California'
  },
  {
    County: 'Santa Clara County',
    State: 'California'
  },
  {
    County: 'Santa Cruz County',
    State: 'California'
  },
  {
    County: 'Shasta County',
    State: 'California'
  },
  {
    County: 'Sierra County',
    State: 'California'
  },
  {
    County: 'Siskiyou County',
    State: 'California'
  },
  {
    County: 'Solano County',
    State: 'California'
  },
  {
    County: 'Sonoma County',
    State: 'California'
  },
  {
    County: 'Stanislaus County',
    State: 'California'
  },
  {
    County: 'Sutter County',
    State: 'California'
  },
  {
    County: 'Tehama County',
    State: 'California'
  },
  {
    County: 'Trinity County',
    State: 'California'
  },
  {
    County: 'Tulare County',
    State: 'California'
  },
  {
    County: 'Tuolumne County',
    State: 'California'
  },
  {
    County: 'Ventura County',
    State: 'California'
  },
  {
    County: 'Yolo County',
    State: 'California'
  },
  {
    County: 'Yuba County',
    State: 'California'
  },
  {
    County: 'Adams County',
    State: 'Colorado'
  },
  {
    County: 'Alamosa County',
    State: 'Colorado'
  },
  {
    County: 'Arapahoe County',
    State: 'Colorado'
  },
  {
    County: 'Archuleta County',
    State: 'Colorado'
  },
  {
    County: 'Baca County',
    State: 'Colorado'
  },
  {
    County: 'Bent County',
    State: 'Colorado'
  },
  {
    County: 'Boulder County',
    State: 'Colorado'
  },
  {
    County: 'Broomfield, City and County of',
    State: 'Colorado'
  },
  {
    County: 'Chaffee County',
    State: 'Colorado'
  },
  {
    County: 'Cheyenne County',
    State: 'Colorado'
  },
  {
    County: 'Clear Creek County',
    State: 'Colorado'
  },
  {
    County: 'Conejos County',
    State: 'Colorado'
  },
  {
    County: 'Costilla County',
    State: 'Colorado'
  },
  {
    County: 'Crowley County',
    State: 'Colorado'
  },
  {
    County: 'Custer County',
    State: 'Colorado'
  },
  {
    County: 'Delta County',
    State: 'Colorado'
  },
  {
    County: 'Denver, City and County of',
    State: 'Colorado'
  },
  {
    County: 'Dolores County',
    State: 'Colorado'
  },
  {
    County: 'Douglas County',
    State: 'Colorado'
  },
  {
    County: 'Eagle County',
    State: 'Colorado'
  },
  {
    County: 'Elbert County',
    State: 'Colorado'
  },
  {
    County: 'El Paso County',
    State: 'Colorado'
  },
  {
    County: 'Fremont County',
    State: 'Colorado'
  },
  {
    County: 'Garfield County',
    State: 'Colorado'
  },
  {
    County: 'Gilpin County',
    State: 'Colorado'
  },
  {
    County: 'Grand County',
    State: 'Colorado'
  },
  {
    County: 'Gunnison County',
    State: 'Colorado'
  },
  {
    County: 'Hinsdale County',
    State: 'Colorado'
  },
  {
    County: 'Huerfano County',
    State: 'Colorado'
  },
  {
    County: 'Jackson County',
    State: 'Colorado'
  },
  {
    County: 'Jefferson County',
    State: 'Colorado'
  },
  {
    County: 'Kiowa County',
    State: 'Colorado'
  },
  {
    County: 'Kit Carson County',
    State: 'Colorado'
  },
  {
    County: 'Lake County',
    State: 'Colorado'
  },
  {
    County: 'La Plata County',
    State: 'Colorado'
  },
  {
    County: 'Larimer County',
    State: 'Colorado'
  },
  {
    County: 'Las Animas County',
    State: 'Colorado'
  },
  {
    County: 'Lincoln County',
    State: 'Colorado'
  },
  {
    County: 'Logan County',
    State: 'Colorado'
  },
  {
    County: 'Mesa County',
    State: 'Colorado'
  },
  {
    County: 'Mineral County',
    State: 'Colorado'
  },
  {
    County: 'Moffat County',
    State: 'Colorado'
  },
  {
    County: 'Montezuma County',
    State: 'Colorado'
  },
  {
    County: 'Montrose County',
    State: 'Colorado'
  },
  {
    County: 'Morgan County',
    State: 'Colorado'
  },
  {
    County: 'Otero County',
    State: 'Colorado'
  },
  {
    County: 'Ouray County',
    State: 'Colorado'
  },
  {
    County: 'Park County',
    State: 'Colorado'
  },
  {
    County: 'Phillips County',
    State: 'Colorado'
  },
  {
    County: 'Pitkin County',
    State: 'Colorado'
  },
  {
    County: 'Prowers County',
    State: 'Colorado'
  },
  {
    County: 'Pueblo County',
    State: 'Colorado'
  },
  {
    County: 'Rio Blanco County',
    State: 'Colorado'
  },
  {
    County: 'Rio Grande County',
    State: 'Colorado'
  },
  {
    County: 'Routt County',
    State: 'Colorado'
  },
  {
    County: 'Saguache County',
    State: 'Colorado'
  },
  {
    County: 'San Juan County',
    State: 'Colorado'
  },
  {
    County: 'San Miguel County',
    State: 'Colorado'
  },
  {
    County: 'Sedgwick County',
    State: 'Colorado'
  },
  {
    County: 'Summit County',
    State: 'Colorado'
  },
  {
    County: 'Teller County',
    State: 'Colorado'
  },
  {
    County: 'Washington County',
    State: 'Colorado'
  },
  {
    County: 'Weld County',
    State: 'Colorado'
  },
  {
    County: 'Yuma County',
    State: 'Colorado'
  },
  {
    County: 'Fairfield County',
    State: 'Connecticut'
  },
  {
    County: 'Hartford County',
    State: 'Connecticut'
  },
  {
    County: 'Litchfield County',
    State: 'Connecticut'
  },
  {
    County: 'Middlesex County',
    State: 'Connecticut'
  },
  {
    County: 'New Haven County',
    State: 'Connecticut'
  },
  {
    County: 'New London County',
    State: 'Connecticut'
  },
  {
    County: 'Tolland County',
    State: 'Connecticut'
  },
  {
    County: 'Windham County',
    State: 'Connecticut'
  },
  {
    County: 'Kent County',
    State: 'Delaware'
  },
  {
    County: 'New Castle County',
    State: 'Delaware'
  },
  {
    County: 'Sussex County',
    State: 'Delaware'
  },
  {
    County: 'District of Columbia',
    State: 'District of Columbia'
  },
  {
    County: 'Alachua County',
    State: 'Florida'
  },
  {
    County: 'Baker County',
    State: 'Florida'
  },
  {
    County: 'Bay County',
    State: 'Florida'
  },
  {
    County: 'Bradford County',
    State: 'Florida'
  },
  {
    County: 'Brevard County',
    State: 'Florida'
  },
  {
    County: 'Broward County',
    State: 'Florida'
  },
  {
    County: 'Calhoun County',
    State: 'Florida'
  },
  {
    County: 'Charlotte County',
    State: 'Florida'
  },
  {
    County: 'Citrus County',
    State: 'Florida'
  },
  {
    County: 'Clay County',
    State: 'Florida'
  },
  {
    County: 'Collier County',
    State: 'Florida'
  },
  {
    County: 'Columbia County',
    State: 'Florida'
  },
  {
    County: 'DeSoto County',
    State: 'Florida'
  },
  {
    County: 'Dixie County',
    State: 'Florida'
  },
  {
    County: 'Duval County',
    State: 'Florida'
  },
  {
    County: 'Escambia County',
    State: 'Florida'
  },
  {
    County: 'Flagler County',
    State: 'Florida'
  },
  {
    County: 'Franklin County',
    State: 'Florida'
  },
  {
    County: 'Gadsden County',
    State: 'Florida'
  },
  {
    County: 'Gilchrist County',
    State: 'Florida'
  },
  {
    County: 'Glades County',
    State: 'Florida'
  },
  {
    County: 'Gulf County',
    State: 'Florida'
  },
  {
    County: 'Hamilton County',
    State: 'Florida'
  },
  {
    County: 'Hardee County',
    State: 'Florida'
  },
  {
    County: 'Hendry County',
    State: 'Florida'
  },
  {
    County: 'Hernando County',
    State: 'Florida'
  },
  {
    County: 'Highlands County',
    State: 'Florida'
  },
  {
    County: 'Hillsborough County',
    State: 'Florida'
  },
  {
    County: 'Holmes County',
    State: 'Florida'
  },
  {
    County: 'Indian River County',
    State: 'Florida'
  },
  {
    County: 'Jackson County',
    State: 'Florida'
  },
  {
    County: 'Jefferson County',
    State: 'Florida'
  },
  {
    County: 'Lafayette County',
    State: 'Florida'
  },
  {
    County: 'Lake County',
    State: 'Florida'
  },
  {
    County: 'Lee County',
    State: 'Florida'
  },
  {
    County: 'Leon County',
    State: 'Florida'
  },
  {
    County: 'Levy County',
    State: 'Florida'
  },
  {
    County: 'Liberty County',
    State: 'Florida'
  },
  {
    County: 'Madison County',
    State: 'Florida'
  },
  {
    County: 'Manatee County',
    State: 'Florida'
  },
  {
    County: 'Marion County',
    State: 'Florida'
  },
  {
    County: 'Martin County',
    State: 'Florida'
  },
  {
    County: 'Miami-Dade County',
    State: 'Florida'
  },
  {
    County: 'Monroe County',
    State: 'Florida'
  },
  {
    County: 'Nassau County',
    State: 'Florida'
  },
  {
    County: 'Okaloosa County',
    State: 'Florida'
  },
  {
    County: 'Okeechobee County',
    State: 'Florida'
  },
  {
    County: 'Orange County',
    State: 'Florida'
  },
  {
    County: 'Osceola County',
    State: 'Florida'
  },
  {
    County: 'Palm Beach County',
    State: 'Florida'
  },
  {
    County: 'Pasco County',
    State: 'Florida'
  },
  {
    County: 'Pinellas County',
    State: 'Florida'
  },
  {
    County: 'Polk County',
    State: 'Florida'
  },
  {
    County: 'Putnam County',
    State: 'Florida'
  },
  {
    County: 'St. Johns County',
    State: 'Florida'
  },
  {
    County: 'St. Lucie County',
    State: 'Florida'
  },
  {
    County: 'Santa Rosa County',
    State: 'Florida'
  },
  {
    County: 'Sarasota County',
    State: 'Florida'
  },
  {
    County: 'Seminole County',
    State: 'Florida'
  },
  {
    County: 'Sumter County',
    State: 'Florida'
  },
  {
    County: 'Suwannee County',
    State: 'Florida'
  },
  {
    County: 'Taylor County',
    State: 'Florida'
  },
  {
    County: 'Union County',
    State: 'Florida'
  },
  {
    County: 'Volusia County',
    State: 'Florida'
  },
  {
    County: 'Wakulla County',
    State: 'Florida'
  },
  {
    County: 'Walton County',
    State: 'Florida'
  },
  {
    County: 'Washington County',
    State: 'Florida'
  },
  {
    County: 'Appling County',
    State: 'Georgia'
  },
  {
    County: 'Atkinson County',
    State: 'Georgia'
  },
  {
    County: 'Bacon County',
    State: 'Georgia'
  },
  {
    County: 'Baker County',
    State: 'Georgia'
  },
  {
    County: 'Baldwin County',
    State: 'Georgia'
  },
  {
    County: 'Banks County',
    State: 'Georgia'
  },
  {
    County: 'Barrow County',
    State: 'Georgia'
  },
  {
    County: 'Bartow County',
    State: 'Georgia'
  },
  {
    County: 'Ben Hill County',
    State: 'Georgia'
  },
  {
    County: 'Berrien County',
    State: 'Georgia'
  },
  {
    County: 'Bibb County',
    State: 'Georgia'
  },
  {
    County: 'Bleckley County',
    State: 'Georgia'
  },
  {
    County: 'Brantley County',
    State: 'Georgia'
  },
  {
    County: 'Brooks County',
    State: 'Georgia'
  },
  {
    County: 'Bryan County',
    State: 'Georgia'
  },
  {
    County: 'Bulloch County',
    State: 'Georgia'
  },
  {
    County: 'Burke County',
    State: 'Georgia'
  },
  {
    County: 'Butts County',
    State: 'Georgia'
  },
  {
    County: 'Calhoun County',
    State: 'Georgia'
  },
  {
    County: 'Camden County',
    State: 'Georgia'
  },
  {
    County: 'Candler County',
    State: 'Georgia'
  },
  {
    County: 'Carroll County',
    State: 'Georgia'
  },
  {
    County: 'Catoosa County',
    State: 'Georgia'
  },
  {
    County: 'Charlton County',
    State: 'Georgia'
  },
  {
    County: 'Chatham County',
    State: 'Georgia'
  },
  {
    County: 'Chattahoochee County',
    State: 'Georgia'
  },
  {
    County: 'Chattooga County',
    State: 'Georgia'
  },
  {
    County: 'Cherokee County',
    State: 'Georgia'
  },
  {
    County: 'Clarke County',
    State: 'Georgia'
  },
  {
    County: 'Clay County',
    State: 'Georgia'
  },
  {
    County: 'Clayton County',
    State: 'Georgia'
  },
  {
    County: 'Clinch County',
    State: 'Georgia'
  },
  {
    County: 'Cobb County',
    State: 'Georgia'
  },
  {
    County: 'Coffee County',
    State: 'Georgia'
  },
  {
    County: 'Colquitt County',
    State: 'Georgia'
  },
  {
    County: 'Columbia County',
    State: 'Georgia'
  },
  {
    County: 'Cook County',
    State: 'Georgia'
  },
  {
    County: 'Coweta County',
    State: 'Georgia'
  },
  {
    County: 'Crawford County',
    State: 'Georgia'
  },
  {
    County: 'Crisp County',
    State: 'Georgia'
  },
  {
    County: 'Dade County',
    State: 'Georgia'
  },
  {
    County: 'Dawson County',
    State: 'Georgia'
  },
  {
    County: 'Decatur County',
    State: 'Georgia'
  },
  {
    County: 'DeKalb County',
    State: 'Georgia'
  },
  {
    County: 'Dodge County',
    State: 'Georgia'
  },
  {
    County: 'Dooly County',
    State: 'Georgia'
  },
  {
    County: 'Dougherty County',
    State: 'Georgia'
  },
  {
    County: 'Douglas County',
    State: 'Georgia'
  },
  {
    County: 'Early County',
    State: 'Georgia'
  },
  {
    County: 'Echols County',
    State: 'Georgia'
  },
  {
    County: 'Effingham County',
    State: 'Georgia'
  },
  {
    County: 'Elbert County',
    State: 'Georgia'
  },
  {
    County: 'Emanuel County',
    State: 'Georgia'
  },
  {
    County: 'Evans County',
    State: 'Georgia'
  },
  {
    County: 'Fannin County',
    State: 'Georgia'
  },
  {
    County: 'Fayette County',
    State: 'Georgia'
  },
  {
    County: 'Floyd County',
    State: 'Georgia'
  },
  {
    County: 'Forsyth County',
    State: 'Georgia'
  },
  {
    County: 'Franklin County',
    State: 'Georgia'
  },
  {
    County: 'Fulton County',
    State: 'Georgia'
  },
  {
    County: 'Gilmer County',
    State: 'Georgia'
  },
  {
    County: 'Glascock County',
    State: 'Georgia'
  },
  {
    County: 'Glynn County',
    State: 'Georgia'
  },
  {
    County: 'Gordon County',
    State: 'Georgia'
  },
  {
    County: 'Grady County',
    State: 'Georgia'
  },
  {
    County: 'Greene County',
    State: 'Georgia'
  },
  {
    County: 'Gwinnett County',
    State: 'Georgia'
  },
  {
    County: 'Habersham County',
    State: 'Georgia'
  },
  {
    County: 'Hall County',
    State: 'Georgia'
  },
  {
    County: 'Hancock County',
    State: 'Georgia'
  },
  {
    County: 'Haralson County',
    State: 'Georgia'
  },
  {
    County: 'Harris County',
    State: 'Georgia'
  },
  {
    County: 'Hart County',
    State: 'Georgia'
  },
  {
    County: 'Heard County',
    State: 'Georgia'
  },
  {
    County: 'Henry County',
    State: 'Georgia'
  },
  {
    County: 'Houston County',
    State: 'Georgia'
  },
  {
    County: 'Irwin County',
    State: 'Georgia'
  },
  {
    County: 'Jackson County',
    State: 'Georgia'
  },
  {
    County: 'Jasper County',
    State: 'Georgia'
  },
  {
    County: 'Jeff Davis County',
    State: 'Georgia'
  },
  {
    County: 'Jefferson County',
    State: 'Georgia'
  },
  {
    County: 'Jenkins County',
    State: 'Georgia'
  },
  {
    County: 'Johnson County',
    State: 'Georgia'
  },
  {
    County: 'Jones County',
    State: 'Georgia'
  },
  {
    County: 'Lamar County',
    State: 'Georgia'
  },
  {
    County: 'Lanier County',
    State: 'Georgia'
  },
  {
    County: 'Laurens County',
    State: 'Georgia'
  },
  {
    County: 'Lee County',
    State: 'Georgia'
  },
  {
    County: 'Liberty County',
    State: 'Georgia'
  },
  {
    County: 'Lincoln County',
    State: 'Georgia'
  },
  {
    County: 'Long County',
    State: 'Georgia'
  },
  {
    County: 'Lowndes County',
    State: 'Georgia'
  },
  {
    County: 'Lumpkin County',
    State: 'Georgia'
  },
  {
    County: 'McDuffie County',
    State: 'Georgia'
  },
  {
    County: 'McIntosh County',
    State: 'Georgia'
  },
  {
    County: 'Macon County',
    State: 'Georgia'
  },
  {
    County: 'Madison County',
    State: 'Georgia'
  },
  {
    County: 'Marion County',
    State: 'Georgia'
  },
  {
    County: 'Meriwether County',
    State: 'Georgia'
  },
  {
    County: 'Miller County',
    State: 'Georgia'
  },
  {
    County: 'Mitchell County',
    State: 'Georgia'
  },
  {
    County: 'Monroe County',
    State: 'Georgia'
  },
  {
    County: 'Montgomery County',
    State: 'Georgia'
  },
  {
    County: 'Morgan County',
    State: 'Georgia'
  },
  {
    County: 'Murray County',
    State: 'Georgia'
  },
  {
    County: 'Muscogee County',
    State: 'Georgia'
  },
  {
    County: 'Newton County',
    State: 'Georgia'
  },
  {
    County: 'Oconee County',
    State: 'Georgia'
  },
  {
    County: 'Oglethorpe County',
    State: 'Georgia'
  },
  {
    County: 'Paulding County',
    State: 'Georgia'
  },
  {
    County: 'Peach County',
    State: 'Georgia'
  },
  {
    County: 'Pickens County',
    State: 'Georgia'
  },
  {
    County: 'Pierce County',
    State: 'Georgia'
  },
  {
    County: 'Pike County',
    State: 'Georgia'
  },
  {
    County: 'Polk County',
    State: 'Georgia'
  },
  {
    County: 'Pulaski County',
    State: 'Georgia'
  },
  {
    County: 'Putnam County',
    State: 'Georgia'
  },
  {
    County: 'Quitman County',
    State: 'Georgia'
  },
  {
    County: 'Rabun County',
    State: 'Georgia'
  },
  {
    County: 'Randolph County',
    State: 'Georgia'
  },
  {
    County: 'Richmond County',
    State: 'Georgia'
  },
  {
    County: 'Rockdale County',
    State: 'Georgia'
  },
  {
    County: 'Schley County',
    State: 'Georgia'
  },
  {
    County: 'Screven County',
    State: 'Georgia'
  },
  {
    County: 'Seminole County',
    State: 'Georgia'
  },
  {
    County: 'Spalding County',
    State: 'Georgia'
  },
  {
    County: 'Stephens County',
    State: 'Georgia'
  },
  {
    County: 'Stewart County',
    State: 'Georgia'
  },
  {
    County: 'Sumter County',
    State: 'Georgia'
  },
  {
    County: 'Talbot County',
    State: 'Georgia'
  },
  {
    County: 'Taliaferro County',
    State: 'Georgia'
  },
  {
    County: 'Tattnall County',
    State: 'Georgia'
  },
  {
    County: 'Taylor County',
    State: 'Georgia'
  },
  {
    County: 'Telfair County',
    State: 'Georgia'
  },
  {
    County: 'Terrell County',
    State: 'Georgia'
  },
  {
    County: 'Thomas County',
    State: 'Georgia'
  },
  {
    County: 'Tift County',
    State: 'Georgia'
  },
  {
    County: 'Toombs County',
    State: 'Georgia'
  },
  {
    County: 'Towns County',
    State: 'Georgia'
  },
  {
    County: 'Treutlen County',
    State: 'Georgia'
  },
  {
    County: 'Troup County',
    State: 'Georgia'
  },
  {
    County: 'Turner County',
    State: 'Georgia'
  },
  {
    County: 'Twiggs County',
    State: 'Georgia'
  },
  {
    County: 'Union County',
    State: 'Georgia'
  },
  {
    County: 'Upson County',
    State: 'Georgia'
  },
  {
    County: 'Walker County',
    State: 'Georgia'
  },
  {
    County: 'Walton County',
    State: 'Georgia'
  },
  {
    County: 'Ware County',
    State: 'Georgia'
  },
  {
    County: 'Warren County',
    State: 'Georgia'
  },
  {
    County: 'Washington County',
    State: 'Georgia'
  },
  {
    County: 'Wayne County',
    State: 'Georgia'
  },
  {
    County: 'Webster County',
    State: 'Georgia'
  },
  {
    County: 'Wheeler County',
    State: 'Georgia'
  },
  {
    County: 'White County',
    State: 'Georgia'
  },
  {
    County: 'Whitfield County',
    State: 'Georgia'
  },
  {
    County: 'Wilcox County',
    State: 'Georgia'
  },
  {
    County: 'Wilkes County',
    State: 'Georgia'
  },
  {
    County: 'Wilkinson County',
    State: 'Georgia'
  },
  {
    County: 'Worth County',
    State: 'Georgia'
  },
  {
    County: 'Hawaii County',
    State: 'Hawai?i'
  },
  {
    County: 'Honolulu, City and County of',
    State: 'Hawai?i'
  },
  {
    County: 'Kalawao County',
    State: 'Hawai?i'
  },
  {
    County: 'Kauai County',
    State: 'Hawai?i'
  },
  {
    County: 'Maui County',
    State: 'Hawai?i'
  },
  {
    County: 'Ada County',
    State: 'Idaho'
  },
  {
    County: 'Adams County',
    State: 'Idaho'
  },
  {
    County: 'Bannock County',
    State: 'Idaho'
  },
  {
    County: 'Bear Lake County',
    State: 'Idaho'
  },
  {
    County: 'Benewah County',
    State: 'Idaho'
  },
  {
    County: 'Bingham County',
    State: 'Idaho'
  },
  {
    County: 'Blaine County',
    State: 'Idaho'
  },
  {
    County: 'Boise County',
    State: 'Idaho'
  },
  {
    County: 'Bonner County',
    State: 'Idaho'
  },
  {
    County: 'Bonneville County',
    State: 'Idaho'
  },
  {
    County: 'Boundary County',
    State: 'Idaho'
  },
  {
    County: 'Butte County',
    State: 'Idaho'
  },
  {
    County: 'Camas County',
    State: 'Idaho'
  },
  {
    County: 'Canyon County',
    State: 'Idaho'
  },
  {
    County: 'Caribou County',
    State: 'Idaho'
  },
  {
    County: 'Cassia County',
    State: 'Idaho'
  },
  {
    County: 'Clark County',
    State: 'Idaho'
  },
  {
    County: 'Clearwater County',
    State: 'Idaho'
  },
  {
    County: 'Custer County',
    State: 'Idaho'
  },
  {
    County: 'Elmore County',
    State: 'Idaho'
  },
  {
    County: 'Franklin County',
    State: 'Idaho'
  },
  {
    County: 'Fremont County',
    State: 'Idaho'
  },
  {
    County: 'Gem County',
    State: 'Idaho'
  },
  {
    County: 'Gooding County',
    State: 'Idaho'
  },
  {
    County: 'Idaho County',
    State: 'Idaho'
  },
  {
    County: 'Jefferson County',
    State: 'Idaho'
  },
  {
    County: 'Jerome County',
    State: 'Idaho'
  },
  {
    County: 'Kootenai County',
    State: 'Idaho'
  },
  {
    County: 'Latah County',
    State: 'Idaho'
  },
  {
    County: 'Lemhi County',
    State: 'Idaho'
  },
  {
    County: 'Lewis County',
    State: 'Idaho'
  },
  {
    County: 'Lincoln County',
    State: 'Idaho'
  },
  {
    County: 'Madison County',
    State: 'Idaho'
  },
  {
    County: 'Minidoka County',
    State: 'Idaho'
  },
  {
    County: 'Nez Perce County',
    State: 'Idaho'
  },
  {
    County: 'Oneida County',
    State: 'Idaho'
  },
  {
    County: 'Owyhee County',
    State: 'Idaho'
  },
  {
    County: 'Payette County',
    State: 'Idaho'
  },
  {
    County: 'Power County',
    State: 'Idaho'
  },
  {
    County: 'Shoshone County',
    State: 'Idaho'
  },
  {
    County: 'Teton County',
    State: 'Idaho'
  },
  {
    County: 'Twin Falls County',
    State: 'Idaho'
  },
  {
    County: 'Valley County',
    State: 'Idaho'
  },
  {
    County: 'Washington County',
    State: 'Idaho'
  },
  {
    County: 'Adams County',
    State: 'Illinois'
  },
  {
    County: 'Alexander County',
    State: 'Illinois'
  },
  {
    County: 'Bond County',
    State: 'Illinois'
  },
  {
    County: 'Boone County',
    State: 'Illinois'
  },
  {
    County: 'Brown County',
    State: 'Illinois'
  },
  {
    County: 'Bureau County',
    State: 'Illinois'
  },
  {
    County: 'Calhoun County',
    State: 'Illinois'
  },
  {
    County: 'Carroll County',
    State: 'Illinois'
  },
  {
    County: 'Cass County',
    State: 'Illinois'
  },
  {
    County: 'Champaign County',
    State: 'Illinois'
  },
  {
    County: 'Christian County',
    State: 'Illinois'
  },
  {
    County: 'Clark County',
    State: 'Illinois'
  },
  {
    County: 'Clay County',
    State: 'Illinois'
  },
  {
    County: 'Clinton County',
    State: 'Illinois'
  },
  {
    County: 'Coles County',
    State: 'Illinois'
  },
  {
    County: 'Cook County',
    State: 'Illinois'
  },
  {
    County: 'Crawford County',
    State: 'Illinois'
  },
  {
    County: 'Cumberland County',
    State: 'Illinois'
  },
  {
    County: 'DeKalb County',
    State: 'Illinois'
  },
  {
    County: 'De Witt County',
    State: 'Illinois'
  },
  {
    County: 'Douglas County',
    State: 'Illinois'
  },
  {
    County: 'DuPage County',
    State: 'Illinois'
  },
  {
    County: 'Edgar County',
    State: 'Illinois'
  },
  {
    County: 'Edwards County',
    State: 'Illinois'
  },
  {
    County: 'Effingham County',
    State: 'Illinois'
  },
  {
    County: 'Fayette County',
    State: 'Illinois'
  },
  {
    County: 'Ford County',
    State: 'Illinois'
  },
  {
    County: 'Franklin County',
    State: 'Illinois'
  },
  {
    County: 'Fulton County',
    State: 'Illinois'
  },
  {
    County: 'Gallatin County',
    State: 'Illinois'
  },
  {
    County: 'Greene County',
    State: 'Illinois'
  },
  {
    County: 'Grundy County',
    State: 'Illinois'
  },
  {
    County: 'Hamilton County',
    State: 'Illinois'
  },
  {
    County: 'Hancock County',
    State: 'Illinois'
  },
  {
    County: 'Hardin County',
    State: 'Illinois'
  },
  {
    County: 'Henderson County',
    State: 'Illinois'
  },
  {
    County: 'Henry County',
    State: 'Illinois'
  },
  {
    County: 'Iroquois County',
    State: 'Illinois'
  },
  {
    County: 'Jackson County',
    State: 'Illinois'
  },
  {
    County: 'Jasper County',
    State: 'Illinois'
  },
  {
    County: 'Jefferson County',
    State: 'Illinois'
  },
  {
    County: 'Jersey County',
    State: 'Illinois'
  },
  {
    County: 'Jo Daviess County',
    State: 'Illinois'
  },
  {
    County: 'Johnson County',
    State: 'Illinois'
  },
  {
    County: 'Kane County',
    State: 'Illinois'
  },
  {
    County: 'Kankakee County',
    State: 'Illinois'
  },
  {
    County: 'Kendall County',
    State: 'Illinois'
  },
  {
    County: 'Knox County',
    State: 'Illinois'
  },
  {
    County: 'Lake County',
    State: 'Illinois'
  },
  {
    County: 'LaSalle County',
    State: 'Illinois'
  },
  {
    County: 'Lawrence County',
    State: 'Illinois'
  },
  {
    County: 'Lee County',
    State: 'Illinois'
  },
  {
    County: 'Livingston County',
    State: 'Illinois'
  },
  {
    County: 'Logan County',
    State: 'Illinois'
  },
  {
    County: 'McDonough County',
    State: 'Illinois'
  },
  {
    County: 'McHenry County',
    State: 'Illinois'
  },
  {
    County: 'McLean County',
    State: 'Illinois'
  },
  {
    County: 'Macon County',
    State: 'Illinois'
  },
  {
    County: 'Macoupin County',
    State: 'Illinois'
  },
  {
    County: 'Madison County',
    State: 'Illinois'
  },
  {
    County: 'Marion County',
    State: 'Illinois'
  },
  {
    County: 'Marshall County',
    State: 'Illinois'
  },
  {
    County: 'Mason County',
    State: 'Illinois'
  },
  {
    County: 'Massac County',
    State: 'Illinois'
  },
  {
    County: 'Menard County',
    State: 'Illinois'
  },
  {
    County: 'Mercer County',
    State: 'Illinois'
  },
  {
    County: 'Monroe County',
    State: 'Illinois'
  },
  {
    County: 'Montgomery County',
    State: 'Illinois'
  },
  {
    County: 'Morgan County',
    State: 'Illinois'
  },
  {
    County: 'Moultrie County',
    State: 'Illinois'
  },
  {
    County: 'Ogle County',
    State: 'Illinois'
  },
  {
    County: 'Peoria County',
    State: 'Illinois'
  },
  {
    County: 'Perry County',
    State: 'Illinois'
  },
  {
    County: 'Piatt County',
    State: 'Illinois'
  },
  {
    County: 'Pike County',
    State: 'Illinois'
  },
  {
    County: 'Pope County',
    State: 'Illinois'
  },
  {
    County: 'Pulaski County',
    State: 'Illinois'
  },
  {
    County: 'Putnam County',
    State: 'Illinois'
  },
  {
    County: 'Randolph County',
    State: 'Illinois'
  },
  {
    County: 'Richland County',
    State: 'Illinois'
  },
  {
    County: 'Rock Island County',
    State: 'Illinois'
  },
  {
    County: 'St. Clair County',
    State: 'Illinois'
  },
  {
    County: 'Saline County',
    State: 'Illinois'
  },
  {
    County: 'Sangamon County',
    State: 'Illinois'
  },
  {
    County: 'Schuyler County',
    State: 'Illinois'
  },
  {
    County: 'Scott County',
    State: 'Illinois'
  },
  {
    County: 'Shelby County',
    State: 'Illinois'
  },
  {
    County: 'Stark County',
    State: 'Illinois'
  },
  {
    County: 'Stephenson County',
    State: 'Illinois'
  },
  {
    County: 'Tazewell County',
    State: 'Illinois'
  },
  {
    County: 'Union County',
    State: 'Illinois'
  },
  {
    County: 'Vermilion County',
    State: 'Illinois'
  },
  {
    County: 'Wabash County',
    State: 'Illinois'
  },
  {
    County: 'Warren County',
    State: 'Illinois'
  },
  {
    County: 'Washington County',
    State: 'Illinois'
  },
  {
    County: 'Wayne County',
    State: 'Illinois'
  },
  {
    County: 'White County',
    State: 'Illinois'
  },
  {
    County: 'Whiteside County',
    State: 'Illinois'
  },
  {
    County: 'Will County',
    State: 'Illinois'
  },
  {
    County: 'Williamson County',
    State: 'Illinois'
  },
  {
    County: 'Winnebago County',
    State: 'Illinois'
  },
  {
    County: 'Woodford County',
    State: 'Illinois'
  },
  {
    County: 'Adams County',
    State: 'Indiana'
  },
  {
    County: 'Allen County',
    State: 'Indiana'
  },
  {
    County: 'Bartholomew County',
    State: 'Indiana'
  },
  {
    County: 'Benton County',
    State: 'Indiana'
  },
  {
    County: 'Blackford County',
    State: 'Indiana'
  },
  {
    County: 'Boone County',
    State: 'Indiana'
  },
  {
    County: 'Brown County',
    State: 'Indiana'
  },
  {
    County: 'Carroll County',
    State: 'Indiana'
  },
  {
    County: 'Cass County',
    State: 'Indiana'
  },
  {
    County: 'Clark County',
    State: 'Indiana'
  },
  {
    County: 'Clay County',
    State: 'Indiana'
  },
  {
    County: 'Clinton County',
    State: 'Indiana'
  },
  {
    County: 'Crawford County',
    State: 'Indiana'
  },
  {
    County: 'Daviess County',
    State: 'Indiana'
  },
  {
    County: 'Dearborn County',
    State: 'Indiana'
  },
  {
    County: 'Decatur County',
    State: 'Indiana'
  },
  {
    County: 'DeKalb County',
    State: 'Indiana'
  },
  {
    County: 'Delaware County',
    State: 'Indiana'
  },
  {
    County: 'Dubois County',
    State: 'Indiana'
  },
  {
    County: 'Elkhart County',
    State: 'Indiana'
  },
  {
    County: 'Fayette County',
    State: 'Indiana'
  },
  {
    County: 'Floyd County',
    State: 'Indiana'
  },
  {
    County: 'Fountain County',
    State: 'Indiana'
  },
  {
    County: 'Franklin County',
    State: 'Indiana'
  },
  {
    County: 'Fulton County',
    State: 'Indiana'
  },
  {
    County: 'Gibson County',
    State: 'Indiana'
  },
  {
    County: 'Grant County',
    State: 'Indiana'
  },
  {
    County: 'Greene County',
    State: 'Indiana'
  },
  {
    County: 'Hamilton County',
    State: 'Indiana'
  },
  {
    County: 'Hancock County',
    State: 'Indiana'
  },
  {
    County: 'Harrison County',
    State: 'Indiana'
  },
  {
    County: 'Hendricks County',
    State: 'Indiana'
  },
  {
    County: 'Henry County',
    State: 'Indiana'
  },
  {
    County: 'Howard County',
    State: 'Indiana'
  },
  {
    County: 'Huntington County',
    State: 'Indiana'
  },
  {
    County: 'Jackson County',
    State: 'Indiana'
  },
  {
    County: 'Jasper County',
    State: 'Indiana'
  },
  {
    County: 'Jay County',
    State: 'Indiana'
  },
  {
    County: 'Jefferson County',
    State: 'Indiana'
  },
  {
    County: 'Jennings County',
    State: 'Indiana'
  },
  {
    County: 'Johnson County',
    State: 'Indiana'
  },
  {
    County: 'Knox County',
    State: 'Indiana'
  },
  {
    County: 'Kosciusko County',
    State: 'Indiana'
  },
  {
    County: 'LaGrange County',
    State: 'Indiana'
  },
  {
    County: 'Lake County',
    State: 'Indiana'
  },
  {
    County: 'LaPorte County',
    State: 'Indiana'
  },
  {
    County: 'Lawrence County',
    State: 'Indiana'
  },
  {
    County: 'Madison County',
    State: 'Indiana'
  },
  {
    County: 'Marion County',
    State: 'Indiana'
  },
  {
    County: 'Marshall County',
    State: 'Indiana'
  },
  {
    County: 'Martin County',
    State: 'Indiana'
  },
  {
    County: 'Miami County',
    State: 'Indiana'
  },
  {
    County: 'Monroe County',
    State: 'Indiana'
  },
  {
    County: 'Montgomery County',
    State: 'Indiana'
  },
  {
    County: 'Morgan County',
    State: 'Indiana'
  },
  {
    County: 'Newton County',
    State: 'Indiana'
  },
  {
    County: 'Noble County',
    State: 'Indiana'
  },
  {
    County: 'Ohio County',
    State: 'Indiana'
  },
  {
    County: 'Orange County',
    State: 'Indiana'
  },
  {
    County: 'Owen County',
    State: 'Indiana'
  },
  {
    County: 'Parke County',
    State: 'Indiana'
  },
  {
    County: 'Perry County',
    State: 'Indiana'
  },
  {
    County: 'Pike County',
    State: 'Indiana'
  },
  {
    County: 'Porter County',
    State: 'Indiana'
  },
  {
    County: 'Posey County',
    State: 'Indiana'
  },
  {
    County: 'Pulaski County',
    State: 'Indiana'
  },
  {
    County: 'Putnam County',
    State: 'Indiana'
  },
  {
    County: 'Randolph County',
    State: 'Indiana'
  },
  {
    County: 'Ripley County',
    State: 'Indiana'
  },
  {
    County: 'Rush County',
    State: 'Indiana'
  },
  {
    County: 'St. Joseph County',
    State: 'Indiana'
  },
  {
    County: 'Scott County',
    State: 'Indiana'
  },
  {
    County: 'Shelby County',
    State: 'Indiana'
  },
  {
    County: 'Spencer County',
    State: 'Indiana'
  },
  {
    County: 'Starke County',
    State: 'Indiana'
  },
  {
    County: 'Steuben County',
    State: 'Indiana'
  },
  {
    County: 'Sullivan County',
    State: 'Indiana'
  },
  {
    County: 'Switzerland County',
    State: 'Indiana'
  },
  {
    County: 'Tippecanoe County',
    State: 'Indiana'
  },
  {
    County: 'Tipton County',
    State: 'Indiana'
  },
  {
    County: 'Union County',
    State: 'Indiana'
  },
  {
    County: 'Vanderburgh County',
    State: 'Indiana'
  },
  {
    County: 'Vermillion County',
    State: 'Indiana'
  },
  {
    County: 'Vigo County',
    State: 'Indiana'
  },
  {
    County: 'Wabash County',
    State: 'Indiana'
  },
  {
    County: 'Warren County',
    State: 'Indiana'
  },
  {
    County: 'Warrick County',
    State: 'Indiana'
  },
  {
    County: 'Washington County',
    State: 'Indiana'
  },
  {
    County: 'Wayne County',
    State: 'Indiana'
  },
  {
    County: 'Wells County',
    State: 'Indiana'
  },
  {
    County: 'White County',
    State: 'Indiana'
  },
  {
    County: 'Whitley County',
    State: 'Indiana'
  },
  {
    County: 'Adair County',
    State: 'Iowa'
  },
  {
    County: 'Adams County',
    State: 'Iowa'
  },
  {
    County: 'Allamakee County',
    State: 'Iowa'
  },
  {
    County: 'Appanoose County',
    State: 'Iowa'
  },
  {
    County: 'Audubon County',
    State: 'Iowa'
  },
  {
    County: 'Benton County',
    State: 'Iowa'
  },
  {
    County: 'Black Hawk County',
    State: 'Iowa'
  },
  {
    County: 'Boone County',
    State: 'Iowa'
  },
  {
    County: 'Bremer County',
    State: 'Iowa'
  },
  {
    County: 'Buchanan County',
    State: 'Iowa'
  },
  {
    County: 'Buena Vista County',
    State: 'Iowa'
  },
  {
    County: 'Butler County',
    State: 'Iowa'
  },
  {
    County: 'Calhoun County',
    State: 'Iowa'
  },
  {
    County: 'Carroll County',
    State: 'Iowa'
  },
  {
    County: 'Cass County',
    State: 'Iowa'
  },
  {
    County: 'Cedar County',
    State: 'Iowa'
  },
  {
    County: 'Cerro Gordo County',
    State: 'Iowa'
  },
  {
    County: 'Cherokee County',
    State: 'Iowa'
  },
  {
    County: 'Chickasaw County',
    State: 'Iowa'
  },
  {
    County: 'Clarke County',
    State: 'Iowa'
  },
  {
    County: 'Clay County',
    State: 'Iowa'
  },
  {
    County: 'Clayton County',
    State: 'Iowa'
  },
  {
    County: 'Clinton County',
    State: 'Iowa'
  },
  {
    County: 'Crawford County',
    State: 'Iowa'
  },
  {
    County: 'Dallas County',
    State: 'Iowa'
  },
  {
    County: 'Davis County',
    State: 'Iowa'
  },
  {
    County: 'Decatur County',
    State: 'Iowa'
  },
  {
    County: 'Delaware County',
    State: 'Iowa'
  },
  {
    County: 'Des Moines County',
    State: 'Iowa'
  },
  {
    County: 'Dickinson County',
    State: 'Iowa'
  },
  {
    County: 'Dubuque County',
    State: 'Iowa'
  },
  {
    County: 'Emmet County',
    State: 'Iowa'
  },
  {
    County: 'Fayette County',
    State: 'Iowa'
  },
  {
    County: 'Floyd County',
    State: 'Iowa'
  },
  {
    County: 'Franklin County',
    State: 'Iowa'
  },
  {
    County: 'Fremont County',
    State: 'Iowa'
  },
  {
    County: 'Greene County',
    State: 'Iowa'
  },
  {
    County: 'Grundy County',
    State: 'Iowa'
  },
  {
    County: 'Guthrie County',
    State: 'Iowa'
  },
  {
    County: 'Hamilton County',
    State: 'Iowa'
  },
  {
    County: 'Hancock County',
    State: 'Iowa'
  },
  {
    County: 'Hardin County',
    State: 'Iowa'
  },
  {
    County: 'Harrison County',
    State: 'Iowa'
  },
  {
    County: 'Henry County',
    State: 'Iowa'
  },
  {
    County: 'Howard County',
    State: 'Iowa'
  },
  {
    County: 'Humboldt County',
    State: 'Iowa'
  },
  {
    County: 'Ida County',
    State: 'Iowa'
  },
  {
    County: 'Iowa County',
    State: 'Iowa'
  },
  {
    County: 'Jackson County',
    State: 'Iowa'
  },
  {
    County: 'Jasper County',
    State: 'Iowa'
  },
  {
    County: 'Jefferson County',
    State: 'Iowa'
  },
  {
    County: 'Johnson County',
    State: 'Iowa'
  },
  {
    County: 'Jones County',
    State: 'Iowa'
  },
  {
    County: 'Keokuk County',
    State: 'Iowa'
  },
  {
    County: 'Kossuth County',
    State: 'Iowa'
  },
  {
    County: 'Lee County',
    State: 'Iowa'
  },
  {
    County: 'Linn County',
    State: 'Iowa'
  },
  {
    County: 'Louisa County',
    State: 'Iowa'
  },
  {
    County: 'Lucas County',
    State: 'Iowa'
  },
  {
    County: 'Lyon County',
    State: 'Iowa'
  },
  {
    County: 'Madison County',
    State: 'Iowa'
  },
  {
    County: 'Mahaska County',
    State: 'Iowa'
  },
  {
    County: 'Marion County',
    State: 'Iowa'
  },
  {
    County: 'Marshall County',
    State: 'Iowa'
  },
  {
    County: 'Mills County',
    State: 'Iowa'
  },
  {
    County: 'Mitchell County',
    State: 'Iowa'
  },
  {
    County: 'Monona County',
    State: 'Iowa'
  },
  {
    County: 'Monroe County',
    State: 'Iowa'
  },
  {
    County: 'Montgomery County',
    State: 'Iowa'
  },
  {
    County: 'Muscatine County',
    State: 'Iowa'
  },
  {
    County: "O'Brien County",
    State: 'Iowa'
  },
  {
    County: 'Osceola County',
    State: 'Iowa'
  },
  {
    County: 'Page County',
    State: 'Iowa'
  },
  {
    County: 'Palo Alto County',
    State: 'Iowa'
  },
  {
    County: 'Plymouth County',
    State: 'Iowa'
  },
  {
    County: 'Pocahontas County',
    State: 'Iowa'
  },
  {
    County: 'Polk County',
    State: 'Iowa'
  },
  {
    County: 'Pottawattamie County',
    State: 'Iowa'
  },
  {
    County: 'Poweshiek County',
    State: 'Iowa'
  },
  {
    County: 'Ringgold County',
    State: 'Iowa'
  },
  {
    County: 'Sac County',
    State: 'Iowa'
  },
  {
    County: 'Scott County',
    State: 'Iowa'
  },
  {
    County: 'Shelby County',
    State: 'Iowa'
  },
  {
    County: 'Sioux County',
    State: 'Iowa'
  },
  {
    County: 'Story County',
    State: 'Iowa'
  },
  {
    County: 'Tama County',
    State: 'Iowa'
  },
  {
    County: 'Taylor County',
    State: 'Iowa'
  },
  {
    County: 'Union County',
    State: 'Iowa'
  },
  {
    County: 'Van Buren County',
    State: 'Iowa'
  },
  {
    County: 'Wapello County',
    State: 'Iowa'
  },
  {
    County: 'Warren County',
    State: 'Iowa'
  },
  {
    County: 'Washington County',
    State: 'Iowa'
  },
  {
    County: 'Wayne County',
    State: 'Iowa'
  },
  {
    County: 'Webster County',
    State: 'Iowa'
  },
  {
    County: 'Winnebago County',
    State: 'Iowa'
  },
  {
    County: 'Winneshiek County',
    State: 'Iowa'
  },
  {
    County: 'Woodbury County',
    State: 'Iowa'
  },
  {
    County: 'Worth County',
    State: 'Iowa'
  },
  {
    County: 'Wright County',
    State: 'Iowa'
  },
  {
    County: 'Allen County',
    State: 'Kansas'
  },
  {
    County: 'Anderson County',
    State: 'Kansas'
  },
  {
    County: 'Atchison County',
    State: 'Kansas'
  },
  {
    County: 'Barber County',
    State: 'Kansas'
  },
  {
    County: 'Barton County',
    State: 'Kansas'
  },
  {
    County: 'Bourbon County',
    State: 'Kansas'
  },
  {
    County: 'Brown County',
    State: 'Kansas'
  },
  {
    County: 'Butler County',
    State: 'Kansas'
  },
  {
    County: 'Chase County',
    State: 'Kansas'
  },
  {
    County: 'Chautauqua County',
    State: 'Kansas'
  },
  {
    County: 'Cherokee County',
    State: 'Kansas'
  },
  {
    County: 'Cheyenne County',
    State: 'Kansas'
  },
  {
    County: 'Clark County',
    State: 'Kansas'
  },
  {
    County: 'Clay County',
    State: 'Kansas'
  },
  {
    County: 'Cloud County',
    State: 'Kansas'
  },
  {
    County: 'Coffey County',
    State: 'Kansas'
  },
  {
    County: 'Comanche County',
    State: 'Kansas'
  },
  {
    County: 'Cowley County',
    State: 'Kansas'
  },
  {
    County: 'Crawford County',
    State: 'Kansas'
  },
  {
    County: 'Decatur County',
    State: 'Kansas'
  },
  {
    County: 'Dickinson County',
    State: 'Kansas'
  },
  {
    County: 'Doniphan County',
    State: 'Kansas'
  },
  {
    County: 'Douglas County',
    State: 'Kansas'
  },
  {
    County: 'Edwards County',
    State: 'Kansas'
  },
  {
    County: 'Elk County',
    State: 'Kansas'
  },
  {
    County: 'Ellis County',
    State: 'Kansas'
  },
  {
    County: 'Ellsworth County',
    State: 'Kansas'
  },
  {
    County: 'Finney County',
    State: 'Kansas'
  },
  {
    County: 'Ford County',
    State: 'Kansas'
  },
  {
    County: 'Franklin County',
    State: 'Kansas'
  },
  {
    County: 'Geary County',
    State: 'Kansas'
  },
  {
    County: 'Gove County',
    State: 'Kansas'
  },
  {
    County: 'Graham County',
    State: 'Kansas'
  },
  {
    County: 'Grant County',
    State: 'Kansas'
  },
  {
    County: 'Gray County',
    State: 'Kansas'
  },
  {
    County: 'Greeley County',
    State: 'Kansas'
  },
  {
    County: 'Greenwood County',
    State: 'Kansas'
  },
  {
    County: 'Hamilton County',
    State: 'Kansas'
  },
  {
    County: 'Harper County',
    State: 'Kansas'
  },
  {
    County: 'Harvey County',
    State: 'Kansas'
  },
  {
    County: 'Haskell County',
    State: 'Kansas'
  },
  {
    County: 'Hodgeman County',
    State: 'Kansas'
  },
  {
    County: 'Jackson County',
    State: 'Kansas'
  },
  {
    County: 'Jefferson County',
    State: 'Kansas'
  },
  {
    County: 'Jewell County',
    State: 'Kansas'
  },
  {
    County: 'Johnson County',
    State: 'Kansas'
  },
  {
    County: 'Kearny County',
    State: 'Kansas'
  },
  {
    County: 'Kingman County',
    State: 'Kansas'
  },
  {
    County: 'Kiowa County',
    State: 'Kansas'
  },
  {
    County: 'Labette County',
    State: 'Kansas'
  },
  {
    County: 'Lane County',
    State: 'Kansas'
  },
  {
    County: 'Leavenworth County',
    State: 'Kansas'
  },
  {
    County: 'Lincoln County',
    State: 'Kansas'
  },
  {
    County: 'Linn County',
    State: 'Kansas'
  },
  {
    County: 'Logan County',
    State: 'Kansas'
  },
  {
    County: 'Lyon County',
    State: 'Kansas'
  },
  {
    County: 'McPherson County',
    State: 'Kansas'
  },
  {
    County: 'Marion County',
    State: 'Kansas'
  },
  {
    County: 'Marshall County',
    State: 'Kansas'
  },
  {
    County: 'Meade County',
    State: 'Kansas'
  },
  {
    County: 'Miami County',
    State: 'Kansas'
  },
  {
    County: 'Mitchell County',
    State: 'Kansas'
  },
  {
    County: 'Montgomery County',
    State: 'Kansas'
  },
  {
    County: 'Morris County',
    State: 'Kansas'
  },
  {
    County: 'Morton County',
    State: 'Kansas'
  },
  {
    County: 'Nemaha County',
    State: 'Kansas'
  },
  {
    County: 'Neosho County',
    State: 'Kansas'
  },
  {
    County: 'Ness County',
    State: 'Kansas'
  },
  {
    County: 'Norton County',
    State: 'Kansas'
  },
  {
    County: 'Osage County',
    State: 'Kansas'
  },
  {
    County: 'Osborne County',
    State: 'Kansas'
  },
  {
    County: 'Ottawa County',
    State: 'Kansas'
  },
  {
    County: 'Pawnee County',
    State: 'Kansas'
  },
  {
    County: 'Phillips County',
    State: 'Kansas'
  },
  {
    County: 'Pottawatomie County',
    State: 'Kansas'
  },
  {
    County: 'Pratt County',
    State: 'Kansas'
  },
  {
    County: 'Rawlins County',
    State: 'Kansas'
  },
  {
    County: 'Reno County',
    State: 'Kansas'
  },
  {
    County: 'Republic County',
    State: 'Kansas'
  },
  {
    County: 'Rice County',
    State: 'Kansas'
  },
  {
    County: 'Riley County',
    State: 'Kansas'
  },
  {
    County: 'Rooks County',
    State: 'Kansas'
  },
  {
    County: 'Rush County',
    State: 'Kansas'
  },
  {
    County: 'Russell County',
    State: 'Kansas'
  },
  {
    County: 'Saline County',
    State: 'Kansas'
  },
  {
    County: 'Scott County',
    State: 'Kansas'
  },
  {
    County: 'Sedgwick County',
    State: 'Kansas'
  },
  {
    County: 'Seward County',
    State: 'Kansas'
  },
  {
    County: 'Shawnee County',
    State: 'Kansas'
  },
  {
    County: 'Sheridan County',
    State: 'Kansas'
  },
  {
    County: 'Sherman County',
    State: 'Kansas'
  },
  {
    County: 'Smith County',
    State: 'Kansas'
  },
  {
    County: 'Stafford County',
    State: 'Kansas'
  },
  {
    County: 'Stanton County',
    State: 'Kansas'
  },
  {
    County: 'Stevens County',
    State: 'Kansas'
  },
  {
    County: 'Sumner County',
    State: 'Kansas'
  },
  {
    County: 'Thomas County',
    State: 'Kansas'
  },
  {
    County: 'Trego County',
    State: 'Kansas'
  },
  {
    County: 'Wabaunsee County',
    State: 'Kansas'
  },
  {
    County: 'Wallace County',
    State: 'Kansas'
  },
  {
    County: 'Washington County',
    State: 'Kansas'
  },
  {
    County: 'Wichita County',
    State: 'Kansas'
  },
  {
    County: 'Wilson County',
    State: 'Kansas'
  },
  {
    County: 'Woodson County',
    State: 'Kansas'
  },
  {
    County: 'Wyandotte County',
    State: 'Kansas'
  },
  {
    County: 'Adair County',
    State: 'Kentucky'
  },
  {
    County: 'Allen County',
    State: 'Kentucky'
  },
  {
    County: 'Anderson County',
    State: 'Kentucky'
  },
  {
    County: 'Ballard County',
    State: 'Kentucky'
  },
  {
    County: 'Barren County',
    State: 'Kentucky'
  },
  {
    County: 'Bath County',
    State: 'Kentucky'
  },
  {
    County: 'Bell County',
    State: 'Kentucky'
  },
  {
    County: 'Boone County',
    State: 'Kentucky'
  },
  {
    County: 'Bourbon County',
    State: 'Kentucky'
  },
  {
    County: 'Boyd County',
    State: 'Kentucky'
  },
  {
    County: 'Boyle County',
    State: 'Kentucky'
  },
  {
    County: 'Bracken County',
    State: 'Kentucky'
  },
  {
    County: 'Breathitt County',
    State: 'Kentucky'
  },
  {
    County: 'Breckinridge County',
    State: 'Kentucky'
  },
  {
    County: 'Bullitt County',
    State: 'Kentucky'
  },
  {
    County: 'Butler County',
    State: 'Kentucky'
  },
  {
    County: 'Caldwell County',
    State: 'Kentucky'
  },
  {
    County: 'Calloway County',
    State: 'Kentucky'
  },
  {
    County: 'Campbell County',
    State: 'Kentucky'
  },
  {
    County: 'Carlisle County',
    State: 'Kentucky'
  },
  {
    County: 'Carroll County',
    State: 'Kentucky'
  },
  {
    County: 'Carter County',
    State: 'Kentucky'
  },
  {
    County: 'Casey County',
    State: 'Kentucky'
  },
  {
    County: 'Christian County',
    State: 'Kentucky'
  },
  {
    County: 'Clark County',
    State: 'Kentucky'
  },
  {
    County: 'Clay County',
    State: 'Kentucky'
  },
  {
    County: 'Clinton County',
    State: 'Kentucky'
  },
  {
    County: 'Crittenden County',
    State: 'Kentucky'
  },
  {
    County: 'Cumberland County',
    State: 'Kentucky'
  },
  {
    County: 'Daviess County',
    State: 'Kentucky'
  },
  {
    County: 'Edmonson County',
    State: 'Kentucky'
  },
  {
    County: 'Elliott County',
    State: 'Kentucky'
  },
  {
    County: 'Estill County',
    State: 'Kentucky'
  },
  {
    County: 'Fayette County',
    State: 'Kentucky'
  },
  {
    County: 'Fleming County',
    State: 'Kentucky'
  },
  {
    County: 'Floyd County',
    State: 'Kentucky'
  },
  {
    County: 'Franklin County',
    State: 'Kentucky'
  },
  {
    County: 'Fulton County',
    State: 'Kentucky'
  },
  {
    County: 'Gallatin County',
    State: 'Kentucky'
  },
  {
    County: 'Garrard County',
    State: 'Kentucky'
  },
  {
    County: 'Grant County',
    State: 'Kentucky'
  },
  {
    County: 'Graves County',
    State: 'Kentucky'
  },
  {
    County: 'Grayson County',
    State: 'Kentucky'
  },
  {
    County: 'Green County',
    State: 'Kentucky'
  },
  {
    County: 'Greenup County',
    State: 'Kentucky'
  },
  {
    County: 'Hancock County',
    State: 'Kentucky'
  },
  {
    County: 'Hardin County',
    State: 'Kentucky'
  },
  {
    County: 'Harlan County',
    State: 'Kentucky'
  },
  {
    County: 'Harrison County',
    State: 'Kentucky'
  },
  {
    County: 'Hart County',
    State: 'Kentucky'
  },
  {
    County: 'Henderson County',
    State: 'Kentucky'
  },
  {
    County: 'Henry County',
    State: 'Kentucky'
  },
  {
    County: 'Hickman County',
    State: 'Kentucky'
  },
  {
    County: 'Hopkins County',
    State: 'Kentucky'
  },
  {
    County: 'Jackson County',
    State: 'Kentucky'
  },
  {
    County: 'Jefferson County',
    State: 'Kentucky'
  },
  {
    County: 'Jessamine County',
    State: 'Kentucky'
  },
  {
    County: 'Johnson County',
    State: 'Kentucky'
  },
  {
    County: 'Kenton County',
    State: 'Kentucky'
  },
  {
    County: 'Knott County',
    State: 'Kentucky'
  },
  {
    County: 'Knox County',
    State: 'Kentucky'
  },
  {
    County: 'Larue County',
    State: 'Kentucky'
  },
  {
    County: 'Laurel County',
    State: 'Kentucky'
  },
  {
    County: 'Lawrence County',
    State: 'Kentucky'
  },
  {
    County: 'Lee County',
    State: 'Kentucky'
  },
  {
    County: 'Leslie County',
    State: 'Kentucky'
  },
  {
    County: 'Letcher County',
    State: 'Kentucky'
  },
  {
    County: 'Lewis County',
    State: 'Kentucky'
  },
  {
    County: 'Lincoln County',
    State: 'Kentucky'
  },
  {
    County: 'Livingston County',
    State: 'Kentucky'
  },
  {
    County: 'Logan County',
    State: 'Kentucky'
  },
  {
    County: 'Lyon County',
    State: 'Kentucky'
  },
  {
    County: 'McCracken County',
    State: 'Kentucky'
  },
  {
    County: 'McCreary County',
    State: 'Kentucky'
  },
  {
    County: 'McLean County',
    State: 'Kentucky'
  },
  {
    County: 'Madison County',
    State: 'Kentucky'
  },
  {
    County: 'Magoffin County',
    State: 'Kentucky'
  },
  {
    County: 'Marion County',
    State: 'Kentucky'
  },
  {
    County: 'Marshall County',
    State: 'Kentucky'
  },
  {
    County: 'Martin County',
    State: 'Kentucky'
  },
  {
    County: 'Mason County',
    State: 'Kentucky'
  },
  {
    County: 'Meade County',
    State: 'Kentucky'
  },
  {
    County: 'Menifee County',
    State: 'Kentucky'
  },
  {
    County: 'Mercer County',
    State: 'Kentucky'
  },
  {
    County: 'Metcalfe County',
    State: 'Kentucky'
  },
  {
    County: 'Monroe County',
    State: 'Kentucky'
  },
  {
    County: 'Montgomery County',
    State: 'Kentucky'
  },
  {
    County: 'Morgan County',
    State: 'Kentucky'
  },
  {
    County: 'Muhlenberg County',
    State: 'Kentucky'
  },
  {
    County: 'Nelson County',
    State: 'Kentucky'
  },
  {
    County: 'Nicholas County',
    State: 'Kentucky'
  },
  {
    County: 'Ohio County',
    State: 'Kentucky'
  },
  {
    County: 'Oldham County',
    State: 'Kentucky'
  },
  {
    County: 'Owen County',
    State: 'Kentucky'
  },
  {
    County: 'Owsley County',
    State: 'Kentucky'
  },
  {
    County: 'Pendleton County',
    State: 'Kentucky'
  },
  {
    County: 'Perry County',
    State: 'Kentucky'
  },
  {
    County: 'Pike County',
    State: 'Kentucky'
  },
  {
    County: 'Powell County',
    State: 'Kentucky'
  },
  {
    County: 'Pulaski County',
    State: 'Kentucky'
  },
  {
    County: 'Robertson County',
    State: 'Kentucky'
  },
  {
    County: 'Rockcastle County',
    State: 'Kentucky'
  },
  {
    County: 'Rowan County',
    State: 'Kentucky'
  },
  {
    County: 'Russell County',
    State: 'Kentucky'
  },
  {
    County: 'Scott County',
    State: 'Kentucky'
  },
  {
    County: 'Shelby County',
    State: 'Kentucky'
  },
  {
    County: 'Simpson County',
    State: 'Kentucky'
  },
  {
    County: 'Spencer County',
    State: 'Kentucky'
  },
  {
    County: 'Taylor County',
    State: 'Kentucky'
  },
  {
    County: 'Todd County',
    State: 'Kentucky'
  },
  {
    County: 'Trigg County',
    State: 'Kentucky'
  },
  {
    County: 'Trimble County',
    State: 'Kentucky'
  },
  {
    County: 'Union County',
    State: 'Kentucky'
  },
  {
    County: 'Warren County',
    State: 'Kentucky'
  },
  {
    County: 'Washington County',
    State: 'Kentucky'
  },
  {
    County: 'Wayne County',
    State: 'Kentucky'
  },
  {
    County: 'Webster County',
    State: 'Kentucky'
  },
  {
    County: 'Whitley County',
    State: 'Kentucky'
  },
  {
    County: 'Wolfe County',
    State: 'Kentucky'
  },
  {
    County: 'Woodford County',
    State: 'Kentucky'
  },
  {
    County: 'Acadia Parish',
    State: 'Louisiana'
  },
  {
    County: 'Allen Parish',
    State: 'Louisiana'
  },
  {
    County: 'Ascension Parish',
    State: 'Louisiana'
  },
  {
    County: 'Assumption Parish',
    State: 'Louisiana'
  },
  {
    County: 'Avoyelles Parish',
    State: 'Louisiana'
  },
  {
    County: 'Beauregard Parish',
    State: 'Louisiana'
  },
  {
    County: 'Bienville Parish',
    State: 'Louisiana'
  },
  {
    County: 'Bossier Parish',
    State: 'Louisiana'
  },
  {
    County: 'Caddo Parish',
    State: 'Louisiana'
  },
  {
    County: 'Calcasieu Parish',
    State: 'Louisiana'
  },
  {
    County: 'Caldwell Parish',
    State: 'Louisiana'
  },
  {
    County: 'Cameron Parish',
    State: 'Louisiana'
  },
  {
    County: 'Catahoula Parish',
    State: 'Louisiana'
  },
  {
    County: 'Claiborne Parish',
    State: 'Louisiana'
  },
  {
    County: 'Concordia Parish',
    State: 'Louisiana'
  },
  {
    County: 'De Soto Parish',
    State: 'Louisiana'
  },
  {
    County: 'East Baton Rouge Parish',
    State: 'Louisiana'
  },
  {
    County: 'East Carroll Parish',
    State: 'Louisiana'
  },
  {
    County: 'East Feliciana Parish',
    State: 'Louisiana'
  },
  {
    County: 'Evangeline Parish',
    State: 'Louisiana'
  },
  {
    County: 'Franklin Parish',
    State: 'Louisiana'
  },
  {
    County: 'Grant Parish',
    State: 'Louisiana'
  },
  {
    County: 'Iberia Parish',
    State: 'Louisiana'
  },
  {
    County: 'Iberville Parish',
    State: 'Louisiana'
  },
  {
    County: 'Jackson Parish',
    State: 'Louisiana'
  },
  {
    County: 'Jefferson Parish',
    State: 'Louisiana'
  },
  {
    County: 'Jefferson Davis Parish',
    State: 'Louisiana'
  },
  {
    County: 'Lafayette Parish',
    State: 'Louisiana'
  },
  {
    County: 'Lafourche Parish',
    State: 'Louisiana'
  },
  {
    County: 'LaSalle Parish',
    State: 'Louisiana'
  },
  {
    County: 'Lincoln Parish',
    State: 'Louisiana'
  },
  {
    County: 'Livingston Parish',
    State: 'Louisiana'
  },
  {
    County: 'Madison Parish',
    State: 'Louisiana'
  },
  {
    County: 'Morehouse Parish',
    State: 'Louisiana'
  },
  {
    County: 'Natchitoches Parish',
    State: 'Louisiana'
  },
  {
    County: 'Orleans Parish',
    State: 'Louisiana'
  },
  {
    County: 'Ouachita Parish',
    State: 'Louisiana'
  },
  {
    County: 'Plaquemines Parish',
    State: 'Louisiana'
  },
  {
    County: 'Pointe Coupee Parish',
    State: 'Louisiana'
  },
  {
    County: 'Rapides Parish',
    State: 'Louisiana'
  },
  {
    County: 'Red River Parish',
    State: 'Louisiana'
  },
  {
    County: 'Richland Parish',
    State: 'Louisiana'
  },
  {
    County: 'Sabine Parish',
    State: 'Louisiana'
  },
  {
    County: 'St. Bernard Parish',
    State: 'Louisiana'
  },
  {
    County: 'St. Charles Parish',
    State: 'Louisiana'
  },
  {
    County: 'St. Helena Parish',
    State: 'Louisiana'
  },
  {
    County: 'St. James Parish',
    State: 'Louisiana'
  },
  {
    County: 'St. John the Baptist Parish',
    State: 'Louisiana'
  },
  {
    County: 'St. Landry Parish',
    State: 'Louisiana'
  },
  {
    County: 'St. Martin Parish',
    State: 'Louisiana'
  },
  {
    County: 'St. Mary Parish',
    State: 'Louisiana'
  },
  {
    County: 'St. Tammany Parish',
    State: 'Louisiana'
  },
  {
    County: 'Tangipahoa Parish',
    State: 'Louisiana'
  },
  {
    County: 'Tensas Parish',
    State: 'Louisiana'
  },
  {
    County: 'Terrebonne Parish',
    State: 'Louisiana'
  },
  {
    County: 'Union Parish',
    State: 'Louisiana'
  },
  {
    County: 'Vermilion Parish',
    State: 'Louisiana'
  },
  {
    County: 'Vernon Parish',
    State: 'Louisiana'
  },
  {
    County: 'Washington Parish',
    State: 'Louisiana'
  },
  {
    County: 'Webster Parish',
    State: 'Louisiana'
  },
  {
    County: 'West Baton Rouge Parish',
    State: 'Louisiana'
  },
  {
    County: 'West Carroll Parish',
    State: 'Louisiana'
  },
  {
    County: 'West Feliciana Parish',
    State: 'Louisiana'
  },
  {
    County: 'Winn Parish',
    State: 'Louisiana'
  },
  {
    County: 'Androscoggin County',
    State: 'Maine'
  },
  {
    County: 'Aroostook County',
    State: 'Maine'
  },
  {
    County: 'Cumberland County',
    State: 'Maine'
  },
  {
    County: 'Franklin County',
    State: 'Maine'
  },
  {
    County: 'Hancock County',
    State: 'Maine'
  },
  {
    County: 'Kennebec County',
    State: 'Maine'
  },
  {
    County: 'Knox County',
    State: 'Maine'
  },
  {
    County: 'Lincoln County',
    State: 'Maine'
  },
  {
    County: 'Oxford County',
    State: 'Maine'
  },
  {
    County: 'Penobscot County',
    State: 'Maine'
  },
  {
    County: 'Piscataquis County',
    State: 'Maine'
  },
  {
    County: 'Sagadahoc County',
    State: 'Maine'
  },
  {
    County: 'Somerset County',
    State: 'Maine'
  },
  {
    County: 'Waldo County',
    State: 'Maine'
  },
  {
    County: 'Washington County',
    State: 'Maine'
  },
  {
    County: 'York County',
    State: 'Maine'
  },
  {
    County: 'Allegany County',
    State: 'Maryland'
  },
  {
    County: 'Anne Arundel County',
    State: 'Maryland'
  },
  {
    County: 'Baltimore County',
    State: 'Maryland'
  },
  {
    County: 'Calvert County',
    State: 'Maryland'
  },
  {
    County: 'Caroline County',
    State: 'Maryland'
  },
  {
    County: 'Carroll County',
    State: 'Maryland'
  },
  {
    County: 'Cecil County',
    State: 'Maryland'
  },
  {
    County: 'Charles County',
    State: 'Maryland'
  },
  {
    County: 'Dorchester County',
    State: 'Maryland'
  },
  {
    County: 'Frederick County',
    State: 'Maryland'
  },
  {
    County: 'Garrett County',
    State: 'Maryland'
  },
  {
    County: 'Harford County',
    State: 'Maryland'
  },
  {
    County: 'Howard County',
    State: 'Maryland'
  },
  {
    County: 'Kent County',
    State: 'Maryland'
  },
  {
    County: 'Montgomery County',
    State: 'Maryland'
  },
  {
    County: "Prince George's County",
    State: 'Maryland'
  },
  {
    County: "Queen Anne's County",
    State: 'Maryland'
  },
  {
    County: "St. Mary's County",
    State: 'Maryland'
  },
  {
    County: 'Somerset County',
    State: 'Maryland'
  },
  {
    County: 'Talbot County',
    State: 'Maryland'
  },
  {
    County: 'Washington County',
    State: 'Maryland'
  },
  {
    County: 'Wicomico County',
    State: 'Maryland'
  },
  {
    County: 'Worcester County',
    State: 'Maryland'
  },
  {
    County: 'Baltimore, City of',
    State: 'Maryland'
  },
  {
    County: 'Barnstable County',
    State: 'Massachusetts'
  },
  {
    County: 'Berkshire County',
    State: 'Massachusetts'
  },
  {
    County: 'Bristol County',
    State: 'Massachusetts'
  },
  {
    County: 'Dukes County',
    State: 'Massachusetts'
  },
  {
    County: 'Essex County',
    State: 'Massachusetts'
  },
  {
    County: 'Franklin County',
    State: 'Massachusetts'
  },
  {
    County: 'Hampden County',
    State: 'Massachusetts'
  },
  {
    County: 'Hampshire County',
    State: 'Massachusetts'
  },
  {
    County: 'Middlesex County',
    State: 'Massachusetts'
  },
  {
    County: 'Nantucket, Town and County of',
    State: 'Massachusetts'
  },
  {
    County: 'Norfolk County',
    State: 'Massachusetts'
  },
  {
    County: 'Plymouth County',
    State: 'Massachusetts'
  },
  {
    County: 'Suffolk County',
    State: 'Massachusetts'
  },
  {
    County: 'Worcester County',
    State: 'Massachusetts'
  },
  {
    County: 'Alcona County',
    State: 'Michigan'
  },
  {
    County: 'Alger County',
    State: 'Michigan'
  },
  {
    County: 'Allegan County',
    State: 'Michigan'
  },
  {
    County: 'Alpena County',
    State: 'Michigan'
  },
  {
    County: 'Antrim County',
    State: 'Michigan'
  },
  {
    County: 'Arenac County',
    State: 'Michigan'
  },
  {
    County: 'Baraga County',
    State: 'Michigan'
  },
  {
    County: 'Barry County',
    State: 'Michigan'
  },
  {
    County: 'Bay County',
    State: 'Michigan'
  },
  {
    County: 'Benzie County',
    State: 'Michigan'
  },
  {
    County: 'Berrien County',
    State: 'Michigan'
  },
  {
    County: 'Branch County',
    State: 'Michigan'
  },
  {
    County: 'Calhoun County',
    State: 'Michigan'
  },
  {
    County: 'Cass County',
    State: 'Michigan'
  },
  {
    County: 'Charlevoix County',
    State: 'Michigan'
  },
  {
    County: 'Cheboygan County',
    State: 'Michigan'
  },
  {
    County: 'Chippewa County',
    State: 'Michigan'
  },
  {
    County: 'Clare County',
    State: 'Michigan'
  },
  {
    County: 'Clinton County',
    State: 'Michigan'
  },
  {
    County: 'Crawford County',
    State: 'Michigan'
  },
  {
    County: 'Delta County',
    State: 'Michigan'
  },
  {
    County: 'Dickinson County',
    State: 'Michigan'
  },
  {
    County: 'Eaton County',
    State: 'Michigan'
  },
  {
    County: 'Emmet County',
    State: 'Michigan'
  },
  {
    County: 'Genesee County',
    State: 'Michigan'
  },
  {
    County: 'Gladwin County',
    State: 'Michigan'
  },
  {
    County: 'Gogebic County',
    State: 'Michigan'
  },
  {
    County: 'Grand Traverse County',
    State: 'Michigan'
  },
  {
    County: 'Gratiot County',
    State: 'Michigan'
  },
  {
    County: 'Hillsdale County',
    State: 'Michigan'
  },
  {
    County: 'Houghton County',
    State: 'Michigan'
  },
  {
    County: 'Huron County',
    State: 'Michigan'
  },
  {
    County: 'Ingham County',
    State: 'Michigan'
  },
  {
    County: 'Ionia County',
    State: 'Michigan'
  },
  {
    County: 'Iosco County',
    State: 'Michigan'
  },
  {
    County: 'Iron County',
    State: 'Michigan'
  },
  {
    County: 'Isabella County',
    State: 'Michigan'
  },
  {
    County: 'Jackson County',
    State: 'Michigan'
  },
  {
    County: 'Kalamazoo County',
    State: 'Michigan'
  },
  {
    County: 'Kalkaska County',
    State: 'Michigan'
  },
  {
    County: 'Kent County',
    State: 'Michigan'
  },
  {
    County: 'Keweenaw County',
    State: 'Michigan'
  },
  {
    County: 'Lake County',
    State: 'Michigan'
  },
  {
    County: 'Lapeer County',
    State: 'Michigan'
  },
  {
    County: 'Leelanau County',
    State: 'Michigan'
  },
  {
    County: 'Lenawee County',
    State: 'Michigan'
  },
  {
    County: 'Livingston County',
    State: 'Michigan'
  },
  {
    County: 'Luce County',
    State: 'Michigan'
  },
  {
    County: 'Mackinac County',
    State: 'Michigan'
  },
  {
    County: 'Macomb County',
    State: 'Michigan'
  },
  {
    County: 'Manistee County',
    State: 'Michigan'
  },
  {
    County: 'Marquette County',
    State: 'Michigan'
  },
  {
    County: 'Mason County',
    State: 'Michigan'
  },
  {
    County: 'Mecosta County',
    State: 'Michigan'
  },
  {
    County: 'Menominee County',
    State: 'Michigan'
  },
  {
    County: 'Midland County',
    State: 'Michigan'
  },
  {
    County: 'Missaukee County',
    State: 'Michigan'
  },
  {
    County: 'Monroe County',
    State: 'Michigan'
  },
  {
    County: 'Montcalm County',
    State: 'Michigan'
  },
  {
    County: 'Montmorency County',
    State: 'Michigan'
  },
  {
    County: 'Muskegon County',
    State: 'Michigan'
  },
  {
    County: 'Newaygo County',
    State: 'Michigan'
  },
  {
    County: 'Oakland County',
    State: 'Michigan'
  },
  {
    County: 'Oceana County',
    State: 'Michigan'
  },
  {
    County: 'Ogemaw County',
    State: 'Michigan'
  },
  {
    County: 'Ontonagon County',
    State: 'Michigan'
  },
  {
    County: 'Osceola County',
    State: 'Michigan'
  },
  {
    County: 'Oscoda County',
    State: 'Michigan'
  },
  {
    County: 'Otsego County',
    State: 'Michigan'
  },
  {
    County: 'Ottawa County',
    State: 'Michigan'
  },
  {
    County: 'Presque Isle County',
    State: 'Michigan'
  },
  {
    County: 'Roscommon County',
    State: 'Michigan'
  },
  {
    County: 'Saginaw County',
    State: 'Michigan'
  },
  {
    County: 'St. Clair County',
    State: 'Michigan'
  },
  {
    County: 'St. Joseph County',
    State: 'Michigan'
  },
  {
    County: 'Sanilac County',
    State: 'Michigan'
  },
  {
    County: 'Schoolcraft County',
    State: 'Michigan'
  },
  {
    County: 'Shiawassee County',
    State: 'Michigan'
  },
  {
    County: 'Tuscola County',
    State: 'Michigan'
  },
  {
    County: 'Van Buren County',
    State: 'Michigan'
  },
  {
    County: 'Washtenaw County',
    State: 'Michigan'
  },
  {
    County: 'Wayne County',
    State: 'Michigan'
  },
  {
    County: 'Wexford County',
    State: 'Michigan'
  },
  {
    County: 'Aitkin County',
    State: 'Minnesota'
  },
  {
    County: 'Anoka County',
    State: 'Minnesota'
  },
  {
    County: 'Becker County',
    State: 'Minnesota'
  },
  {
    County: 'Beltrami County',
    State: 'Minnesota'
  },
  {
    County: 'Benton County',
    State: 'Minnesota'
  },
  {
    County: 'Big Stone County',
    State: 'Minnesota'
  },
  {
    County: 'Blue Earth County',
    State: 'Minnesota'
  },
  {
    County: 'Brown County',
    State: 'Minnesota'
  },
  {
    County: 'Carlton County',
    State: 'Minnesota'
  },
  {
    County: 'Carver County',
    State: 'Minnesota'
  },
  {
    County: 'Cass County',
    State: 'Minnesota'
  },
  {
    County: 'Chippewa County',
    State: 'Minnesota'
  },
  {
    County: 'Chisago County',
    State: 'Minnesota'
  },
  {
    County: 'Clay County',
    State: 'Minnesota'
  },
  {
    County: 'Clearwater County',
    State: 'Minnesota'
  },
  {
    County: 'Cook County',
    State: 'Minnesota'
  },
  {
    County: 'Cottonwood County',
    State: 'Minnesota'
  },
  {
    County: 'Crow Wing County',
    State: 'Minnesota'
  },
  {
    County: 'Dakota County',
    State: 'Minnesota'
  },
  {
    County: 'Dodge County',
    State: 'Minnesota'
  },
  {
    County: 'Douglas County',
    State: 'Minnesota'
  },
  {
    County: 'Faribault County',
    State: 'Minnesota'
  },
  {
    County: 'Fillmore County',
    State: 'Minnesota'
  },
  {
    County: 'Freeborn County',
    State: 'Minnesota'
  },
  {
    County: 'Goodhue County',
    State: 'Minnesota'
  },
  {
    County: 'Grant County',
    State: 'Minnesota'
  },
  {
    County: 'Hennepin County',
    State: 'Minnesota'
  },
  {
    County: 'Houston County',
    State: 'Minnesota'
  },
  {
    County: 'Hubbard County',
    State: 'Minnesota'
  },
  {
    County: 'Isanti County',
    State: 'Minnesota'
  },
  {
    County: 'Itasca County',
    State: 'Minnesota'
  },
  {
    County: 'Jackson County',
    State: 'Minnesota'
  },
  {
    County: 'Kanabec County',
    State: 'Minnesota'
  },
  {
    County: 'Kandiyohi County',
    State: 'Minnesota'
  },
  {
    County: 'Kittson County',
    State: 'Minnesota'
  },
  {
    County: 'Koochiching County',
    State: 'Minnesota'
  },
  {
    County: 'Lac qui Parle County',
    State: 'Minnesota'
  },
  {
    County: 'Lake County',
    State: 'Minnesota'
  },
  {
    County: 'Lake of the Woods County',
    State: 'Minnesota'
  },
  {
    County: 'Le Sueur County',
    State: 'Minnesota'
  },
  {
    County: 'Lincoln County',
    State: 'Minnesota'
  },
  {
    County: 'Lyon County',
    State: 'Minnesota'
  },
  {
    County: 'McLeod County',
    State: 'Minnesota'
  },
  {
    County: 'Mahnomen County',
    State: 'Minnesota'
  },
  {
    County: 'Marshall County',
    State: 'Minnesota'
  },
  {
    County: 'Martin County',
    State: 'Minnesota'
  },
  {
    County: 'Meeker County',
    State: 'Minnesota'
  },
  {
    County: 'Mille Lacs County',
    State: 'Minnesota'
  },
  {
    County: 'Morrison County',
    State: 'Minnesota'
  },
  {
    County: 'Mower County',
    State: 'Minnesota'
  },
  {
    County: 'Murray County',
    State: 'Minnesota'
  },
  {
    County: 'Nicollet County',
    State: 'Minnesota'
  },
  {
    County: 'Nobles County',
    State: 'Minnesota'
  },
  {
    County: 'Norman County',
    State: 'Minnesota'
  },
  {
    County: 'Olmsted County',
    State: 'Minnesota'
  },
  {
    County: 'Otter Tail County',
    State: 'Minnesota'
  },
  {
    County: 'Pennington County',
    State: 'Minnesota'
  },
  {
    County: 'Pine County',
    State: 'Minnesota'
  },
  {
    County: 'Pipestone County',
    State: 'Minnesota'
  },
  {
    County: 'Polk County',
    State: 'Minnesota'
  },
  {
    County: 'Pope County',
    State: 'Minnesota'
  },
  {
    County: 'Ramsey County',
    State: 'Minnesota'
  },
  {
    County: 'Red Lake County',
    State: 'Minnesota'
  },
  {
    County: 'Redwood County',
    State: 'Minnesota'
  },
  {
    County: 'Renville County',
    State: 'Minnesota'
  },
  {
    County: 'Rice County',
    State: 'Minnesota'
  },
  {
    County: 'Rock County',
    State: 'Minnesota'
  },
  {
    County: 'Roseau County',
    State: 'Minnesota'
  },
  {
    County: 'St. Louis County',
    State: 'Minnesota'
  },
  {
    County: 'Scott County',
    State: 'Minnesota'
  },
  {
    County: 'Sherburne County',
    State: 'Minnesota'
  },
  {
    County: 'Sibley County',
    State: 'Minnesota'
  },
  {
    County: 'Stearns County',
    State: 'Minnesota'
  },
  {
    County: 'Steele County',
    State: 'Minnesota'
  },
  {
    County: 'Stevens County',
    State: 'Minnesota'
  },
  {
    County: 'Swift County',
    State: 'Minnesota'
  },
  {
    County: 'Todd County',
    State: 'Minnesota'
  },
  {
    County: 'Traverse County',
    State: 'Minnesota'
  },
  {
    County: 'Wabasha County',
    State: 'Minnesota'
  },
  {
    County: 'Wadena County',
    State: 'Minnesota'
  },
  {
    County: 'Waseca County',
    State: 'Minnesota'
  },
  {
    County: 'Washington County',
    State: 'Minnesota'
  },
  {
    County: 'Watonwan County',
    State: 'Minnesota'
  },
  {
    County: 'Wilkin County',
    State: 'Minnesota'
  },
  {
    County: 'Winona County',
    State: 'Minnesota'
  },
  {
    County: 'Wright County',
    State: 'Minnesota'
  },
  {
    County: 'Yellow Medicine County',
    State: 'Minnesota'
  },
  {
    County: 'Adams County',
    State: 'Mississippi'
  },
  {
    County: 'Alcorn County',
    State: 'Mississippi'
  },
  {
    County: 'Amite County',
    State: 'Mississippi'
  },
  {
    County: 'Attala County',
    State: 'Mississippi'
  },
  {
    County: 'Benton County',
    State: 'Mississippi'
  },
  {
    County: 'Bolivar County',
    State: 'Mississippi'
  },
  {
    County: 'Calhoun County',
    State: 'Mississippi'
  },
  {
    County: 'Carroll County',
    State: 'Mississippi'
  },
  {
    County: 'Chickasaw County',
    State: 'Mississippi'
  },
  {
    County: 'Choctaw County',
    State: 'Mississippi'
  },
  {
    County: 'Claiborne County',
    State: 'Mississippi'
  },
  {
    County: 'Clarke County',
    State: 'Mississippi'
  },
  {
    County: 'Clay County',
    State: 'Mississippi'
  },
  {
    County: 'Coahoma County',
    State: 'Mississippi'
  },
  {
    County: 'Copiah County',
    State: 'Mississippi'
  },
  {
    County: 'Covington County',
    State: 'Mississippi'
  },
  {
    County: 'DeSoto County',
    State: 'Mississippi'
  },
  {
    County: 'Forrest County',
    State: 'Mississippi'
  },
  {
    County: 'Franklin County',
    State: 'Mississippi'
  },
  {
    County: 'George County',
    State: 'Mississippi'
  },
  {
    County: 'Greene County',
    State: 'Mississippi'
  },
  {
    County: 'Grenada County',
    State: 'Mississippi'
  },
  {
    County: 'Hancock County',
    State: 'Mississippi'
  },
  {
    County: 'Harrison County',
    State: 'Mississippi'
  },
  {
    County: 'Hinds County',
    State: 'Mississippi'
  },
  {
    County: 'Holmes County',
    State: 'Mississippi'
  },
  {
    County: 'Humphreys County',
    State: 'Mississippi'
  },
  {
    County: 'Issaquena County',
    State: 'Mississippi'
  },
  {
    County: 'Itawamba County',
    State: 'Mississippi'
  },
  {
    County: 'Jackson County',
    State: 'Mississippi'
  },
  {
    County: 'Jasper County',
    State: 'Mississippi'
  },
  {
    County: 'Jefferson County',
    State: 'Mississippi'
  },
  {
    County: 'Jefferson Davis County',
    State: 'Mississippi'
  },
  {
    County: 'Jones County',
    State: 'Mississippi'
  },
  {
    County: 'Kemper County',
    State: 'Mississippi'
  },
  {
    County: 'Lafayette County',
    State: 'Mississippi'
  },
  {
    County: 'Lamar County',
    State: 'Mississippi'
  },
  {
    County: 'Lauderdale County',
    State: 'Mississippi'
  },
  {
    County: 'Lawrence County',
    State: 'Mississippi'
  },
  {
    County: 'Leake County',
    State: 'Mississippi'
  },
  {
    County: 'Lee County',
    State: 'Mississippi'
  },
  {
    County: 'Leflore County',
    State: 'Mississippi'
  },
  {
    County: 'Lincoln County',
    State: 'Mississippi'
  },
  {
    County: 'Lowndes County',
    State: 'Mississippi'
  },
  {
    County: 'Madison County',
    State: 'Mississippi'
  },
  {
    County: 'Marion County',
    State: 'Mississippi'
  },
  {
    County: 'Marshall County',
    State: 'Mississippi'
  },
  {
    County: 'Monroe County',
    State: 'Mississippi'
  },
  {
    County: 'Montgomery County',
    State: 'Mississippi'
  },
  {
    County: 'Neshoba County',
    State: 'Mississippi'
  },
  {
    County: 'Newton County',
    State: 'Mississippi'
  },
  {
    County: 'Noxubee County',
    State: 'Mississippi'
  },
  {
    County: 'Oktibbeha County',
    State: 'Mississippi'
  },
  {
    County: 'Panola County',
    State: 'Mississippi'
  },
  {
    County: 'Pearl River County',
    State: 'Mississippi'
  },
  {
    County: 'Perry County',
    State: 'Mississippi'
  },
  {
    County: 'Pike County',
    State: 'Mississippi'
  },
  {
    County: 'Pontotoc County',
    State: 'Mississippi'
  },
  {
    County: 'Prentiss County',
    State: 'Mississippi'
  },
  {
    County: 'Quitman County',
    State: 'Mississippi'
  },
  {
    County: 'Rankin County',
    State: 'Mississippi'
  },
  {
    County: 'Scott County',
    State: 'Mississippi'
  },
  {
    County: 'Sharkey County',
    State: 'Mississippi'
  },
  {
    County: 'Simpson County',
    State: 'Mississippi'
  },
  {
    County: 'Smith County',
    State: 'Mississippi'
  },
  {
    County: 'Stone County',
    State: 'Mississippi'
  },
  {
    County: 'Sunflower County',
    State: 'Mississippi'
  },
  {
    County: 'Tallahatchie County',
    State: 'Mississippi'
  },
  {
    County: 'Tate County',
    State: 'Mississippi'
  },
  {
    County: 'Tippah County',
    State: 'Mississippi'
  },
  {
    County: 'Tishomingo County',
    State: 'Mississippi'
  },
  {
    County: 'Tunica County',
    State: 'Mississippi'
  },
  {
    County: 'Union County',
    State: 'Mississippi'
  },
  {
    County: 'Walthall County',
    State: 'Mississippi'
  },
  {
    County: 'Warren County',
    State: 'Mississippi'
  },
  {
    County: 'Washington County',
    State: 'Mississippi'
  },
  {
    County: 'Wayne County',
    State: 'Mississippi'
  },
  {
    County: 'Webster County',
    State: 'Mississippi'
  },
  {
    County: 'Wilkinson County',
    State: 'Mississippi'
  },
  {
    County: 'Winston County',
    State: 'Mississippi'
  },
  {
    County: 'Yalobusha County',
    State: 'Mississippi'
  },
  {
    County: 'Yazoo County',
    State: 'Mississippi'
  },
  {
    County: 'Adair County',
    State: 'Missouri'
  },
  {
    County: 'Andrew County',
    State: 'Missouri'
  },
  {
    County: 'Atchison County',
    State: 'Missouri'
  },
  {
    County: 'Audrain County',
    State: 'Missouri'
  },
  {
    County: 'Barry County',
    State: 'Missouri'
  },
  {
    County: 'Barton County',
    State: 'Missouri'
  },
  {
    County: 'Bates County',
    State: 'Missouri'
  },
  {
    County: 'Benton County',
    State: 'Missouri'
  },
  {
    County: 'Bollinger County',
    State: 'Missouri'
  },
  {
    County: 'Boone County',
    State: 'Missouri'
  },
  {
    County: 'Buchanan County',
    State: 'Missouri'
  },
  {
    County: 'Butler County',
    State: 'Missouri'
  },
  {
    County: 'Caldwell County',
    State: 'Missouri'
  },
  {
    County: 'Callaway County',
    State: 'Missouri'
  },
  {
    County: 'Camden County',
    State: 'Missouri'
  },
  {
    County: 'Cape Girardeau County',
    State: 'Missouri'
  },
  {
    County: 'Carroll County',
    State: 'Missouri'
  },
  {
    County: 'Carter County',
    State: 'Missouri'
  },
  {
    County: 'Cass County',
    State: 'Missouri'
  },
  {
    County: 'Cedar County',
    State: 'Missouri'
  },
  {
    County: 'Chariton County',
    State: 'Missouri'
  },
  {
    County: 'Christian County',
    State: 'Missouri'
  },
  {
    County: 'Clark County',
    State: 'Missouri'
  },
  {
    County: 'Clay County',
    State: 'Missouri'
  },
  {
    County: 'Clinton County',
    State: 'Missouri'
  },
  {
    County: 'Cole County',
    State: 'Missouri'
  },
  {
    County: 'Cooper County',
    State: 'Missouri'
  },
  {
    County: 'Crawford County',
    State: 'Missouri'
  },
  {
    County: 'Dade County',
    State: 'Missouri'
  },
  {
    County: 'Dallas County',
    State: 'Missouri'
  },
  {
    County: 'Daviess County',
    State: 'Missouri'
  },
  {
    County: 'DeKalb County',
    State: 'Missouri'
  },
  {
    County: 'Dent County',
    State: 'Missouri'
  },
  {
    County: 'Douglas County',
    State: 'Missouri'
  },
  {
    County: 'Dunklin County',
    State: 'Missouri'
  },
  {
    County: 'Franklin County',
    State: 'Missouri'
  },
  {
    County: 'Gasconade County',
    State: 'Missouri'
  },
  {
    County: 'Gentry County',
    State: 'Missouri'
  },
  {
    County: 'Greene County',
    State: 'Missouri'
  },
  {
    County: 'Grundy County',
    State: 'Missouri'
  },
  {
    County: 'Harrison County',
    State: 'Missouri'
  },
  {
    County: 'Henry County',
    State: 'Missouri'
  },
  {
    County: 'Hickory County',
    State: 'Missouri'
  },
  {
    County: 'Holt County',
    State: 'Missouri'
  },
  {
    County: 'Howard County',
    State: 'Missouri'
  },
  {
    County: 'Howell County',
    State: 'Missouri'
  },
  {
    County: 'Iron County',
    State: 'Missouri'
  },
  {
    County: 'Jackson County',
    State: 'Missouri'
  },
  {
    County: 'Jasper County',
    State: 'Missouri'
  },
  {
    County: 'Jefferson County',
    State: 'Missouri'
  },
  {
    County: 'Johnson County',
    State: 'Missouri'
  },
  {
    County: 'Knox County',
    State: 'Missouri'
  },
  {
    County: 'Laclede County',
    State: 'Missouri'
  },
  {
    County: 'Lafayette County',
    State: 'Missouri'
  },
  {
    County: 'Lawrence County',
    State: 'Missouri'
  },
  {
    County: 'Lewis County',
    State: 'Missouri'
  },
  {
    County: 'Lincoln County',
    State: 'Missouri'
  },
  {
    County: 'Linn County',
    State: 'Missouri'
  },
  {
    County: 'Livingston County',
    State: 'Missouri'
  },
  {
    County: 'McDonald County',
    State: 'Missouri'
  },
  {
    County: 'Macon County',
    State: 'Missouri'
  },
  {
    County: 'Madison County',
    State: 'Missouri'
  },
  {
    County: 'Maries County',
    State: 'Missouri'
  },
  {
    County: 'Marion County',
    State: 'Missouri'
  },
  {
    County: 'Mercer County',
    State: 'Missouri'
  },
  {
    County: 'Miller County',
    State: 'Missouri'
  },
  {
    County: 'Mississippi County',
    State: 'Missouri'
  },
  {
    County: 'Moniteau County',
    State: 'Missouri'
  },
  {
    County: 'Monroe County',
    State: 'Missouri'
  },
  {
    County: 'Montgomery County',
    State: 'Missouri'
  },
  {
    County: 'Morgan County',
    State: 'Missouri'
  },
  {
    County: 'New Madrid County',
    State: 'Missouri'
  },
  {
    County: 'Newton County',
    State: 'Missouri'
  },
  {
    County: 'Nodaway County',
    State: 'Missouri'
  },
  {
    County: 'Oregon County',
    State: 'Missouri'
  },
  {
    County: 'Osage County',
    State: 'Missouri'
  },
  {
    County: 'Ozark County',
    State: 'Missouri'
  },
  {
    County: 'Pemiscot County',
    State: 'Missouri'
  },
  {
    County: 'Perry County',
    State: 'Missouri'
  },
  {
    County: 'Pettis County',
    State: 'Missouri'
  },
  {
    County: 'Phelps County',
    State: 'Missouri'
  },
  {
    County: 'Pike County',
    State: 'Missouri'
  },
  {
    County: 'Platte County',
    State: 'Missouri'
  },
  {
    County: 'Polk County',
    State: 'Missouri'
  },
  {
    County: 'Pulaski County',
    State: 'Missouri'
  },
  {
    County: 'Putnam County',
    State: 'Missouri'
  },
  {
    County: 'Ralls County',
    State: 'Missouri'
  },
  {
    County: 'Randolph County',
    State: 'Missouri'
  },
  {
    County: 'Ray County',
    State: 'Missouri'
  },
  {
    County: 'Reynolds County',
    State: 'Missouri'
  },
  {
    County: 'Ripley County',
    State: 'Missouri'
  },
  {
    County: 'St. Charles County',
    State: 'Missouri'
  },
  {
    County: 'St. Clair County',
    State: 'Missouri'
  },
  {
    County: 'Ste. Genevieve County',
    State: 'Missouri'
  },
  {
    County: 'St. Francois County',
    State: 'Missouri'
  },
  {
    County: 'St. Louis County',
    State: 'Missouri'
  },
  {
    County: 'Saline County',
    State: 'Missouri'
  },
  {
    County: 'Schuyler County',
    State: 'Missouri'
  },
  {
    County: 'Scotland County',
    State: 'Missouri'
  },
  {
    County: 'Scott County',
    State: 'Missouri'
  },
  {
    County: 'Shannon County',
    State: 'Missouri'
  },
  {
    County: 'Shelby County',
    State: 'Missouri'
  },
  {
    County: 'Stoddard County',
    State: 'Missouri'
  },
  {
    County: 'Stone County',
    State: 'Missouri'
  },
  {
    County: 'Sullivan County',
    State: 'Missouri'
  },
  {
    County: 'Taney County',
    State: 'Missouri'
  },
  {
    County: 'Texas County',
    State: 'Missouri'
  },
  {
    County: 'Vernon County',
    State: 'Missouri'
  },
  {
    County: 'Warren County',
    State: 'Missouri'
  },
  {
    County: 'Washington County',
    State: 'Missouri'
  },
  {
    County: 'Wayne County',
    State: 'Missouri'
  },
  {
    County: 'Webster County',
    State: 'Missouri'
  },
  {
    County: 'Worth County',
    State: 'Missouri'
  },
  {
    County: 'Wright County',
    State: 'Missouri'
  },
  {
    County: 'St. Louis, City of',
    State: 'Missouri'
  },
  {
    County: 'Beaverhead County',
    State: 'Montana'
  },
  {
    County: 'Big Horn County',
    State: 'Montana'
  },
  {
    County: 'Blaine County',
    State: 'Montana'
  },
  {
    County: 'Broadwater County',
    State: 'Montana'
  },
  {
    County: 'Carbon County',
    State: 'Montana'
  },
  {
    County: 'Carter County',
    State: 'Montana'
  },
  {
    County: 'Cascade County',
    State: 'Montana'
  },
  {
    County: 'Chouteau County',
    State: 'Montana'
  },
  {
    County: 'Custer County',
    State: 'Montana'
  },
  {
    County: 'Daniels County',
    State: 'Montana'
  },
  {
    County: 'Dawson County',
    State: 'Montana'
  },
  {
    County: 'Deer Lodge County',
    State: 'Montana'
  },
  {
    County: 'Fallon County',
    State: 'Montana'
  },
  {
    County: 'Fergus County',
    State: 'Montana'
  },
  {
    County: 'Flathead County',
    State: 'Montana'
  },
  {
    County: 'Gallatin County',
    State: 'Montana'
  },
  {
    County: 'Garfield County',
    State: 'Montana'
  },
  {
    County: 'Glacier County',
    State: 'Montana'
  },
  {
    County: 'Golden Valley County',
    State: 'Montana'
  },
  {
    County: 'Granite County',
    State: 'Montana'
  },
  {
    County: 'Hill County',
    State: 'Montana'
  },
  {
    County: 'Jefferson County',
    State: 'Montana'
  },
  {
    County: 'Judith Basin County',
    State: 'Montana'
  },
  {
    County: 'Lake County',
    State: 'Montana'
  },
  {
    County: 'Lewis and Clark County',
    State: 'Montana'
  },
  {
    County: 'Liberty County',
    State: 'Montana'
  },
  {
    County: 'Lincoln County',
    State: 'Montana'
  },
  {
    County: 'McCone County',
    State: 'Montana'
  },
  {
    County: 'Madison County',
    State: 'Montana'
  },
  {
    County: 'Meagher County',
    State: 'Montana'
  },
  {
    County: 'Mineral County',
    State: 'Montana'
  },
  {
    County: 'Missoula County',
    State: 'Montana'
  },
  {
    County: 'Musselshell County',
    State: 'Montana'
  },
  {
    County: 'Park County',
    State: 'Montana'
  },
  {
    County: 'Petroleum County',
    State: 'Montana'
  },
  {
    County: 'Phillips County',
    State: 'Montana'
  },
  {
    County: 'Pondera County',
    State: 'Montana'
  },
  {
    County: 'Powder River County',
    State: 'Montana'
  },
  {
    County: 'Powell County',
    State: 'Montana'
  },
  {
    County: 'Prairie County',
    State: 'Montana'
  },
  {
    County: 'Ravalli County',
    State: 'Montana'
  },
  {
    County: 'Richland County',
    State: 'Montana'
  },
  {
    County: 'Roosevelt County',
    State: 'Montana'
  },
  {
    County: 'Rosebud County',
    State: 'Montana'
  },
  {
    County: 'Sanders County',
    State: 'Montana'
  },
  {
    County: 'Sheridan County',
    State: 'Montana'
  },
  {
    County: 'Silver Bow County',
    State: 'Montana'
  },
  {
    County: 'Stillwater County',
    State: 'Montana'
  },
  {
    County: 'Sweet Grass County',
    State: 'Montana'
  },
  {
    County: 'Teton County',
    State: 'Montana'
  },
  {
    County: 'Toole County',
    State: 'Montana'
  },
  {
    County: 'Treasure County',
    State: 'Montana'
  },
  {
    County: 'Valley County',
    State: 'Montana'
  },
  {
    County: 'Wheatland County',
    State: 'Montana'
  },
  {
    County: 'Wibaux County',
    State: 'Montana'
  },
  {
    County: 'Yellowstone County',
    State: 'Montana'
  },
  {
    County: 'Adams County',
    State: 'Nebraska'
  },
  {
    County: 'Antelope County',
    State: 'Nebraska'
  },
  {
    County: 'Arthur County',
    State: 'Nebraska'
  },
  {
    County: 'Banner County',
    State: 'Nebraska'
  },
  {
    County: 'Blaine County',
    State: 'Nebraska'
  },
  {
    County: 'Boone County',
    State: 'Nebraska'
  },
  {
    County: 'Box Butte County',
    State: 'Nebraska'
  },
  {
    County: 'Boyd County',
    State: 'Nebraska'
  },
  {
    County: 'Brown County',
    State: 'Nebraska'
  },
  {
    County: 'Buffalo County',
    State: 'Nebraska'
  },
  {
    County: 'Burt County',
    State: 'Nebraska'
  },
  {
    County: 'Butler County',
    State: 'Nebraska'
  },
  {
    County: 'Cass County',
    State: 'Nebraska'
  },
  {
    County: 'Cedar County',
    State: 'Nebraska'
  },
  {
    County: 'Chase County',
    State: 'Nebraska'
  },
  {
    County: 'Cherry County',
    State: 'Nebraska'
  },
  {
    County: 'Cheyenne County',
    State: 'Nebraska'
  },
  {
    County: 'Clay County',
    State: 'Nebraska'
  },
  {
    County: 'Colfax County',
    State: 'Nebraska'
  },
  {
    County: 'Cuming County',
    State: 'Nebraska'
  },
  {
    County: 'Custer County',
    State: 'Nebraska'
  },
  {
    County: 'Dakota County',
    State: 'Nebraska'
  },
  {
    County: 'Dawes County',
    State: 'Nebraska'
  },
  {
    County: 'Dawson County',
    State: 'Nebraska'
  },
  {
    County: 'Deuel County',
    State: 'Nebraska'
  },
  {
    County: 'Dixon County',
    State: 'Nebraska'
  },
  {
    County: 'Dodge County',
    State: 'Nebraska'
  },
  {
    County: 'Douglas County',
    State: 'Nebraska'
  },
  {
    County: 'Dundy County',
    State: 'Nebraska'
  },
  {
    County: 'Fillmore County',
    State: 'Nebraska'
  },
  {
    County: 'Franklin County',
    State: 'Nebraska'
  },
  {
    County: 'Frontier County',
    State: 'Nebraska'
  },
  {
    County: 'Furnas County',
    State: 'Nebraska'
  },
  {
    County: 'Gage County',
    State: 'Nebraska'
  },
  {
    County: 'Garden County',
    State: 'Nebraska'
  },
  {
    County: 'Garfield County',
    State: 'Nebraska'
  },
  {
    County: 'Gosper County',
    State: 'Nebraska'
  },
  {
    County: 'Grant County',
    State: 'Nebraska'
  },
  {
    County: 'Greeley County',
    State: 'Nebraska'
  },
  {
    County: 'Hall County',
    State: 'Nebraska'
  },
  {
    County: 'Hamilton County',
    State: 'Nebraska'
  },
  {
    County: 'Harlan County',
    State: 'Nebraska'
  },
  {
    County: 'Hayes County',
    State: 'Nebraska'
  },
  {
    County: 'Hitchcock County',
    State: 'Nebraska'
  },
  {
    County: 'Holt County',
    State: 'Nebraska'
  },
  {
    County: 'Hooker County',
    State: 'Nebraska'
  },
  {
    County: 'Howard County',
    State: 'Nebraska'
  },
  {
    County: 'Jefferson County',
    State: 'Nebraska'
  },
  {
    County: 'Johnson County',
    State: 'Nebraska'
  },
  {
    County: 'Kearney County',
    State: 'Nebraska'
  },
  {
    County: 'Keith County',
    State: 'Nebraska'
  },
  {
    County: 'Keya Paha County',
    State: 'Nebraska'
  },
  {
    County: 'Kimball County',
    State: 'Nebraska'
  },
  {
    County: 'Knox County',
    State: 'Nebraska'
  },
  {
    County: 'Lancaster County',
    State: 'Nebraska'
  },
  {
    County: 'Lincoln County',
    State: 'Nebraska'
  },
  {
    County: 'Logan County',
    State: 'Nebraska'
  },
  {
    County: 'Loup County',
    State: 'Nebraska'
  },
  {
    County: 'McPherson County',
    State: 'Nebraska'
  },
  {
    County: 'Madison County',
    State: 'Nebraska'
  },
  {
    County: 'Merrick County',
    State: 'Nebraska'
  },
  {
    County: 'Morrill County',
    State: 'Nebraska'
  },
  {
    County: 'Nance County',
    State: 'Nebraska'
  },
  {
    County: 'Nemaha County',
    State: 'Nebraska'
  },
  {
    County: 'Nuckolls County',
    State: 'Nebraska'
  },
  {
    County: 'Otoe County',
    State: 'Nebraska'
  },
  {
    County: 'Pawnee County',
    State: 'Nebraska'
  },
  {
    County: 'Perkins County',
    State: 'Nebraska'
  },
  {
    County: 'Phelps County',
    State: 'Nebraska'
  },
  {
    County: 'Pierce County',
    State: 'Nebraska'
  },
  {
    County: 'Platte County',
    State: 'Nebraska'
  },
  {
    County: 'Polk County',
    State: 'Nebraska'
  },
  {
    County: 'Red Willow County',
    State: 'Nebraska'
  },
  {
    County: 'Richardson County',
    State: 'Nebraska'
  },
  {
    County: 'Rock County',
    State: 'Nebraska'
  },
  {
    County: 'Saline County',
    State: 'Nebraska'
  },
  {
    County: 'Sarpy County',
    State: 'Nebraska'
  },
  {
    County: 'Saunders County',
    State: 'Nebraska'
  },
  {
    County: 'Scotts Bluff County',
    State: 'Nebraska'
  },
  {
    County: 'Seward County',
    State: 'Nebraska'
  },
  {
    County: 'Sheridan County',
    State: 'Nebraska'
  },
  {
    County: 'Sherman County',
    State: 'Nebraska'
  },
  {
    County: 'Sioux County',
    State: 'Nebraska'
  },
  {
    County: 'Stanton County',
    State: 'Nebraska'
  },
  {
    County: 'Thayer County',
    State: 'Nebraska'
  },
  {
    County: 'Thomas County',
    State: 'Nebraska'
  },
  {
    County: 'Thurston County',
    State: 'Nebraska'
  },
  {
    County: 'Valley County',
    State: 'Nebraska'
  },
  {
    County: 'Washington County',
    State: 'Nebraska'
  },
  {
    County: 'Wayne County',
    State: 'Nebraska'
  },
  {
    County: 'Webster County',
    State: 'Nebraska'
  },
  {
    County: 'Wheeler County',
    State: 'Nebraska'
  },
  {
    County: 'York County',
    State: 'Nebraska'
  },
  {
    County: 'Churchill County',
    State: 'Nevada'
  },
  {
    County: 'Clark County',
    State: 'Nevada'
  },
  {
    County: 'Douglas County',
    State: 'Nevada'
  },
  {
    County: 'Elko County',
    State: 'Nevada'
  },
  {
    County: 'Esmeralda County',
    State: 'Nevada'
  },
  {
    County: 'Eureka County',
    State: 'Nevada'
  },
  {
    County: 'Humboldt County',
    State: 'Nevada'
  },
  {
    County: 'Lander County',
    State: 'Nevada'
  },
  {
    County: 'Lincoln County',
    State: 'Nevada'
  },
  {
    County: 'Lyon County',
    State: 'Nevada'
  },
  {
    County: 'Mineral County',
    State: 'Nevada'
  },
  {
    County: 'Nye County',
    State: 'Nevada'
  },
  {
    County: 'Pershing County',
    State: 'Nevada'
  },
  {
    County: 'Storey County',
    State: 'Nevada'
  },
  {
    County: 'Washoe County',
    State: 'Nevada'
  },
  {
    County: 'White Pine County',
    State: 'Nevada'
  },
  {
    County: 'Carson City, Consolidated Municipality of',
    State: 'Nevada'
  },
  {
    County: 'Belknap County',
    State: 'New Hampshire'
  },
  {
    County: 'Carroll County',
    State: 'New Hampshire'
  },
  {
    County: 'Cheshire County',
    State: 'New Hampshire'
  },
  {
    County: 'Coos County',
    State: 'New Hampshire'
  },
  {
    County: 'Grafton County',
    State: 'New Hampshire'
  },
  {
    County: 'Hillsborough County',
    State: 'New Hampshire'
  },
  {
    County: 'Merrimack County',
    State: 'New Hampshire'
  },
  {
    County: 'Rockingham County',
    State: 'New Hampshire'
  },
  {
    County: 'Strafford County',
    State: 'New Hampshire'
  },
  {
    County: 'Sullivan County',
    State: 'New Hampshire'
  },
  {
    County: 'Atlantic County',
    State: 'New Jersey'
  },
  {
    County: 'Bergen County',
    State: 'New Jersey'
  },
  {
    County: 'Burlington County',
    State: 'New Jersey'
  },
  {
    County: 'Camden County',
    State: 'New Jersey'
  },
  {
    County: 'Cape May County',
    State: 'New Jersey'
  },
  {
    County: 'Cumberland County',
    State: 'New Jersey'
  },
  {
    County: 'Essex County',
    State: 'New Jersey'
  },
  {
    County: 'Gloucester County',
    State: 'New Jersey'
  },
  {
    County: 'Hudson County',
    State: 'New Jersey'
  },
  {
    County: 'Hunterdon County',
    State: 'New Jersey'
  },
  {
    County: 'Mercer County',
    State: 'New Jersey'
  },
  {
    County: 'Middlesex County',
    State: 'New Jersey'
  },
  {
    County: 'Monmouth County',
    State: 'New Jersey'
  },
  {
    County: 'Morris County',
    State: 'New Jersey'
  },
  {
    County: 'Ocean County',
    State: 'New Jersey'
  },
  {
    County: 'Passaic County',
    State: 'New Jersey'
  },
  {
    County: 'Salem County',
    State: 'New Jersey'
  },
  {
    County: 'Somerset County',
    State: 'New Jersey'
  },
  {
    County: 'Sussex County',
    State: 'New Jersey'
  },
  {
    County: 'Union County',
    State: 'New Jersey'
  },
  {
    County: 'Warren County',
    State: 'New Jersey'
  },
  {
    County: 'Bernalillo County',
    State: 'New Mexico'
  },
  {
    County: 'Catron County',
    State: 'New Mexico'
  },
  {
    County: 'Chaves County',
    State: 'New Mexico'
  },
  {
    County: 'Cibola County',
    State: 'New Mexico'
  },
  {
    County: 'Colfax County',
    State: 'New Mexico'
  },
  {
    County: 'Curry County',
    State: 'New Mexico'
  },
  {
    County: 'De Baca County',
    State: 'New Mexico'
  },
  {
    County: 'Do�a Ana County',
    State: 'New Mexico'
  },
  {
    County: 'Eddy County',
    State: 'New Mexico'
  },
  {
    County: 'Grant County',
    State: 'New Mexico'
  },
  {
    County: 'Guadalupe County',
    State: 'New Mexico'
  },
  {
    County: 'Harding County',
    State: 'New Mexico'
  },
  {
    County: 'Hidalgo County',
    State: 'New Mexico'
  },
  {
    County: 'Lea County',
    State: 'New Mexico'
  },
  {
    County: 'Lincoln County',
    State: 'New Mexico'
  },
  {
    County: 'Los Alamos County',
    State: 'New Mexico'
  },
  {
    County: 'Luna County',
    State: 'New Mexico'
  },
  {
    County: 'McKinley County',
    State: 'New Mexico'
  },
  {
    County: 'Mora County',
    State: 'New Mexico'
  },
  {
    County: 'Otero County',
    State: 'New Mexico'
  },
  {
    County: 'Quay County',
    State: 'New Mexico'
  },
  {
    County: 'Rio Arriba County',
    State: 'New Mexico'
  },
  {
    County: 'Roosevelt County',
    State: 'New Mexico'
  },
  {
    County: 'Sandoval County',
    State: 'New Mexico'
  },
  {
    County: 'San Juan County',
    State: 'New Mexico'
  },
  {
    County: 'San Miguel County',
    State: 'New Mexico'
  },
  {
    County: 'Santa Fe County',
    State: 'New Mexico'
  },
  {
    County: 'Sierra County',
    State: 'New Mexico'
  },
  {
    County: 'Socorro County',
    State: 'New Mexico'
  },
  {
    County: 'Taos County',
    State: 'New Mexico'
  },
  {
    County: 'Torrance County',
    State: 'New Mexico'
  },
  {
    County: 'Union County',
    State: 'New Mexico'
  },
  {
    County: 'Valencia County',
    State: 'New Mexico'
  },
  {
    County: 'Albany County',
    State: 'New York'
  },
  {
    County: 'Allegany County',
    State: 'New York'
  },
  {
    County: 'Bronx County',
    State: 'New York'
  },
  {
    County: 'Broome County',
    State: 'New York'
  },
  {
    County: 'Cattaraugus County',
    State: 'New York'
  },
  {
    County: 'Cayuga County',
    State: 'New York'
  },
  {
    County: 'Chautauqua County',
    State: 'New York'
  },
  {
    County: 'Chemung County',
    State: 'New York'
  },
  {
    County: 'Chenango County',
    State: 'New York'
  },
  {
    County: 'Clinton County',
    State: 'New York'
  },
  {
    County: 'Columbia County',
    State: 'New York'
  },
  {
    County: 'Cortland County',
    State: 'New York'
  },
  {
    County: 'Delaware County',
    State: 'New York'
  },
  {
    County: 'Dutchess County',
    State: 'New York'
  },
  {
    County: 'Erie County',
    State: 'New York'
  },
  {
    County: 'Essex County',
    State: 'New York'
  },
  {
    County: 'Franklin County',
    State: 'New York'
  },
  {
    County: 'Fulton County',
    State: 'New York'
  },
  {
    County: 'Genesee County',
    State: 'New York'
  },
  {
    County: 'Greene County',
    State: 'New York'
  },
  {
    County: 'Hamilton County',
    State: 'New York'
  },
  {
    County: 'Herkimer County',
    State: 'New York'
  },
  {
    County: 'Jefferson County',
    State: 'New York'
  },
  {
    County: 'Kings County',
    State: 'New York'
  },
  {
    County: 'Lewis County',
    State: 'New York'
  },
  {
    County: 'Livingston County',
    State: 'New York'
  },
  {
    County: 'Madison County',
    State: 'New York'
  },
  {
    County: 'Monroe County',
    State: 'New York'
  },
  {
    County: 'Montgomery County',
    State: 'New York'
  },
  {
    County: 'Nassau County',
    State: 'New York'
  },
  {
    County: 'New York County',
    State: 'New York'
  },
  {
    County: 'Niagara County',
    State: 'New York'
  },
  {
    County: 'Oneida County',
    State: 'New York'
  },
  {
    County: 'Onondaga County',
    State: 'New York'
  },
  {
    County: 'Ontario County',
    State: 'New York'
  },
  {
    County: 'Orange County',
    State: 'New York'
  },
  {
    County: 'Orleans County',
    State: 'New York'
  },
  {
    County: 'Oswego County',
    State: 'New York'
  },
  {
    County: 'Otsego County',
    State: 'New York'
  },
  {
    County: 'Putnam County',
    State: 'New York'
  },
  {
    County: 'Queens County',
    State: 'New York'
  },
  {
    County: 'Rensselaer County',
    State: 'New York'
  },
  {
    County: 'Richmond County',
    State: 'New York'
  },
  {
    County: 'Rockland County',
    State: 'New York'
  },
  {
    County: 'St. Lawrence County',
    State: 'New York'
  },
  {
    County: 'Saratoga County',
    State: 'New York'
  },
  {
    County: 'Schenectady County',
    State: 'New York'
  },
  {
    County: 'Schoharie County',
    State: 'New York'
  },
  {
    County: 'Schuyler County',
    State: 'New York'
  },
  {
    County: 'Seneca County',
    State: 'New York'
  },
  {
    County: 'Steuben County',
    State: 'New York'
  },
  {
    County: 'Suffolk County',
    State: 'New York'
  },
  {
    County: 'Sullivan County',
    State: 'New York'
  },
  {
    County: 'Tioga County',
    State: 'New York'
  },
  {
    County: 'Tompkins County',
    State: 'New York'
  },
  {
    County: 'Ulster County',
    State: 'New York'
  },
  {
    County: 'Warren County',
    State: 'New York'
  },
  {
    County: 'Washington County',
    State: 'New York'
  },
  {
    County: 'Wayne County',
    State: 'New York'
  },
  {
    County: 'Westchester County',
    State: 'New York'
  },
  {
    County: 'Wyoming County',
    State: 'New York'
  },
  {
    County: 'Yates County',
    State: 'New York'
  },
  {
    County: 'Alamance County',
    State: 'North Carolina'
  },
  {
    County: 'Alexander County',
    State: 'North Carolina'
  },
  {
    County: 'Alleghany County',
    State: 'North Carolina'
  },
  {
    County: 'Anson County',
    State: 'North Carolina'
  },
  {
    County: 'Ashe County',
    State: 'North Carolina'
  },
  {
    County: 'Avery County',
    State: 'North Carolina'
  },
  {
    County: 'Beaufort County',
    State: 'North Carolina'
  },
  {
    County: 'Bertie County',
    State: 'North Carolina'
  },
  {
    County: 'Bladen County',
    State: 'North Carolina'
  },
  {
    County: 'Brunswick County',
    State: 'North Carolina'
  },
  {
    County: 'Buncombe County',
    State: 'North Carolina'
  },
  {
    County: 'Burke County',
    State: 'North Carolina'
  },
  {
    County: 'Cabarrus County',
    State: 'North Carolina'
  },
  {
    County: 'Caldwell County',
    State: 'North Carolina'
  },
  {
    County: 'Camden County',
    State: 'North Carolina'
  },
  {
    County: 'Carteret County',
    State: 'North Carolina'
  },
  {
    County: 'Caswell County',
    State: 'North Carolina'
  },
  {
    County: 'Catawba County',
    State: 'North Carolina'
  },
  {
    County: 'Chatham County',
    State: 'North Carolina'
  },
  {
    County: 'Cherokee County',
    State: 'North Carolina'
  },
  {
    County: 'Chowan County',
    State: 'North Carolina'
  },
  {
    County: 'Clay County',
    State: 'North Carolina'
  },
  {
    County: 'Cleveland County',
    State: 'North Carolina'
  },
  {
    County: 'Columbus County',
    State: 'North Carolina'
  },
  {
    County: 'Craven County',
    State: 'North Carolina'
  },
  {
    County: 'Cumberland County',
    State: 'North Carolina'
  },
  {
    County: 'Currituck County',
    State: 'North Carolina'
  },
  {
    County: 'Dare County',
    State: 'North Carolina'
  },
  {
    County: 'Davidson County',
    State: 'North Carolina'
  },
  {
    County: 'Davie County',
    State: 'North Carolina'
  },
  {
    County: 'Duplin County',
    State: 'North Carolina'
  },
  {
    County: 'Durham County',
    State: 'North Carolina'
  },
  {
    County: 'Edgecombe County',
    State: 'North Carolina'
  },
  {
    County: 'Forsyth County',
    State: 'North Carolina'
  },
  {
    County: 'Franklin County',
    State: 'North Carolina'
  },
  {
    County: 'Gaston County',
    State: 'North Carolina'
  },
  {
    County: 'Gates County',
    State: 'North Carolina'
  },
  {
    County: 'Graham County',
    State: 'North Carolina'
  },
  {
    County: 'Granville County',
    State: 'North Carolina'
  },
  {
    County: 'Greene County',
    State: 'North Carolina'
  },
  {
    County: 'Guilford County',
    State: 'North Carolina'
  },
  {
    County: 'Halifax County',
    State: 'North Carolina'
  },
  {
    County: 'Harnett County',
    State: 'North Carolina'
  },
  {
    County: 'Haywood County',
    State: 'North Carolina'
  },
  {
    County: 'Henderson County',
    State: 'North Carolina'
  },
  {
    County: 'Hertford County',
    State: 'North Carolina'
  },
  {
    County: 'Hoke County',
    State: 'North Carolina'
  },
  {
    County: 'Hyde County',
    State: 'North Carolina'
  },
  {
    County: 'Iredell County',
    State: 'North Carolina'
  },
  {
    County: 'Jackson County',
    State: 'North Carolina'
  },
  {
    County: 'Johnston County',
    State: 'North Carolina'
  },
  {
    County: 'Jones County',
    State: 'North Carolina'
  },
  {
    County: 'Lee County',
    State: 'North Carolina'
  },
  {
    County: 'Lenoir County',
    State: 'North Carolina'
  },
  {
    County: 'Lincoln County',
    State: 'North Carolina'
  },
  {
    County: 'McDowell County',
    State: 'North Carolina'
  },
  {
    County: 'Macon County',
    State: 'North Carolina'
  },
  {
    County: 'Madison County',
    State: 'North Carolina'
  },
  {
    County: 'Martin County',
    State: 'North Carolina'
  },
  {
    County: 'Mecklenburg County',
    State: 'North Carolina'
  },
  {
    County: 'Mitchell County',
    State: 'North Carolina'
  },
  {
    County: 'Montgomery County',
    State: 'North Carolina'
  },
  {
    County: 'Moore County',
    State: 'North Carolina'
  },
  {
    County: 'Nash County',
    State: 'North Carolina'
  },
  {
    County: 'New Hanover County',
    State: 'North Carolina'
  },
  {
    County: 'Northampton County',
    State: 'North Carolina'
  },
  {
    County: 'Onslow County',
    State: 'North Carolina'
  },
  {
    County: 'Orange County',
    State: 'North Carolina'
  },
  {
    County: 'Pamlico County',
    State: 'North Carolina'
  },
  {
    County: 'Pasquotank County',
    State: 'North Carolina'
  },
  {
    County: 'Pender County',
    State: 'North Carolina'
  },
  {
    County: 'Perquimans County',
    State: 'North Carolina'
  },
  {
    County: 'Person County',
    State: 'North Carolina'
  },
  {
    County: 'Pitt County',
    State: 'North Carolina'
  },
  {
    County: 'Polk County',
    State: 'North Carolina'
  },
  {
    County: 'Randolph County',
    State: 'North Carolina'
  },
  {
    County: 'Richmond County',
    State: 'North Carolina'
  },
  {
    County: 'Robeson County',
    State: 'North Carolina'
  },
  {
    County: 'Rockingham County',
    State: 'North Carolina'
  },
  {
    County: 'Rowan County',
    State: 'North Carolina'
  },
  {
    County: 'Rutherford County',
    State: 'North Carolina'
  },
  {
    County: 'Sampson County',
    State: 'North Carolina'
  },
  {
    County: 'Scotland County',
    State: 'North Carolina'
  },
  {
    County: 'Stanly County',
    State: 'North Carolina'
  },
  {
    County: 'Stokes County',
    State: 'North Carolina'
  },
  {
    County: 'Surry County',
    State: 'North Carolina'
  },
  {
    County: 'Swain County',
    State: 'North Carolina'
  },
  {
    County: 'Transylvania County',
    State: 'North Carolina'
  },
  {
    County: 'Tyrrell County',
    State: 'North Carolina'
  },
  {
    County: 'Union County',
    State: 'North Carolina'
  },
  {
    County: 'Vance County',
    State: 'North Carolina'
  },
  {
    County: 'Wake County',
    State: 'North Carolina'
  },
  {
    County: 'Warren County',
    State: 'North Carolina'
  },
  {
    County: 'Washington County',
    State: 'North Carolina'
  },
  {
    County: 'Watauga County',
    State: 'North Carolina'
  },
  {
    County: 'Wayne County',
    State: 'North Carolina'
  },
  {
    County: 'Wilkes County',
    State: 'North Carolina'
  },
  {
    County: 'Wilson County',
    State: 'North Carolina'
  },
  {
    County: 'Yadkin County',
    State: 'North Carolina'
  },
  {
    County: 'Yancey County',
    State: 'North Carolina'
  },
  {
    County: 'Adams County',
    State: 'North Dakota'
  },
  {
    County: 'Barnes County',
    State: 'North Dakota'
  },
  {
    County: 'Benson County',
    State: 'North Dakota'
  },
  {
    County: 'Billings County',
    State: 'North Dakota'
  },
  {
    County: 'Bottineau County',
    State: 'North Dakota'
  },
  {
    County: 'Bowman County',
    State: 'North Dakota'
  },
  {
    County: 'Burke County',
    State: 'North Dakota'
  },
  {
    County: 'Burleigh County',
    State: 'North Dakota'
  },
  {
    County: 'Cass County',
    State: 'North Dakota'
  },
  {
    County: 'Cavalier County',
    State: 'North Dakota'
  },
  {
    County: 'Dickey County',
    State: 'North Dakota'
  },
  {
    County: 'Divide County',
    State: 'North Dakota'
  },
  {
    County: 'Dunn County',
    State: 'North Dakota'
  },
  {
    County: 'Eddy County',
    State: 'North Dakota'
  },
  {
    County: 'Emmons County',
    State: 'North Dakota'
  },
  {
    County: 'Foster County',
    State: 'North Dakota'
  },
  {
    County: 'Golden Valley County',
    State: 'North Dakota'
  },
  {
    County: 'Grand Forks County',
    State: 'North Dakota'
  },
  {
    County: 'Grant County',
    State: 'North Dakota'
  },
  {
    County: 'Griggs County',
    State: 'North Dakota'
  },
  {
    County: 'Hettinger County',
    State: 'North Dakota'
  },
  {
    County: 'Kidder County',
    State: 'North Dakota'
  },
  {
    County: 'LaMoure County',
    State: 'North Dakota'
  },
  {
    County: 'Logan County',
    State: 'North Dakota'
  },
  {
    County: 'McHenry County',
    State: 'North Dakota'
  },
  {
    County: 'McIntosh County',
    State: 'North Dakota'
  },
  {
    County: 'McKenzie County',
    State: 'North Dakota'
  },
  {
    County: 'McLean County',
    State: 'North Dakota'
  },
  {
    County: 'Mercer County',
    State: 'North Dakota'
  },
  {
    County: 'Morton County',
    State: 'North Dakota'
  },
  {
    County: 'Mountrail County',
    State: 'North Dakota'
  },
  {
    County: 'Nelson County',
    State: 'North Dakota'
  },
  {
    County: 'Oliver County',
    State: 'North Dakota'
  },
  {
    County: 'Pembina County',
    State: 'North Dakota'
  },
  {
    County: 'Pierce County',
    State: 'North Dakota'
  },
  {
    County: 'Ramsey County',
    State: 'North Dakota'
  },
  {
    County: 'Ransom County',
    State: 'North Dakota'
  },
  {
    County: 'Renville County',
    State: 'North Dakota'
  },
  {
    County: 'Richland County',
    State: 'North Dakota'
  },
  {
    County: 'Rolette County',
    State: 'North Dakota'
  },
  {
    County: 'Sargent County',
    State: 'North Dakota'
  },
  {
    County: 'Sheridan County',
    State: 'North Dakota'
  },
  {
    County: 'Sioux County',
    State: 'North Dakota'
  },
  {
    County: 'Slope County',
    State: 'North Dakota'
  },
  {
    County: 'Stark County',
    State: 'North Dakota'
  },
  {
    County: 'Steele County',
    State: 'North Dakota'
  },
  {
    County: 'Stutsman County',
    State: 'North Dakota'
  },
  {
    County: 'Towner County',
    State: 'North Dakota'
  },
  {
    County: 'Traill County',
    State: 'North Dakota'
  },
  {
    County: 'Walsh County',
    State: 'North Dakota'
  },
  {
    County: 'Ward County',
    State: 'North Dakota'
  },
  {
    County: 'Wells County',
    State: 'North Dakota'
  },
  {
    County: 'Williams County',
    State: 'North Dakota'
  },
  {
    County: 'Adams County',
    State: 'Ohio'
  },
  {
    County: 'Allen County',
    State: 'Ohio'
  },
  {
    County: 'Ashland County',
    State: 'Ohio'
  },
  {
    County: 'Ashtabula County',
    State: 'Ohio'
  },
  {
    County: 'Athens County',
    State: 'Ohio'
  },
  {
    County: 'Auglaize County',
    State: 'Ohio'
  },
  {
    County: 'Belmont County',
    State: 'Ohio'
  },
  {
    County: 'Brown County',
    State: 'Ohio'
  },
  {
    County: 'Butler County',
    State: 'Ohio'
  },
  {
    County: 'Carroll County',
    State: 'Ohio'
  },
  {
    County: 'Champaign County',
    State: 'Ohio'
  },
  {
    County: 'Clark County',
    State: 'Ohio'
  },
  {
    County: 'Clermont County',
    State: 'Ohio'
  },
  {
    County: 'Clinton County',
    State: 'Ohio'
  },
  {
    County: 'Columbiana County',
    State: 'Ohio'
  },
  {
    County: 'Coshocton County',
    State: 'Ohio'
  },
  {
    County: 'Crawford County',
    State: 'Ohio'
  },
  {
    County: 'Cuyahoga County',
    State: 'Ohio'
  },
  {
    County: 'Darke County',
    State: 'Ohio'
  },
  {
    County: 'Defiance County',
    State: 'Ohio'
  },
  {
    County: 'Delaware County',
    State: 'Ohio'
  },
  {
    County: 'Erie County',
    State: 'Ohio'
  },
  {
    County: 'Fairfield County',
    State: 'Ohio'
  },
  {
    County: 'Fayette County',
    State: 'Ohio'
  },
  {
    County: 'Franklin County',
    State: 'Ohio'
  },
  {
    County: 'Fulton County',
    State: 'Ohio'
  },
  {
    County: 'Gallia County',
    State: 'Ohio'
  },
  {
    County: 'Geauga County',
    State: 'Ohio'
  },
  {
    County: 'Greene County',
    State: 'Ohio'
  },
  {
    County: 'Guernsey County',
    State: 'Ohio'
  },
  {
    County: 'Hamilton County',
    State: 'Ohio'
  },
  {
    County: 'Hancock County',
    State: 'Ohio'
  },
  {
    County: 'Hardin County',
    State: 'Ohio'
  },
  {
    County: 'Harrison County',
    State: 'Ohio'
  },
  {
    County: 'Henry County',
    State: 'Ohio'
  },
  {
    County: 'Highland County',
    State: 'Ohio'
  },
  {
    County: 'Hocking County',
    State: 'Ohio'
  },
  {
    County: 'Holmes County',
    State: 'Ohio'
  },
  {
    County: 'Huron County',
    State: 'Ohio'
  },
  {
    County: 'Jackson County',
    State: 'Ohio'
  },
  {
    County: 'Jefferson County',
    State: 'Ohio'
  },
  {
    County: 'Knox County',
    State: 'Ohio'
  },
  {
    County: 'Lake County',
    State: 'Ohio'
  },
  {
    County: 'Lawrence County',
    State: 'Ohio'
  },
  {
    County: 'Licking County',
    State: 'Ohio'
  },
  {
    County: 'Logan County',
    State: 'Ohio'
  },
  {
    County: 'Lorain County',
    State: 'Ohio'
  },
  {
    County: 'Lucas County',
    State: 'Ohio'
  },
  {
    County: 'Madison County',
    State: 'Ohio'
  },
  {
    County: 'Mahoning County',
    State: 'Ohio'
  },
  {
    County: 'Marion County',
    State: 'Ohio'
  },
  {
    County: 'Medina County',
    State: 'Ohio'
  },
  {
    County: 'Meigs County',
    State: 'Ohio'
  },
  {
    County: 'Mercer County',
    State: 'Ohio'
  },
  {
    County: 'Miami County',
    State: 'Ohio'
  },
  {
    County: 'Monroe County',
    State: 'Ohio'
  },
  {
    County: 'Montgomery County',
    State: 'Ohio'
  },
  {
    County: 'Morgan County',
    State: 'Ohio'
  },
  {
    County: 'Morrow County',
    State: 'Ohio'
  },
  {
    County: 'Muskingum County',
    State: 'Ohio'
  },
  {
    County: 'Noble County',
    State: 'Ohio'
  },
  {
    County: 'Ottawa County',
    State: 'Ohio'
  },
  {
    County: 'Paulding County',
    State: 'Ohio'
  },
  {
    County: 'Perry County',
    State: 'Ohio'
  },
  {
    County: 'Pickaway County',
    State: 'Ohio'
  },
  {
    County: 'Pike County',
    State: 'Ohio'
  },
  {
    County: 'Portage County',
    State: 'Ohio'
  },
  {
    County: 'Preble County',
    State: 'Ohio'
  },
  {
    County: 'Putnam County',
    State: 'Ohio'
  },
  {
    County: 'Richland County',
    State: 'Ohio'
  },
  {
    County: 'Ross County',
    State: 'Ohio'
  },
  {
    County: 'Sandusky County',
    State: 'Ohio'
  },
  {
    County: 'Scioto County',
    State: 'Ohio'
  },
  {
    County: 'Seneca County',
    State: 'Ohio'
  },
  {
    County: 'Shelby County',
    State: 'Ohio'
  },
  {
    County: 'Stark County',
    State: 'Ohio'
  },
  {
    County: 'Summit County',
    State: 'Ohio'
  },
  {
    County: 'Trumbull County',
    State: 'Ohio'
  },
  {
    County: 'Tuscarawas County',
    State: 'Ohio'
  },
  {
    County: 'Union County',
    State: 'Ohio'
  },
  {
    County: 'Van Wert County',
    State: 'Ohio'
  },
  {
    County: 'Vinton County',
    State: 'Ohio'
  },
  {
    County: 'Warren County',
    State: 'Ohio'
  },
  {
    County: 'Washington County',
    State: 'Ohio'
  },
  {
    County: 'Wayne County',
    State: 'Ohio'
  },
  {
    County: 'Williams County',
    State: 'Ohio'
  },
  {
    County: 'Wood County',
    State: 'Ohio'
  },
  {
    County: 'Wyandot County',
    State: 'Ohio'
  },
  {
    County: 'Adair County',
    State: 'Oklahoma'
  },
  {
    County: 'Alfalfa County',
    State: 'Oklahoma'
  },
  {
    County: 'Atoka County',
    State: 'Oklahoma'
  },
  {
    County: 'Beaver County',
    State: 'Oklahoma'
  },
  {
    County: 'Beckham County',
    State: 'Oklahoma'
  },
  {
    County: 'Blaine County',
    State: 'Oklahoma'
  },
  {
    County: 'Bryan County',
    State: 'Oklahoma'
  },
  {
    County: 'Caddo County',
    State: 'Oklahoma'
  },
  {
    County: 'Canadian County',
    State: 'Oklahoma'
  },
  {
    County: 'Carter County',
    State: 'Oklahoma'
  },
  {
    County: 'Cherokee County',
    State: 'Oklahoma'
  },
  {
    County: 'Choctaw County',
    State: 'Oklahoma'
  },
  {
    County: 'Cimarron County',
    State: 'Oklahoma'
  },
  {
    County: 'Cleveland County',
    State: 'Oklahoma'
  },
  {
    County: 'Coal County',
    State: 'Oklahoma'
  },
  {
    County: 'Comanche County',
    State: 'Oklahoma'
  },
  {
    County: 'Cotton County',
    State: 'Oklahoma'
  },
  {
    County: 'Craig County',
    State: 'Oklahoma'
  },
  {
    County: 'Creek County',
    State: 'Oklahoma'
  },
  {
    County: 'Custer County',
    State: 'Oklahoma'
  },
  {
    County: 'Delaware County',
    State: 'Oklahoma'
  },
  {
    County: 'Dewey County',
    State: 'Oklahoma'
  },
  {
    County: 'Ellis County',
    State: 'Oklahoma'
  },
  {
    County: 'Garfield County',
    State: 'Oklahoma'
  },
  {
    County: 'Garvin County',
    State: 'Oklahoma'
  },
  {
    County: 'Grady County',
    State: 'Oklahoma'
  },
  {
    County: 'Grant County',
    State: 'Oklahoma'
  },
  {
    County: 'Greer County',
    State: 'Oklahoma'
  },
  {
    County: 'Harmon County',
    State: 'Oklahoma'
  },
  {
    County: 'Harper County',
    State: 'Oklahoma'
  },
  {
    County: 'Haskell County',
    State: 'Oklahoma'
  },
  {
    County: 'Hughes County',
    State: 'Oklahoma'
  },
  {
    County: 'Jackson County',
    State: 'Oklahoma'
  },
  {
    County: 'Jefferson County',
    State: 'Oklahoma'
  },
  {
    County: 'Johnston County',
    State: 'Oklahoma'
  },
  {
    County: 'Kay County',
    State: 'Oklahoma'
  },
  {
    County: 'Kingfisher County',
    State: 'Oklahoma'
  },
  {
    County: 'Kiowa County',
    State: 'Oklahoma'
  },
  {
    County: 'Latimer County',
    State: 'Oklahoma'
  },
  {
    County: 'Le Flore County',
    State: 'Oklahoma'
  },
  {
    County: 'Lincoln County',
    State: 'Oklahoma'
  },
  {
    County: 'Logan County',
    State: 'Oklahoma'
  },
  {
    County: 'Love County',
    State: 'Oklahoma'
  },
  {
    County: 'McClain County',
    State: 'Oklahoma'
  },
  {
    County: 'McCurtain County',
    State: 'Oklahoma'
  },
  {
    County: 'McIntosh County',
    State: 'Oklahoma'
  },
  {
    County: 'Major County',
    State: 'Oklahoma'
  },
  {
    County: 'Marshall County',
    State: 'Oklahoma'
  },
  {
    County: 'Mayes County',
    State: 'Oklahoma'
  },
  {
    County: 'Murray County',
    State: 'Oklahoma'
  },
  {
    County: 'Muskogee County',
    State: 'Oklahoma'
  },
  {
    County: 'Noble County',
    State: 'Oklahoma'
  },
  {
    County: 'Nowata County',
    State: 'Oklahoma'
  },
  {
    County: 'Okfuskee County',
    State: 'Oklahoma'
  },
  {
    County: 'Oklahoma County',
    State: 'Oklahoma'
  },
  {
    County: 'Okmulgee County',
    State: 'Oklahoma'
  },
  {
    County: 'Osage County',
    State: 'Oklahoma'
  },
  {
    County: 'Ottawa County',
    State: 'Oklahoma'
  },
  {
    County: 'Pawnee County',
    State: 'Oklahoma'
  },
  {
    County: 'Payne County',
    State: 'Oklahoma'
  },
  {
    County: 'Pittsburg County',
    State: 'Oklahoma'
  },
  {
    County: 'Pontotoc County',
    State: 'Oklahoma'
  },
  {
    County: 'Pottawatomie County',
    State: 'Oklahoma'
  },
  {
    County: 'Pushmataha County',
    State: 'Oklahoma'
  },
  {
    County: 'Roger Mills County',
    State: 'Oklahoma'
  },
  {
    County: 'Rogers County',
    State: 'Oklahoma'
  },
  {
    County: 'Seminole County',
    State: 'Oklahoma'
  },
  {
    County: 'Sequoyah County',
    State: 'Oklahoma'
  },
  {
    County: 'Stephens County',
    State: 'Oklahoma'
  },
  {
    County: 'Texas County',
    State: 'Oklahoma'
  },
  {
    County: 'Tillman County',
    State: 'Oklahoma'
  },
  {
    County: 'Tulsa County',
    State: 'Oklahoma'
  },
  {
    County: 'Wagoner County',
    State: 'Oklahoma'
  },
  {
    County: 'Washington County',
    State: 'Oklahoma'
  },
  {
    County: 'Washita County',
    State: 'Oklahoma'
  },
  {
    County: 'Woods County',
    State: 'Oklahoma'
  },
  {
    County: 'Woodward County',
    State: 'Oklahoma'
  },
  {
    County: 'Baker County',
    State: 'Oregon'
  },
  {
    County: 'Benton County',
    State: 'Oregon'
  },
  {
    County: 'Clackamas County',
    State: 'Oregon'
  },
  {
    County: 'Clatsop County',
    State: 'Oregon'
  },
  {
    County: 'Columbia County',
    State: 'Oregon'
  },
  {
    County: 'Coos County',
    State: 'Oregon'
  },
  {
    County: 'Crook County',
    State: 'Oregon'
  },
  {
    County: 'Curry County',
    State: 'Oregon'
  },
  {
    County: 'Deschutes County',
    State: 'Oregon'
  },
  {
    County: 'Douglas County',
    State: 'Oregon'
  },
  {
    County: 'Gilliam County',
    State: 'Oregon'
  },
  {
    County: 'Grant County',
    State: 'Oregon'
  },
  {
    County: 'Harney County',
    State: 'Oregon'
  },
  {
    County: 'Hood River County',
    State: 'Oregon'
  },
  {
    County: 'Jackson County',
    State: 'Oregon'
  },
  {
    County: 'Jefferson County',
    State: 'Oregon'
  },
  {
    County: 'Josephine County',
    State: 'Oregon'
  },
  {
    County: 'Klamath County',
    State: 'Oregon'
  },
  {
    County: 'Lake County',
    State: 'Oregon'
  },
  {
    County: 'Lane County',
    State: 'Oregon'
  },
  {
    County: 'Lincoln County',
    State: 'Oregon'
  },
  {
    County: 'Linn County',
    State: 'Oregon'
  },
  {
    County: 'Malheur County',
    State: 'Oregon'
  },
  {
    County: 'Marion County',
    State: 'Oregon'
  },
  {
    County: 'Morrow County',
    State: 'Oregon'
  },
  {
    County: 'Multnomah County',
    State: 'Oregon'
  },
  {
    County: 'Polk County',
    State: 'Oregon'
  },
  {
    County: 'Sherman County',
    State: 'Oregon'
  },
  {
    County: 'Tillamook County',
    State: 'Oregon'
  },
  {
    County: 'Umatilla County',
    State: 'Oregon'
  },
  {
    County: 'Union County',
    State: 'Oregon'
  },
  {
    County: 'Wallowa County',
    State: 'Oregon'
  },
  {
    County: 'Wasco County',
    State: 'Oregon'
  },
  {
    County: 'Washington County',
    State: 'Oregon'
  },
  {
    County: 'Wheeler County',
    State: 'Oregon'
  },
  {
    County: 'Yamhill County',
    State: 'Oregon'
  },
  {
    County: 'Adams County',
    State: 'Pennsylvania'
  },
  {
    County: 'Allegheny County',
    State: 'Pennsylvania'
  },
  {
    County: 'Armstrong County',
    State: 'Pennsylvania'
  },
  {
    County: 'Beaver County',
    State: 'Pennsylvania'
  },
  {
    County: 'Bedford County',
    State: 'Pennsylvania'
  },
  {
    County: 'Berks County',
    State: 'Pennsylvania'
  },
  {
    County: 'Blair County',
    State: 'Pennsylvania'
  },
  {
    County: 'Bradford County',
    State: 'Pennsylvania'
  },
  {
    County: 'Bucks County',
    State: 'Pennsylvania'
  },
  {
    County: 'Butler County',
    State: 'Pennsylvania'
  },
  {
    County: 'Cambria County',
    State: 'Pennsylvania'
  },
  {
    County: 'Cameron County',
    State: 'Pennsylvania'
  },
  {
    County: 'Carbon County',
    State: 'Pennsylvania'
  },
  {
    County: 'Centre County',
    State: 'Pennsylvania'
  },
  {
    County: 'Chester County',
    State: 'Pennsylvania'
  },
  {
    County: 'Clarion County',
    State: 'Pennsylvania'
  },
  {
    County: 'Clearfield County',
    State: 'Pennsylvania'
  },
  {
    County: 'Clinton County',
    State: 'Pennsylvania'
  },
  {
    County: 'Columbia County',
    State: 'Pennsylvania'
  },
  {
    County: 'Crawford County',
    State: 'Pennsylvania'
  },
  {
    County: 'Cumberland County',
    State: 'Pennsylvania'
  },
  {
    County: 'Dauphin County',
    State: 'Pennsylvania'
  },
  {
    County: 'Delaware County',
    State: 'Pennsylvania'
  },
  {
    County: 'Elk County',
    State: 'Pennsylvania'
  },
  {
    County: 'Erie County',
    State: 'Pennsylvania'
  },
  {
    County: 'Fayette County',
    State: 'Pennsylvania'
  },
  {
    County: 'Forest County',
    State: 'Pennsylvania'
  },
  {
    County: 'Franklin County',
    State: 'Pennsylvania'
  },
  {
    County: 'Fulton County',
    State: 'Pennsylvania'
  },
  {
    County: 'Greene County',
    State: 'Pennsylvania'
  },
  {
    County: 'Huntingdon County',
    State: 'Pennsylvania'
  },
  {
    County: 'Indiana County',
    State: 'Pennsylvania'
  },
  {
    County: 'Jefferson County',
    State: 'Pennsylvania'
  },
  {
    County: 'Juniata County',
    State: 'Pennsylvania'
  },
  {
    County: 'Lackawanna County',
    State: 'Pennsylvania'
  },
  {
    County: 'Lancaster County',
    State: 'Pennsylvania'
  },
  {
    County: 'Lawrence County',
    State: 'Pennsylvania'
  },
  {
    County: 'Lebanon County',
    State: 'Pennsylvania'
  },
  {
    County: 'Lehigh County',
    State: 'Pennsylvania'
  },
  {
    County: 'Luzerne County',
    State: 'Pennsylvania'
  },
  {
    County: 'Lycoming County',
    State: 'Pennsylvania'
  },
  {
    County: 'McKean County',
    State: 'Pennsylvania'
  },
  {
    County: 'Mercer County',
    State: 'Pennsylvania'
  },
  {
    County: 'Mifflin County',
    State: 'Pennsylvania'
  },
  {
    County: 'Monroe County',
    State: 'Pennsylvania'
  },
  {
    County: 'Montgomery County',
    State: 'Pennsylvania'
  },
  {
    County: 'Montour County',
    State: 'Pennsylvania'
  },
  {
    County: 'Northampton County',
    State: 'Pennsylvania'
  },
  {
    County: 'Northumberland County',
    State: 'Pennsylvania'
  },
  {
    County: 'Perry County',
    State: 'Pennsylvania'
  },
  {
    County: 'Philadelphia County',
    State: 'Pennsylvania'
  },
  {
    County: 'Pike County',
    State: 'Pennsylvania'
  },
  {
    County: 'Potter County',
    State: 'Pennsylvania'
  },
  {
    County: 'Schuylkill County',
    State: 'Pennsylvania'
  },
  {
    County: 'Snyder County',
    State: 'Pennsylvania'
  },
  {
    County: 'Somerset County',
    State: 'Pennsylvania'
  },
  {
    County: 'Sullivan County',
    State: 'Pennsylvania'
  },
  {
    County: 'Susquehanna County',
    State: 'Pennsylvania'
  },
  {
    County: 'Tioga County',
    State: 'Pennsylvania'
  },
  {
    County: 'Union County',
    State: 'Pennsylvania'
  },
  {
    County: 'Venango County',
    State: 'Pennsylvania'
  },
  {
    County: 'Warren County',
    State: 'Pennsylvania'
  },
  {
    County: 'Washington County',
    State: 'Pennsylvania'
  },
  {
    County: 'Wayne County',
    State: 'Pennsylvania'
  },
  {
    County: 'Westmoreland County',
    State: 'Pennsylvania'
  },
  {
    County: 'Wyoming County',
    State: 'Pennsylvania'
  },
  {
    County: 'York County',
    State: 'Pennsylvania'
  },
  {
    County: 'Bristol County',
    State: 'Rhode Island'
  },
  {
    County: 'Kent County',
    State: 'Rhode Island'
  },
  {
    County: 'Newport County',
    State: 'Rhode Island'
  },
  {
    County: 'Providence County',
    State: 'Rhode Island'
  },
  {
    County: 'Washington County',
    State: 'Rhode Island'
  },
  {
    County: 'Abbeville County',
    State: 'South Carolina'
  },
  {
    County: 'Aiken County',
    State: 'South Carolina'
  },
  {
    County: 'Allendale County',
    State: 'South Carolina'
  },
  {
    County: 'Anderson County',
    State: 'South Carolina'
  },
  {
    County: 'Bamberg County',
    State: 'South Carolina'
  },
  {
    County: 'Barnwell County',
    State: 'South Carolina'
  },
  {
    County: 'Beaufort County',
    State: 'South Carolina'
  },
  {
    County: 'Berkeley County',
    State: 'South Carolina'
  },
  {
    County: 'Calhoun County',
    State: 'South Carolina'
  },
  {
    County: 'Charleston County',
    State: 'South Carolina'
  },
  {
    County: 'Cherokee County',
    State: 'South Carolina'
  },
  {
    County: 'Chester County',
    State: 'South Carolina'
  },
  {
    County: 'Chesterfield County',
    State: 'South Carolina'
  },
  {
    County: 'Clarendon County',
    State: 'South Carolina'
  },
  {
    County: 'Colleton County',
    State: 'South Carolina'
  },
  {
    County: 'Darlington County',
    State: 'South Carolina'
  },
  {
    County: 'Dillon County',
    State: 'South Carolina'
  },
  {
    County: 'Dorchester County',
    State: 'South Carolina'
  },
  {
    County: 'Edgefield County',
    State: 'South Carolina'
  },
  {
    County: 'Fairfield County',
    State: 'South Carolina'
  },
  {
    County: 'Florence County',
    State: 'South Carolina'
  },
  {
    County: 'Georgetown County',
    State: 'South Carolina'
  },
  {
    County: 'Greenville County',
    State: 'South Carolina'
  },
  {
    County: 'Greenwood County',
    State: 'South Carolina'
  },
  {
    County: 'Hampton County',
    State: 'South Carolina'
  },
  {
    County: 'Horry County',
    State: 'South Carolina'
  },
  {
    County: 'Jasper County',
    State: 'South Carolina'
  },
  {
    County: 'Kershaw County',
    State: 'South Carolina'
  },
  {
    County: 'Lancaster County',
    State: 'South Carolina'
  },
  {
    County: 'Laurens County',
    State: 'South Carolina'
  },
  {
    County: 'Lee County',
    State: 'South Carolina'
  },
  {
    County: 'Lexington County',
    State: 'South Carolina'
  },
  {
    County: 'McCormick County',
    State: 'South Carolina'
  },
  {
    County: 'Marion County',
    State: 'South Carolina'
  },
  {
    County: 'Marlboro County',
    State: 'South Carolina'
  },
  {
    County: 'Newberry County',
    State: 'South Carolina'
  },
  {
    County: 'Oconee County',
    State: 'South Carolina'
  },
  {
    County: 'Orangeburg County',
    State: 'South Carolina'
  },
  {
    County: 'Pickens County',
    State: 'South Carolina'
  },
  {
    County: 'Richland County',
    State: 'South Carolina'
  },
  {
    County: 'Saluda County',
    State: 'South Carolina'
  },
  {
    County: 'Spartanburg County',
    State: 'South Carolina'
  },
  {
    County: 'Sumter County',
    State: 'South Carolina'
  },
  {
    County: 'Union County',
    State: 'South Carolina'
  },
  {
    County: 'Williamsburg County',
    State: 'South Carolina'
  },
  {
    County: 'York County',
    State: 'South Carolina'
  },
  {
    County: 'Aurora County',
    State: 'South Dakota'
  },
  {
    County: 'Beadle County',
    State: 'South Dakota'
  },
  {
    County: 'Bennett County',
    State: 'South Dakota'
  },
  {
    County: 'Bon Homme County',
    State: 'South Dakota'
  },
  {
    County: 'Brookings County',
    State: 'South Dakota'
  },
  {
    County: 'Brown County',
    State: 'South Dakota'
  },
  {
    County: 'Brule County',
    State: 'South Dakota'
  },
  {
    County: 'Buffalo County',
    State: 'South Dakota'
  },
  {
    County: 'Butte County',
    State: 'South Dakota'
  },
  {
    County: 'Campbell County',
    State: 'South Dakota'
  },
  {
    County: 'Charles Mix County',
    State: 'South Dakota'
  },
  {
    County: 'Clark County',
    State: 'South Dakota'
  },
  {
    County: 'Clay County',
    State: 'South Dakota'
  },
  {
    County: 'Codington County',
    State: 'South Dakota'
  },
  {
    County: 'Corson County',
    State: 'South Dakota'
  },
  {
    County: 'Custer County',
    State: 'South Dakota'
  },
  {
    County: 'Davison County',
    State: 'South Dakota'
  },
  {
    County: 'Day County',
    State: 'South Dakota'
  },
  {
    County: 'Deuel County',
    State: 'South Dakota'
  },
  {
    County: 'Dewey County',
    State: 'South Dakota'
  },
  {
    County: 'Douglas County',
    State: 'South Dakota'
  },
  {
    County: 'Edmunds County',
    State: 'South Dakota'
  },
  {
    County: 'Fall River County',
    State: 'South Dakota'
  },
  {
    County: 'Faulk County',
    State: 'South Dakota'
  },
  {
    County: 'Grant County',
    State: 'South Dakota'
  },
  {
    County: 'Gregory County',
    State: 'South Dakota'
  },
  {
    County: 'Haakon County',
    State: 'South Dakota'
  },
  {
    County: 'Hamlin County',
    State: 'South Dakota'
  },
  {
    County: 'Hand County',
    State: 'South Dakota'
  },
  {
    County: 'Hanson County',
    State: 'South Dakota'
  },
  {
    County: 'Harding County',
    State: 'South Dakota'
  },
  {
    County: 'Hughes County',
    State: 'South Dakota'
  },
  {
    County: 'Hutchinson County',
    State: 'South Dakota'
  },
  {
    County: 'Hyde County',
    State: 'South Dakota'
  },
  {
    County: 'Jackson County',
    State: 'South Dakota'
  },
  {
    County: 'Jerauld County',
    State: 'South Dakota'
  },
  {
    County: 'Jones County',
    State: 'South Dakota'
  },
  {
    County: 'Kingsbury County',
    State: 'South Dakota'
  },
  {
    County: 'Lake County',
    State: 'South Dakota'
  },
  {
    County: 'Lawrence County',
    State: 'South Dakota'
  },
  {
    County: 'Lincoln County',
    State: 'South Dakota'
  },
  {
    County: 'Lyman County',
    State: 'South Dakota'
  },
  {
    County: 'McCook County',
    State: 'South Dakota'
  },
  {
    County: 'McPherson County',
    State: 'South Dakota'
  },
  {
    County: 'Marshall County',
    State: 'South Dakota'
  },
  {
    County: 'Meade County',
    State: 'South Dakota'
  },
  {
    County: 'Mellette County',
    State: 'South Dakota'
  },
  {
    County: 'Miner County',
    State: 'South Dakota'
  },
  {
    County: 'Minnehaha County',
    State: 'South Dakota'
  },
  {
    County: 'Moody County',
    State: 'South Dakota'
  },
  {
    County: 'Pennington County',
    State: 'South Dakota'
  },
  {
    County: 'Perkins County',
    State: 'South Dakota'
  },
  {
    County: 'Potter County',
    State: 'South Dakota'
  },
  {
    County: 'Roberts County',
    State: 'South Dakota'
  },
  {
    County: 'Sanborn County',
    State: 'South Dakota'
  },
  {
    County: 'Shannon County',
    State: 'South Dakota'
  },
  {
    County: 'Spink County',
    State: 'South Dakota'
  },
  {
    County: 'Stanley County',
    State: 'South Dakota'
  },
  {
    County: 'Sully County',
    State: 'South Dakota'
  },
  {
    County: 'Todd County',
    State: 'South Dakota'
  },
  {
    County: 'Tripp County',
    State: 'South Dakota'
  },
  {
    County: 'Turner County',
    State: 'South Dakota'
  },
  {
    County: 'Union County',
    State: 'South Dakota'
  },
  {
    County: 'Walworth County',
    State: 'South Dakota'
  },
  {
    County: 'Yankton County',
    State: 'South Dakota'
  },
  {
    County: 'Ziebach County',
    State: 'South Dakota'
  },
  {
    County: 'Anderson County',
    State: 'Tennessee'
  },
  {
    County: 'Bedford County',
    State: 'Tennessee'
  },
  {
    County: 'Benton County',
    State: 'Tennessee'
  },
  {
    County: 'Bledsoe County',
    State: 'Tennessee'
  },
  {
    County: 'Blount County',
    State: 'Tennessee'
  },
  {
    County: 'Bradley County',
    State: 'Tennessee'
  },
  {
    County: 'Campbell County',
    State: 'Tennessee'
  },
  {
    County: 'Cannon County',
    State: 'Tennessee'
  },
  {
    County: 'Carroll County',
    State: 'Tennessee'
  },
  {
    County: 'Carter County',
    State: 'Tennessee'
  },
  {
    County: 'Cheatham County',
    State: 'Tennessee'
  },
  {
    County: 'Chester County',
    State: 'Tennessee'
  },
  {
    County: 'Claiborne County',
    State: 'Tennessee'
  },
  {
    County: 'Clay County',
    State: 'Tennessee'
  },
  {
    County: 'Cocke County',
    State: 'Tennessee'
  },
  {
    County: 'Coffee County',
    State: 'Tennessee'
  },
  {
    County: 'Crockett County',
    State: 'Tennessee'
  },
  {
    County: 'Cumberland County',
    State: 'Tennessee'
  },
  {
    County: 'Davidson County',
    State: 'Tennessee'
  },
  {
    County: 'Decatur County',
    State: 'Tennessee'
  },
  {
    County: 'DeKalb County',
    State: 'Tennessee'
  },
  {
    County: 'Dickson County',
    State: 'Tennessee'
  },
  {
    County: 'Dyer County',
    State: 'Tennessee'
  },
  {
    County: 'Fayette County',
    State: 'Tennessee'
  },
  {
    County: 'Fentress County',
    State: 'Tennessee'
  },
  {
    County: 'Franklin County',
    State: 'Tennessee'
  },
  {
    County: 'Gibson County',
    State: 'Tennessee'
  },
  {
    County: 'Giles County',
    State: 'Tennessee'
  },
  {
    County: 'Grainger County',
    State: 'Tennessee'
  },
  {
    County: 'Greene County',
    State: 'Tennessee'
  },
  {
    County: 'Grundy County',
    State: 'Tennessee'
  },
  {
    County: 'Hamblen County',
    State: 'Tennessee'
  },
  {
    County: 'Hamilton County',
    State: 'Tennessee'
  },
  {
    County: 'Hancock County',
    State: 'Tennessee'
  },
  {
    County: 'Hardeman County',
    State: 'Tennessee'
  },
  {
    County: 'Hardin County',
    State: 'Tennessee'
  },
  {
    County: 'Hawkins County',
    State: 'Tennessee'
  },
  {
    County: 'Haywood County',
    State: 'Tennessee'
  },
  {
    County: 'Henderson County',
    State: 'Tennessee'
  },
  {
    County: 'Henry County',
    State: 'Tennessee'
  },
  {
    County: 'Hickman County',
    State: 'Tennessee'
  },
  {
    County: 'Houston County',
    State: 'Tennessee'
  },
  {
    County: 'Humphreys County',
    State: 'Tennessee'
  },
  {
    County: 'Jackson County',
    State: 'Tennessee'
  },
  {
    County: 'Jefferson County',
    State: 'Tennessee'
  },
  {
    County: 'Johnson County',
    State: 'Tennessee'
  },
  {
    County: 'Knox County',
    State: 'Tennessee'
  },
  {
    County: 'Lake County',
    State: 'Tennessee'
  },
  {
    County: 'Lauderdale County',
    State: 'Tennessee'
  },
  {
    County: 'Lawrence County',
    State: 'Tennessee'
  },
  {
    County: 'Lewis County',
    State: 'Tennessee'
  },
  {
    County: 'Lincoln County',
    State: 'Tennessee'
  },
  {
    County: 'Loudon County',
    State: 'Tennessee'
  },
  {
    County: 'McMinn County',
    State: 'Tennessee'
  },
  {
    County: 'McNairy County',
    State: 'Tennessee'
  },
  {
    County: 'Macon County',
    State: 'Tennessee'
  },
  {
    County: 'Madison County',
    State: 'Tennessee'
  },
  {
    County: 'Marion County',
    State: 'Tennessee'
  },
  {
    County: 'Marshall County',
    State: 'Tennessee'
  },
  {
    County: 'Maury County',
    State: 'Tennessee'
  },
  {
    County: 'Meigs County',
    State: 'Tennessee'
  },
  {
    County: 'Monroe County',
    State: 'Tennessee'
  },
  {
    County: 'Montgomery County',
    State: 'Tennessee'
  },
  {
    County: 'Moore County',
    State: 'Tennessee'
  },
  {
    County: 'Morgan County',
    State: 'Tennessee'
  },
  {
    County: 'Obion County',
    State: 'Tennessee'
  },
  {
    County: 'Overton County',
    State: 'Tennessee'
  },
  {
    County: 'Perry County',
    State: 'Tennessee'
  },
  {
    County: 'Pickett County',
    State: 'Tennessee'
  },
  {
    County: 'Polk County',
    State: 'Tennessee'
  },
  {
    County: 'Putnam County',
    State: 'Tennessee'
  },
  {
    County: 'Rhea County',
    State: 'Tennessee'
  },
  {
    County: 'Roane County',
    State: 'Tennessee'
  },
  {
    County: 'Robertson County',
    State: 'Tennessee'
  },
  {
    County: 'Rutherford County',
    State: 'Tennessee'
  },
  {
    County: 'Scott County',
    State: 'Tennessee'
  },
  {
    County: 'Sequatchie County',
    State: 'Tennessee'
  },
  {
    County: 'Sevier County',
    State: 'Tennessee'
  },
  {
    County: 'Shelby County',
    State: 'Tennessee'
  },
  {
    County: 'Smith County',
    State: 'Tennessee'
  },
  {
    County: 'Stewart County',
    State: 'Tennessee'
  },
  {
    County: 'Sullivan County',
    State: 'Tennessee'
  },
  {
    County: 'Sumner County',
    State: 'Tennessee'
  },
  {
    County: 'Tipton County',
    State: 'Tennessee'
  },
  {
    County: 'Trousdale County',
    State: 'Tennessee'
  },
  {
    County: 'Unicoi County',
    State: 'Tennessee'
  },
  {
    County: 'Union County',
    State: 'Tennessee'
  },
  {
    County: 'Van Buren County',
    State: 'Tennessee'
  },
  {
    County: 'Warren County',
    State: 'Tennessee'
  },
  {
    County: 'Washington County',
    State: 'Tennessee'
  },
  {
    County: 'Wayne County',
    State: 'Tennessee'
  },
  {
    County: 'Weakley County',
    State: 'Tennessee'
  },
  {
    County: 'White County',
    State: 'Tennessee'
  },
  {
    County: 'Williamson County',
    State: 'Tennessee'
  },
  {
    County: 'Wilson County',
    State: 'Tennessee'
  },
  {
    County: 'Anderson County',
    State: 'Texas'
  },
  {
    County: 'Andrews County',
    State: 'Texas'
  },
  {
    County: 'Angelina County',
    State: 'Texas'
  },
  {
    County: 'Aransas County',
    State: 'Texas'
  },
  {
    County: 'Archer County',
    State: 'Texas'
  },
  {
    County: 'Armstrong County',
    State: 'Texas'
  },
  {
    County: 'Atascosa County',
    State: 'Texas'
  },
  {
    County: 'Austin County',
    State: 'Texas'
  },
  {
    County: 'Bailey County',
    State: 'Texas'
  },
  {
    County: 'Bandera County',
    State: 'Texas'
  },
  {
    County: 'Bastrop County',
    State: 'Texas'
  },
  {
    County: 'Baylor County',
    State: 'Texas'
  },
  {
    County: 'Bee County',
    State: 'Texas'
  },
  {
    County: 'Bell County',
    State: 'Texas'
  },
  {
    County: 'Bexar County',
    State: 'Texas'
  },
  {
    County: 'Blanco County',
    State: 'Texas'
  },
  {
    County: 'Borden County',
    State: 'Texas'
  },
  {
    County: 'Bosque County',
    State: 'Texas'
  },
  {
    County: 'Bowie County',
    State: 'Texas'
  },
  {
    County: 'Brazoria County',
    State: 'Texas'
  },
  {
    County: 'Brazos County',
    State: 'Texas'
  },
  {
    County: 'Brewster County',
    State: 'Texas'
  },
  {
    County: 'Briscoe County',
    State: 'Texas'
  },
  {
    County: 'Brooks County',
    State: 'Texas'
  },
  {
    County: 'Brown County',
    State: 'Texas'
  },
  {
    County: 'Burleson County',
    State: 'Texas'
  },
  {
    County: 'Burnet County',
    State: 'Texas'
  },
  {
    County: 'Caldwell County',
    State: 'Texas'
  },
  {
    County: 'Calhoun County',
    State: 'Texas'
  },
  {
    County: 'Callahan County',
    State: 'Texas'
  },
  {
    County: 'Cameron County',
    State: 'Texas'
  },
  {
    County: 'Camp County',
    State: 'Texas'
  },
  {
    County: 'Carson County',
    State: 'Texas'
  },
  {
    County: 'Cass County',
    State: 'Texas'
  },
  {
    County: 'Castro County',
    State: 'Texas'
  },
  {
    County: 'Chambers County',
    State: 'Texas'
  },
  {
    County: 'Cherokee County',
    State: 'Texas'
  },
  {
    County: 'Childress County',
    State: 'Texas'
  },
  {
    County: 'Clay County',
    State: 'Texas'
  },
  {
    County: 'Cochran County',
    State: 'Texas'
  },
  {
    County: 'Coke County',
    State: 'Texas'
  },
  {
    County: 'Coleman County',
    State: 'Texas'
  },
  {
    County: 'Collin County',
    State: 'Texas'
  },
  {
    County: 'Collingsworth County',
    State: 'Texas'
  },
  {
    County: 'Colorado County',
    State: 'Texas'
  },
  {
    County: 'Comal County',
    State: 'Texas'
  },
  {
    County: 'Comanche County',
    State: 'Texas'
  },
  {
    County: 'Concho County',
    State: 'Texas'
  },
  {
    County: 'Cooke County',
    State: 'Texas'
  },
  {
    County: 'Coryell County',
    State: 'Texas'
  },
  {
    County: 'Cottle County',
    State: 'Texas'
  },
  {
    County: 'Crane County',
    State: 'Texas'
  },
  {
    County: 'Crockett County',
    State: 'Texas'
  },
  {
    County: 'Crosby County',
    State: 'Texas'
  },
  {
    County: 'Culberson County',
    State: 'Texas'
  },
  {
    County: 'Dallam County',
    State: 'Texas'
  },
  {
    County: 'Dallas County',
    State: 'Texas'
  },
  {
    County: 'Dawson County',
    State: 'Texas'
  },
  {
    County: 'Deaf Smith County',
    State: 'Texas'
  },
  {
    County: 'Delta County',
    State: 'Texas'
  },
  {
    County: 'Denton County',
    State: 'Texas'
  },
  {
    County: 'DeWitt County',
    State: 'Texas'
  },
  {
    County: 'Dickens County',
    State: 'Texas'
  },
  {
    County: 'Dimmit County',
    State: 'Texas'
  },
  {
    County: 'Donley County',
    State: 'Texas'
  },
  {
    County: 'Duval County',
    State: 'Texas'
  },
  {
    County: 'Eastland County',
    State: 'Texas'
  },
  {
    County: 'Ector County',
    State: 'Texas'
  },
  {
    County: 'Edwards County',
    State: 'Texas'
  },
  {
    County: 'Ellis County',
    State: 'Texas'
  },
  {
    County: 'El Paso County',
    State: 'Texas'
  },
  {
    County: 'Erath County',
    State: 'Texas'
  },
  {
    County: 'Falls County',
    State: 'Texas'
  },
  {
    County: 'Fannin County',
    State: 'Texas'
  },
  {
    County: 'Fayette County',
    State: 'Texas'
  },
  {
    County: 'Fisher County',
    State: 'Texas'
  },
  {
    County: 'Floyd County',
    State: 'Texas'
  },
  {
    County: 'Foard County',
    State: 'Texas'
  },
  {
    County: 'Fort Bend County',
    State: 'Texas'
  },
  {
    County: 'Franklin County',
    State: 'Texas'
  },
  {
    County: 'Freestone County',
    State: 'Texas'
  },
  {
    County: 'Frio County',
    State: 'Texas'
  },
  {
    County: 'Gaines County',
    State: 'Texas'
  },
  {
    County: 'Galveston County',
    State: 'Texas'
  },
  {
    County: 'Garza County',
    State: 'Texas'
  },
  {
    County: 'Gillespie County',
    State: 'Texas'
  },
  {
    County: 'Glasscock County',
    State: 'Texas'
  },
  {
    County: 'Goliad County',
    State: 'Texas'
  },
  {
    County: 'Gonzales County',
    State: 'Texas'
  },
  {
    County: 'Gray County',
    State: 'Texas'
  },
  {
    County: 'Grayson County',
    State: 'Texas'
  },
  {
    County: 'Gregg County',
    State: 'Texas'
  },
  {
    County: 'Grimes County',
    State: 'Texas'
  },
  {
    County: 'Guadalupe County',
    State: 'Texas'
  },
  {
    County: 'Hale County',
    State: 'Texas'
  },
  {
    County: 'Hall County',
    State: 'Texas'
  },
  {
    County: 'Hamilton County',
    State: 'Texas'
  },
  {
    County: 'Hansford County',
    State: 'Texas'
  },
  {
    County: 'Hardeman County',
    State: 'Texas'
  },
  {
    County: 'Hardin County',
    State: 'Texas'
  },
  {
    County: 'Harris County',
    State: 'Texas'
  },
  {
    County: 'Harrison County',
    State: 'Texas'
  },
  {
    County: 'Hartley County',
    State: 'Texas'
  },
  {
    County: 'Haskell County',
    State: 'Texas'
  },
  {
    County: 'Hays County',
    State: 'Texas'
  },
  {
    County: 'Hemphill County',
    State: 'Texas'
  },
  {
    County: 'Henderson County',
    State: 'Texas'
  },
  {
    County: 'Hidalgo County',
    State: 'Texas'
  },
  {
    County: 'Hill County',
    State: 'Texas'
  },
  {
    County: 'Hockley County',
    State: 'Texas'
  },
  {
    County: 'Hood County',
    State: 'Texas'
  },
  {
    County: 'Hopkins County',
    State: 'Texas'
  },
  {
    County: 'Houston County',
    State: 'Texas'
  },
  {
    County: 'Howard County',
    State: 'Texas'
  },
  {
    County: 'Hudspeth County',
    State: 'Texas'
  },
  {
    County: 'Hunt County',
    State: 'Texas'
  },
  {
    County: 'Hutchinson County',
    State: 'Texas'
  },
  {
    County: 'Irion County',
    State: 'Texas'
  },
  {
    County: 'Jack County',
    State: 'Texas'
  },
  {
    County: 'Jackson County',
    State: 'Texas'
  },
  {
    County: 'Jasper County',
    State: 'Texas'
  },
  {
    County: 'Jeff Davis County',
    State: 'Texas'
  },
  {
    County: 'Jefferson County',
    State: 'Texas'
  },
  {
    County: 'Jim Hogg County',
    State: 'Texas'
  },
  {
    County: 'Jim Wells County',
    State: 'Texas'
  },
  {
    County: 'Johnson County',
    State: 'Texas'
  },
  {
    County: 'Jones County',
    State: 'Texas'
  },
  {
    County: 'Karnes County',
    State: 'Texas'
  },
  {
    County: 'Kaufman County',
    State: 'Texas'
  },
  {
    County: 'Kendall County',
    State: 'Texas'
  },
  {
    County: 'Kenedy County',
    State: 'Texas'
  },
  {
    County: 'Kent County',
    State: 'Texas'
  },
  {
    County: 'Kerr County',
    State: 'Texas'
  },
  {
    County: 'Kimble County',
    State: 'Texas'
  },
  {
    County: 'King County',
    State: 'Texas'
  },
  {
    County: 'Kinney County',
    State: 'Texas'
  },
  {
    County: 'Kleberg County',
    State: 'Texas'
  },
  {
    County: 'Knox County',
    State: 'Texas'
  },
  {
    County: 'Lamar County',
    State: 'Texas'
  },
  {
    County: 'Lamb County',
    State: 'Texas'
  },
  {
    County: 'Lampasas County',
    State: 'Texas'
  },
  {
    County: 'La Salle County',
    State: 'Texas'
  },
  {
    County: 'Lavaca County',
    State: 'Texas'
  },
  {
    County: 'Lee County',
    State: 'Texas'
  },
  {
    County: 'Leon County',
    State: 'Texas'
  },
  {
    County: 'Liberty County',
    State: 'Texas'
  },
  {
    County: 'Limestone County',
    State: 'Texas'
  },
  {
    County: 'Lipscomb County',
    State: 'Texas'
  },
  {
    County: 'Live Oak County',
    State: 'Texas'
  },
  {
    County: 'Llano County',
    State: 'Texas'
  },
  {
    County: 'Loving County',
    State: 'Texas'
  },
  {
    County: 'Lubbock County',
    State: 'Texas'
  },
  {
    County: 'Lynn County',
    State: 'Texas'
  },
  {
    County: 'McCulloch County',
    State: 'Texas'
  },
  {
    County: 'McLennan County',
    State: 'Texas'
  },
  {
    County: 'McMullen County',
    State: 'Texas'
  },
  {
    County: 'Madison County',
    State: 'Texas'
  },
  {
    County: 'Marion County',
    State: 'Texas'
  },
  {
    County: 'Martin County',
    State: 'Texas'
  },
  {
    County: 'Mason County',
    State: 'Texas'
  },
  {
    County: 'Matagorda County',
    State: 'Texas'
  },
  {
    County: 'Maverick County',
    State: 'Texas'
  },
  {
    County: 'Medina County',
    State: 'Texas'
  },
  {
    County: 'Menard County',
    State: 'Texas'
  },
  {
    County: 'Midland County',
    State: 'Texas'
  },
  {
    County: 'Milam County',
    State: 'Texas'
  },
  {
    County: 'Mills County',
    State: 'Texas'
  },
  {
    County: 'Mitchell County',
    State: 'Texas'
  },
  {
    County: 'Montague County',
    State: 'Texas'
  },
  {
    County: 'Montgomery County',
    State: 'Texas'
  },
  {
    County: 'Moore County',
    State: 'Texas'
  },
  {
    County: 'Morris County',
    State: 'Texas'
  },
  {
    County: 'Motley County',
    State: 'Texas'
  },
  {
    County: 'Nacogdoches County',
    State: 'Texas'
  },
  {
    County: 'Navarro County',
    State: 'Texas'
  },
  {
    County: 'Newton County',
    State: 'Texas'
  },
  {
    County: 'Nolan County',
    State: 'Texas'
  },
  {
    County: 'Nueces County',
    State: 'Texas'
  },
  {
    County: 'Ochiltree County',
    State: 'Texas'
  },
  {
    County: 'Oldham County',
    State: 'Texas'
  },
  {
    County: 'Orange County',
    State: 'Texas'
  },
  {
    County: 'Palo Pinto County',
    State: 'Texas'
  },
  {
    County: 'Panola County',
    State: 'Texas'
  },
  {
    County: 'Parker County',
    State: 'Texas'
  },
  {
    County: 'Parmer County',
    State: 'Texas'
  },
  {
    County: 'Pecos County',
    State: 'Texas'
  },
  {
    County: 'Polk County',
    State: 'Texas'
  },
  {
    County: 'Potter County',
    State: 'Texas'
  },
  {
    County: 'Presidio County',
    State: 'Texas'
  },
  {
    County: 'Rains County',
    State: 'Texas'
  },
  {
    County: 'Randall County',
    State: 'Texas'
  },
  {
    County: 'Reagan County',
    State: 'Texas'
  },
  {
    County: 'Real County',
    State: 'Texas'
  },
  {
    County: 'Red River County',
    State: 'Texas'
  },
  {
    County: 'Reeves County',
    State: 'Texas'
  },
  {
    County: 'Refugio County',
    State: 'Texas'
  },
  {
    County: 'Roberts County',
    State: 'Texas'
  },
  {
    County: 'Robertson County',
    State: 'Texas'
  },
  {
    County: 'Rockwall County',
    State: 'Texas'
  },
  {
    County: 'Runnels County',
    State: 'Texas'
  },
  {
    County: 'Rusk County',
    State: 'Texas'
  },
  {
    County: 'Sabine County',
    State: 'Texas'
  },
  {
    County: 'San Augustine County',
    State: 'Texas'
  },
  {
    County: 'San Jacinto County',
    State: 'Texas'
  },
  {
    County: 'San Patricio County',
    State: 'Texas'
  },
  {
    County: 'San Saba County',
    State: 'Texas'
  },
  {
    County: 'Schleicher County',
    State: 'Texas'
  },
  {
    County: 'Scurry County',
    State: 'Texas'
  },
  {
    County: 'Shackelford County',
    State: 'Texas'
  },
  {
    County: 'Shelby County',
    State: 'Texas'
  },
  {
    County: 'Sherman County',
    State: 'Texas'
  },
  {
    County: 'Smith County',
    State: 'Texas'
  },
  {
    County: 'Somervell County',
    State: 'Texas'
  },
  {
    County: 'Starr County',
    State: 'Texas'
  },
  {
    County: 'Stephens County',
    State: 'Texas'
  },
  {
    County: 'Sterling County',
    State: 'Texas'
  },
  {
    County: 'Stonewall County',
    State: 'Texas'
  },
  {
    County: 'Sutton County',
    State: 'Texas'
  },
  {
    County: 'Swisher County',
    State: 'Texas'
  },
  {
    County: 'Tarrant County',
    State: 'Texas'
  },
  {
    County: 'Taylor County',
    State: 'Texas'
  },
  {
    County: 'Terrell County',
    State: 'Texas'
  },
  {
    County: 'Terry County',
    State: 'Texas'
  },
  {
    County: 'Throckmorton County',
    State: 'Texas'
  },
  {
    County: 'Titus County',
    State: 'Texas'
  },
  {
    County: 'Tom Green County',
    State: 'Texas'
  },
  {
    County: 'Travis County',
    State: 'Texas'
  },
  {
    County: 'Trinity County',
    State: 'Texas'
  },
  {
    County: 'Tyler County',
    State: 'Texas'
  },
  {
    County: 'Upshur County',
    State: 'Texas'
  },
  {
    County: 'Upton County',
    State: 'Texas'
  },
  {
    County: 'Uvalde County',
    State: 'Texas'
  },
  {
    County: 'Val Verde County',
    State: 'Texas'
  },
  {
    County: 'Van Zandt County',
    State: 'Texas'
  },
  {
    County: 'Victoria County',
    State: 'Texas'
  },
  {
    County: 'Walker County',
    State: 'Texas'
  },
  {
    County: 'Waller County',
    State: 'Texas'
  },
  {
    County: 'Ward County',
    State: 'Texas'
  },
  {
    County: 'Washington County',
    State: 'Texas'
  },
  {
    County: 'Webb County',
    State: 'Texas'
  },
  {
    County: 'Wharton County',
    State: 'Texas'
  },
  {
    County: 'Wheeler County',
    State: 'Texas'
  },
  {
    County: 'Wichita County',
    State: 'Texas'
  },
  {
    County: 'Wilbarger County',
    State: 'Texas'
  },
  {
    County: 'Willacy County',
    State: 'Texas'
  },
  {
    County: 'Williamson County',
    State: 'Texas'
  },
  {
    County: 'Wilson County',
    State: 'Texas'
  },
  {
    County: 'Winkler County',
    State: 'Texas'
  },
  {
    County: 'Wise County',
    State: 'Texas'
  },
  {
    County: 'Wood County',
    State: 'Texas'
  },
  {
    County: 'Yoakum County',
    State: 'Texas'
  },
  {
    County: 'Young County',
    State: 'Texas'
  },
  {
    County: 'Zapata County',
    State: 'Texas'
  },
  {
    County: 'Zavala County',
    State: 'Texas'
  },
  {
    County: 'Beaver County',
    State: 'Utah'
  },
  {
    County: 'Box Elder County',
    State: 'Utah'
  },
  {
    County: 'Cache County',
    State: 'Utah'
  },
  {
    County: 'Carbon County',
    State: 'Utah'
  },
  {
    County: 'Daggett County',
    State: 'Utah'
  },
  {
    County: 'Davis County',
    State: 'Utah'
  },
  {
    County: 'Duchesne County',
    State: 'Utah'
  },
  {
    County: 'Emery County',
    State: 'Utah'
  },
  {
    County: 'Garfield County',
    State: 'Utah'
  },
  {
    County: 'Grand County',
    State: 'Utah'
  },
  {
    County: 'Iron County',
    State: 'Utah'
  },
  {
    County: 'Juab County',
    State: 'Utah'
  },
  {
    County: 'Kane County',
    State: 'Utah'
  },
  {
    County: 'Millard County',
    State: 'Utah'
  },
  {
    County: 'Morgan County',
    State: 'Utah'
  },
  {
    County: 'Piute County',
    State: 'Utah'
  },
  {
    County: 'Rich County',
    State: 'Utah'
  },
  {
    County: 'Salt Lake County',
    State: 'Utah'
  },
  {
    County: 'San Juan County',
    State: 'Utah'
  },
  {
    County: 'Sanpete County',
    State: 'Utah'
  },
  {
    County: 'Sevier County',
    State: 'Utah'
  },
  {
    County: 'Summit County',
    State: 'Utah'
  },
  {
    County: 'Tooele County',
    State: 'Utah'
  },
  {
    County: 'Uintah County',
    State: 'Utah'
  },
  {
    County: 'Utah County',
    State: 'Utah'
  },
  {
    County: 'Wasatch County',
    State: 'Utah'
  },
  {
    County: 'Washington County',
    State: 'Utah'
  },
  {
    County: 'Wayne County',
    State: 'Utah'
  },
  {
    County: 'Weber County',
    State: 'Utah'
  },
  {
    County: 'Addison County',
    State: 'Vermont'
  },
  {
    County: 'Bennington County',
    State: 'Vermont'
  },
  {
    County: 'Caledonia County',
    State: 'Vermont'
  },
  {
    County: 'Chittenden County',
    State: 'Vermont'
  },
  {
    County: 'Essex County',
    State: 'Vermont'
  },
  {
    County: 'Franklin County',
    State: 'Vermont'
  },
  {
    County: 'Grand Isle County',
    State: 'Vermont'
  },
  {
    County: 'Lamoille County',
    State: 'Vermont'
  },
  {
    County: 'Orange County',
    State: 'Vermont'
  },
  {
    County: 'Orleans County',
    State: 'Vermont'
  },
  {
    County: 'Rutland County',
    State: 'Vermont'
  },
  {
    County: 'Washington County',
    State: 'Vermont'
  },
  {
    County: 'Windham County',
    State: 'Vermont'
  },
  {
    County: 'Windsor County',
    State: 'Vermont'
  },
  {
    County: 'Accomack County',
    State: 'Virginia'
  },
  {
    County: 'Albemarle County',
    State: 'Virginia'
  },
  {
    County: 'Alleghany County',
    State: 'Virginia'
  },
  {
    County: 'Amelia County',
    State: 'Virginia'
  },
  {
    County: 'Amherst County',
    State: 'Virginia'
  },
  {
    County: 'Appomattox County',
    State: 'Virginia'
  },
  {
    County: 'Arlington County',
    State: 'Virginia'
  },
  {
    County: 'Augusta County',
    State: 'Virginia'
  },
  {
    County: 'Bath County',
    State: 'Virginia'
  },
  {
    County: 'Bedford County',
    State: 'Virginia'
  },
  {
    County: 'Bland County',
    State: 'Virginia'
  },
  {
    County: 'Botetourt County',
    State: 'Virginia'
  },
  {
    County: 'Brunswick County',
    State: 'Virginia'
  },
  {
    County: 'Buchanan County',
    State: 'Virginia'
  },
  {
    County: 'Buckingham County',
    State: 'Virginia'
  },
  {
    County: 'Campbell County',
    State: 'Virginia'
  },
  {
    County: 'Caroline County',
    State: 'Virginia'
  },
  {
    County: 'Carroll County',
    State: 'Virginia'
  },
  {
    County: 'Charles City County',
    State: 'Virginia'
  },
  {
    County: 'Charlotte County',
    State: 'Virginia'
  },
  {
    County: 'Chesterfield County',
    State: 'Virginia'
  },
  {
    County: 'Clarke County',
    State: 'Virginia'
  },
  {
    County: 'Craig County',
    State: 'Virginia'
  },
  {
    County: 'Culpeper County',
    State: 'Virginia'
  },
  {
    County: 'Cumberland County',
    State: 'Virginia'
  },
  {
    County: 'Dickenson County',
    State: 'Virginia'
  },
  {
    County: 'Dinwiddie County',
    State: 'Virginia'
  },
  {
    County: 'Essex County',
    State: 'Virginia'
  },
  {
    County: 'Fairfax County',
    State: 'Virginia'
  },
  {
    County: 'Fauquier County',
    State: 'Virginia'
  },
  {
    County: 'Floyd County',
    State: 'Virginia'
  },
  {
    County: 'Fluvanna County',
    State: 'Virginia'
  },
  {
    County: 'Franklin County',
    State: 'Virginia'
  },
  {
    County: 'Frederick County',
    State: 'Virginia'
  },
  {
    County: 'Giles County',
    State: 'Virginia'
  },
  {
    County: 'Gloucester County',
    State: 'Virginia'
  },
  {
    County: 'Goochland County',
    State: 'Virginia'
  },
  {
    County: 'Grayson County',
    State: 'Virginia'
  },
  {
    County: 'Greene County',
    State: 'Virginia'
  },
  {
    County: 'Greensville County',
    State: 'Virginia'
  },
  {
    County: 'Halifax County',
    State: 'Virginia'
  },
  {
    County: 'Hanover County',
    State: 'Virginia'
  },
  {
    County: 'Henrico County',
    State: 'Virginia'
  },
  {
    County: 'Henry County',
    State: 'Virginia'
  },
  {
    County: 'Highland County',
    State: 'Virginia'
  },
  {
    County: 'Isle of Wight County',
    State: 'Virginia'
  },
  {
    County: 'James City County',
    State: 'Virginia'
  },
  {
    County: 'King and Queen County',
    State: 'Virginia'
  },
  {
    County: 'King George County',
    State: 'Virginia'
  },
  {
    County: 'King William County',
    State: 'Virginia'
  },
  {
    County: 'Lancaster County',
    State: 'Virginia'
  },
  {
    County: 'Lee County',
    State: 'Virginia'
  },
  {
    County: 'Loudoun County',
    State: 'Virginia'
  },
  {
    County: 'Louisa County',
    State: 'Virginia'
  },
  {
    County: 'Lunenburg County',
    State: 'Virginia'
  },
  {
    County: 'Madison County',
    State: 'Virginia'
  },
  {
    County: 'Mathews County',
    State: 'Virginia'
  },
  {
    County: 'Mecklenburg County',
    State: 'Virginia'
  },
  {
    County: 'Middlesex County',
    State: 'Virginia'
  },
  {
    County: 'Montgomery County',
    State: 'Virginia'
  },
  {
    County: 'Nelson County',
    State: 'Virginia'
  },
  {
    County: 'New Kent County',
    State: 'Virginia'
  },
  {
    County: 'Northampton County',
    State: 'Virginia'
  },
  {
    County: 'Northumberland County',
    State: 'Virginia'
  },
  {
    County: 'Nottoway County',
    State: 'Virginia'
  },
  {
    County: 'Orange County',
    State: 'Virginia'
  },
  {
    County: 'Page County',
    State: 'Virginia'
  },
  {
    County: 'Patrick County',
    State: 'Virginia'
  },
  {
    County: 'Pittsylvania County',
    State: 'Virginia'
  },
  {
    County: 'Powhatan County',
    State: 'Virginia'
  },
  {
    County: 'Prince Edward County',
    State: 'Virginia'
  },
  {
    County: 'Prince George County',
    State: 'Virginia'
  },
  {
    County: 'Prince William County',
    State: 'Virginia'
  },
  {
    County: 'Pulaski County',
    State: 'Virginia'
  },
  {
    County: 'Rappahannock County',
    State: 'Virginia'
  },
  {
    County: 'Richmond County',
    State: 'Virginia'
  },
  {
    County: 'Roanoke County',
    State: 'Virginia'
  },
  {
    County: 'Rockbridge County',
    State: 'Virginia'
  },
  {
    County: 'Rockingham County',
    State: 'Virginia'
  },
  {
    County: 'Russell County',
    State: 'Virginia'
  },
  {
    County: 'Scott County',
    State: 'Virginia'
  },
  {
    County: 'Shenandoah County',
    State: 'Virginia'
  },
  {
    County: 'Smyth County',
    State: 'Virginia'
  },
  {
    County: 'Southampton County',
    State: 'Virginia'
  },
  {
    County: 'Spotsylvania County',
    State: 'Virginia'
  },
  {
    County: 'Stafford County',
    State: 'Virginia'
  },
  {
    County: 'Surry County',
    State: 'Virginia'
  },
  {
    County: 'Sussex County',
    State: 'Virginia'
  },
  {
    County: 'Tazewell County',
    State: 'Virginia'
  },
  {
    County: 'Warren County',
    State: 'Virginia'
  },
  {
    County: 'Washington County',
    State: 'Virginia'
  },
  {
    County: 'Westmoreland County',
    State: 'Virginia'
  },
  {
    County: 'Wise County',
    State: 'Virginia'
  },
  {
    County: 'Wythe County',
    State: 'Virginia'
  },
  {
    County: 'York County',
    State: 'Virginia'
  },
  {
    County: 'Alexandria, City of',
    State: 'Virginia'
  },
  {
    County: 'Bedford, City of',
    State: 'Virginia'
  },
  {
    County: 'Bristol, City of',
    State: 'Virginia'
  },
  {
    County: 'Buena Vista, City of',
    State: 'Virginia'
  },
  {
    County: 'Charlottesville, City of',
    State: 'Virginia'
  },
  {
    County: 'Chesapeake, City of',
    State: 'Virginia'
  },
  {
    County: 'Colonial Heights, City of',
    State: 'Virginia'
  },
  {
    County: 'Covington, City of',
    State: 'Virginia'
  },
  {
    County: 'Danville, City of',
    State: 'Virginia'
  },
  {
    County: 'Emporia, City of',
    State: 'Virginia'
  },
  {
    County: 'Fairfax, City of',
    State: 'Virginia'
  },
  {
    County: 'Falls Church, City of',
    State: 'Virginia'
  },
  {
    County: 'Franklin, City of',
    State: 'Virginia'
  },
  {
    County: 'Fredericksburg, City of',
    State: 'Virginia'
  },
  {
    County: 'Galax, City of',
    State: 'Virginia'
  },
  {
    County: 'Hampton, City of',
    State: 'Virginia'
  },
  {
    County: 'Harrisonburg, City of',
    State: 'Virginia'
  },
  {
    County: 'Hopewell, City of',
    State: 'Virginia'
  },
  {
    County: 'Lexington, City of',
    State: 'Virginia'
  },
  {
    County: 'Lynchburg, City of',
    State: 'Virginia'
  },
  {
    County: 'Manassas, City of',
    State: 'Virginia'
  },
  {
    County: 'Manassas Park, City of',
    State: 'Virginia'
  },
  {
    County: 'Martinsville, City of',
    State: 'Virginia'
  },
  {
    County: 'Newport News, City of',
    State: 'Virginia'
  },
  {
    County: 'Norfolk, City of',
    State: 'Virginia'
  },
  {
    County: 'Norton, City of',
    State: 'Virginia'
  },
  {
    County: 'Petersburg, City of',
    State: 'Virginia'
  },
  {
    County: 'Poquoson, City of',
    State: 'Virginia'
  },
  {
    County: 'Portsmouth, City of',
    State: 'Virginia'
  },
  {
    County: 'Radford, City of',
    State: 'Virginia'
  },
  {
    County: 'Richmond, City of',
    State: 'Virginia'
  },
  {
    County: 'Roanoke, City of',
    State: 'Virginia'
  },
  {
    County: 'Salem, City of',
    State: 'Virginia'
  },
  {
    County: 'Staunton, City of',
    State: 'Virginia'
  },
  {
    County: 'Suffolk, City of',
    State: 'Virginia'
  },
  {
    County: 'Virginia Beach, City of',
    State: 'Virginia'
  },
  {
    County: 'Waynesboro, City of',
    State: 'Virginia'
  },
  {
    County: 'Williamsburg, City of',
    State: 'Virginia'
  },
  {
    County: 'Winchester, City of',
    State: 'Virginia'
  },
  {
    County: 'Adams County',
    State: 'Washington'
  },
  {
    County: 'Asotin County',
    State: 'Washington'
  },
  {
    County: 'Benton County',
    State: 'Washington'
  },
  {
    County: 'Chelan County',
    State: 'Washington'
  },
  {
    County: 'Clallam County',
    State: 'Washington'
  },
  {
    County: 'Clark County',
    State: 'Washington'
  },
  {
    County: 'Columbia County',
    State: 'Washington'
  },
  {
    County: 'Cowlitz County',
    State: 'Washington'
  },
  {
    County: 'Douglas County',
    State: 'Washington'
  },
  {
    County: 'Ferry County',
    State: 'Washington'
  },
  {
    County: 'Franklin County',
    State: 'Washington'
  },
  {
    County: 'Garfield County',
    State: 'Washington'
  },
  {
    County: 'Grant County',
    State: 'Washington'
  },
  {
    County: 'Grays Harbor County',
    State: 'Washington'
  },
  {
    County: 'Island County',
    State: 'Washington'
  },
  {
    County: 'Jefferson County',
    State: 'Washington'
  },
  {
    County: 'King County',
    State: 'Washington'
  },
  {
    County: 'Kitsap County',
    State: 'Washington'
  },
  {
    County: 'Kittitas County',
    State: 'Washington'
  },
  {
    County: 'Klickitat County',
    State: 'Washington'
  },
  {
    County: 'Lewis County',
    State: 'Washington'
  },
  {
    County: 'Lincoln County',
    State: 'Washington'
  },
  {
    County: 'Mason County',
    State: 'Washington'
  },
  {
    County: 'Okanogan County',
    State: 'Washington'
  },
  {
    County: 'Pacific County',
    State: 'Washington'
  },
  {
    County: 'Pend Oreille County',
    State: 'Washington'
  },
  {
    County: 'Pierce County',
    State: 'Washington'
  },
  {
    County: 'San Juan County',
    State: 'Washington'
  },
  {
    County: 'Skagit County',
    State: 'Washington'
  },
  {
    County: 'Skamania County',
    State: 'Washington'
  },
  {
    County: 'Snohomish County',
    State: 'Washington'
  },
  {
    County: 'Spokane County',
    State: 'Washington'
  },
  {
    County: 'Stevens County',
    State: 'Washington'
  },
  {
    County: 'Thurston County',
    State: 'Washington'
  },
  {
    County: 'Wahkiakum County',
    State: 'Washington'
  },
  {
    County: 'Walla Walla County',
    State: 'Washington'
  },
  {
    County: 'Whatcom County',
    State: 'Washington'
  },
  {
    County: 'Whitman County',
    State: 'Washington'
  },
  {
    County: 'Yakima County',
    State: 'Washington'
  },
  {
    County: 'Barbour County',
    State: 'West Virginia'
  },
  {
    County: 'Berkeley County',
    State: 'West Virginia'
  },
  {
    County: 'Boone County',
    State: 'West Virginia'
  },
  {
    County: 'Braxton County',
    State: 'West Virginia'
  },
  {
    County: 'Brooke County',
    State: 'West Virginia'
  },
  {
    County: 'Cabell County',
    State: 'West Virginia'
  },
  {
    County: 'Calhoun County',
    State: 'West Virginia'
  },
  {
    County: 'Clay County',
    State: 'West Virginia'
  },
  {
    County: 'Doddridge County',
    State: 'West Virginia'
  },
  {
    County: 'Fayette County',
    State: 'West Virginia'
  },
  {
    County: 'Gilmer County',
    State: 'West Virginia'
  },
  {
    County: 'Grant County',
    State: 'West Virginia'
  },
  {
    County: 'Greenbrier County',
    State: 'West Virginia'
  },
  {
    County: 'Hampshire County',
    State: 'West Virginia'
  },
  {
    County: 'Hancock County',
    State: 'West Virginia'
  },
  {
    County: 'Hardy County',
    State: 'West Virginia'
  },
  {
    County: 'Harrison County',
    State: 'West Virginia'
  },
  {
    County: 'Jackson County',
    State: 'West Virginia'
  },
  {
    County: 'Jefferson County',
    State: 'West Virginia'
  },
  {
    County: 'Kanawha County',
    State: 'West Virginia'
  },
  {
    County: 'Lewis County',
    State: 'West Virginia'
  },
  {
    County: 'Lincoln County',
    State: 'West Virginia'
  },
  {
    County: 'Logan County',
    State: 'West Virginia'
  },
  {
    County: 'McDowell County',
    State: 'West Virginia'
  },
  {
    County: 'Marion County',
    State: 'West Virginia'
  },
  {
    County: 'Marshall County',
    State: 'West Virginia'
  },
  {
    County: 'Mason County',
    State: 'West Virginia'
  },
  {
    County: 'Mercer County',
    State: 'West Virginia'
  },
  {
    County: 'Mineral County',
    State: 'West Virginia'
  },
  {
    County: 'Mingo County',
    State: 'West Virginia'
  },
  {
    County: 'Monongalia County',
    State: 'West Virginia'
  },
  {
    County: 'Monroe County',
    State: 'West Virginia'
  },
  {
    County: 'Morgan County',
    State: 'West Virginia'
  },
  {
    County: 'Nicholas County',
    State: 'West Virginia'
  },
  {
    County: 'Ohio County',
    State: 'West Virginia'
  },
  {
    County: 'Pendleton County',
    State: 'West Virginia'
  },
  {
    County: 'Pleasants County',
    State: 'West Virginia'
  },
  {
    County: 'Pocahontas County',
    State: 'West Virginia'
  },
  {
    County: 'Preston County',
    State: 'West Virginia'
  },
  {
    County: 'Putnam County',
    State: 'West Virginia'
  },
  {
    County: 'Raleigh County',
    State: 'West Virginia'
  },
  {
    County: 'Randolph County',
    State: 'West Virginia'
  },
  {
    County: 'Ritchie County',
    State: 'West Virginia'
  },
  {
    County: 'Roane County',
    State: 'West Virginia'
  },
  {
    County: 'Summers County',
    State: 'West Virginia'
  },
  {
    County: 'Taylor County',
    State: 'West Virginia'
  },
  {
    County: 'Tucker County',
    State: 'West Virginia'
  },
  {
    County: 'Tyler County',
    State: 'West Virginia'
  },
  {
    County: 'Upshur County',
    State: 'West Virginia'
  },
  {
    County: 'Wayne County',
    State: 'West Virginia'
  },
  {
    County: 'Webster County',
    State: 'West Virginia'
  },
  {
    County: 'Wetzel County',
    State: 'West Virginia'
  },
  {
    County: 'Wirt County',
    State: 'West Virginia'
  },
  {
    County: 'Wood County',
    State: 'West Virginia'
  },
  {
    County: 'Wyoming County',
    State: 'West Virginia'
  },
  {
    County: 'Adams County',
    State: 'Wisconsin'
  },
  {
    County: 'Ashland County',
    State: 'Wisconsin'
  },
  {
    County: 'Barron County',
    State: 'Wisconsin'
  },
  {
    County: 'Bayfield County',
    State: 'Wisconsin'
  },
  {
    County: 'Brown County',
    State: 'Wisconsin'
  },
  {
    County: 'Buffalo County',
    State: 'Wisconsin'
  },
  {
    County: 'Burnett County',
    State: 'Wisconsin'
  },
  {
    County: 'Calumet County',
    State: 'Wisconsin'
  },
  {
    County: 'Chippewa County',
    State: 'Wisconsin'
  },
  {
    County: 'Clark County',
    State: 'Wisconsin'
  },
  {
    County: 'Columbia County',
    State: 'Wisconsin'
  },
  {
    County: 'Crawford County',
    State: 'Wisconsin'
  },
  {
    County: 'Dane County',
    State: 'Wisconsin'
  },
  {
    County: 'Dodge County',
    State: 'Wisconsin'
  },
  {
    County: 'Door County',
    State: 'Wisconsin'
  },
  {
    County: 'Douglas County',
    State: 'Wisconsin'
  },
  {
    County: 'Dunn County',
    State: 'Wisconsin'
  },
  {
    County: 'Eau Claire County',
    State: 'Wisconsin'
  },
  {
    County: 'Florence County',
    State: 'Wisconsin'
  },
  {
    County: 'Fond du Lac County',
    State: 'Wisconsin'
  },
  {
    County: 'Forest County',
    State: 'Wisconsin'
  },
  {
    County: 'Grant County',
    State: 'Wisconsin'
  },
  {
    County: 'Green County',
    State: 'Wisconsin'
  },
  {
    County: 'Green Lake County',
    State: 'Wisconsin'
  },
  {
    County: 'Iowa County',
    State: 'Wisconsin'
  },
  {
    County: 'Iron County',
    State: 'Wisconsin'
  },
  {
    County: 'Jackson County',
    State: 'Wisconsin'
  },
  {
    County: 'Jefferson County',
    State: 'Wisconsin'
  },
  {
    County: 'Juneau County',
    State: 'Wisconsin'
  },
  {
    County: 'Kenosha County',
    State: 'Wisconsin'
  },
  {
    County: 'Kewaunee County',
    State: 'Wisconsin'
  },
  {
    County: 'La Crosse County',
    State: 'Wisconsin'
  },
  {
    County: 'Lafayette County',
    State: 'Wisconsin'
  },
  {
    County: 'Langlade County',
    State: 'Wisconsin'
  },
  {
    County: 'Lincoln County',
    State: 'Wisconsin'
  },
  {
    County: 'Manitowoc County',
    State: 'Wisconsin'
  },
  {
    County: 'Marathon County',
    State: 'Wisconsin'
  },
  {
    County: 'Marinette County',
    State: 'Wisconsin'
  },
  {
    County: 'Marquette County',
    State: 'Wisconsin'
  },
  {
    County: 'Menominee County',
    State: 'Wisconsin'
  },
  {
    County: 'Milwaukee County',
    State: 'Wisconsin'
  },
  {
    County: 'Monroe County',
    State: 'Wisconsin'
  },
  {
    County: 'Oconto County',
    State: 'Wisconsin'
  },
  {
    County: 'Oneida County',
    State: 'Wisconsin'
  },
  {
    County: 'Outagamie County',
    State: 'Wisconsin'
  },
  {
    County: 'Ozaukee County',
    State: 'Wisconsin'
  },
  {
    County: 'Pepin County',
    State: 'Wisconsin'
  },
  {
    County: 'Pierce County',
    State: 'Wisconsin'
  },
  {
    County: 'Polk County',
    State: 'Wisconsin'
  },
  {
    County: 'Portage County',
    State: 'Wisconsin'
  },
  {
    County: 'Price County',
    State: 'Wisconsin'
  },
  {
    County: 'Racine County',
    State: 'Wisconsin'
  },
  {
    County: 'Richland County',
    State: 'Wisconsin'
  },
  {
    County: 'Rock County',
    State: 'Wisconsin'
  },
  {
    County: 'Rusk County',
    State: 'Wisconsin'
  },
  {
    County: 'St. Croix County',
    State: 'Wisconsin'
  },
  {
    County: 'Sauk County',
    State: 'Wisconsin'
  },
  {
    County: 'Sawyer County',
    State: 'Wisconsin'
  },
  {
    County: 'Shawano County',
    State: 'Wisconsin'
  },
  {
    County: 'Sheboygan County',
    State: 'Wisconsin'
  },
  {
    County: 'Taylor County',
    State: 'Wisconsin'
  },
  {
    County: 'Trempealeau County',
    State: 'Wisconsin'
  },
  {
    County: 'Vernon County',
    State: 'Wisconsin'
  },
  {
    County: 'Vilas County',
    State: 'Wisconsin'
  },
  {
    County: 'Walworth County',
    State: 'Wisconsin'
  },
  {
    County: 'Washburn County',
    State: 'Wisconsin'
  },
  {
    County: 'Washington County',
    State: 'Wisconsin'
  },
  {
    County: 'Waukesha County',
    State: 'Wisconsin'
  },
  {
    County: 'Waupaca County',
    State: 'Wisconsin'
  },
  {
    County: 'Waushara County',
    State: 'Wisconsin'
  },
  {
    County: 'Winnebago County',
    State: 'Wisconsin'
  },
  {
    County: 'Wood County',
    State: 'Wisconsin'
  },
  {
    County: 'Albany County',
    State: 'Wyoming'
  },
  {
    County: 'Big Horn County',
    State: 'Wyoming'
  },
  {
    County: 'Campbell County',
    State: 'Wyoming'
  },
  {
    County: 'Carbon County',
    State: 'Wyoming'
  },
  {
    County: 'Converse County',
    State: 'Wyoming'
  },
  {
    County: 'Crook County',
    State: 'Wyoming'
  },
  {
    County: 'Fremont County',
    State: 'Wyoming'
  },
  {
    County: 'Goshen County',
    State: 'Wyoming'
  },
  {
    County: 'Hot Springs County',
    State: 'Wyoming'
  },
  {
    County: 'Johnson County',
    State: 'Wyoming'
  },
  {
    County: 'Laramie County',
    State: 'Wyoming'
  },
  {
    County: 'Lincoln County',
    State: 'Wyoming'
  },
  {
    County: 'Natrona County',
    State: 'Wyoming'
  },
  {
    County: 'Niobrara County',
    State: 'Wyoming'
  },
  {
    County: 'Park County',
    State: 'Wyoming'
  },
  {
    County: 'Platte County',
    State: 'Wyoming'
  },
  {
    County: 'Sheridan County',
    State: 'Wyoming'
  },
  {
    County: 'Sublette County',
    State: 'Wyoming'
  },
  {
    County: 'Sweetwater County',
    State: 'Wyoming'
  },
  {
    County: 'Teton County',
    State: 'Wyoming'
  },
  {
    County: 'Uinta County',
    State: 'Wyoming'
  },
  {
    County: 'Washakie County',
    State: 'Wyoming'
  },
  {
    County: 'Weston County',
    State: 'Wyoming'
  }
]

export default counties
