import React from 'react'
import { Form, Field, Formik } from 'formik'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import toast from 'react-hot-toast'

import BaseModal from '../modal/BaseModal'
import Button from '../buttons/Button'
import SAVE_PROFILE_EXPENSE from '../../gql/mutations/saveProfileExpense'
import GET_PROFILE_EXPENSES from '../../gql/queries/getProfileExpenses'
import SAVE_PROFILE_INCOME from '../../gql/mutations/saveProfileIncome'
import GET_PROFILE_INCOMES from '../../gql/queries/getProfileIncomes'

function EditAllocationModal({ closeModal, isOpen, initialValue }) {
  const [saveProfileExpense] = useMutation(SAVE_PROFILE_EXPENSE)
  const [saveProfileIncome] = useMutation(SAVE_PROFILE_INCOME)

  const initialValues = {
    allocation: initialValue.allocation,
    allocationType: initialValue.allocation === 0 ? 'auto' : 'custom',
    expenseId: initialValue.expenseId,
    incomeId: initialValue.incomeId,
    profileId: initialValue.profileId
  }

  const onSubmit = async (values, { resetForm }) => {
    const data = {}
    if (values.allocationType === 'custom') data.allocation = values.allocation
    data.isAutoAllocated = values.allocationType === 'auto'

    if (values.expenseId) {
      toast.promise(
        saveProfileExpense({
          variables: {
            input: {
              profileId: values.profileId,
              expenseId: values.expenseId,
              ...data
            }
          },
          refetchQueries: [GET_PROFILE_EXPENSES]
        }),
        {
          loading: 'Saving the commodity allocation...',
          success: 'Commodity allocation saved successfully.',
          error: 'Error while saving the commodity allocation.'
        }
      )
    } else {
      if (values.allocationType === 'auto') data.allocation = 0
      toast.promise(
        saveProfileIncome({
          variables: {
            input: {
              profileId: values.profileId,
              incomeId: values.incomeId,
              ...data
            }
          },
          refetchQueries: [GET_PROFILE_INCOMES]
        }),
        {
          loading: 'Saving the commodity allocation...',
          success: 'Commodity allocation saved successfully.',
          error: 'Error while saving the commodity allocation.'
        }
      )
    }

    resetForm()
    closeModal()
  }

  const validationSchema = Yup.object({
    allocation: Yup.string().required('Required!')
  })

  return (
    <BaseModal isOpen={isOpen} closeModal={closeModal}>
      <div className="h-24v  w-72">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values }) => (
            <>
              <h4 className="mb-6 font-bold text-gray-900">Allocate</h4>
              <Form>
                <div role="group">
                  <label>
                    <Field type="radio" name="allocationType" value="auto" className="mr-2" />
                    auto allocate
                  </label>
                  <br />
                  <label>
                    <Field type="radio" name="allocationType" value="custom" className="mr-2" />
                    custom allocation
                  </label>
                </div>
                {values.allocationType === 'custom' && (
                  <div className="mt-1 ml-6 flex flex-row">
                    <Field
                      type="number"
                      id="allocation"
                      name="allocation"
                      className="h-8 w-20 rounded rounded-r-none border border-gray-300 px-1 py-2 pr-1 text-left text-sm leading-none text-gray-500 outline-none"
                    />
                    <div className="flex h-8 w-fit items-center justify-center rounded rounded-l-none border border-gray-300 bg-gray-50 p-2">
                      %
                    </div>
                  </div>
                )}

                <div className="mt-6 flex justify-end gap-x-2">
                  <Button type="button" color="neutral" label="Cancel" onClick={closeModal} />
                  <Button type="submit" color="info" label="Save" />
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </BaseModal>
  )
}

export default EditAllocationModal
