import { gql } from '@apollo/client'

const GET_DELIVERIES = gql`
  query getDeliveries($saleId: String!) {
    getDeliveries(saleId: $saleId) {
      id
      saleId
      amount
      date
      unloadNumber
      file
      notes
    }
  }
`

export default GET_DELIVERIES
