/* eslint-disable no-unsafe-optional-chaining */
import { useMutation, useQuery } from '@apollo/client'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import toast from 'react-hot-toast'

import BaseModal from '../modal/BaseModal'
import Button from '../buttons/Button'
import counties from '../../constants/counties'
import CREATE_INSURANCE from '../../gql/mutations/createInsurance'
import GET_INSURANCES from '../../gql/queries/getInsurances'
import GET_INSURANCE_PRICINGS from '../../gql/queries/getInsurancePricings'
import Loader from '../Loader'
import SignedInput from '../SignedInput'
import states from '../../constants/states'
import UPDATE_INSURANCE from '../../gql/mutations/updateInsurance'
import { renderPrice } from '../../utils/helper'
import units from '../../constants/units'

const defaultPrice = 4
function InsuranceModal({
  isOpen,
  isCreate,
  insurance,
  closeModal,
  insuranceProfileData,
  loading,
  productionYear,
  clientId
}) {
  const [createInsurance, { loading: createInsuranceLoading }] = useMutation(CREATE_INSURANCE)
  const [updateInsurance, { loading: updateInsuranceLoading }] = useMutation(UPDATE_INSURANCE)
  const { data: insurancePriceData, loading: insuranceLoading } = useQuery(GET_INSURANCE_PRICINGS, {
    variables: {
      input: {
        clientId,
        productionYear: parseInt(productionYear, 10)
      }
    }
  })

  if (
    (!insuranceProfileData && loading) ||
    createInsuranceLoading ||
    updateInsuranceLoading ||
    insuranceLoading
  ) {
    return (
      <BaseModal isOpen={isOpen} closeModal={closeModal}>
        <div className="loader flex flow-root sm:text-center">
          <Loader message="Fetching insurance..." variant="fetchData" />
        </div>
      </BaseModal>
    )
  }

  const cropPriceArray = insurancePriceData?.getInsurancePricings?.map((pricing) => ({
    cropName: pricing?.crop?.name,
    id: pricing?.crop?.id,
    price: pricing?.price,
    productionYear: pricing?.productionYear
  }))

  const getCropPrice = (profileId) => {
    const profile = insuranceProfileData?.find((profile) => profile.id === profileId)
    const price = cropPriceArray?.find(
      (crop) =>
        crop?.id === profile.cropId && crop?.productionYear === profile?.productionYear?.name
    )?.price
    return price
  }

  const initialValues = {
    profileId: insuranceProfileData[0]?.id,
    productKind: 'RP',
    state: states[0]?.name,
    county: counties[0]?.County,
    coverage: 50,
    dryYield: undefined,
    isCustomPrice: false,
    price: (insuranceProfileData && getCropPrice(insuranceProfileData[0]?.id)) || defaultPrice,
    protectionFactor: 50,
    calculateBy: 'YIELD'
  }

  const updatedInitialValues = {
    profileId: insurance?.profile?.id,
    productKind: insurance?.productKind,
    state: insurance?.state,
    county: insurance?.county,
    coverage: insurance?.coverage,
    dryYield: insurance?.dryYield?.toFixed(2),
    isCustomPrice: false,
    price: insurance?.price,
    protectionFactor: insurance?.protectionFactor,
    calculateBy: 'YIELD'
  }

  const productOptions = ['RP', 'RP_HPE', 'YP', 'APH', 'ARP_HRE', 'ARP', 'AYP']
  const protectionFactorOptions = ['ARP_HPE', 'ARP', 'AYP']
  const onSubmit = (values, { resetForm }) => {
    let { dryYield } = values
    if (values?.revenue) {
      const currentProfile = insuranceProfileData?.find(
        (profile) => profile?.id === values?.profileId
      )
      dryYield =
        values?.revenue /
        ((currentProfile?.drylandAcres + currentProfile?.irrigatedAcres) * values?.price)
    }

    if (isCreate) {
      toast.promise(
        createInsurance({
          variables: {
            input: {
              profileId: values?.profileId,
              productKind: values?.productKind,
              state: values?.state,
              county: values?.county,
              dryYield: parseFloat(dryYield),
              price: parseFloat(values?.price) || 0,
              coverage: parseFloat(values?.coverage),
              protectionFactor: values?.protectionFactor
            }
          },
          refetchQueries: [GET_INSURANCES]
        }),
        {
          loading: 'Creating the Insurance...',
          success: 'Insurance added successfully.',
          error: 'Error while creating the Insurance.'
        }
      )
    } else {
      toast.promise(
        updateInsurance({
          variables: {
            input: {
              id: insurance.id,
              profileId: insurance?.profile?.id,
              productKind: values?.productKind,
              state: values?.state,
              county: values?.county,
              dryYield: parseFloat(dryYield),
              price: parseFloat(values?.price) || 0,
              coverage: parseFloat(values?.coverage),
              protectionFactor: values?.protectionFactor
            }
          }
        }),
        {
          loading: 'Updating the Insurance...',
          success: 'Insurance updated successfully.',
          error: 'Error while updating the Insurance.'
        }
      )
    }
    closeModal()
    resetForm()
  }

  const setPriceField = (value, setFieldValue) => {
    if (isCreate) {
      setFieldValue('price', getCropPrice(value) || defaultPrice)
    } else {
      setFieldValue(
        'price',
        insurance.profile.id === value ? insurance?.price : getCropPrice(value) || defaultPrice
      )
    }
  }

  const handleChange = (event, setFieldValue, value) => {
    setFieldValue('isCustomPrice', !value.isCustomPrice)
    const currentProfile = insuranceProfileData?.find((profile) => profile?.id === value?.profileId)
    setPriceField(currentProfile?.id, setFieldValue)
  }

  return (
    <BaseModal isOpen={isOpen} closeModal={closeModal}>
      <div className="space-y-6">
        <Formik initialValues={isCreate ? initialValues : updatedInitialValues} onSubmit={onSubmit}>
          {({ values, setFieldValue }) => {
            const insuranceCounties = counties.filter((item) => item?.State === values?.state)
            const currentProfile = insuranceProfileData?.find(
              (profile) => profile?.id === values?.profileId
            )
            const quantity =
              (currentProfile?.drylandAcres + currentProfile?.irrigatedAcres) * values?.dryYield
            const coverage = values?.coverage / 100
            const insured = (quantity * coverage)?.toFixed(2) || 0
            const revenue = (insured * values?.price)?.toFixed(4) || 0

            const insuredByRevenue = (values?.revenue / values?.price)?.toFixed(2) || 0

            const dryYield = (
              values?.revenue /
              (coverage *
                values?.price *
                (currentProfile?.drylandAcres + currentProfile?.irrigatedAcres))
            )?.toFixed(2)

            if (values?.calculateBy === 'YIELD' && values?.revenue !== revenue) {
              setFieldValue('revenue', revenue)
              setFieldValue('insured', insured)
            } else if (values?.calculateBy === 'REVENUE' && values?.dryYield !== dryYield) {
              setFieldValue('dryYield', dryYield)
              setFieldValue('insured', insuredByRevenue)
            }
            return (
              <Form>
                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                  <div className="md:grid md:grid-cols-2 md:gap-6">
                    <div className="md:col-span-1">
                      <h3 className="text-lg font-bold leading-6 text-gray-900">
                        {isCreate ? 'Add' : 'Edit'}&nbsp;Insurance
                      </h3>
                    </div>
                    <div className="mt-5 md:col-span-1 md:mt-0">
                      {insuranceProfileData?.length > 0 ? (
                        <div className="col-span-2">
                          <label className="text-sm font-bold text-gray-700" htmlFor="profileId">
                            Profile
                          </label>
                          <Field
                            as="select"
                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                            type="profileId"
                            id="profileId"
                            name="profileId"
                            onChange={(event) => {
                              setFieldValue('profileId', event?.target?.value)
                              setPriceField(event?.target?.value, setFieldValue)
                            }}
                          >
                            {insuranceProfileData.map((item) => (
                              <option value={item.id}>
                                {item.crop?.name}&nbsp;({item.productionYear?.name})
                              </option>
                            ))}
                          </Field>
                        </div>
                      ) : (
                        <p className="text-red-600">Please add a dryland crop commodity.</p>
                      )}
                      <div className="col-span-2 mt-6">
                        <label className="text-sm font-bold text-gray-700" htmlFor="productKind">
                          Product
                        </label>
                        <Field
                          as="select"
                          className="mt-1 block w-2/4 rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                          type="profile"
                          id="productKind"
                          name="productKind"
                        >
                          {productOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="col-span-2 mt-6">
                        <label className="text-sm font-bold text-gray-700" htmlFor="addressDetails">
                          State and County
                        </label>
                        <div className="grid grid-cols-2 gap-x-1.5">
                          <Field
                            as="select"
                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                            name="state"
                          >
                            {states.map((option) => (
                              <option key={option?.name} value={option?.name}>
                                {option.name}
                              </option>
                            ))}
                          </Field>
                          <Field
                            as="select"
                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                            name="county"
                          >
                            {insuranceCounties.map((option) => (
                              <option key={option?.County} value={option?.County}>
                                {option?.County}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </div>
                      <div className="col-span-2 mt-6">
                        <label className="text-sm font-bold text-gray-700" htmlFor="coverage">
                          Coverage Level
                        </label>
                        <Field
                          as="select"
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                          id="coverage"
                          name="coverage"
                        >
                          <option value="50">50%</option>
                          <option value="55">55%</option>
                          <option value="60">60%</option>
                          <option value="65">65%</option>
                          <option value="70">70%</option>
                          <option value="75">75%</option>
                          <option value="80">80%</option>
                          <option value="85">85%</option>
                          <option value="90">90%</option>
                        </Field>
                      </div>
                      {protectionFactorOptions.includes(values?.productKind) && (
                        <div className="col-span-2 mt-6">
                          <label className="text-sm font-bold text-gray-700" htmlFor="coverage">
                            Protection Level
                          </label>
                          <Field
                            as="select"
                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                            id="protectionFactor"
                            name="protectionFactor"
                          >
                            <option value="50">50%</option>
                            <option value="55">55%</option>
                            <option value="60">60%</option>
                            <option value="65">65%</option>
                            <option value="70">70%</option>
                            <option value="75">75%</option>
                            <option value="80">80%</option>
                            <option value="85">85%</option>
                            <option value="90">90%</option>
                            <option value="95">95%</option>
                            <option value="100">100%</option>
                          </Field>
                        </div>
                      )}
                      <div className="mt-4">
                        <label className="text-sm font-bold text-gray-700">
                          Calculate by:{' '}
                          <div role="group" className="mt-2 ml-6 inline-block items-center">
                            <label className="mr-4">
                              <Field
                                type="radio"
                                name="calculateBy"
                                value="YIELD"
                                className="mr-2"
                                onChange={() => {
                                  setFieldValue('revenue', '')
                                  setFieldValue('dryYield', '')
                                  setFieldValue('calculateBy', 'YIELD')
                                  if (!isCreate) {
                                    setFieldValue('dryYield', insurance?.dryYield?.toFixed(2))
                                    setFieldValue('revenue', revenue)
                                  }
                                }}
                              />
                              Yield
                            </label>
                            <label>
                              <Field
                                type="radio"
                                name="calculateBy"
                                value="REVENUE"
                                className="mr-2"
                                onChange={() => {
                                  setFieldValue('dryYield', '')
                                  setFieldValue('revenue', '')
                                  setFieldValue('calculateBy', 'REVENUE')
                                  if (!isCreate) {
                                    setFieldValue('revenue', revenue)
                                    setFieldValue('dryYield', dryYield)
                                  }
                                }}
                              />
                              Revenue
                            </label>
                          </div>
                        </label>
                      </div>
                      <div className="col-span-2 mt-6">
                        <SignedInput
                          label="APH yield"
                          inputstyle={
                            values?.calculateBy === 'REVENUE'
                              ? 'bg-gray-200  cursor-not-allowed'
                              : ''
                          }
                          isDisabled={values?.calculateBy === 'REVENUE'}
                          type="number"
                          min="0"
                          name="dryYield"
                          unit={currentProfile?.crop?.unit}
                        />
                      </div>
                      <div className="col-span-2 mt-6">
                        <SignedInput
                          label={`Insured ${units[currentProfile?.crop?.unit]}`}
                          inputstyle="bg-gray-200  cursor-not-allowed"
                          isDisabled
                          type="number"
                          min="0"
                          name="insured"
                          unit={currentProfile?.crop?.unit}
                        />
                      </div>
                      <div className="col-span-2 mt-6">
                        <SignedInput
                          label="Insured Revenue"
                          inputstyle={
                            values?.calculateBy === 'YIELD' ? 'bg-gray-200  cursor-not-allowed' : ''
                          }
                          isDisabled={values?.calculateBy === 'YIELD'}
                          type="number"
                          min="0"
                          name="revenue"
                          unit="$"
                        />
                      </div>
                      <div className="mt-6">
                        <Field
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                          id="isCustomPrice"
                          name="isCustomPrice"
                          checked={values.isCustomPrice}
                          onChange={(event) => handleChange(event, setFieldValue, values)}
                        />
                        <label
                          className="ml-2 text-sm font-bold text-gray-700"
                          htmlFor="isCustomPrice"
                        >
                          Enter a Price
                        </label>
                        {values.isCustomPrice && (
                          <SignedInput
                            label=""
                            type="number"
                            min="0.01"
                            step="0.01"
                            name="price"
                            unit="$"
                          />
                        )}
                      </div>
                      <div className="col-span-2">
                        <div className="mt-6 flex-col rounded-lg bg-gray-300 px-5 py-6 shadow">
                          <div className="flex justify-end">
                            <p className="text-lg font-bold leading-6 text-gray-900">
                              {values.price ? `${renderPrice(values.price)}` : `$0.00`}
                            </p>
                          </div>
                          <p className="flex justify-end"> USDA PRICE</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-2 mt-6">
                  <div className="flex justify-end gap-x-2">
                    <Button type="button" color="neutral" label="Cancel" onClick={closeModal} />
                    <Button type="submit" color="info" label="Save" />
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </BaseModal>
  )
}

export default InsuranceModal
