import * as yup from 'yup'
import React from 'react'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import toast from 'react-hot-toast'
import { Form, Formik } from 'formik'
import { useMutation } from '@apollo/client'

import BaseModal from '../../modal/BaseModal'
import Button from '../../buttons/Button'
import Input from '../../inputs/Input'
import SEND_REPORT from '../../../gql/mutations/sendReport'
import { useGetUser } from '../../../services/queries/useGetUser'

function SendReportModal({ isOpen, closeModal, clientId, kind, productionYears }) {
  const { data: userData } = useGetUser(clientId, { skip: !clientId })
  const [sendReport, { loading }] = useMutation(SEND_REPORT)

  const user = userData?.getUser
  const userEmail = user?.email || ''

  const onSubmit = (values) => {
    const emails = values.emails.trim().length ? values.emails.split(',').map((i) => i.trim()) : []

    const duplicateEmails = filter(emails, (val, i, iteratee) => includes(iteratee, val, i + 1))

    if (duplicateEmails.length) {
      toast.error(`remove duplicate email(s): ${duplicateEmails.join(',')}`)
      return
    }

    try {
      yup.array().of(yup.string().email()).validateSync(emails)
    } catch (error) {
      const email = error?.params?.value
      toast.error(`"${email}" must be a valid email`)
      return
    }

    toast.promise(
      sendReport({
        variables: {
          input: {
            clientId,
            kind,
            productionYears,
            emails
          }
        },
        onCompleted: closeModal
      }),
      {
        loading: 'Sending report by email...',
        success: 'Report sent successfully.',
        error: 'Error while sending the report.'
      }
    )
  }

  return (
    <BaseModal isOpen={isOpen} closeModal={closeModal}>
      <Formik initialValues={{ emails: '' }} onSubmit={onSubmit}>
        <Form>
          <div className="flex w-[320px] flex-col gap-4 md:w-[520px]">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Email report</h3>

            <p className="text-sm">
              Client email: <span className="font-semibold">{userEmail}</span>
            </p>

            <div>
              <Input label="Emails" type="text" name="emails" />
              <p className="mt-1 text-xs text-gray-500">Enter comma(,) separated emails</p>
            </div>
          </div>

          <div className="mt-6 flex justify-end gap-x-2">
            <Button
              type="button"
              color="neutral"
              label="Cancel"
              disabled={loading}
              onClick={closeModal}
            />
            <Button type="submit" color="info" label="Send" disabled={loading} />
          </div>
        </Form>
      </Formik>
    </BaseModal>
  )
}

export default SendReportModal
