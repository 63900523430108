const INTERNAL_SERVER_ERROR = 'Something went wrong. Please try again.'

const formatErrors = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors.length)
    return graphQLErrors[0]?.extensions?.exception?.response?.error || INTERNAL_SERVER_ERROR

  if (networkError) return `Network error: ${networkError?.message}`

  return INTERNAL_SERVER_ERROR
}

export default formatErrors
