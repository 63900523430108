/* eslint-disable no-unsafe-optional-chaining */
import * as yup from 'yup'
import React from 'react'
import toast from 'react-hot-toast'
import { Field, Form, Formik } from 'formik'
import { useMutation, useQuery } from '@apollo/client'

import BaseModal from '../modal/BaseModal'
import Button from '../buttons/Button'
import CREATE_PROFILE from '../../gql/mutations/createProfile'
import GET_CROPS from '../../gql/queries/getCrops'
import GET_SUMMARY from '../../gql/queries/getSummary'
import Input from '../inputs/Input'
import PROFILES from '../../gql/queries/Profiles'
import SignedInput from '../SignedInput'
import UPDATE_PROFILE from '../../gql/mutations/updateProfile'
import { renderNumber } from '../../utils/helper'

function CropProfileModal({ clientId, year, isOpen, profile, onClose }) {
  const isCreate = !profile?.id
  const { data } = useQuery(GET_CROPS)
  const crops = data?.getCrops

  const initialValues = {
    cropId: crops?.[0]?.id,
    profileId: undefined,
    productionYearId: undefined,
    isDryland: false,
    isIrrigated: false,
    drylandAcres: undefined,
    drylandYield: undefined,
    irrigatedAcres: undefined,
    irrigatedYield: undefined,
    amountNotForSale: 0,
    storageAvailable: 0,
    description: undefined,
    notes: undefined
  }

  const updatedInitialValues = {
    cropId: profile?.cropId,
    profileId: profile?.id,
    isDryland: profile?.isDryland,
    isIrrigated: profile?.isIrrigated,
    drylandAcres: profile?.drylandAcres,
    drylandYield: profile?.drylandYield,
    irrigatedAcres: profile?.irrigatedAcres,
    irrigatedYield: profile?.irrigatedYield,
    amountNotForSale: profile?.amountNotForSale,
    storageAvailable: profile?.storageAvailable,
    description: profile?.description,
    notes: profile?.notes
  }

  const clientsSchema = yup.object({
    cropId: yup.string().required('Required'),
    isDryland: yup.bool().when('isIrrigated', {
      is: (isIrrigated) => !isIrrigated,
      then: yup.bool().oneOf([true], 'at least one practice needs to be checked')
    }),
    drylandAcres: yup.number().when('isDryland', {
      is: true,
      then: yup
        .number()
        .typeError('Required')
        .required('Required')
        .min(0, 'Dryland acres must be greater than or equal to 0'),
      otherwise: yup.number().nullable()
    }),
    irrigatedAcres: yup.number().when('isIrrigated', {
      is: true,
      then: yup
        .number()
        .typeError('Required')
        .required('Required')
        .min(0, 'Irrigated acres must be greater than or equal to 0'),
      otherwise: yup.number().nullable()
    }),
    drylandYield: yup
      .number()
      .nullable()
      .min(0, 'Dryland yield must be greater than or equal to 0'),
    irrigatedYield: yup
      .number()
      .nullable()
      .min(0, 'Irrigated yield must be greater than or equal to 0')
  })

  const [createProfile] = useMutation(CREATE_PROFILE)
  const [updateProfile] = useMutation(UPDATE_PROFILE)

  const createCropProfile = async (value) => {
    toast.promise(
      createProfile({
        variables: {
          input: {
            clientId,
            productionYearId: year[0]?.id,
            kind: 'CROP',
            cropId: value?.cropId,
            drylandAcres: value?.isDryland ? value?.drylandAcres : null,
            drylandYield: value?.isDryland ? value?.drylandYield : null,
            irrigatedAcres: value?.isIrrigated ? value?.irrigatedAcres : null,
            irrigatedYield: value?.isIrrigated ? value?.irrigatedYield : null,
            isDryland: value?.isDryland,
            isIrrigated: value?.isIrrigated,
            amountNotForSale: value?.amountNotForSale,
            storageAvailable: value?.storageAvailable,
            description: value?.description,
            notes: value?.notes
          }
        },
        refetchQueries: [PROFILES, GET_SUMMARY]
      }),
      {
        loading: 'Creating the Crop commodity...',
        success: 'Crop commodity created successfully.',
        error: 'Error while creating the Crop commodity.'
      }
    )
  }

  const updateCropProfile = async (value) => {
    toast.promise(
      updateProfile({
        variables: {
          input: {
            id: profile?.id,
            cropId: value?.cropId,
            drylandAcres: value?.isDryland ? value?.drylandAcres : null,
            drylandYield: value?.isDryland ? value?.drylandYield : null,
            irrigatedAcres: value?.isIrrigated ? value?.irrigatedAcres : null,
            irrigatedYield: value?.isIrrigated ? value?.irrigatedYield : null,
            isDryland: value?.isDryland,
            isIrrigated: value?.isIrrigated,
            amountNotForSale: value?.amountNotForSale,
            storageAvailable: value?.storageAvailable,
            description: value?.description,
            notes: value?.notes
          }
        },
        refetchQueries: [PROFILES]
      }),
      {
        loading: 'Updating the Crop commodity...',
        success: 'Crop commodity updated successfully.',
        error: 'Error while updating the Crop commodity.'
      }
    )
  }

  const handleDrylandChange = (event, setFieldValue, value) => {
    setFieldValue('isDryland', !value)
  }

  const handleIrrigatedChange = (event, setFieldValue, value) => {
    setFieldValue('isIrrigated', !value)
  }
  return (
    <BaseModal isOpen={isOpen} closeModal={onClose}>
      <div className="space-y-6">
        <Formik
          initialValues={isCreate ? initialValues : updatedInitialValues}
          validationSchema={clientsSchema}
          onSubmit={(value) => {
            isCreate ? createCropProfile(value) : updateCropProfile(value)
            onClose()
          }}
        >
          {({ values, errors, setFieldValue }) => {
            const drylandProduction = values?.drylandAcres * values?.drylandYield
            const irrigatedProduction = values?.irrigatedAcres * values?.irrigatedYield
            let production
            if (values?.isDryland && values?.isIrrigated) {
              production = drylandProduction + irrigatedProduction
            } else if (values?.isDryland) {
              production = drylandProduction
            } else if (values?.isIrrigated) {
              production = irrigatedProduction
            }
            const crop = crops?.find((crop) => crop?.id === values?.cropId)

            return (
              <Form>
                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                  <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="flex-col md:col-span-1">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        {isCreate ? 'Add' : 'Edit'}&nbsp;crop
                      </h3>
                      <div className=" mt-40 flex-col rounded-lg bg-gray-300 px-5 py-6 shadow">
                        <div className="flex justify-center text-xl">
                          {production > 0 ? renderNumber(production) : 0}
                          &nbsp;{crop?.unit.toLowerCase()}
                        </div>
                        <div className=" flex justify-center text-xs text-gray-500">PRODUCTION</div>
                      </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <label className="text-sm font-bold text-gray-700" htmlFor="cropId">
                            Crop
                          </label>
                          <Field
                            as="select"
                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                            type="crop"
                            id="cropId"
                            name="cropId"
                          >
                            {crops?.map((item) => (
                              <option value={item?.id}>{item?.name}</option>
                            ))}
                          </Field>
                        </div>

                        <div className="col-span-6">
                          <Input label="Description" type="text" name="description" />
                        </div>

                        <div className="col-span-6">
                          <label
                            id="checkbox-group"
                            className="mb-1 block text-sm font-bold text-gray-700 "
                          >
                            Practice
                          </label>
                          <div role="group" aria-labelledby="checkbox-group">
                            <div className="flex">
                              <div className="relative flex items-start">
                                <div className="flex h-5 items-center">
                                  <div className="flex h-5 items-center">
                                    <label className="flex font-medium text-gray-700">
                                      <Field
                                        type="checkbox"
                                        name="isDryland"
                                        className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                                        checked={values?.isDryland}
                                        onChange={(event) =>
                                          handleDrylandChange(
                                            event,
                                            setFieldValue,
                                            values?.isDryland
                                          )
                                        }
                                      />
                                      <div className="ml-3 text-sm">Dryland</div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="relative ml-4 flex items-start">
                                <div className="flex h-5 items-center">
                                  <label className=" flex font-medium text-gray-700">
                                    <Field
                                      type="checkbox"
                                      name="isIrrigated"
                                      className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                                      checked={values?.isIrrigated}
                                      onChange={(event) =>
                                        handleIrrigatedChange(
                                          event,
                                          setFieldValue,
                                          values?.isIrrigated
                                        )
                                      }
                                    />
                                    <div className="ml-3 text-sm">Irrigated</div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {errors.isDryland && (
                            <p className="mt-1 text-sm text-red-500">{errors.isDryland}</p>
                          )}
                        </div>
                        {values?.isDryland && (
                          <div className="col-span-6">
                            <div className="flex">
                              <div className="mr-7 w-1/2">
                                <SignedInput
                                  label="Dryland Acres"
                                  type="number"
                                  name="drylandAcres"
                                  unit="acres"
                                />
                              </div>
                              <div className="w-1/2">
                                <SignedInput
                                  label="Dryland Yield"
                                  type="number"
                                  name="drylandYield"
                                  unit={crop?.unit.toLowerCase()}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {values?.isIrrigated && (
                          <div className="col-span-6">
                            <div className="flex">
                              <div className="mr-7 w-1/2">
                                <SignedInput
                                  label="Irrigated Acres"
                                  type="number"
                                  name="irrigatedAcres"
                                  unit="acres"
                                />
                              </div>
                              <div className="w-1/2">
                                <SignedInput
                                  label="Irrigated Yield"
                                  type="number"
                                  name="irrigatedYield"
                                  unit={crop?.unit.toLowerCase()}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {/* <div className="col-span-6 sm:col-span-3">
                              <Input
                                label="Amount not for sale"
                                type="number"
                                name="amountNotForSale"
                              />
                            </div> */}

                        {/* <div className="col-span-6 sm:col-span-3">
                              <Input
                                label="Storage available"
                                type="number"
                                name="storageAvailable"
                              />
                            </div> */}

                        <div className="col-span-6">
                          <label htmlFor="notes" className="block text-sm font-bold text-gray-700">
                            Notes
                          </label>
                          <div className="mt-1">
                            <Field
                              as="textarea"
                              rows={3}
                              type="notes"
                              id="notes"
                              name="notes"
                              defaultValue=""
                              className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                            />
                          </div>
                          <p className="mt-2 text-sm text-gray-500">
                            Any notes related to this crop
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex justify-end gap-x-2">
                  <Button type="button" color="neutral" label="Cancel" onClick={onClose} />
                  <Button type="submit" color="info" label="Save" />
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </BaseModal>
  )
}

export default CropProfileModal
