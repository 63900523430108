import React, { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'

import RequireUser from '../authentication/RequireUser'
import RequireVisitor from '../authentication/RequireVisitor'

import AcceptInvitePage from '../../pages/external/AcceptInvitePage'
import AdminDetailsPage from '../../pages/admin/AdminDetailsPage'
import AdminPage from '../../pages/admin/AdminPage'
import AdminReportsPage from '../../pages/admin/AdminReportsPage'
import ChangeEmailAddressPage from '../../pages/account/ChangeEmailAddressPage'
import ChangePasswordPage from '../../pages/account/ChangePasswordPage'
import ClientProductionYearsPage from '../../pages/ClientProductionYearsPage'
import ClientsDetailsPage from '../../pages/ClientsDetailsPage'
import ClientsPage from '../../pages/ClientsPage'
import ConfirmNewEmailAddressPage from '../../pages/account/ConfirmNewEmailAddressPage'
import dashX from '../../lib/dashx'
import DefaultBasisValuationPage from '../../pages/DefaultBasisValuationsPage'
import ExternalLayout from '../layouts/ExternalLayout'
import ExternalReportPage from '../../pages/external/ExternalReportPage'
import ForgotPasswordPage from '../../pages/external/ForgotPasswordPage'
import FuturesPricesPage from '../../pages/FuturesPricesPage'
import LoginPage from '../../pages/external/LoginPage'
import LogoutPage from '../../pages/external/LogoutPage'
import ManagersPage from '../../pages/ManagersPage'
import NotFoundPage from '../../pages/NotFoundPage'
import ProfileAccountPage from '../../pages/account/ProfileAccountPage'
import ResetPasswordPage from '../../pages/external/ResetPasswordPage'
import roles from '../../constants/roles'
import SetPasswordPage from '../../pages/external/SetPasswordPage'
import UnauthorizedPage from '../../pages/UnauthorizedPage'
import USDAPricesPage from '../../pages/admin/USDAPricesPage'
import UserLayout from '../layouts/UserLayout'
import {
  ADMIN_ROUTES,
  CLIENT_ROUTES,
  PLAN_ROUTES,
  USER_ROUTES,
  VISITOR_ROUTES
} from '../../constants/routes'
import { useAuth } from '../../hooks/useAuth'

function AppRouter() {
  const auth = useAuth()
  const location = useLocation()

  useEffect(() => {
    dashX.track('PageView', { path: location.pathname })
  }, [location.pathname])

  return (
    <Routes>
      <Route path={VISITOR_ROUTES.UNAUTHORIZED} element={<UnauthorizedPage />} />
      <Route path={VISITOR_ROUTES.REPORT} element={<ExternalReportPage />} />

      <Route element={<RequireVisitor />}>
        <Route element={<ExternalLayout />}>
          <Route path={VISITOR_ROUTES.LOGIN} element={<LoginPage />} />
          <Route path={VISITOR_ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
          <Route path={VISITOR_ROUTES.RESET_PASSWORD} element={<ResetPasswordPage />} />
          <Route path={USER_ROUTES.ACCEPT_INVITE} element={<AcceptInvitePage />} />
        </Route>
      </Route>

      <Route
        element={<RequireUser allowedRoles={[roles.MANAGER, roles.SUPER_MANAGER, roles.CLIENT]} />}
      >
        <Route path={USER_ROUTES.LOGOUT} element={<LogoutPage />} />
        <Route element={<ExternalLayout />}>
          <Route path={USER_ROUTES.SET_PASSWORD} element={<SetPasswordPage />} />
        </Route>
        <Route element={<UserLayout />}>
          <Route
            path={USER_ROUTES.HOME}
            element={
              auth?.user?.kind === roles.CLIENT ? <ClientProductionYearsPage /> : <ClientsPage />
            }
          />
          <Route path={USER_ROUTES.CLIENTS_DETAILS}>
            {Object.values(CLIENT_ROUTES)?.map((route) => (
              <Route key={route} path={route} element={<ClientsDetailsPage tab={route} />} />
            ))}
            <Route
              path={PLAN_ROUTES.PLAN}
              element={<Navigate to={PLAN_ROUTES.PLAN_TARGETS} replace />}
            />
          </Route>
          <Route
            path={USER_ROUTES.CLIENTS_PRODUCTION_YEARS}
            element={<ClientProductionYearsPage />}
          />
          <Route path={USER_ROUTES.CHANGE_PASSWORD} element={<ChangePasswordPage />} />
          <Route path={USER_ROUTES.PROFILE} element={<ProfileAccountPage />} />
          <Route path={USER_ROUTES.CHANGE_EMAIL_ADDRESS} element={<ChangeEmailAddressPage />} />
          <Route
            path={USER_ROUTES.CONFIRM_NEW_EMAIL_ADDRESS}
            element={<ConfirmNewEmailAddressPage />}
          />
        </Route>
      </Route>

      <Route element={<RequireUser allowedRoles={[roles.MANAGER, roles.SUPER_MANAGER]} />}>
        <Route element={<UserLayout />}>
          <Route path={USER_ROUTES.ADMIN} element={<AdminPage />} />
          <Route path={USER_ROUTES.REPORTS} element={<AdminReportsPage />} />
          <Route path={USER_ROUTES.MANAGERS} element={<ManagersPage />} />
          <Route path={USER_ROUTES.IMPORTS} element={<USDAPricesPage />} />
          <Route path={USER_ROUTES.ADMIN}>
            <Route
              key={ADMIN_ROUTES.REPORTS}
              path={ADMIN_ROUTES.REPORTS}
              element={<AdminDetailsPage tab={ADMIN_ROUTES.REPORTS} />}
            />
            <Route
              key={ADMIN_ROUTES.PRICES}
              path={ADMIN_ROUTES.PRICES}
              element={<AdminDetailsPage tab={ADMIN_ROUTES.PRICES} />}
            />
            <Route
              key={ADMIN_ROUTES.FUTURES}
              path={ADMIN_ROUTES.FUTURES}
              element={<FuturesPricesPage tab={ADMIN_ROUTES.FUTURES} />}
            />
            <Route
              key={ADMIN_ROUTES.DEFAULT_BASIS}
              path={ADMIN_ROUTES.DEFAULT_BASIS}
              element={<DefaultBasisValuationPage tab={ADMIN_ROUTES.DEFAULT_BASIS} />}
            />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default AppRouter
