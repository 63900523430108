/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { ErrorMessage, Form, Field, Formik } from 'formik'

import { useMutation, useQuery } from '@apollo/client'
import * as yup from 'yup'
import toast from 'react-hot-toast'

import BaseModal from '../modal/BaseModal'
import Button from '../buttons/Button'
import BuyerModal from './BuyerModal'
import CustomDateInput from '../inputs/CustomDateInput'
import Input from '../inputs/Input'
import SignedInput from '../SignedInput'

import CREATE_SALE from '../../gql/mutations/createSale'
import UPDATE_SALE from '../../gql/mutations/updateSale'
import GET_FUTURE_MONTHS from '../../gql/queries/getFutureMonths'
import GET_SALES_PROFILES from '../../gql/queries/getSalesProfile'

import AlertBox from '../AlertBox'
import GET_PROFILE_SUMMARIES from '../../gql/queries/getProfileSummaries'
import Loader from '../Loader'
import { renderQuantity } from '../../utils/helper'
import SelectInput from '../inputs/SelectInput'

const saleTypes = Object.freeze({
  CASH_SALE: 'Cash Sale',
  BASIS: 'Basis',
  HTA: 'Futures Only - HTA'
})

function OrderModal({
  addNewBuyer,
  buyerValues,
  buyers,
  closeModal,
  createUserModal,
  handleCloseBuyerModal,
  id,
  name,
  initialValues: salesInitialValues,
  profile,
  profilesData,
  setBuyerId,
  isOpen,
  type
}) {
  const [createSale] = useMutation(CREATE_SALE)
  const [updateSale] = useMutation(UPDATE_SALE)

  const { data: profileSummaryData, loading: profileSummaryLoading } = useQuery(
    GET_PROFILE_SUMMARIES,
    {
      variables: {
        productionYear: name,
        clientId: id
      }
    }
  )
  const cropsProfileData = profilesData?.profiles?.filter((item) => item?.kind === 'CROP')
  const { data: pricingsData } = useQuery(GET_FUTURE_MONTHS)
  const [crop, setCrop] = useState()
  const [unsoldData, setUnsoldData] = useState()
  const [currentProfile, setCurrentProfile] = useState()

  const addNestedBuyerModal = createUserModal?.filter((modal) => modal.type === 'CREATE_BUYER')[0]
    ?.nested

  const orderSchema = yup.object().shape({
    subkind: yup.string().ensure().required('Required'),
    profileId: yup.string().ensure().required('Required'),
    buyerId: yup.string().ensure().required('Required'),
    saleDate: yup.date().required('Required'),
    orderExpiryDate: yup
      .date()
      .nullable()
      .min(yup.ref('saleDate'), 'Order expiration should be after order date'),
    amount: yup
      .number()
      .typeError('Must be a number')
      .test('max-decimal', 'Must have up to 4 decimal places', (value) => {
        if (value === undefined || value === null || value === '' || isNaN(value)) {
          return true
        }
        return /^(\d{1,8}(\.\d{1,4})?)?$/.test(value.toString())
      })
      .nullable(),
    basisAmount: yup
      .number()
      .typeError('Must be a number')
      .test('max-decimal', 'Must have up to 4 decimal places', (value) => {
        if (value === undefined || value === null || isNaN(value)) {
          return true
        }
        return /^-?(\d{1,8}(\.\d{1,4})?)?$/.test(value.toString())
      })
      .nullable(),
    futuresAmount: yup
      .number()
      .nullable()
      .typeError('Must be a number')
      .test('max-decimal', 'Must have up to 4 decimal places', (value) => {
        if (value === undefined || value === null || isNaN(value)) {
          return true
        }
        return /^(\d{1,8}(\.\d{1,4})?)?$/.test(value.toString())
      })
      .nullable(),
    quantity: yup
      .number()
      .typeError('Must be a number')
      .test('max-decimal', 'Must have up to 4 decimal places', (value) => {
        if (value === undefined || value === null || isNaN(value)) {
          return true
        }
        return /^(\d{1,8}(\.\d{1,4})?)?$/.test(value.toString())
      })
      .nullable()
  })

  const handleOrderSubmit = async (value) => {
    if (type === 'CREATE_ORDER') {
      toast.promise(
        createSale({
          variables: {
            input: {
              kind: 'ORDER',
              subkind: value?.subkind,
              clientId: id,
              profileId: value?.profileId,
              buyerId: value?.buyerId,
              amount: parseFloat(value?.amount),
              basisAmount: parseFloat(value?.basisAmount),
              futuresAmount: parseFloat(value?.futuresAmount),
              feeAmount: parseFloat(value?.feeAmount),
              feeMode: value?.feeMode,
              quantity: value?.quantity,
              orderExpiryDate: value?.orderExpiryDate,
              futuresMonth: value?.futuresMonth,
              saleDate: value?.saleDate,
              referenceNumber: value?.referenceNumber,
              unloadNumber: value?.unloadNumber,
              notes: value?.notes
            }
          },
          refetchQueries: [GET_SALES_PROFILES]
        }),
        {
          loading: 'Creating the order...',
          success: 'Order created successfully.',
          error: 'Something went wrong while creating the order.'
        }
      )
    }
    if (type === 'EDIT_ORDER') {
      toast.promise(
        updateSale({
          variables: {
            input: {
              id: salesInitialValues?.id,
              kind: 'ORDER',
              subkind: value?.subkind,
              profileId: value?.profileId,
              buyerId: value?.buyerId,
              amount: parseFloat(value?.amount),
              basisAmount: parseFloat(value?.basisAmount),
              futuresAmount: parseFloat(value?.futuresAmount),
              feeAmount: parseFloat(value?.feeAmount),
              feeMode: value?.feeMode,
              quantity: value?.quantity,
              deliveryStartDate: value?.deliveryStartDate,
              deliveryEndDate: value?.deliveryEndDate,
              orderExpiryDate: value?.orderExpiryDate,
              futuresMonth: value?.futuresMonth,
              saleDate: value?.saleDate,
              referenceNumber: value?.referenceNumber,
              unloadNumber: value?.unloadNumber,
              notes: value?.notes
            }
          },
          refetchQueries: [GET_SALES_PROFILES]
        }),
        {
          loading: 'Updating the order...',
          success: 'Order updated successfully.',
          error: 'Something went wrong while updating the order.'
        }
      )
    }
    closeModal()
  }

  const setFutureValue = (values, setFieldValue) => {
    if (values?.subkind === 'BASIS' && values?.futuresMonth) {
      const monthYear = values?.futuresMonth ? values?.futuresMonth.split(' ') : []
      const cropProfile = cropsProfileData.find((item) => item.id === values?.profileId)
      const salesData = pricingsData.getFutureMonths.find(
        (item) =>
          item.month === monthYear[0] &&
          item.year === monthYear[1] &&
          item.crop?.id === cropProfile.crop.id
      )
      setFieldValue('futuresAmount', salesData?.price ? salesData.price : 0)
    }
  }

  return (
    <BaseModal isOpen={isOpen} closeModal={closeModal}>
      {profileSummaryLoading && !profileSummaryData && (
        <div className="mx-32 w-96">
          <Loader message="Loading..." variant="fetchData" />
        </div>
      )}
      {!profileSummaryData && !profileSummaryLoading && <AlertBox message="An error occurred." />}
      {profileSummaryData && (
        <div className="space-y-6">
          <Formik
            enableReinitialize
            initialValues={salesInitialValues}
            validationSchema={orderSchema}
            onSubmit={handleOrderSubmit}
          >
            {({ values, setFieldValue }) => {
              const summary = profileSummaryData?.getProfileSummaries?.find(
                (item) => item?.profileId === values?.profileId
              )
              setUnsoldData(summary)
              return (
                <Form>
                  <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                      <div className="md:col-span-1">
                        <h3 className="text-lg font-bold leading-6 text-gray-900">Add Order</h3>
                      </div>
                      <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="kind" className="block text-sm font-bold text-gray-700">
                              Sale type
                            </label>
                            <Field
                              as="select"
                              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                              type="subkind"
                              id="subkind"
                              name="subkind"
                              onChange={(obj) => {
                                setFieldValue('subkind', obj.target.value)
                                setFutureValue(
                                  { ...values, subkind: obj.target.value },
                                  setFieldValue
                                )
                              }}
                            >
                              <option disabled value="">
                                Select Type
                              </option>
                              {Object.keys(saleTypes).map((key) => (
                                <option value={key}>{saleTypes[key]}</option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="subkind"
                              component="p"
                              className="mt-1 text-sm font-medium text-red-600"
                            />
                          </div>
                          <div className="col-span-6">
                            <label
                              htmlFor="kind"
                              className="mb-1 block text-sm font-bold text-gray-700"
                            >
                              Commodity
                            </label>
                            <Field
                              as="select"
                              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                              type="profileId"
                              id="profileId"
                              name="profileId"
                              value={values?.profileId}
                              onChange={(obj) => {
                                const profileId = obj.target.value
                                setFieldValue('profileId', profileId)
                                const profile = cropsProfileData?.find((p) => p.id === profileId)
                                setCrop(profile.crop)
                              }}
                            >
                              <option disabled value="">
                                Select Commodity
                              </option>
                              {cropsProfileData?.map((item) => (
                                <option value={item?.id} key={item?.id}>
                                  {item?.crop?.name}&nbsp;({item?.productionYear?.name})
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="profileId"
                              component="p"
                              className="mt-1 text-sm font-medium text-red-600"
                            />
                          </div>
                          <div className="col-span-6">
                            <label
                              htmlFor="kind"
                              className="mb-1 block text-sm font-bold text-gray-700"
                            >
                              Buyer
                            </label>
                            <Field
                              as="select"
                              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                              type="buyerId"
                              id="buyerId"
                              name="buyerId"
                            >
                              <option disabled value="">
                                Select Buyer
                              </option>
                              {buyers?.map((item) => (
                                <option value={item?.id}>{item?.name}</option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="buyerId"
                              component="p"
                              className="mt-1 text-sm font-medium text-red-600"
                            />
                            <div className="mx-4 my-1 flex justify-end text-[14px] font-bold text-emerald-600">
                              <button
                                type="button"
                                onClick={() => {
                                  addNewBuyer('NESTED_BUYER')
                                }}
                              >
                                Add buyer
                              </button>
                            </div>
                          </div>
                          <div className="col-span-6">
                            <SignedInput
                              label="Quantity"
                              type="number"
                              min="1"
                              name="quantity"
                              unit={profile?.crop?.unit?.toLowerCase()}
                            />
                            <div className="mt-2 rounded-md bg-emerald-50 p-1 text-gray-700">
                              <div className="ml-2 text-emerald-500">
                                {crop
                                  ? unsoldData
                                    ? `You have
                                    ${renderQuantity(
                                      unsoldData.unsold.quantity
                                    )} ${crop.unit.toLowerCase()}
                                     unsold from ${profile?.productionYear?.name}.`
                                    : 'No data available to calculate unsold quantity.'
                                  : `Select a commodity to see unsold quantity`}
                              </div>
                            </div>
                          </div>
                          {values?.subkind === 'CASH_SALE' && (
                            <div className=" col-span-6 sm:col-span-3">
                              <SignedInput
                                label="Ask"
                                type="number"
                                min="0"
                                name="amount"
                                unit="$"
                              />
                            </div>
                          )}
                          {values?.subkind === 'BASIS' && (
                            <>
                              <div className="col-span-6 sm:col-span-3">
                                <SignedInput
                                  label="Basis"
                                  type="number"
                                  name="basisAmount"
                                  unit="$"
                                />
                                {!values.futuresAmount && (
                                  <div className="mt-1 rounded-md bg-emerald-50 p-1 text-gray-700">
                                    <div className="ml-2 text-emerald-500">
                                      Missing price for this futures month.
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="about"
                                  className="block text-sm font-bold text-gray-700"
                                >
                                  Futures month
                                </label>
                                <SelectInput
                                  name="futuresMonth"
                                  crop={crop}
                                  profile={profile}
                                  onChange={(obj) => {
                                    setFieldValue('futuresMonth', obj)
                                    setFutureValue({ ...values, futuresMonth: obj }, setFieldValue)
                                  }}
                                />
                              </div>
                            </>
                          )}
                          {values?.subkind === 'HTA' && (
                            <>
                              <div className="col-span-6 sm:col-span-3">
                                <SignedInput
                                  label="Futures"
                                  type="number"
                                  name="futuresAmount"
                                  unit="$"
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="about"
                                  className="block text-sm font-bold text-gray-700"
                                >
                                  Futures month
                                </label>
                                <SelectInput name="futuresMonth" crop={crop} profile={profile} />
                              </div>
                            </>
                          )}
                          <div className="col-span-6 sm:col-span-3">
                            <CustomDateInput label="Order expiration date" name="orderExpiryDate" />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <CustomDateInput label="Order date" name="saleDate" />
                          </div>
                          <div className="col-span-6">
                            <Input label="Contract number" type="text" name="referenceNumber" />
                          </div>
                          <div className="col-span-6">
                            <Input label="Unload number" type="text" name="unloadNumber" />
                          </div>
                          <div className="sm:col-span-6">
                            <label
                              htmlFor="about"
                              className="block text-sm font-bold text-gray-700"
                            >
                              Notes
                            </label>
                            <div className="mt-1">
                              <Field
                                as="textarea"
                                rows={3}
                                type="notes"
                                id="notes"
                                name="notes"
                                defaultValue=""
                                className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                              />
                            </div>
                            <p className="mt-2 text-sm text-gray-500">
                              Any notes related to this sale
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex justify-end gap-x-2">
                    <Button
                      type="button"
                      color="neutral"
                      label="Cancel"
                      onClick={() => closeModal()}
                    />
                    <Button
                      type="submit"
                      color="info"
                      label={type === 'EDIT_ORDER' ? ' Save' : 'Add Order'}
                    />
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      )}

      {addNestedBuyerModal && (
        <BuyerModal
          closeModal={handleCloseBuyerModal}
          initialValues={buyerValues}
          createUserModal={createUserModal}
          setBuyerId={setBuyerId}
          clientId={id}
        />
      )}
    </BaseModal>
  )
}

export default OrderModal
