import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import GET_SUMMARY from '../gql/queries/getSummary'

import { barChartInitialData } from '../utils/chartUtils'
import GET_PROFILE_SUMMARIES from '../gql/queries/getProfileSummaries'

export default function useSummary() {
  const [initalCommodity, setInitalCommodity] = useState([])
  const { id, productionYearId } = useParams()
  const [barChartData, setBarChartData] = useState(barChartInitialData)

  const formatSummaryData = (summaryData, type = '', filterOption = '') => {
    let { profilesWithRevenue } = summaryData

    if (type && (barChartData?.type !== type || filterOption)) {
      profilesWithRevenue = summaryData.profilesWithRevenue.filter(
        (profile) => profile.kind === type
      )

      if (filterOption) {
        const revenueByFilter = filterOption === 'ACRES' ? 'totalAcres' : 'totalHeads'

        profilesWithRevenue = profilesWithRevenue.map((profile) => ({
          ...profile,
          currentRevenue: profile.currentRevenue / profile[revenueByFilter],
          targetRevenue: profile.targetRevenue / profile[revenueByFilter]
        }))
      }
    }

    const labels = profilesWithRevenue?.map((profile) => profile.crop?.name || profile.description)
    const currentDataset = profilesWithRevenue?.map((profile) => profile.currentRevenue)
    const targeDataset = profilesWithRevenue?.map((profile) => profile.targetRevenue)

    setBarChartData((prevBarchartData) => ({
      ...prevBarchartData,
      type: prevBarchartData.type === type ? '' : type,
      labels,
      datasets: [
        { ...prevBarchartData.datasets[0], data: currentDataset },
        { ...prevBarchartData.datasets[1], data: targeDataset }
      ]
    }))
  }

  const {
    data: summaryData,
    error,
    loading
  } = useQuery(GET_SUMMARY, {
    variables: {
      productionYear: Number(productionYearId),
      clientId: id
    },
    onCompleted: (summaryData) => {
      formatSummaryData(summaryData?.getSummary)
    }
  })

  const {
    data: profileSummary,
    error: profileSummaryError,
    loading: profileSummaryLoading
  } = useQuery(GET_PROFILE_SUMMARIES, {
    variables: {
      productionYear: Number(productionYearId),
      clientId: id
    },
    onCompleted: (data) => {
      setInitalCommodity(data?.getProfileSummaries[0])
    }
  })

  return {
    error,
    loading,
    summaryData,
    barChartData,
    formatSummaryData,
    initalCommodity,
    profileSummary,
    profileSummaryError,
    profileSummaryLoading
  }
}
