/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

export default function Pagination({
  postsPerPage,
  totalPosts,
  paginate,
  currentPage,
  setCurrentPage
}) {
  const pageNumbers = []
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i)
  }

  const paginateFront = () =>
    setCurrentPage(currentPage === pageNumbers.length ? 1 : currentPage + 1)
  const paginateBack = () =>
    setCurrentPage(currentPage === 1 ? pageNumbers.length : currentPage - 1)

  return (
    <div className="mt-4">
      <div className="flex items-center justify-between border-gray-200 bg-white px-4 pt-3 sm:px-6">
        <div className="flex flex-1 justify-between sm:hidden">
          <div
            role="button"
            tabIndex={0}
            onClick={() => {
              paginateBack()
            }}
            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Previous
          </div>
          <div
            role="button"
            tabIndex={0}
            onClick={() => {
              paginateFront()
            }}
            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Next
          </div>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              <span className="font-medium">{totalPosts}</span>&nbsp;
              {totalPosts !== 1 ? 'results' : 'result'}
            </p>
          </div>
          <div>
            <nav
              className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
              aria-label="Pagination"
            >
              <div
                role="button"
                tabIndex={0}
                onClick={() => {
                  paginateBack()
                }}
                className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              {/* Current: "z-10 bg-emerald-50 border-emerald-500 text-emerald-600",
                  Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
              {pageNumbers.map((number) => (
                <div
                  onClick={() => {
                    paginate(number)
                  }}
                  key={number}
                  role="button"
                  tabIndex={0}
                  className={
                    currentPage === number
                      ? 'relative z-10 inline-flex items-center border border-emerald-500 bg-emerald-50 px-4 py-2 text-sm font-medium text-emerald-600'
                      : 'relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-blue-200'
                  }
                >
                  {number}
                </div>
              ))}
              <div
                role="button"
                tabIndex={0}
                onClick={() => {
                  paginateFront()
                }}
                className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}
