import { gql } from '@apollo/client'

const SAVE_PROFILE_INCOME = gql`
  mutation saveProfileIncome($input: ProfileIncomeInput!) {
    saveProfileIncome(input: $input) {
      id
      profileId
      incomeId
      allocation
      isAutoAllocated
    }
  }
`

export default SAVE_PROFILE_INCOME
