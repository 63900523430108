/* eslint-disable no-unsafe-optional-chaining */
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useMutation, useQuery } from '@apollo/client'

import Button from '../buttons/Button'
import ExpenseInput from '../inputs/ExpenseInput'
import GET_PROFILE_EXPENSES from '../../gql/queries/getProfileExpenses'
import SAVE_PROFILE_EXPENSE from '../../gql/mutations/saveProfileExpense'
import SAVE_GROSS_EXPENSE from '../../gql/mutations/saveGrossExpense'
import ScrollButton from '../buttons/ScrollButton'
import EmptyState from '../EmptyState'
import GET_EXPENSES from '../../gql/queries/getExpenses'
import { useToggle } from '../../hooks/useToggle'
import EditAllocationModal from '../modals/EditAllocationModal'
import { renderMoney } from '../../utils/helper'

function ScrollTable({
  clientId,
  buttons,
  expenses,
  profiles,
  kind,
  setGetProfileExpensesError,
  productionYearId
}) {
  const ref = useRef(null)
  const [saveProfileExpense] = useMutation(SAVE_PROFILE_EXPENSE)
  const [saveGrossExpense] = useMutation(SAVE_GROSS_EXPENSE)
  const [disableLeftScroll, setDisableLeftScroll] = useState(false)
  const [disableRightScroll, setDisableRightScroll] = useState(false)
  const [isOpen, toggleIsOpen] = useToggle()
  const [editAllocationModal, setEditAllocationModal] = useState(undefined)
  const [initialValue, setInitialValue] = useState({
    allocation: null,
    expenseId: null,
    profileId: null
  })

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset
    if (ref.current.scrollLeft === 0) {
      setDisableLeftScroll(true)
    } else {
      setDisableLeftScroll(false)
    }

    if (ref.current.scrollLeft + ref.current.clientWidth >= ref.current.scrollWidth) {
      setDisableRightScroll(true)
    } else {
      setDisableRightScroll(false)
    }
  }

  useEffect(() => {
    if (ref.current.scrollLeft === 0) {
      setDisableLeftScroll(true)
    }

    if (ref.current.scrollLeft + ref.current.clientWidth >= ref.current.scrollWidth) {
      setDisableRightScroll(true)
    }
  }, [])

  const profileIds = profiles?.map((item) => item.id)

  const { data: profileExpenses } = useQuery(GET_PROFILE_EXPENSES, {
    variables: {
      profileIds
    },
    skip: !profileIds?.length,
    onError: (error) => {
      setGetProfileExpensesError(error)
    }
  })

  const getName = (profile) => {
    if (profile.kind === 'CROP') return `${profile.name} (${profile.productionYear.name})`
    if (profile.livestockKind === 'COW_CALF') {
      return profile?.description ? `Cow-Calf (${profile.description})` : `Cow-Calf`
    }
    if (profile.livestockKind === 'LIVE_CATTLE') {
      return profile?.description ? `Live-Cattle (${profile.description})` : `Live-Cattle`
    }
    if (profile.livestockKind === 'FEEDER_CATTLE') {
      return profile?.description ? `Feeder-Cattle (${profile.description})` : `Feeder-Cattle`
    }
    return null
  }

  const getValue = (profile) => {
    let expensePerUnit = 0
    const profileExpensesList = profileExpenses?.getProfileExpenses?.filter(
      (expense) => expense.profileId === profile.id
    )

    if (profile.kind === 'CROP') {
      const { drylandAcres, drylandYield, irrigatedAcres, irrigatedYield } = profile
      const drylandProduction = drylandAcres * drylandYield
      const irrigatedProduction = irrigatedAcres * irrigatedYield
      const drylandProfileExpenses = profileExpensesList?.map((expense) => expense.drylandCost)
      const irrigatedProfileExpenses = profileExpensesList?.map((expense) => expense.irrigatedCost)

      let production
      if (profile?.isDryland && profile?.isIrrigated) {
        production = drylandProduction + irrigatedProduction
        if (production === 0) return 0

        expensePerUnit =
          (drylandProfileExpenses?.reduce((acc, expense) => acc + expense, 0) * drylandAcres +
            irrigatedProfileExpenses?.reduce((acc, expense) => acc + expense, 0) * irrigatedAcres) /
          production
      } else if (profile?.isDryland) {
        production = drylandProduction
        if (production === 0) return 0
        expensePerUnit =
          (drylandProfileExpenses?.reduce((acc, expense) => acc + expense, 0) * drylandAcres) /
          production
      } else if (profile?.isIrrigated) {
        production = irrigatedProduction
        if (production === 0) return 0
        expensePerUnit =
          (irrigatedProfileExpenses?.reduce((acc, expense) => acc + expense, 0) * irrigatedAcres) /
          production
      }

      return expensePerUnit ? expensePerUnit.toFixed(4) : 0
    }

    if (profile.kind === 'LIVESTOCK') {
      const { headsCount, outWeight, livestockKind } = profile
      const perHeadExpenses = profileExpensesList?.map((expense) => expense.perHeadCost)

      if (livestockKind === 'COW_CALF') {
        const {
          cowsCount,
          bullsSaleCount,
          bullsSaleWeight,
          heiferSaleCount,
          heiferRetainCount,
          heiferSaleWeight
        } = profile
        if (perHeadExpenses?.length) {
          if (
            bullsSaleCount * bullsSaleWeight +
              (heiferSaleCount - heiferRetainCount) * heiferSaleWeight >
            0
          ) {
            expensePerUnit =
              (perHeadExpenses?.reduce((acc, expense) => acc + expense, 0) * cowsCount) /
              (bullsSaleCount * bullsSaleWeight + heiferSaleCount * heiferSaleWeight)
          }
        }
        return expensePerUnit > 0 ? expensePerUnit.toFixed(4) : 0
      }

      if ((headsCount || 0) * outWeight > 0) {
        expensePerUnit =
          (perHeadExpenses?.reduce((acc, expense) => acc + expense, 0) * headsCount) /
          (headsCount * outWeight)
      }
      return expensePerUnit ? expensePerUnit.toFixed(4) : 0
    }

    return 0
  }

  const getGrossValue = (profile) => {
    let totalAcres = 0
    let totalHeads = 0
    let totalCows = 0
    let grossValue = 0
    let expensePerUnit = 0

    profiles?.forEach((item) => {
      totalAcres += item?.drylandAcres + item?.irrigatedAcres
      totalHeads += item?.headsCount
      totalCows += item?.cowsCount
    })

    const total = totalAcres + totalHeads + totalCows
    const { drylandAcres, irrigatedAcres } = profile
    expenses?.map((item) => {
      item?.grossExpenses?.map((item1) => {
        if (item1?.productionYearId === productionYearId) {
          grossValue += item1?.cost
        }
      })
    })
    if (profile.kind === 'CROP') {
      totalAcres = drylandAcres + irrigatedAcres
      expensePerUnit = (grossValue * totalAcres) / total
    }

    if (profile.kind === 'LIVESTOCK') {
      const { headsCount, livestockKind } = profile
      expensePerUnit = (grossValue * headsCount) / total
      if (livestockKind === 'COW_CALF') {
        const { cowsCount } = profile
        expensePerUnit = (grossValue * cowsCount) / total

        return expensePerUnit > 0 ? expensePerUnit.toFixed(4) : 0
      }
      return expensePerUnit
    }
    return expensePerUnit > 0 ? expensePerUnit.toFixed(4) : 0
  }

  const getAllocationValue = (expense, profileId) => {
    const grossExpense = getGrossExpense(expense)
    const allocation = getExpense(profileId, expense.id, 'GROSS')
    return renderMoney((allocation * grossExpense) / 100)
  }
  const getExpense = (profileId, expenseId, itemType) => {
    if (profileExpenses?.getProfileExpenses?.length) {
      const profile = profileExpenses.getProfileExpenses.find(
        (item) => item.profileId === profileId && item.expenseId === expenseId
      )

      if (profile) {
        if (itemType === 'DRYLAND') {
          return profile.drylandCost
        }
        if (itemType === 'IRRIGATED') {
          return profile.irrigatedCost
        }
        if (itemType === 'LIVESTOCK') {
          return profile.perHeadCost
        }
        if (itemType === 'GROSS') {
          return profile.allocation.toFixed(2)
        }
      }
    }
    return Number(0).toFixed(2)
  }

  const getAllocation = (profileId, expenseId) => {
    const profile = profileExpenses.getProfileExpenses.find(
      (item) => item.profileId === profileId && item.expenseId === expenseId
    )
    if (profile.isAutoAllocated) return 0
    return profile.allocation.toFixed(2)
  }

  const getGrossExpense = (expense) =>
    expense?.grossExpenses?.find((item) => item.productionYearId === productionYearId)?.cost
  const handleProfileExpenseChange = (event, expenseId, profileId, type) => {
    const data = {}
    if (type === 'DRYLAND') {
      data.drylandCost = event
    } else if (type === 'IRRIGATED') {
      data.irrigatedCost = event
    } else if (type === 'LIVESTOCK') {
      data.perHeadCost = event
    }

    toast.promise(
      saveProfileExpense({
        variables: {
          input: {
            profileId,
            expenseId,
            ...data
          }
        },
        refetchQueries: [GET_PROFILE_EXPENSES]
      }),
      {
        loading: 'Saving the expense...',
        success: 'Expense saved successfully.',
        error: 'Error while saving the expense.'
      }
    )
  }

  const handleGrossExpenseChange = async (event, expenseId) => {
    toast.promise(
      await saveGrossExpense({
        variables: {
          input: {
            productionYearId,
            expenseId,
            clientId,
            cost: event || 0
          }
        },
        refetchQueries: [GET_EXPENSES, GET_PROFILE_EXPENSES]
      }),
      {
        loading: 'Saving the gross expense...',
        success: 'Gross expense saved successfully.',
        error: 'Error while saving the gross expense.'
      }
    )
  }
  return (
    <>
      <div className="mt-3 flex flex-row">
        <ScrollButton direction="left" onClick={() => scroll(-200)} disabled={disableLeftScroll} />
        <div className="-my-2 -mx-4 min-w-full overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div
              className="overflow-x-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
              ref={ref}
            >
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 first:sticky first:left-0 first:bg-gray-50 sm:pl-10"
                    >
                      &nbsp;
                    </th>
                    {kind === 'GROSS' && (
                      <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 first:sticky first:left-0 first:bg-gray-50 sm:pl-10"
                      >
                        &nbsp;
                      </th>
                    )}
                    {profiles?.map((profile) => {
                      const commodityValue = Number(
                        kind === 'GROSS' ? getGrossValue(profile) : getValue(profile)
                      )
                      const unit = profile?.crop?.unit?.toLowerCase()
                      return (
                        <th
                          scope="col"
                          key={profile.id}
                          className="whitespace-nowrap px-6 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          <div className="flex flow-root">
                            <div className="block text-sm font-bold">{getName(profile)}</div>
                            <div>
                              <span className="pr-2 text-2xl font-bold text-green-500">
                                {renderMoney(commodityValue)}
                              </span>
                              <span className="text-sm text-gray-400">
                                per&nbsp;{profile.kind === 'CROP' ? unit : 'lb'}
                              </span>
                            </div>
                            <div className="flex space-x-6">
                              {profile.breakEven && (
                                <div className="text-left text-xs text-gray-400">
                                  (${profile.breakEven}&nbsp;breakeven)
                                </div>
                              )}
                            </div>
                          </div>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {expenses.map((expense, index) => (
                    <tr>
                      <th
                        key={expense.id}
                        className="ml-4 whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium first:sticky first:left-0 first:bg-white sm:pr-8"
                      >
                        {expense.name}
                      </th>
                      {kind === 'GROSS' && (
                        <td
                          key={expense.name}
                          className="ml-4 whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm  font-medium first:sticky first:left-0 first:bg-white sm:pr-8"
                        >
                          <ExpenseInput
                            prefix="$"
                            value={getGrossExpense(expense)}
                            onChange={(event) => handleGrossExpenseChange(event, expense.id)}
                          />
                        </td>
                      )}
                      {!profiles?.length && index === 0 && (
                        <td rowSpan={expenses?.length}>
                          <EmptyState
                            header="No commodities"
                            message="Get started by adding commodity."
                            disabled
                          />
                        </td>
                      )}
                      {profiles?.map((profile) =>
                        kind === 'GROSS' ? (
                          getGrossExpense(expense) > 0 && (
                            <td
                              key={profile.id}
                              className="whitespace-nowrap py-4 px-80 pl-4 pr-14 text-sm font-medium text-gray-900 sm:pl-6"
                            >
                              ({getAllocationValue(expense, profile.id)})&nbsp;
                              {getExpense(profile.id, expense.id, 'GROSS')}%&nbsp;
                              <span>
                                <Button
                                  type="button"
                                  color="action"
                                  actionLabel="Edit"
                                  onClick={() => {
                                    setInitialValue({
                                      allocation: getAllocation(profile.id, expense.id),
                                      expenseId: expense.id,
                                      profileId: profile.id
                                    })
                                    setEditAllocationModal('EDIT')
                                    toggleIsOpen()
                                  }}
                                />
                              </span>
                            </td>
                          )
                        ) : (
                          <td
                            key={profile.id}
                            className="whitespace-nowrap py-4 px-80 pl-4 pr-14 text-sm font-medium text-gray-900 sm:pl-6"
                          >
                            <div className="flex flex-row space-x-4">
                              {profile.isDryland && (
                                <ExpenseInput
                                  prefix="$"
                                  suffix="dry"
                                  value={getExpense(profile.id, expense.id, 'DRYLAND')}
                                  onChange={(event) =>
                                    handleProfileExpenseChange(
                                      event,
                                      expense.id,
                                      profile.id,
                                      'DRYLAND'
                                    )
                                  }
                                />
                              )}
                              {profile.isIrrigated && (
                                <ExpenseInput
                                  prefix="$"
                                  suffix="irr"
                                  value={getExpense(profile.id, expense.id, 'IRRIGATED')}
                                  onChange={(event) =>
                                    handleProfileExpenseChange(
                                      event,
                                      expense.id,
                                      profile.id,
                                      'IRRIGATED'
                                    )
                                  }
                                />
                              )}
                              {profile.kind === 'LIVESTOCK' && (
                                <ExpenseInput
                                  prefix="$"
                                  suffix="per head"
                                  value={getExpense(profile.id, expense.id, 'LIVESTOCK')}
                                  onChange={(event) =>
                                    handleProfileExpenseChange(
                                      event,
                                      expense.id,
                                      profile.id,
                                      'LIVESTOCK'
                                    )
                                  }
                                />
                              )}
                            </div>
                          </td>
                        )
                      )}
                    </tr>
                  ))}
                  {kind === 'CROP' && (
                    <tr>
                      <th className="ml-4 whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium first:sticky first:left-0 first:bg-white sm:pr-8">
                        &nbsp;
                      </th>
                      {profiles?.map((profile) => (
                        <td
                          key={profile.id}
                          className="whitespace-nowrap py-4 px-80 pl-4 pr-14 text-sm font-medium text-gray-900 sm:pl-6"
                        >
                          <div className="flex flow-root">
                            <div>
                              {renderMoney(
                                (Number(getValue(profile)) *
                                  ((profile.drylandAcres || 0) * (profile.drylandYield || 0) +
                                    (profile.irrigatedAcres || 0) *
                                      (profile.irrigatedYield || 0))) /
                                  ((profile.drylandAcres || 0) + (profile.irrigatedAcres || 0) || 1)
                              )}
                              &nbsp;
                              <span className="text-sm text-gray-400">per acre</span>
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ScrollButton direction="right" onClick={() => scroll(200)} disabled={disableRightScroll} />
        {editAllocationModal === 'EDIT' && (
          <EditAllocationModal
            initialValue={initialValue}
            type={editAllocationModal}
            isOpen={isOpen}
            closeModal={toggleIsOpen}
          />
        )}
      </div>
      {buttons?.length && (
        <div
          className={classNames(
            'flow-row mt-6',
            profiles?.length ? 'ml-6' : 'ml-8',
            'flex space-x-4'
          )}
        >
          {buttons.map((button) => (
            <Link to="manage-custom-expenses" state={{ title: button.title, kind }}>
              <Button type="button" color={button.color} label={button.label} key={button.label} />
            </Link>
          ))}
        </div>
      )}
    </>
  )
}

export default ScrollTable
