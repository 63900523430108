/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import Button from '../../buttons/Button'
import BuyerModal from '../../modals/BuyerModal'
import DELETE_BUYER from '../../../gql/mutations/deleteBuyer'
import DeleteModal from '../../modals/DeleteModal'
import EmptyState from '../../EmptyState'
import ErrorModal from '../../modals/ErrorModal'
import formatErrors from '../../../utils/formatErrors'
import GET_BUYERS from '../../../gql/queries/getBuyers'
import SubTitle from '../../typography/SubTitle'
import SubTitleWrapper from '../../wrappers/SubTitleWrapper'
import TableWrapper from '../../wrappers/TableWrapper'
import ViewWrapper from '../../wrappers/ViewWrapper'
import { sortHandler } from '../../../utils/helper.js'
import downArrow from '../../../assets/downArrow.png'

const buyerIntialValues = {
  name: '',
  city: '',
  state: ''
}

function BuyersView({ data }) {
  const { id } = useParams()

  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [errorModal, setErrorModal] = useState(null)
  const [buyerValues, setBuyerValues] = useState(buyerIntialValues)
  const [sortOrder, setSortOrder] = useState()
  const [sortKey, setSortKey] = useState()

  const [deleteBuyer] = useMutation(DELETE_BUYER)

  const removeBuyer = async () => {
    await deleteBuyer({
      variables: {
        input: {
          id: buyerValues?.id
        }
      },
      onError(deleteBuyerError) {
        const errorMessage = formatErrors(deleteBuyerError)
        toast.error(errorMessage)

        setErrorModal({
          title: `Deleting buyer ${buyerValues.name} failed`,
          message: errorMessage
        })
      },
      refetchQueries: [GET_BUYERS]
    })
  }
  const handleCloseSaleModal = () => {
    setIsDeleteModalOpen(false)
  }

  const handleCloseBuyerModal = () => {
    setIsOpen(false)
  }

  const handleCloseErrorModal = () => {
    setErrorModal(null)
  }

  const addNewBuyer = () => {
    setBuyerValues(buyerIntialValues)

    setIsOpen(true)
  }

  return (
    <ViewWrapper>
      <SubTitleWrapper
        title={<SubTitle color="gray">Buyers</SubTitle>}
        button={
          <Button type="button" color="info" label="Add Buyer" onClick={() => addNewBuyer()} />
        }
      />
      {data?.length > 0 ? (
        <div className="-my-2 overflow-x-auto">
          <div className="inline-block min-w-full py-2 px-0.5 align-middle">
            <TableWrapper
              columns={[
                <div
                  className="flex cursor-pointer items-center gap-2"
                  onClick={() => {
                    setSortKey('name')
                    setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                  }}
                  aria-hidden="true"
                >
                  <span>Name</span>
                  {sortKey && sortKey === 'name' && (
                    <div
                      className="w-[7px]"
                      style={{
                        rotate: sortOrder === 'asc' ? '180deg' : '0deg'
                      }}
                    >
                      <img src={downArrow} alt="" height={7} width={7} />
                    </div>
                  )}
                </div>,
                <div
                  className="-ml-3 flex cursor-pointer items-center gap-2"
                  onClick={() => {
                    setSortKey('state')
                    setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                  }}
                  aria-hidden="true"
                >
                  <span>State</span>
                  {sortKey && sortKey === 'state' && (
                    <div
                      className="w-[7px]"
                      style={{
                        rotate: sortOrder === 'asc' ? '180deg' : '0deg'
                      }}
                    >
                      <img src={downArrow} alt="" height={7} width={7} />
                    </div>
                  )}
                </div>,
                <div
                  className="-ml-3 flex cursor-pointer items-center gap-2"
                  onClick={() => {
                    setSortKey('city')
                    setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                  }}
                  aria-hidden="true"
                >
                  <span>City</span>
                  {sortKey && sortKey === 'city' && (
                    <div
                      className="w-[7px]"
                      style={{
                        rotate: sortOrder === 'asc' ? '180deg' : '0deg'
                      }}
                    >
                      <img src={downArrow} alt="" height={7} width={7} />
                    </div>
                  )}
                </div>,
                'Actions'
              ]}
              rows={sortHandler([...data], sortOrder, sortKey)?.map((buyer) => [
                buyer.name,
                <span className="flex">{buyer.state}</span>,
                <span className="flex">{buyer.city}</span>,
                <div>
                  <Button
                    color="action"
                    actionLabel="Edit"
                    onClick={() => {
                      setBuyerValues({ ...buyer })
                      setIsOpen(true)
                    }}
                  />
                  <Button
                    color={buyer?.sales?.length > 0 ? 'disabled' : 'action'}
                    actionLabel="Delete"
                    onClick={() => {
                      setBuyerValues(buyer)
                      setIsDeleteModalOpen(true)
                    }}
                  />
                </div>
              ])}
            />
          </div>
        </div>
      ) : (
        <EmptyState header="No buyer" message="Get started by adding a buyer." disabled />
      )}

      <BuyerModal
        closeModal={handleCloseBuyerModal}
        initialValues={buyerValues}
        clientId={id}
        isOpen={isOpen}
      />
      <DeleteModal
        onClose={handleCloseSaleModal}
        isOpen={isDeleteModalOpen}
        description="Are you sure you want to delete this buyer?"
        title={buyerValues?.name}
        onSubmit={removeBuyer}
      />
      <ErrorModal
        closeModal={handleCloseErrorModal}
        message={errorModal?.message}
        title={errorModal?.title}
        isOpen={!!errorModal}
      />
    </ViewWrapper>
  )
}

export default BuyersView
