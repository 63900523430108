import { useState, useEffect } from 'react'

import { getUniqueArrayList } from '../../utils/helper'

export const useAutocompleteBox = ({
  locationOptions,
  storageModalType,
  initialValues,
  setFieldValue
}) => {
  const [selected, setSelected] = useState(
    storageModalType === 'EDIT_STORAGE' ? initialValues.location : ''
  )

  const [query, setQuery] = useState('')

  useEffect(() => {
    setFieldValue('location', selected)
  }, [selected])

  const options = getUniqueArrayList(
    locationOptions?.getStorageProfile?.map((data) => data.location)
  ).filter(Boolean)

  const filteredLocationOptions =
    query === ''
      ? options
      : options.filter((location) =>
          location
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        )

  return { selected, setSelected, query, setQuery, filteredLocationOptions }
}
