import classNames from 'classnames'
import React from 'react'

export default function TableDataCell({ children, type = 'value' }) {
  return (
    <td
      className={classNames({
        'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium text-gray-500 sm:pr-6':
          type === 'value' || type === 'valueGreen' || type === 'valueRed',
        'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6':
          type === 'title',
        'relative whitespace-nowrap py-4 pl-6 pr-4 text-left text-sm font-bold sm:pr-6':
          type === 'total',
        'font-medium text-green-500': type === 'valueGreen',
        'font-medium text-red-500': type === 'valueRed'
      })}
    >
      {children}
    </td>
  )
}
