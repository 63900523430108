import React from 'react'

import ReportSubTitle from '../../reportPrimitives/ReportSubtitle'
import ReportTable from '../../reportPrimitives/ReportTable'
import { renderNumber } from '../../../utils/helper'

const reportsTableColumns = ['Profile', 'Crop', 'Acres', 'Yield', 'Production', 'Storage']

function ProfilesView({ variant, cropProfileMappedByProductionYear }) {
  return (
    <>
      <ReportSubTitle variant={variant} title="PROFILES" />
      {cropProfileMappedByProductionYear.map((data) => (
        <ReportTable
          key={data.productionYear}
          variant={variant}
          columns={reportsTableColumns}
          title={<>{data.productionYear} Profiles</>}
          rows={data?.reports
            .map((report) => {
              const storage =
                report.Storage?.reduce((sum, storage) => sum + storage.quantity, 0) || 0
              const production =
                report.drylandAcres * report.drylandYield +
                report.irrigatedAcres * report.irrigatedYield
              return [
                <>
                  {report.crop.name}&nbsp;
                  {report.description ? `(${report.description})` : null}
                </>,
                report.crop.name,
                <>
                  {renderNumber(report.drylandAcres + report.irrigatedAcres)} ({report.drylandAcres}
                  {report.drylandAcres && ' dry'}
                  {report.drylandAcres && report.irrigatedAcres && ' / '}
                  {report.irrigatedAcres}
                  {report.irrigatedAcres && ' irr'})
                </>,
                <>
                  {renderNumber(report.drylandYield + report.irrigatedYield)} ({report.drylandYield}
                  {report.drylandYield && ' dry'}
                  {report.drylandYield && report.irrigatedYield && ' / '}
                  {report.irrigatedYield}
                  {report.irrigatedYield && ' irr'})
                </>,
                renderNumber(production),
                storage
              ]
            })
            .concat([
              [
                'Total',
                '',
                renderNumber(
                  data?.reports
                    .map((totalAcres) => totalAcres.drylandAcres + totalAcres.irrigatedAcres)
                    ?.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                ),
                '',
                '',
                ''
              ]
            ])}
        />
      ))}
    </>
  )
}

export default ProfilesView
