import { gql } from '@apollo/client'

const MODIFY_FUTURE_MONTH = gql`
  mutation modifyFutureMonth($input: ModifyFutureMonthInput!) {
    modifyFutureMonth(input: $input) {
      id
      month
      year
      price
      cropId
    }
  }
`

export default MODIFY_FUTURE_MONTH
