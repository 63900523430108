/* eslint-disable no-unused-vars */
import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import AlertBox from '../../components/AlertBox'
import BuyersView from '../../components/views/sales/BuyersView'
import FilterSection from '../../components/FilterSection'
import GET_BUYERS from '../../gql/queries/getBuyers'
import GET_SALES_PROFILES from '../../gql/queries/getSalesProfile'
import Header from '../../components/Header'
import Loader from '../../components/Loader'
import OrdersView from '../../components/views/sales/OrdersView'
import SalesView from '../../components/views/sales/SalesView'
import StoragesView from '../../components/views/sales/StoragesView'
import { useFilterContext } from '../../context/FilterContext'

function CashSalesPage() {
  const { dispatch, filteredCashSalesData } = useFilterContext()

  const { id, productionYearId } = useParams()
  const name = Number(productionYearId)

  const { error, loading, data } = useQuery(GET_SALES_PROFILES, {
    variables: {
      clientId: id,
      productionYearId: name
    },
    onCompleted: (data) => {
      dispatch({ type: 'LOAD_CASH_SALE_DATA', payload: data?.getSaleProfile })
    }
  })

  const { data: buyersData } = useQuery(GET_BUYERS, {
    variables: { clientId: id }
  })

  const sales = filteredCashSalesData?.filter((item) => item?.kind === 'SALE')
  const orders = filteredCashSalesData?.filter((item) => item?.kind === 'ORDER')

  return (
    <>
      <Header>Contracts</Header>
      {loading && !data && <Loader message="Fetching data..." variant="fetchData" />}
      {error && <AlertBox message="Oops! Something went wrong while fetching data." />}

      {data && <FilterSection buyers={buyersData?.getBuyers} />}
      {data && (
        <>
          <SalesView data={sales} buyers={buyersData?.getBuyers} />

          <StoragesView buyers={buyersData?.getBuyers} />

          <OrdersView data={orders} buyers={buyersData?.getBuyers} />

          <BuyersView data={buyersData?.getBuyers} />
        </>
      )}
    </>
  )
}

export default CashSalesPage
