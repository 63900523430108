import { gql } from '@apollo/client'

import USER_FRAGMENT from '../fragments/user'

const ARCHIVE_USER = gql`
  mutation archiveUser($clientId: String!) {
    archiveUser(clientId: $clientId) {
      ...User
    }
  }
  ${USER_FRAGMENT}
`

export default ARCHIVE_USER
