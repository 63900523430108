import { gql } from '@apollo/client'

const GET_GLANCE_REPORTS = gql`
  query getMonthlyReports($clientId: String!, $productionYear: Float!) {
    getPriceFetchedOn
    getMonthlyReports(clientId: $clientId, productionYear: $productionYear) {
      clientId
      id
      amountNotForSale
      bullsInWeight
      bullsSaleCount
      bullsSaleWeight
      calvingDate
      cowsCount
      cropId
      dailyGain
      deathsCount
      description
      drylandAcres
      drylandYield
      headsCount
      heiferInWeight
      heiferRetainCount
      heiferSaleCount
      heiferSaleWeight
      inDate
      inWeight
      irrigatedAcres
      irrigatedYield
      isDryland
      isIrrigated
      kind
      livestockKind
      outWeight
      saleDate
      storageAvailable
      crop {
        id
        name
      }
      Storage {
        location
        quantity
      }
      productionYear {
        id
        name
      }
      profilesWithRevenue {
        id
        cropId
        description
        livestockKind
        totalAcres
        totalHeads
        totalQuantity
        basisAmount
        totalExpense
        totalIncome
        totalTargets
        breakeven
        targetPrice
        futureMonth {
          id
          month
          year
          price
          cropId
        }
        soldQuantity {
          kind
          subkind
          quantity
          amount
          basisAmount
          feeAmount
          futuresAmount
        }
      }
    }
  }
`

export default GET_GLANCE_REPORTS
