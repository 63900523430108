import { onError } from '@apollo/client/link/error'

const logout = () => {
  localStorage.clear()
  window.location.reload()
}

const errorLink = onError(({ graphQLErrors }) => {
  const currentToken = localStorage.getItem('token')
  if (graphQLErrors?.[0]?.extensions?.response?.statusCode === 401 && currentToken) {
    logout()
  }
})

export default errorLink
export { logout }
