import { range } from 'lodash'
import { getMonth, getYear } from 'date-fns'
import React from 'react'
import DatePicker from 'react-datepicker'
import { ErrorMessage, useField } from 'formik'

function CustomDateInput({ ...props }) {
  const years = range(1990, getYear(new Date()) + 5, 1)
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const inputClass =
    'appearance-none block w-full py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm'

  const labelClass = 'block text-sm text-gray-700'

  const [field, meta, { setValue }] = useField(props)
  const { name, label, variant, width, deliveryStartDate = '' } = props
  const errorClass = meta.touched && meta.error ? 'error' : ''

  return (
    <div>
      <label
        htmlFor={name}
        className={`
            ${labelClass}
            ${variant ? 'font-medium' : 'font-bold'}
          `}
      >
        {label}
      </label>
      <div className="mt-1">
        <DatePicker
          className={`
            ${inputClass}
            ${errorClass ? 'border border-red-500' : ''}
          `}
          {...field}
          {...props}
          autoComplete="off"
          dateFormat="MM/dd/yyyy"
          placeholderText="MM/DD/YYYY"
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) => (
            <div
              style={{
                margin: 10,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <button onClick={decreaseMonth} type="button" disabled={prevMonthButtonDisabled}>
                {'<'}
              </button>
              <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button onClick={increaseMonth} type="button" disabled={nextMonthButtonDisabled}>
                {'>'}
              </button>
            </div>
          )}
          openToDate={deliveryStartDate && !field.value && new Date(deliveryStartDate)}
          selected={field.value && new Date(field.value)}
          onChange={(val) => {
            setValue(val)
          }}
        />
      </div>
      <ErrorMessage
        name={name}
        component="p"
        className={`mt-1 ${width} text-sm font-medium text-red-600`}
      />
    </div>
  )
}

export default CustomDateInput
