import { gql } from '@apollo/client'

const GET_DEFAULT_BASIS_VALUATIONS = gql`
  query {
    getDefaultBasisValuations {
      id
      basisAmount
      futuresMonth
      futuresName
      productionYear
    }
  }
`
export default GET_DEFAULT_BASIS_VALUATIONS
