import React, { useEffect, useRef } from 'react'
import { Form, Formik } from 'formik'
import { useMutation } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import jwtDecode from 'jwt-decode'

import AlertBox from '../../components/AlertBox'
import CONFIRM_NEW_EMAIL_ADDRESS from '../../gql/mutations/confirmNewEmailAddress'
import { isTokenExpired } from '../../utils/jwtUtils'
import ActionComplete from '../../components/feedback/ActionComplete'
import Button from '../../components/buttons/Button'
import { VISITOR_ROUTES } from '../../constants/routes'
import { useUserContext } from '../../context/UserContext'

const initialValues = {
  token: '',
  currentEmailAddress: '',
  newEmailAddress: ''
}

function ConfirmNewEmailAddressPage() {
  const { setToken } = useUserContext()

  const [confirmNewEmailAddress, { error }] = useMutation(CONFIRM_NEW_EMAIL_ADDRESS)

  const navigate = useNavigate()

  const { accessToken } = useParams()
  const decodededJwtToken = jwtDecode(accessToken)
  const isJwtTokenExpired = isTokenExpired(decodededJwtToken)

  const formRef = useRef()
  useEffect(() => {
    if (formRef.current && !isJwtTokenExpired) {
      formRef.current.handleSubmit()
    }
  }, [isJwtTokenExpired])

  const onSubmit = async () => {
    await confirmNewEmailAddress({
      variables: {
        input: {
          token: accessToken,
          currentEmailAddress: decodededJwtToken?.currentEmailAddress,
          newEmailAddress: decodededJwtToken?.newEmailAddress
        }
      }
    })

    localStorage.clear()
    setToken(null)
  }

  return (
    <div className="flex justify-center overflow-hidden">
      <div className="w-full rounded-lg sm:max-w-md">
        <div className="mt-8">
          <Formik innerRef={formRef} initialValues={initialValues} onSubmit={onSubmit}>
            <Form>
              {!isJwtTokenExpired && !error ? (
                <>
                  <ActionComplete
                    header="Your Email Address has been changed successfully!"
                    message="you can now log in with the new Email Address."
                  />
                  <div className="mt-3 flex justify-center">
                    <Button label="Login" onClick={() => navigate(VISITOR_ROUTES.LOGIN)} />
                  </div>
                </>
              ) : (
                <AlertBox message="The confirmation link has been expired or already used" />
              )}
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default ConfirmNewEmailAddressPage
