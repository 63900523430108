import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Transition } from '@headlessui/react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline'

import { useFilterContext } from '../context/FilterContext'
import { getUniqueArrayList } from '../utils/helper'
import Button from './buttons/Button'
import SearchInput from './SearchInput'

function FilterSection({ buyers }) {
  const {
    allCashSalesData,
    updateFilter,
    clearFilters,
    filters: {
      buyer,
      commodity,
      type,
      futMonths,
      deliveryStartDate,
      deliveryEndDate,
      deliveredStatus,
      saleDate
    }
  } = useFilterContext()

  useEffect(() => () => clearFilters(), [])

  const [open, setOpen] = useState(false)

  const toggle = () => {
    setOpen(!open)
  }

  const getSubKindOptions = getUniqueArrayList(allCashSalesData?.map(({ subkind }) => subkind))
  const getDeliveryStartDateOptions = getUniqueArrayList(
    allCashSalesData?.map(({ deliveryStartDate }) => deliveryStartDate)
  ).filter((date) => date)
  const getDeliveryEndDateOptions = getUniqueArrayList(
    allCashSalesData?.map(({ deliveryEndDate }) => deliveryEndDate)
  ).filter((date) => date)
  const getSaleDateOptions = getUniqueArrayList(
    allCashSalesData?.map(({ saleDate }) => saleDate)
  ).filter((date) => date)

  const getFutMonthsOptions = getUniqueArrayList(
    allCashSalesData?.map(({ futuresMonth }) => futuresMonth)
  ).filter(Boolean)
  const onSearch = (value) => {
    const event = {
      target: {
        name: 'searchContracts',
        value
      }
    }
    updateFilter(event)
  }

  const getCommodityOptions = getUniqueArrayList(allCashSalesData?.map(({ profile }) => profile))

  return (
    <div>
      <form>
        <div className="my-3 flex justify-items-stretch gap-x-3">
          <SearchInput classNames="mt-7" placeholder="Search contract number" onChange={onSearch} />
          <div>
            <label className="text-base font-semibold text-gray-900" htmlFor="buyer">
              Buyer
            </label>
            <select
              className="mt-1 block w-fit rounded-md border border-gray-300 bg-white py-2 pl-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
              id="buyer"
              name="buyer"
              value={buyer}
              onChange={updateFilter}
            >
              <option value="any">Any</option>

              {buyers?.map(({ name, id }) => (
                <option key={id} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="text-base font-semibold text-gray-900" htmlFor="type">
              Type
            </label>
            <select
              className="mt-1 block w-fit rounded-md border border-gray-300 bg-white py-2 pl-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
              id="type"
              value={type}
              name="type"
              onChange={updateFilter}
            >
              <option value="any">Any</option>
              {getSubKindOptions.map((subKindOption) => {
                let typeValue
                if (subKindOption === 'CASH_SALE') {
                  typeValue = 'Cash Sale'
                } else if (subKindOption === 'BASIS') {
                  typeValue = 'Basis'
                } else if (subKindOption === 'OPEN_STORAGE') {
                  typeValue = 'Open Storage'
                } else if (subKindOption === 'NPE') {
                  typeValue = 'NPE'
                } else {
                  typeValue = 'HTA'
                }
                return (
                  <option key={subKindOption} value={subKindOption}>
                    {typeValue}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="flex flex-grow items-end justify-end gap-x-3">
            <Button label="Reset Filter" onClick={clearFilters} />
            <Button
              color="neutral"
              icon={
                <div className="flex items-center gap-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                  {open ? (
                    <ChevronUpIcon className="h-3 w-3" />
                  ) : (
                    <ChevronDownIcon className="h-3 w-3" />
                  )}
                </div>
              }
              onClick={toggle}
            />
          </div>
        </div>
        <Transition
          show={open}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="my-3 flex flex-wrap gap-3 border-b border-gray-300 pb-4">
            <div>
              <label className="text-base font-semibold text-gray-900" htmlFor="commodity">
                Commodity
              </label>
              <select
                className="mt-1 block w-fit rounded-md border border-gray-300 bg-white py-2 pl-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                id="commodity"
                name="commodity"
                value={commodity}
                onChange={updateFilter}
              >
                <option value="any">Any</option>

                {getCommodityOptions.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="text-base font-semibold text-gray-900" htmlFor="futMonths">
                Futures Month
              </label>
              <select
                className="mt-1 block w-fit rounded-md border border-gray-300 bg-white py-2 pl-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                id="futMonths"
                name="futMonths"
                value={futMonths}
                onChange={updateFilter}
              >
                <option value="any">Any</option>
                {getFutMonthsOptions.map((FutMonth) => (
                  <option key={FutMonth} value={FutMonth}>
                    {FutMonth}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="text-base font-semibold text-gray-900" htmlFor="deliveryStartDate">
                Delivery Start Date
              </label>
              <select
                className="mt-1 block w-40 rounded-md border border-gray-300 bg-white py-2 pl-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                id="deliveryStartDate"
                name="deliveryStartDate"
                value={deliveryStartDate}
                onChange={updateFilter}
              >
                <option value="any">Any</option>
                {getDeliveryStartDateOptions.map((date) => (
                  <option key={date} value={date}>
                    {dayjs(date).format('MM/DD/YYYY')}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="text-base font-semibold text-gray-900" htmlFor="deliveryEndDate">
                Delivery End Date
              </label>
              <select
                className="mt-1 block w-40 rounded-md border border-gray-300 bg-white py-2 pl-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                id="deliveryEndDate"
                name="deliveryEndDate"
                value={deliveryEndDate}
                onChange={updateFilter}
              >
                <option value="any">Any</option>
                {getDeliveryEndDateOptions.map((date) => (
                  <option key={date} value={date}>
                    {dayjs(date).format('MM/DD/YYYY')}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="text-base font-semibold text-gray-900" htmlFor="deliveredStatus">
                Delivery Status
              </label>
              <select
                className="mt-1 block w-fit rounded-md border border-gray-300 bg-white py-2 pl-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                id="deliveredStatus"
                name="deliveredStatus"
                value={deliveredStatus}
                onChange={updateFilter}
              >
                <option value="any">Any</option>
                <option value="yes">Delivered</option>
                <option value="no">Not delivered</option>
              </select>
            </div>
            <div>
              <label className="text-base font-semibold text-gray-900" htmlFor="saleDate">
                Sale Date
              </label>
              <select
                className="mt-1 block w-fit rounded-md border border-gray-300 bg-white py-2 pl-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                id="saleDate"
                name="saleDate"
                value={saleDate}
                onChange={updateFilter}
              >
                <option value="any">Any</option>
                {getSaleDateOptions.map((date) => (
                  <option key={date} value={date}>
                    {dayjs(date).format('MM/DD/YYYY')}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Transition>
      </form>
    </div>
  )
}

export default FilterSection
