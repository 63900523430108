import { gql } from '@apollo/client'

const GET_SUMMARY = gql`
  query getSummary($clientId: String!, $productionYear: Float!) {
    getSummary(clientId: $clientId, productionYear: $productionYear) {
      totalRevenue
      totalAcres
      totalHeads
      totalOpenSales
      totalClosedSales
    }
  }
`

export default GET_SUMMARY
