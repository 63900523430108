import { useQuery } from '@apollo/client'

import GET_USER from '../../gql/queries/getUser'

export const useGetUser = (id, options) => {
  const { data, error, loading } = useQuery(GET_USER, {
    variables: {
      id
    },
    skip: !id,
    ...options
  })

  return {
    data,
    error,
    loading
  }
}
