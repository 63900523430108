import { useQuery } from '@apollo/client'

import GET_ALL_PRODUCTION_YEARS from '../../gql/queries/getAllProductionyears'

export const useGetAllProductionYears = (adminId, options) => {
  const { data, error, loading } = useQuery(GET_ALL_PRODUCTION_YEARS, {
    variables: { adminId },
    ...options
  })

  const allProductionYears = data?.getAllProductionYears
  return {
    allProductionYears,
    error,
    loading
  }
}
