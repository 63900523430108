import React from 'react'
import { Outlet, useRoutes } from 'react-router-dom'

import ExpensesPerAcrePage from './ExpensesPerAcrePage'
import ExpensesPerHeadPage from './ExpensesPerHeadPage'
import GrossExpensesPage from './GrossExpensesPage'
import AdhocIncomePage from './AdhocIncomePage'
import LayoutWrapper from '../../../components/layouts/LayoutWrapper'
import ManageCustomExpensesPage from './ManageCustomExpensesPage'
import NavTabs from '../../../components/NavTabs'
import PlanTargetsPage from './PlanTargetsPage'
import { PLAN_ROUTES } from '../../../constants/routes'
import GoalsPage from './GoalsPage'

const tabs = [
  {
    name: 'Summary',
    href: `${PLAN_ROUTES.PLAN}/${PLAN_ROUTES.PLAN_TARGETS}`
  },
  {
    name: 'Crop Expenses',
    href: `${PLAN_ROUTES.PLAN}/${PLAN_ROUTES.EXPENSES_PER_ACRE}`
  },
  {
    name: 'Livestock Expenses',
    href: `${PLAN_ROUTES.PLAN}/${PLAN_ROUTES.EXPENSES_PER_HEAD}`
  },
  {
    name: 'Gross Expenses',
    href: `${PLAN_ROUTES.PLAN}/${PLAN_ROUTES.EXPENSES_GROSS}`
  },
  {
    name: 'Ad-Hoc Income',
    href: `${PLAN_ROUTES.PLAN}/${PLAN_ROUTES.ADHOC_INCOME}`
  },
  {
    name: 'Goals',
    href: `${PLAN_ROUTES.PLAN}/${PLAN_ROUTES.PROFIT_GOALS}`
  }
]

export default function Plan() {
  const tabElement = useRoutes([
    { index: true, element: <PlanTargetsPage /> },
    { path: PLAN_ROUTES.PLAN_TARGETS, element: <PlanTargetsPage /> },
    {
      path: PLAN_ROUTES.EXPENSES_PER_ACRE,
      element: <Outlet />,
      children: [
        { index: true, element: <ExpensesPerAcrePage /> },
        { path: PLAN_ROUTES.MANAGE_CUSTOM_EXPENSES, element: <ManageCustomExpensesPage /> }
      ]
    },
    {
      path: PLAN_ROUTES.EXPENSES_PER_HEAD,
      element: <Outlet />,
      children: [
        { index: true, element: <ExpensesPerHeadPage /> },
        { path: PLAN_ROUTES.MANAGE_CUSTOM_EXPENSES, element: <ManageCustomExpensesPage /> }
      ]
    },
    {
      path: PLAN_ROUTES.EXPENSES_GROSS,
      element: <Outlet />,
      children: [
        { index: true, element: <GrossExpensesPage /> },
        { path: PLAN_ROUTES.MANAGE_CUSTOM_EXPENSES, element: <ManageCustomExpensesPage /> }
      ]
    },
    { path: PLAN_ROUTES.ADHOC_INCOME, element: <AdhocIncomePage /> },
    { path: PLAN_ROUTES.PROFIT_GOALS, element: <GoalsPage /> }
  ])

  return (
    <LayoutWrapper>
      <NavTabs tabs={tabs} tabElement={tabElement} />
    </LayoutWrapper>
  )
}
