import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import AlertBox from '../components/AlertBox'
import Button from '../components/buttons/Button'
import DELETE_PRODUCTION_YEAR from '../gql/mutations/deleteProductionYear'
import DeleteModal from '../components/modals/DeleteModal'
import EmptyState from '../components/EmptyState'
import GET_PRODUCTION_YEARS from '../gql/queries/getProductionYears'
import GET_USER from '../gql/queries/getUser'
import Header from '../components/Header'
import Loader from '../components/Loader'
import Pagination from '../components/Pagination'
import ProductionYearModal from '../components/modals/ProductionYearModal'
import SearchInput from '../components/SearchInput'
import { getName } from '../utils/helper'
import { useAuth } from '../hooks/useAuth'
import { useToggle } from '../hooks/useToggle'

function ClientProductionYearsPage() {
  const [isOpen, toggleIsOpen] = useToggle()
  const [user, setUser] = useState(undefined)
  const [createusermodal, setCreateUserModal] = useState(undefined)
  const [intialValue, setIntialValue] = useState('')
  const auth = useAuth()
  const params = useParams()

  const id = params?.id || auth?.user?.id

  const {
    data: productionYearData,
    error,
    loading
  } = useQuery(GET_PRODUCTION_YEARS, {
    variables: {
      clientId: id
    }
  })

  const { data: userData } = useQuery(GET_USER, {
    variables: {
      id
    }
  })

  const [deleteProductionYear] = useMutation(DELETE_PRODUCTION_YEAR)

  const deleteProdYear = async () => {
    await deleteProductionYear({
      variables: {
        input: {
          id: user?.id
        }
      },
      refetchQueries: [GET_PRODUCTION_YEARS]
    })
  }

  const [postsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const productionYearsList = productionYearData?.getProductionYears.slice(
    indexOfFirstPost,
    indexOfLastPost
  )

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  useEffect(() => {
    if (productionYearData?.getProductionYears.length === 10 && currentPage !== 1) {
      setCurrentPage(1)
    }
  }, [productionYearData, currentPage])

  const handleCloseModal = () => {
    setCreateUserModal(undefined)
  }

  return (
    <>
      <Header>{getName(userData?.getUser)}</Header>

      {loading && !productionYearData && <Loader message="Fetching data..." variant="fetchData" />}
      {error && <AlertBox message="Oops! Something went wrong while data." />}
      {productionYearData && !error && (
        <div className="sm:text-justify">
          {productionYearData?.getProductionYears.length ? (
            <>
              <div className="flow-root sm:flex-row sm:items-center">
                <SearchInput placeholder="Search a production year" />
                <div className="float-right mt-4 sm:mt-0 sm:ml-16">
                  <Button
                    type="button"
                    color="info"
                    label="New Production Year"
                    onClick={() => {
                      setCreateUserModal('ADD')
                      toggleIsOpen()
                      setIntialValue('')
                    }}
                  />
                </div>
              </div>
              <div className="mt-8 flex flex-col">
                <div className="-my-2 overflow-x-auto">
                  <div className="inline-block min-w-full py-2 px-0.5 align-middle">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Year
                            </th>
                            <th
                              scope="col"
                              className="px-8 py-3.5 text-right text-sm font-semibold text-gray-900"
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {productionYearsList?.map((item) => (
                            <tr key={item?.id}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <Link to={`/clients/${id}/${item?.name}/profiles`}>
                                  {item?.name}
                                </Link>
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <div>
                                  <button
                                    type="button"
                                    className="flex-shrink-0 p-1 text-emerald-200 hover:text-emerald-600 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                                    onClick={() => {
                                      setIntialValue(item?.name)
                                      setCreateUserModal('EDIT')
                                      toggleIsOpen()
                                      setUser(item)
                                    }}
                                  >
                                    <div className="text-emerald-600 hover:text-emerald-900">
                                      Edit
                                    </div>
                                  </button>
                                  <button
                                    type="button"
                                    className="flex-shrink-0 p-1 text-emerald-200 hover:text-emerald-600 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                                    onClick={() => {
                                      setIntialValue(item?.name)
                                      setUser(item)
                                      setCreateUserModal('DELETE')
                                      toggleIsOpen()
                                    }}
                                  >
                                    <span className="sr-only">Delete</span>
                                    <div className="text-emerald-600 hover:text-emerald-900">
                                      Delete
                                    </div>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {productionYearsList?.length > 0 && (
                <Pagination
                  data={productionYearsList}
                  postsPerPage={postsPerPage}
                  totalPosts={productionYearData?.getProductionYears.length}
                  paginate={paginate}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
            </>
          ) : (
            <EmptyState
              clientId={id}
              header="No production years"
              message="Get started by creating a production year."
              label="New Production Year"
            />
          )}

          {(createusermodal === 'ADD' || createusermodal === 'EDIT') && (
            <ProductionYearModal
              productionYearsData={productionYearData?.getProductionYears}
              clientId={id}
              intialValue={intialValue}
              user={user}
              type={createusermodal}
              isOpen={isOpen}
              closeModal={toggleIsOpen}
            />
          )}
          <DeleteModal
            title={user?.name}
            description="Are you sure you want to delete this production year?"
            isOpen={createusermodal === 'DELETE'}
            onClose={handleCloseModal}
            onSubmit={deleteProdYear}
          />
        </div>
      )}
    </>
  )
}

export default ClientProductionYearsPage
