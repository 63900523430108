import { gql } from '@apollo/client'

const GET_SALES_PROFILES = gql`
  query getSaleProfile($clientId: String!, $productionYearId: Float!) {
    getSaleProfile(clientId: $clientId, productionYearId: $productionYearId) {
      id
      kind
      quantity
      subkind
      feeAmount
      feeMode
      amount
      basisAmount
      futuresAmount
      futuresMonth
      referenceNumber
      deliveryStartDate
      unloadNumber
      deliveryEndDate
      saleDate
      delivered
      orderExpiryDate
      notes
      displayPrice
      expectedRevenue
      fob
      soldTo
      location
      visibleToClient
      transportStatus
      transportToLocation
      buyer {
        id
        name
      }
      profile {
        id
        name
        cropId
        crop {
          id
          name
          unit
          tradingMonths
          futuresName
        }
        productionYear {
          id
          name
        }
      }
    }
  }
`

export default GET_SALES_PROFILES
