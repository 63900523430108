import { gql } from '@apollo/client'

const CREATE_EXPENSE = gql`
  mutation createExpense($input: CreateExpenseInput!) {
    createExpense(input: $input) {
      clientId
      kind
      name
      position
    }
  }
`

export default CREATE_EXPENSE
