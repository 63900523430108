import { gql } from '@apollo/client'

const DOWNLOAD_REPORT_FILE = gql`
  query DownloadReportFile(
    $clientId: String!
    $productionYear: [Float!]!
    $kind: String!
    $orientation: String!
  ) {
    downloadReportFile(
      clientId: $clientId
      productionYear: $productionYear
      kind: $kind
      orientation: $orientation
    ) {
      fileName
      pdfStringValue
    }
  }
`

export default DOWNLOAD_REPORT_FILE
