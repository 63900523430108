import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
/* eslint-disable no-unsafe-optional-chaining */

ChartJS.register(ArcElement, Tooltip, Legend)

function PieChart({ commodityData }) {
  const soldConstants = [
    { key: 'cash', value: 'Cash' },
    { key: 'basis', value: 'Basis' },
    { key: 'hta', value: 'HTA' },
    { key: 'npe', value: 'NPE' }
  ]

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (item) => `${item.label}: ${item.formattedValue} %`
        }
      }
    }
  }

  const renamedSubkindNames = soldConstants?.map((item) => item?.value)

  const totalQuantity = commodityData?.total?.quantity || 0

  const data = {
    labels: ['Unsold', ...renamedSubkindNames],
    datasets: [
      {
        label: 'Quantity',
        data: [
          (commodityData?.unsold?.quantity / totalQuantity) * 100,
          ...soldConstants.map(
            (item) => ((commodityData?.[item?.key]?.quantity || 0) / totalQuantity) * 100
          )
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1
      }
    ]
  }

  return <Pie data={data} options={options} />
}

export default PieChart
