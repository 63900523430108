/* eslint-disable no-unsafe-optional-chaining */
import React, { useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import AlertBox from '../../components/AlertBox'
import Button from '../../components/buttons/Button'
import DELETE_INSURANCE from '../../gql/mutations/deleteInsurance'
import DeleteModal from '../../components/modals/DeleteModal'
import GET_INSURANCES from '../../gql/queries/getInsurances'
import Header from '../../components/Header'
import InsuranceModal from '../../components/modals/InsuranceModal'
import Loader from '../../components/Loader'
import { useToggle } from '../../hooks/useToggle'
import PROFILES from '../../gql/queries/Profiles'
import EmptyState from '../../components/EmptyState'
import { renderPrice, renderQuantity } from '../../utils/helper'

export default function InsurancePage() {
  const { id, productionYearId } = useParams()

  const name = Number(productionYearId)

  const [isOpen, toggleIsOpen] = useToggle()
  const [insurance, setInsurance] = useState(undefined)
  const [isCreate, setIsCreate] = useState(false)
  const [createInsuranceModal, setCreateInsuranceModal] = useState('')

  const [deleteInsurance] = useMutation(DELETE_INSURANCE)

  const { data, loading, error } = useQuery(GET_INSURANCES, {
    variables: {
      clientId: id
    }
  })

  const insuranceData = data?.getInsurances?.filter(
    (item) => item?.profile?.productionYear?.name === name
  )
  const deleteProfileInsurance = async () => {
    await deleteInsurance({
      variables: {
        input: {
          id: insurance?.id
        }
      },
      refetchQueries: [GET_INSURANCES]
    })
  }

  const handleCloseModal = () => {
    setCreateInsuranceModal(undefined)
  }

  const { data: profilesData, loading: profileLoading } = useQuery(PROFILES, {
    variables: {
      clientId: id,
      productionYearId: name
    }
  })

  const insuranceProfileData = profilesData?.profiles.filter((item) => item?.isDryland)

  return (
    <>
      <Header>Insurance</Header>
      {!data && loading && <Loader message="Fetching data..." variant="fetchData" />}
      {!data && error && <AlertBox message="Oops! Something went wrong while fetching data." />}
      {data && (
        <div className="sm:text-justify">
          <div className="mb-4 flex justify-between">
            <h1 className="text-xl font-semibold text-gray-900">Insurance</h1>
            {insuranceProfileData?.length ? (
              <Button
                type="button"
                color="info"
                label="Add Insurance"
                onClick={() => {
                  setCreateInsuranceModal('INSURANCE')
                  setIsCreate(true)
                  toggleIsOpen()
                }}
              />
            ) : null}
          </div>
          {insuranceProfileData?.length ? (
            <div className="-my-2 min-w-full overflow-x-auto">
              <div className="inline-block min-w-full py-2 px-0.5 align-middle">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Profile
                        </th>
                        <th
                          scope="col"
                          className="px-8 py-3.5 text-center text-sm font-semibold text-gray-900"
                        >
                          Commodity
                        </th>
                        <th
                          scope="col"
                          className="px-8 py-3.5 text-right text-sm font-semibold text-gray-900"
                        >
                          Product
                        </th>
                        <th
                          scope="col"
                          className="px-8 py-3.5 text-right text-sm font-semibold text-gray-900"
                        >
                          APH
                        </th>
                        <th
                          scope="col"
                          className="px-8 py-3.5 text-right text-sm font-semibold text-gray-900"
                        >
                          Expected Qty.
                        </th>
                        <th
                          scope="col"
                          className="px-8 py-3.5 text-right text-sm font-semibold text-gray-900"
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          className="px-8 py-3.5 text-center text-sm font-semibold text-gray-900"
                        >
                          Insured Qty.
                        </th>
                        <th
                          scope="col"
                          className="px-8 py-3.5 text-center text-sm font-semibold text-gray-900"
                        >
                          Insured Revenue
                        </th>
                        <th
                          scope="col"
                          className="px-8 py-3.5 text-center text-sm font-semibold text-gray-900"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {insuranceData?.map((item) => {
                        const production =
                          (item?.profile?.drylandAcres + item?.profile?.irrigatedAcres) *
                          item?.dryYield
                        const insuredBushels = production * (item?.coverage / 100)
                        const revenue = item?.price * insuredBushels
                        return (
                          <tr key={item?.id}>
                            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                            <td className="relative ml-4 whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-8">
                              {item?.profile?.crop?.name}&nbsp;
                              {item?.profile?.description
                                ? `(${item?.profile?.description})`
                                : null}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-center text-sm  text-gray-900 sm:pl-6">
                              {item?.profile?.crop?.name}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-8">
                              {item?.productKind}-{item?.coverage}%
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-8">
                              {renderQuantity(item?.dryYield)}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-8">
                              {renderQuantity(production)} &nbsp;
                              {item?.profile?.crop?.unit.toLowerCase()}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-8">
                              {renderPrice(item?.price, 4)}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm text-gray-500 sm:pr-8">
                              {renderQuantity(insuredBushels)} &nbsp;
                              {item?.profile?.crop?.unit.toLowerCase()}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm text-gray-500 sm:pr-8">
                              {renderPrice(revenue, 4)}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-6">
                              <div>
                                <button
                                  type="button"
                                  className="flex-shrink-0 p-1 text-emerald-200 hover:text-emerald-600 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                                  onClick={() => {
                                    setInsurance(item)
                                    setCreateInsuranceModal('INSURANCE')
                                    setIsCreate(false)
                                    toggleIsOpen()
                                  }}
                                >
                                  <span className="sr-only">Edit</span>
                                  <div className="text-emerald-600 hover:text-emerald-900">
                                    Edit
                                  </div>
                                </button>
                                <button
                                  type="button"
                                  className="flex-shrink-0 p-1 text-emerald-200 hover:text-emerald-600 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                                  onClick={() => {
                                    setInsurance(item)
                                    setCreateInsuranceModal('DELETE')
                                    toggleIsOpen()
                                  }}
                                >
                                  <span className="sr-only">Delete</span>
                                  <div className="text-emerald-600 hover:text-emerald-900">
                                    Delete
                                  </div>
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <EmptyState
              header="No dryland crop commodities"
              message="Get started by adding a dryland crop commodity."
              disabled
            />
          )}
        </div>
      )}
      {createInsuranceModal === 'INSURANCE' && (
        <InsuranceModal
          isOpen={isOpen}
          isCreate={isCreate}
          insurance={insurance}
          closeModal={toggleIsOpen}
          insuranceProfileData={insuranceProfileData}
          loading={profileLoading}
          productionYear={Number(productionYearId)}
          clientId={id}
        />
      )}
      {createInsuranceModal === 'DELETE' && (
        <DeleteModal
          onClose={handleCloseModal}
          isOpen={createInsuranceModal === 'DELETE'}
          description="Are you sure you want to delete this insurance?"
          title={insurance?.profile?.crop?.name}
          onSubmit={deleteProfileInsurance}
        />
      )}
    </>
  )
}
