import React from 'react'
import { Outlet } from 'react-router-dom'

import ProgressBar from '../ProgressBar'
import Sidebar from '../Sidebar'

export default function UserLayout() {
  return (
    <>
      <Sidebar />
      <ProgressBar />
      <div className="mx-auto">
        <div className="bg-green_medium pb-20">
          <header className="ml-5 py-10 pl-48">
            <div className="mx-auto lg:ml-20 lg:px-2">
              <div id="header" className="h-9 min-h-full" />
            </div>
          </header>
        </div>
        <main className="-mt-32 flex-1 lg:pl-64">
          <div className="mx-auto px-4 py-4 sm:px-6 sm:py-6 md:px-8 md:py-8 lg:px-12 lg:py-12">
            <div className="rounded-lg bg-white px-4 py-4 shadow sm:px-6 sm:py-6 md:px-8 md:py-8 lg:px-12 lg:py-12">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
