import { gql } from '@apollo/client'

const UPDATE_PROFILE = gql`
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      id
      cropId
      isDryland
      isIrrigated
    }
  }
`

export default UPDATE_PROFILE
