/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQuery } from '@apollo/client'
// import { DuplicateIcon } from '@heroicons/react/outline'

import Button from '../buttons/Button'
import ExpenseInput from '../inputs/ExpenseInput'
import EditAllocationModal from '../modals/EditAllocationModal'
import EmptyState from '../EmptyState'
import GET_EXPENSES from '../../gql/queries/getExpenses'
import GET_PRODUCTION_YEAR_INCOMES from '../../gql/queries/getProductionYearIncomes'
import GET_PROFILE_EXPENSES from '../../gql/queries/getProfileExpenses'
import GET_PROFILE_INCOMES from '../../gql/queries/getProfileIncomes'
import SAVE_PRODUCTION_YEAR_INCOME from '../../gql/mutations/saveProductionYearIncome'
import ScrollButton from '../buttons/ScrollButton'
import { useToggle } from '../../hooks/useToggle'
import { convertNumberTwoDecimal } from '../../utils/helper'

function IncomeTable({ clientId, incomes, profiles, productionYearId, setGetProfileIncomesError }) {
  const ref = useRef(null)
  const [saveProductionYearIncome] = useMutation(SAVE_PRODUCTION_YEAR_INCOME)
  const [isOpen, toggleIsOpen] = useToggle()
  const [editAllocationModal, setEditAllocationModal] = useState(undefined)
  const [initialValue, setInitialValue] = useState({
    allocation: null,
    incomeId: null,
    profileId: null
  })
  const [disableLeftScroll, setDisableLeftScroll] = useState(false)
  const [disableRightScroll, setDisableRightScroll] = useState(false)

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset
    if (ref.current.scrollLeft === 0) {
      setDisableLeftScroll(true)
    } else {
      setDisableLeftScroll(false)
    }

    if (ref.current.scrollLeft + ref.current.clientWidth >= ref.current.scrollWidth) {
      setDisableRightScroll(true)
    } else {
      setDisableRightScroll(false)
    }
  }

  useEffect(() => {
    if (ref.current.scrollLeft === 0) {
      setDisableLeftScroll(true)
    }

    if (ref.current.scrollLeft + ref.current.clientWidth >= ref.current.scrollWidth) {
      setDisableRightScroll(true)
    }
  }, [])

  const profileIds = profiles?.map((item) => item.id)

  const { data: profileExpenses } = useQuery(GET_PROFILE_EXPENSES, {
    variables: {
      profileIds
    },
    skip: !profileIds?.length,
    onError: (error) => {
      setGetProfileIncomesError(error)
    }
  })

  const { data: expensesData } = useQuery(GET_EXPENSES, {
    variables: {
      clientId
    }
  })

  const grossExpenses = expensesData?.getExpenses?.filter((item) => item.kind === 'GROSS')

  const productionYearIds = [productionYearId]

  const { data: incomeData } = useQuery(GET_PRODUCTION_YEAR_INCOMES, {
    variables: {
      productionYearIds
    }
  })

  const { data: profileIncome } = useQuery(GET_PROFILE_INCOMES, {
    variables: {
      profileIds
    }
  })

  const getName = (profile) => {
    if (profile.kind === 'CROP') return `${profile.name} (${profile.productionYear.name})`
    if (profile.livestockKind === 'COW_CALF') {
      return profile?.description ? `Cow-Calf (${profile.description})` : `Cow-Calf`
    }
    if (profile.livestockKind === 'LIVE_CATTLE') {
      return profile?.description ? `Live-Cattle (${profile.description})` : `Live-Cattle`
    }
    if (profile.livestockKind === 'FEEDER_CATTLE') {
      return profile?.description ? `Feeder-Cattle (${profile.description})` : `Feeder-Cattle`
    }
    return null
  }

  const getValue = (profile) => {
    let expensePerUnit = 0
    const profileExpensesList = profileExpenses?.getProfileExpenses?.filter(
      (expense) => expense.profileId === profile.id
    )

    const grossExpensesList = grossExpenses?.map((expense) => {
      if (expense.grossExpenses?.length) {
        const grossExpense = expense.grossExpenses.find(
          (item) => item.productionYearId === productionYearId
        )

        const profileExpense = profileExpensesList?.find((item) => item.expenseId === expense.id)

        return (profileExpense?.allocation * grossExpense?.cost) / 100
      }

      return null
    })

    if (profile.kind === 'CROP') {
      const { drylandAcres, drylandYield, irrigatedAcres, irrigatedYield } = profile
      const drylandProduction = drylandAcres * drylandYield
      const irrigatedProduction = irrigatedAcres * irrigatedYield

      const drylandProfileExpenses = profileExpensesList?.map((expense) => expense.drylandCost)
      const irrigatedProfileExpenses = profileExpensesList?.map((expense) => expense.irrigatedCost)

      let production
      if (profile?.isDryland && profile?.isIrrigated) {
        production = drylandProduction + irrigatedProduction
        if (production === 0) return 0
        expensePerUnit =
          (drylandProfileExpenses?.reduce((acc, expense) => acc + expense, 0) * drylandAcres +
            irrigatedProfileExpenses?.reduce((acc, expense) => acc + expense, 0) * irrigatedAcres +
            grossExpensesList?.reduce((acc, expense) => acc + expense, 0)) /
          production
      } else if (profile?.isDryland) {
        production = drylandProduction
        if (production === 0) return 0
        expensePerUnit =
          (drylandProfileExpenses?.reduce((acc, expense) => acc + expense, 0) * drylandAcres +
            grossExpensesList?.reduce((acc, expense) => acc + (expense || 0), 0)) /
          production
      } else if (profile?.isIrrigated) {
        production = irrigatedProduction
        if (production === 0) return 0
        expensePerUnit =
          (irrigatedProfileExpenses?.reduce((acc, expense) => acc + expense, 0) * irrigatedAcres +
            grossExpensesList?.reduce((acc, expense) => acc + (expense || 0), 0)) /
          production
      }

      return expensePerUnit ? expensePerUnit?.toFixed(4) : 0
    }

    if (profile.kind === 'LIVESTOCK') {
      const { headsCount, deathsCount, outWeight, livestockKind } = profile
      const perHeadExpenses = profileExpensesList?.map((expense) => expense.perHeadCost)

      if (livestockKind === 'COW_CALF') {
        const incomes = 100
        const {
          cowsCount,
          bullsSaleCount,
          bullsSaleWeight,
          heiferSaleCount,
          heiferRetainCount,
          heiferSaleWeight
        } = profile
        if (perHeadExpenses?.length) {
          if (
            bullsSaleCount * bullsSaleWeight +
              (heiferSaleCount - heiferRetainCount) * heiferSaleWeight >
            0
          ) {
            expensePerUnit =
              (perHeadExpenses?.reduce((acc, expense) => acc + expense, 0) +
                grossExpensesList?.reduce((acc, expense) => acc + (expense || 0), 0) * cowsCount -
                incomes) /
              (bullsSaleCount * bullsSaleWeight +
                (heiferSaleCount - heiferRetainCount) * heiferSaleWeight)
          }
        }
        return expensePerUnit > 0 ? expensePerUnit?.toFixed(4) : 0
      }

      if ((headsCount || 0) - (deathsCount || 0) * outWeight > 0) {
        expensePerUnit =
          (perHeadExpenses?.reduce((acc, expense) => acc + expense, 0) +
            grossExpensesList?.reduce((acc, expense) => acc + (expense || 0), 0) * headsCount) /
          ((headsCount - (deathsCount || 0)) * outWeight)
      }
      return expensePerUnit ? expensePerUnit?.toFixed(4) : 0
    }

    return 0
  }

  const getAllocationValue = (income, profileId) => {
    const grossIncome = getProductionYearIncome(income)
    const allocation = getProfileIncome(profileId, income.id)
    const value = convertNumberTwoDecimal((allocation * grossIncome) / 100)
    return `$${value}`
  }

  const getProfileIncome = (profileId, incomeId) => {
    if (profileIncome?.getProfileIncomes?.length) {
      const profile = profileIncome.getProfileIncomes.find(
        (item) => item.profileId === profileId && item.incomeId === incomeId
      )

      if (profile) {
        return profile.allocation?.toFixed(2)
      }
    }
    return null
  }

  const getProductionYearIncome = (inputIncome) =>
    incomeData?.getProductionYearIncomes?.find((income) => income.incomeId === inputIncome.id)
      ?.amount

  const handleProductionYearIncomeChange = (event, incomeId) => {
    toast.promise(
      saveProductionYearIncome({
        variables: {
          input: {
            productionYearId,
            incomeId,
            amount: event || 0
          }
        },
        refetchQueries: [GET_PRODUCTION_YEAR_INCOMES, GET_PROFILE_INCOMES]
      }),
      {
        loading: 'Saving the income...',
        success: 'Income saved successfully.',
        error: 'Error while saving the income.'
      }
    )
  }

  const getAllocation = (profileId, incomeId) => {
    const profile = profileIncome.getProfileIncomes.find(
      (item) => item.profileId === profileId && item.incomeId === incomeId
    )

    if (profile.isAutoAllocated) return 0
    return profile.allocation?.toFixed(2)
  }

  return (
    <div className="flex flex-row">
      <ScrollButton direction="left" onClick={() => scroll(-200)} disabled={disableLeftScroll} />
      <div className="-my-2 -mx-4 min-w-full overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div
            className="overflow-x-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
            ref={ref}
          >
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 first:sticky first:left-0 first:bg-gray-50 sm:pl-10"
                  >
                    &nbsp;
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 first:sticky first:left-0 first:bg-gray-50 sm:pl-10"
                  >
                    &nbsp;
                  </th>
                  {profiles?.map((profile) => {
                    const profileValue = Number(getValue(profile))
                    return (
                      <th
                        scope="col"
                        key={profile.id}
                        className="whitespace-nowrap px-6 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="flex flow-root">
                          <div className="block text-sm font-bold">{getName(profile)}</div>
                          <div>
                            <span className="pr-2 text-2xl font-bold text-green-500">
                              ${convertNumberTwoDecimal(profileValue)}
                            </span>
                            <span className="text-sm text-gray-400">
                              per&nbsp;{profile.kind === 'CROP' ? 'bu' : 'lb'}
                            </span>
                          </div>
                          <div className="flex space-x-6">
                            {profile.breakEven && (
                              <div className="text-left text-xs text-gray-400">
                                (${profile.breakEven}&nbsp;breakeven)
                              </div>
                            )}
                          </div>
                        </div>
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {incomes.map((income, index) => (
                  <tr>
                    <th
                      key={income.id}
                      className="ml-4 whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-bold font-medium first:sticky first:left-0 first:bg-white sm:pr-8"
                    >
                      {income.name}
                    </th>
                    <td
                      key={income.name}
                      className="ml-4 whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-bold font-medium first:sticky first:left-0 first:bg-white sm:pr-8"
                    >
                      <ExpenseInput
                        prefix="$"
                        value={getProductionYearIncome(income)}
                        onChange={(event) => handleProductionYearIncomeChange(event, income.id)}
                      />
                    </td>
                    {!profiles?.length && index === 0 && (
                      <td rowSpan={incomes?.length}>
                        <EmptyState
                          header="No commodities"
                          message="Get started by adding commodity."
                          disabled
                        />
                      </td>
                    )}
                    {profiles?.map(
                      (profile) =>
                        getProductionYearIncome(income) > 0 && (
                          <td
                            key={profile.id}
                            className="whitespace-nowrap py-4 px-80 pl-4 pr-14 text-sm font-medium text-gray-900 sm:pl-6"
                          >
                            ({getAllocationValue(income, profile.id)})&nbsp;
                            {getProfileIncome(profile.id, income.id)}%&nbsp;
                            <span>
                              <Button
                                type="button"
                                color="action"
                                actionLabel="Edit"
                                onClick={() => {
                                  setInitialValue({
                                    allocation: getAllocation(profile.id, income.id),
                                    incomeId: income.id,
                                    profileId: profile.id
                                  })
                                  setEditAllocationModal('EDIT')
                                  toggleIsOpen()
                                }}
                              />
                            </span>
                          </td>
                        )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ScrollButton direction="right" onClick={() => scroll(200)} disabled={disableRightScroll} />
      {editAllocationModal === 'EDIT' && (
        <EditAllocationModal
          initialValue={initialValue}
          type={editAllocationModal}
          isOpen={isOpen}
          closeModal={toggleIsOpen}
        />
      )}
    </div>
  )
}

export default IncomeTable
