import React from 'react'

export default function Title({ Icon, onClick, title, children }) {
  return (
    <div className="mb-4 sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <div className="flex">
          {Icon && (
            <Icon
              role="button"
              className="mr-2 mt-1 h-5 w-5"
              aria-hidden="true"
              onClick={onClick}
            />
          )}
          <h1 className="text-xl font-semibold text-gray-900">{title || children}</h1>
        </div>
      </div>
    </div>
  )
}
