import React from 'react'
import dayjs from 'dayjs'

import TableWrapper from '../../../wrappers/TableWrapper'
import Title from '../../../typography/Title'
import ViewWrapper from '../../../wrappers/ViewWrapper'
import { renderMoney } from '../../../../utils/helper'

// Table Columns
const columns = ['Commodity', 'Expenses', 'Income', 'Profit Goal', 'Breakeven', 'Target Price']
const getName = (profile) => {
  if (profile.livestockKind === 'COW_CALF') {
    return profile?.description ? `Cow-Calf (${profile.description})` : `Cow-Calf`
  }
  if (profile.livestockKind === 'LIVE_CATTLE') {
    return profile?.description ? `Live-Cattle (${profile.description})` : `Live-Cattle`
  }
  if (profile.livestockKind === 'FEEDER_CATTLE') {
    return profile?.description ? `Feeder-Cattle (${profile.description})` : `Feeder-Cattle`
  }
  return null
}

function LivestockProfilesView({ profiles }) {
  return profiles?.length ? (
    <ViewWrapper>
      <Title title="Livestock" />
      <TableWrapper
        exportable={{
          filename: `Expenses-Summary-Livestocks-${dayjs().format('MM-DD-YYYY')}`
        }}
        columns={columns}
        rows={profiles
          ?.map((item) => [
            getName(item),
            item.totalExpense,
            item.totalIncome,
            item.totalTargets,
            item.breakeven,
            item.targetPrice
          ])
          .reduce(
            (acc, item, index, { length }) => {
              acc[0] = acc[0].map((value, i) => (i ? value + item[i] : value))

              const format = (item) =>
                item.map((item, index) => {
                  if (index === 1 || index === 2 || index === 3) {
                    return renderMoney(item)
                  }
                  if (index === 4 || index === 5) {
                    return item >= 0 ? renderMoney(item) : '-'
                  }
                  return item
                })

              if (index === length - 1) {
                return [...acc.slice(1), format(item), format(acc[0])]
              }
              return [...acc, format(item)]
            },
            [['Total', 0, 0, 0, 0, 0]]
          )}
      />
    </ViewWrapper>
  ) : null
}

export default LivestockProfilesView
