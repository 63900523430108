import dayjs from 'dayjs'
import React from 'react'

import ReportSubTitle from '../../reportPrimitives/ReportSubtitle'
import ReportTable from '../../reportPrimitives/ReportTable'
import { renderNumber } from '../../../utils/helper'

const liveStockTableColumns = [
  'Description',
  'Out date',
  'Head',
  'In weight',
  'Out weight',
  'Daily gain',
  'Days on feed',
  'Sellable'
]

const cowCalfTable = ['Commodity', 'Cows', 'Calving', 'Sale', 'Steers', 'Heifers', 'Culls']

function LivestockView({
  variant,
  hasLiveCattleData,
  hasCowCalfData,
  hasFeederCattleData,
  liveCattleMappedByProductionYear,
  cowCalfMappedByProductionYear,
  feederCattleMappedByProductionYear
}) {
  return (
    <>
      <ReportSubTitle break variant={variant} title="LIVESTOCK" />
      {hasLiveCattleData &&
        liveCattleMappedByProductionYear?.map((data) => (
          <ReportTable
            key={data.productionYear}
            variant={variant}
            columns={liveStockTableColumns}
            title={<>{data.productionYear} Live Cattle</>}
            rows={data?.reports.map((report) => {
              const sellable = report.headsCount - report.deathsCount
              const feedCount = (report.outWeight - report.inWeight) / report.dailyGain
              const date = new Date(report.inDate)
              const outDate = date.setDate(date.getDate() + feedCount)
              return [
                <>
                  Live Cattle&nbsp;
                  {report.description ? `(${report.description})` : null}
                </>,
                outDate ? dayjs(outDate).format('MM/DD/YYYY') : '-',
                report.headsCount?.toLocaleString('en-US'),
                renderNumber(report.inWeight),
                renderNumber(report.outWeight),
                report.dailyGain?.toLocaleString('en-US'),
                feedCount && feedCount !== Infinity ? Math.round(feedCount) : '-',
                sellable?.toLocaleString('en-US')
              ]
            })}
          />
        ))}
      {hasCowCalfData &&
        cowCalfMappedByProductionYear?.map((data) => (
          <ReportTable
            key={data.productionYear}
            variant={variant}
            title={<>{data.productionYear} Cow Calf</>}
            columns={cowCalfTable}
            rows={data?.reports.map((report) => [
              <>
                Cow-Calf&nbsp;
                {report.description ? `(${report.description})` : null}
              </>,
              report.cowsCount?.toLocaleString('en-US'),
              dayjs(report.calvingDate).format('MM/DD/YYYY'),
              dayjs(report.saleDate).format('MM/DD/YYYY'),
              <>
                {report.bullsSaleCount?.toLocaleString('en-US')}&nbsp;@&nbsp;
                {renderNumber(report.bullsSaleWeight)}
                &nbsp;lbs
              </>,
              <>
                {report.heiferSaleCount?.toLocaleString('en-US')}&nbsp;@&nbsp;
                {renderNumber(report.heiferSaleWeight)}
                &nbsp;lbs
              </>,
              0
            ])}
          />
        ))}
      {hasFeederCattleData &&
        feederCattleMappedByProductionYear?.map((data) => (
          <ReportTable
            key={data.productionYear}
            variant={variant}
            title={<>{data.productionYear} Feeder Cattle</>}
            columns={liveStockTableColumns}
            rows={data?.reports.map((report) => {
              const sellable = report.headsCount - report.deathsCount
              const feedCount = (report.outWeight - report.inWeight) / report.dailyGain
              const date = new Date(report.inDate)
              const outDate = date.setDate(date.getDate() + feedCount)
              return [
                <>
                  Feeder Cattle&nbsp;
                  {report.description ? `(${report.description})` : null}
                </>,
                outDate ? dayjs(outDate).format('MM/DD/YYYY') : '-',
                report.headsCount?.toLocaleString('en-US'),
                renderNumber(report.inWeight),
                renderNumber(report.outWeight),
                report.dailyGain?.toLocaleString('en-US'),
                feedCount && feedCount !== Infinity ? Math.round(feedCount) : '-',
                sellable?.toLocaleString('en-US')
              ]
            })}
          />
        ))}
    </>
  )
}

export default LivestockView
