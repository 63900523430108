import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import AlertBox from '../../components/AlertBox'
import Button from '../../components/buttons/Button'
import Input from '../../components/inputs/Input'
import Loader from '../../components/Loader'
import UPDATE_PROFILE_ACCOUNT from '../../gql/mutations/updateProfileAccount'
import { useAuth } from '../../hooks/useAuth'
import { phoneRegExp } from '../../utils/validationUtils'
import states from '../../constants/states'
import GET_USER from '../../gql/queries/getUser'

const validationSchema = Yup.object({
  firstName: Yup.string().required('Required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(10, 'Phone number too short')
    .max(10, 'Phone number too long')
})

function ProfileAccountPage() {
  const { user } = useAuth()
  const [updateProfileStatus, setUpdateProfileStatus] = useState(false)

  const [updateUserProfileAccount, { loading: isProfileUpdateLoading }] =
    useMutation(UPDATE_PROFILE_ACCOUNT)

  const { data: userData, loading: isUserDataLoading } = useQuery(GET_USER, {
    variables: {
      id: user?.id
    },
    skip: !user?.id
  })

  const initialValues = {
    firstName: userData?.getUser?.firstName ?? '',
    lastName: userData?.getUser?.lastName ?? '',
    state: userData?.getUser?.state || states[0].name,
    city: userData?.getUser?.city ?? '',
    phone: userData?.getUser?.phone ?? ''
  }

  const onSubmit = async (values, { resetForm }) => {
    setUpdateProfileStatus(false)

    await updateUserProfileAccount({
      variables: {
        input: {
          id: user?.id,
          firstName: values.firstName,
          lastName: values.lastName,
          city: values.city,
          state: values.state,
          phone: values.phone.toString()
        }
      },
      refetchQueries: [GET_USER]
    })

    setUpdateProfileStatus(true)
    resetForm()
  }

  return (
    <div className="flex justify-center overflow-hidden">
      <div className="w-full rounded-lg sm:max-w-md">
        {isUserDataLoading ? (
          <Loader message="Fetching user details..." variant="fetchData" />
        ) : (
          <>
            <h1 className="text-xl font-bold text-gray-900">
              Hello,&nbsp;{userData?.getUser?.firstName}!
            </h1>
            <p className="text-gray-500">Change your account related details here.</p>
            <div className="mt-8">
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize
              >
                <Form>
                  {updateProfileStatus && (
                    <AlertBox
                      type="success"
                      message="Your profile has been updated successfully."
                    />
                  )}
                  <div className="col-span-2 grid gap-2">
                    <div className="mb-6">
                      <Input label="First Name" type="text" name="firstName" />
                    </div>
                    <div className="mb-6">
                      <Input label="Last Name" type="text" name="lastName" />
                    </div>
                    <div className="mb-6">
                      <label
                        htmlFor="state"
                        className="
          block text-sm font-bold
          text-gray-700
        "
                      >
                        State
                      </label>
                      <Field
                        as="select"
                        name="state"
                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      >
                        {states.map((option) => (
                          <option key={option?.name} value={option?.name}>
                            {option.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="mb-6">
                      <Input label="City" type="text" name="city" />
                    </div>
                    <div className="col-span-2 mb-6">
                      <Input label="Phone Number" type="number" name="phone" />
                    </div>
                    <div>
                      <Button
                        type="submit"
                        label="Save Changes"
                        color="info"
                        loading={isProfileUpdateLoading}
                      />
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ProfileAccountPage
