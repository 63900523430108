import { useQuery } from '@apollo/client'

import GET_USERS from '../../gql/queries/getUsers'

export const useGetUsers = (userType, searchValue = '') => {
  const { data, error, loading } = useQuery(GET_USERS, {
    variables: {
      kinds: [userType],
      search: searchValue
    }
  })

  return {
    data,
    error,
    loading
  }
}
