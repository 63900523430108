/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { ArrowCircleLeftIcon } from '@heroicons/react/outline'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import AddExpenseModal from '../../../components/modals/AddExpenseModal'
import AlertBox from '../../../components/AlertBox'
import Button from '../../../components/buttons/Button'
import DELETE_EXPENSE from '../../../gql/mutations/deleteExpense'
import DeleteModal from '../../../components/modals/DeleteModal'
import EmptyState from '../../../components/EmptyState'
import GET_EXPENSES from '../../../gql/queries/getExpenses'
import Loader from '../../../components/Loader'
import Pagination from '../../../components/Pagination'
import Table from '../../../components/tables/Table'
import TableDataCell from '../../../components/tables/TableDataCell'
import TableRow from '../../../components/tables/TableRow'
import Title from '../../../components/typography/Title'
import { useToggle } from '../../../hooks/useToggle'

export default function ManageCustomExpensesPage() {
  const columns = ['Expense name', 'Actions']
  const [isOpen, toggleIsOpen] = useToggle()
  const [expense, setExpense] = useState(undefined)
  const [createExpenseModal, setCreateExpenseModal] = useState(undefined)
  const [initialValue, setInitialValue] = useState('')
  const [expensePerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const navigate = useNavigate()
  const location = useLocation()
  const [state] = useState(location.state || {})

  const { id } = useParams()
  const {
    data: expensesData,
    error,
    loading
  } = useQuery(GET_EXPENSES, {
    variables: {
      clientId: id
    }
  })

  const customExpenseItems = expensesData?.getExpenses?.filter(
    (item) => item.kind === state.kind && item.clientId
  )

  const globalExpenseItems = expensesData?.getExpenses?.filter((item) => item.kind === state.kind)

  const [deleteExpense] = useMutation(DELETE_EXPENSE)

  const deleteCustomExpense = async () => {
    await deleteExpense({
      variables: {
        id: expense?.id
      },
      refetchQueries: [GET_EXPENSES]
    })
  }

  const indexOfLastPost = currentPage * expensePerPage
  const indexOfFirstPost = indexOfLastPost - expensePerPage
  const expensesList = customExpenseItems?.slice(indexOfFirstPost, indexOfLastPost)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  useEffect(() => {
    if (customExpenseItems?.length === 10 && currentPage !== 1) {
      setCurrentPage(1)
    }
  }, [customExpenseItems, currentPage])

  const handleCloseModal = () => {
    setCreateExpenseModal(undefined)
  }

  return (
    <div className="mt-4 px-4 sm:px-6 lg:px-8">
      <Title title={state.title} Icon={ArrowCircleLeftIcon} onClick={() => navigate(-1)} />
      <Button
        type="button"
        color="info"
        label="Add Expense"
        onClick={() => {
          setCreateExpenseModal('ADD')
          toggleIsOpen()
          setInitialValue('')
        }}
      />
      {loading && <Loader message="Fetching data..." variant="fetchData" />}
      {error && <AlertBox message="Oops! Something went wrong while fetching data." />}
      {!loading && !error && customExpenseItems?.length ? (
        <>
          <div className="mt-8 flex flex-col">
            <Table columns={columns}>
              {customExpenseItems.map((item) => (
                <TableRow keyValue={item?.id}>
                  <TableDataCell type="title">{item?.name}</TableDataCell>
                  <TableDataCell>
                    <Button
                      type="button"
                      color="action"
                      actionLabel="Edit"
                      onClick={() => {
                        setInitialValue(item?.name)
                        setCreateExpenseModal('EDIT')
                        toggleIsOpen()
                        setExpense(item)
                      }}
                    />
                    <Button
                      type="button"
                      color="action"
                      actionLabel="Delete"
                      onClick={() => {
                        setInitialValue(item?.name)
                        setExpense(item)
                        setCreateExpenseModal('DELETE')
                        toggleIsOpen()
                      }}
                    />
                  </TableDataCell>
                </TableRow>
              ))}
            </Table>
          </div>
          {expensesList?.length > 0 && (
            <Pagination
              data={expensesList}
              postsPerPage={expensePerPage}
              totalPosts={customExpenseItems?.length}
              paginate={paginate}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </>
      ) : (
        <EmptyState
          clientId={id}
          header="No custom expenses"
          message="Get started by creating a custom expense."
          disabled
        />
      )}

      {(createExpenseModal === 'ADD' || createExpenseModal === 'EDIT') && (
        <AddExpenseModal
          clientId={id}
          initialValue={initialValue}
          expense={expense}
          type={createExpenseModal}
          isOpen={isOpen}
          closeModal={toggleIsOpen}
          kind={state.kind}
          length={
            customExpenseItems[customExpenseItems?.length - 1]?.position ||
            globalExpenseItems[globalExpenseItems?.length - 1]?.position ||
            1
          }
        />
      )}
      {createExpenseModal === 'DELETE' && (
        <DeleteModal
          isOpen={createExpenseModal === 'DELETE'}
          title={expense?.name}
          description="Are you sure you want to delete this expense?"
          onClose={handleCloseModal}
          onSubmit={deleteCustomExpense}
        />
      )}
    </div>
  )
}
