import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import GET_SALES_PROFILES from '../../../gql/queries/getSalesProfile'
import { renderNumber, renderMoney } from '../../../utils/helper'
import ViewWrapper from '../../wrappers/ViewWrapper'

const renderStats = (stats) =>
  stats.map(([key, value]) => (
    <div className="flex-col" key={key}>
      <div className="text-sm font-bold text-gray-500">{key}</div>
      <div className="text-xl font-bold text-sky-400">{value}</div>
    </div>
  ))

function StatsView({ summary }) {
  const { id, productionYearId } = useParams()
  const name = Number(productionYearId)

  const { data: salesData } = useQuery(GET_SALES_PROFILES, {
    variables: {
      clientId: id,
      productionYearId: name
    }
  })

  const openContracts = salesData?.getSaleProfile?.filter(
    (profile) => !profile.delivered && profile.kind === 'SALE'
  )
  const closedContracts = salesData?.getSaleProfile?.filter(
    (profile) => profile.delivered && profile.kind === 'SALE'
  )

  return (
    <ViewWrapper>
      <div className="flex justify-around rounded-lg bg-gray-100 p-10 px-5 text-center">
        {renderStats([
          ['Acres', renderNumber(summary?.totalAcres)],
          ['Heads', renderNumber(summary?.totalHeads)],
          ['Cash Sales', renderMoney(summary?.totalRevenue)],
          ['No. of Open Contracts', renderNumber(openContracts?.length) || 0],
          ['No. of Closed Contracts', renderNumber(closedContracts?.length) || 0]
        ])}
      </div>
    </ViewWrapper>
  )
}

export default StatsView
