/* eslint-disable no-unsafe-optional-chaining */
import dayjs from 'dayjs'
import React, { Fragment, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'

import Button from '../../buttons/Button'
import DELETE_PROFILE from '../../../gql/mutations/deleteProfile'
import DeleteModal from '../../modals/DeleteModal'
import EmptyState from '../../EmptyState'
import GET_PRODUCTION_YEARS from '../../../gql/queries/getProductionYears'
import LivestockProfileModal from '../../modals/LivestockProfileModal'
import PROFILES from '../../../gql/queries/Profiles'
import SubTitle from '../../typography/SubTitle'
import TableWrapper from '../../wrappers/TableWrapper'
import Title from '../../typography/Title'
import { renderNumber } from '../../../utils/helper'

function LivestockView({ profiles }) {
  const { id, productionYearId } = useParams()
  const name = Number(productionYearId)

  const { data: productionYearData } = useQuery(GET_PRODUCTION_YEARS, {
    variables: {
      clientId: id
    }
  })
  const [isOpen, setIsOpen] = useState(false)
  const [profile, setProfile] = useState(undefined)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [livestockKind, setLivestockKind] = useState(undefined)
  const [isLiveCattle, setIsLiveCattle] = useState(false)
  const [deleteProfile] = useMutation(DELETE_PROFILE)

  const year = productionYearData?.getProductionYears?.filter((item) => item?.name === name)
  const livestockProfile = profiles?.filter((item) => item?.kind === 'LIVESTOCK')
  const cowCalfProfile = livestockProfile?.filter((item) => item?.livestockKind === 'COW_CALF')
  const liveCattleProfile = livestockProfile?.filter(
    (item) => item?.livestockKind === 'LIVE_CATTLE'
  )
  const feederCattleProfile = livestockProfile?.filter(
    (item) => item?.livestockKind === 'FEEDER_CATTLE'
  )
  const closeModal = () => {
    setIsOpen(false)
    setProfile(undefined)
  }

  const handleCloseModal = () => {
    setIsDeleteModalOpen(false)
  }

  const deleteCropProfile = () =>
    deleteProfile({
      variables: {
        input: {
          id: profile?.id
        }
      },
      refetchQueries: [
        {
          query: PROFILES,
          variables: {
            clientId: id,
            productionYearId: name
          }
        }
      ]
    })

  return (
    <>
      <div className="mt-8 sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <Title>Livestock</Title>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button
                className="inline-flex items-center justify-center rounded-md border
                        border-transparent bg-green_medium px-4 py-2 text-sm font-medium
                        text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2
                        focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
              >
                Add Livestock
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-44 origin-top-right rounded-md bg-white opacity-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1 opacity-100">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        className={classNames(
                          active ? 'bg-gray-100 font-bold text-gray-900' : 'text-gray-700',
                          'block w-44 px-4 py-2 text-sm'
                        )}
                        onClick={(e) => {
                          e.preventDefault()
                          setLivestockKind('COW_CALF')
                          setIsOpen(!isOpen)
                        }}
                      >
                        Cow-Calf
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        className={classNames(
                          active ? 'bg-gray-100 font-bold text-gray-900' : 'text-gray-700',
                          'block w-44 px-4 py-2 text-sm'
                        )}
                        onClick={(e) => {
                          e.preventDefault()
                          setLivestockKind('CATTLE')
                          setIsLiveCattle(true)
                          setIsOpen(!isOpen)
                        }}
                      >
                        Live Cattle
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        className={classNames(
                          active ? 'bg-gray-100 font-bold text-gray-900' : 'text-gray-700',
                          'block w-44 px-4 py-2 text-sm'
                        )}
                        onClick={(e) => {
                          e.preventDefault()
                          setLivestockKind('CATTLE')
                          setIsLiveCattle(false)
                          setIsOpen(!isOpen)
                        }}
                      >
                        Feeder Cattle
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      {livestockProfile?.length > 0 ? (
        <>
          {feederCattleProfile?.length > 0 && (
            <div className="flex flex-col">
              <div className="mb-3 sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <SubTitle>Feeder Cattle</SubTitle>
                </div>
              </div>
              <div className="-my-2 min-w-full overflow-x-auto">
                <div className="inline-block min-w-full py-2 px-0.5 align-middle">
                  <TableWrapper
                    columns={[
                      'Description',
                      'Out date',
                      'Head',
                      'In weight',
                      'Out weight',
                      'Daily gain',
                      'Days on feed',
                      'Sellable',
                      'Actions'
                    ]}
                    rows={feederCattleProfile?.map((item) => {
                      const sellable = item?.headsCount - item?.deathsCount
                      const feedCount = (item?.outWeight - item?.inWeight) / item?.dailyGain
                      const date = new Date(item?.inDate)
                      const outDate = date.setDate(date.getDate() + feedCount)
                      return [
                        <>
                          Feeder Cattle&nbsp;
                          {item?.description ? `(${item?.description})` : null}
                        </>,
                        outDate ? dayjs(outDate).format('MM/DD/YYYY') : '-',
                        item?.headsCount?.toLocaleString('en-US'),
                        renderNumber(item?.inWeight),
                        renderNumber(item?.outWeight),
                        item?.dailyGain?.toLocaleString('en-US'),
                        feedCount && feedCount !== Infinity ? Math.round(feedCount) : '-',
                        sellable?.toLocaleString('en-US'),
                        <div>
                          <Button
                            actionLabel="Edit"
                            color="action"
                            onClick={() => {
                              setProfile(item)
                              setLivestockKind('CATTLE')
                              setIsLiveCattle(false)
                              setIsOpen(!isOpen)
                            }}
                          />
                          <Button
                            actionLabel="Delete"
                            color="action"
                            onClick={() => {
                              setProfile(item)
                              setIsDeleteModalOpen(true)
                            }}
                          />
                        </div>
                      ]
                    })}
                  />
                </div>
              </div>
            </div>
          )}

          {cowCalfProfile?.length > 0 && (
            <div className="mt-8 flex flex-col">
              <div className="mb-3 sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <SubTitle>Cow-Calf</SubTitle>
                </div>
              </div>
              <div className="-my-2 min-w-full overflow-x-auto">
                <div className="inline-block min-w-full py-2 px-0.5 align-middle">
                  <TableWrapper
                    columns={[
                      'Commodity',
                      'Cows',
                      'Calving',
                      'Sale',
                      'Steers',
                      'Heifers',
                      'Culls',
                      'Actions'
                    ]}
                    rows={cowCalfProfile?.map((item) => [
                      <>
                        Cow-Calf&nbsp;
                        {item?.description ? `(${item?.description})` : null}
                      </>,
                      item?.cowsCount?.toLocaleString('en-US'),
                      dayjs(item?.calvingDate).format('MM/DD/YYYY'),
                      dayjs(item?.saleDate).format('MM/DD/YYYY'),
                      `${item?.bullsSaleCount?.toLocaleString('en-US')} @ ${renderNumber(
                        item?.bullsSaleWeight
                      )} lbs`,
                      `${item?.heiferSaleCount?.toLocaleString('en-US')} @ ${renderNumber(
                        item?.heiferSaleWeight
                      )} lbs`,
                      0,
                      <div>
                        <Button
                          actionLabel="Edit"
                          color="action"
                          onClick={() => {
                            setProfile(item)
                            setLivestockKind('COW_CALF')
                            setIsOpen(!isOpen)
                          }}
                        />
                        <Button
                          actionLabel="Delete"
                          color="action"
                          onClick={() => {
                            setProfile(item)
                            setIsDeleteModalOpen(true)
                          }}
                        />
                      </div>
                    ])}
                  />
                </div>
              </div>
            </div>
          )}

          {liveCattleProfile?.length > 0 && (
            <div className="mt-8 flex flex-col">
              <div className="mb-3 sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <SubTitle>Live Cattle</SubTitle>
                </div>
              </div>
              <div className="-my-2 min-w-full overflow-x-auto">
                <div className="inline-block min-w-full py-2 px-0.5 align-middle">
                  <TableWrapper
                    columns={[
                      'Description',
                      'Out date',
                      'Head',
                      'In weight',
                      'Out weight',
                      'Daily gain',
                      'Days on feed',
                      'Sellable',
                      'Actions'
                    ]}
                    rows={liveCattleProfile?.map((item) => {
                      const sellable = item?.headsCount - item?.deathsCount
                      const feedCount = (item?.outWeight - item?.inWeight) / item?.dailyGain
                      const date = new Date(item?.inDate)
                      const outDate = date.setDate(date.getDate() + feedCount)
                      return [
                        <>
                          Live Cattle&nbsp;
                          {item?.description ? `(${item?.description})` : null}
                        </>,
                        outDate ? dayjs(outDate).format('MM/DD/YYYY') : '-',
                        item?.headsCount?.toLocaleString('en-US'),
                        renderNumber(item?.inWeight),
                        renderNumber(item?.outWeight),
                        item?.dailyGain?.toLocaleString('en-US'),
                        feedCount && feedCount !== Infinity ? Math.round(feedCount) : '-',
                        sellable?.toLocaleString('en-US'),
                        <div>
                          <Button
                            actionLabel="Edit"
                            color="action"
                            onClick={() => {
                              setProfile(item)
                              setLivestockKind('CATTLE')
                              setIsLiveCattle(true)
                              setIsOpen(!isOpen)
                            }}
                          />
                          <Button
                            actionLabel="Delete"
                            color="action"
                            onClick={() => {
                              setProfile(item)
                              setIsDeleteModalOpen(true)
                            }}
                          />
                        </div>
                      ]
                    })}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <EmptyState header="No livestocks" message="Get started by adding livestock." disabled />
      )}

      <LivestockProfileModal
        clientId={id}
        year={year}
        profile={profile}
        isOpen={isOpen}
        livestockKind={livestockKind}
        isLiveCattle={isLiveCattle}
        onClose={closeModal}
      />

      <DeleteModal
        title={`Livestock ${profile?.description ? `(${profile?.description})` : ''}`}
        description="Are you sure you want to delete this livestock?"
        onSubmit={deleteCropProfile}
        onClose={handleCloseModal}
        isOpen={isDeleteModalOpen}
      />
    </>
  )
}

export default LivestockView
