import React from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'

import Button from '../components/buttons/Button'
import { useAuth } from '../hooks/useAuth'

export default function UnauthorizedPage() {
  const navigate = useNavigate()
  const auth = useAuth()

  return (
    <>
      <Helmet>
        <title>Unauthorized</title>
      </Helmet>
      <div className="flex h-full flex-col items-center justify-center gap-2 text-lg">
        Unauthorized
        {auth ? (
          <div className="flex space-x-2">
            <Button onClick={() => navigate(`/`)} label="Go Home" />
            <Button onClick={() => navigate('/auth/logout')} label="Logout" />
          </div>
        ) : (
          <Button onClick={() => navigate(`/login`)} label="Login" />
        )}
      </div>
    </>
  )
}
