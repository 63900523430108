import React from 'react'

const defaultClass = 'inline-flex fs-15 items-center border-none text-sm ease-in-out duration-150'
const fetchingDataClass =
  'mt-20 mb-20 px-4 py-2 font-semibold transition leading-10 cursor-text text-lg text-emerald-600'
const svgDefaultClass = 'animate-spin mr-2 h-6 w-6 fill-blue-600 text-gray-200 dark:text-white'
const svgFetchingDataClass = 'animate-spin mr-2 h-7 w-7 text-emerald-600'

function Loader({ message, variant }) {
  return (
    <div className="loader flex flow-root sm:text-center">
      <div className="flex items-center justify-center">
        <div
          className={`${defaultClass} ${
            variant === 'fetchData' ? fetchingDataClass : 'text-white'
          }`}
        >
          <svg
            role="status"
            className={`${variant === 'fetchData' ? svgFetchingDataClass : svgDefaultClass}`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth={`${variant === 'fetchData' ? '4' : '2'}`}
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
          <span
            className={`${variant === 'fetchData' ? 'text-emerald text-lg' : 'text-sm text-white'}`}
          >
            {message}
          </span>
        </div>
      </div>
    </div>
  )
}

export default Loader
