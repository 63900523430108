import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import AlertBox from '../../components/AlertBox'
import Button from '../../components/buttons/Button'
import Input from '../../components/inputs/Input'

import UPDATE_PASSWORD from '../../gql/mutations/updatePassword'

const initialValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: ''
}

const validationSchema = Yup.object({
  currentPassword: Yup.string().required('Old Password is required'),
  newPassword: Yup.string().required('New Password is required'),
  confirmPassword: Yup.string()
    .required('Confirm new password is required')
    .oneOf([Yup.ref('newPassword')], 'Confirm new password do not match with new password.')
})

export default function ChangePassword() {
  const [updatePassword, { error }] = useMutation(UPDATE_PASSWORD)
  const [updatePasswordStatus, setUpdatePasswordStatus] = useState(false)

  const onSubmit = async (values, { resetForm }) => {
    setUpdatePasswordStatus(false)

    await updatePassword({
      variables: {
        input: {
          currentPassword: values?.currentPassword,
          newPassword: values?.newPassword
        }
      }
    })
    setUpdatePasswordStatus(true)
    resetForm()
  }

  return (
    <div className="flex justify-center overflow-hidden">
      <div className="w-full rounded-lg sm:max-w-md">
        <h1 className="text-xl font-bold text-gray-900">Change Password</h1>
        <p className="text-gray-500">
          Your new password must be different from currently used password.
        </p>
        <div className="mt-8">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              {error && (
                <AlertBox message="You have entered an incorrect current password. Please try again." />
              )}
              {updatePasswordStatus && (
                <AlertBox type="success" message="Your Password has been successfully changed." />
              )}
              <div className="mb-6">
                <Input label="Current Password" type="password" name="currentPassword" />
              </div>
              <div className="mb-6">
                <Input label="New Password" type="password" name="newPassword" />
              </div>
              <div className="mb-6">
                <Input label="Confirm New Password" type="password" name="confirmPassword" />
              </div>
              <div className="mt-6">
                <Button type="submit" label="Change Password" color="info" />
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  )
}
