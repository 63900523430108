import { gql } from '@apollo/client'

const MODIFY_DEFAULT_BASIS_VALUATION = gql`
  mutation modifyDefaultBasisValuation($input: DefaultBasisValuationInput!) {
    modifyDefaultBasisValuation(input: $input) {
      id
      futuresName
      futuresMonth
      basisAmount
    }
  }
`

export default MODIFY_DEFAULT_BASIS_VALUATION
