import { gql } from '@apollo/client'

const CREATE_INSURANCE = gql`
  mutation createInsurance($input: CreateInsuranceInput!) {
    createInsurance(input: $input) {
      id
      productKind
      state
      county
      dryYield
      price
      coverage
      protectionFactor
    }
  }
`

export default CREATE_INSURANCE
