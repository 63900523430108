import React from 'react'

const DISCLAIMER_TEXT = `The Sunrise team has prepared your current grain position for your review. Please review, verify, and reach out to your Sunrise ABS sales rep with any questions. Sunrise AgriBusiness Solutions LLC (Sunrise ABS), nor any of the individual employees shall be held liable for any improper or incorrect information described and or contained in this report. In no event shall Sunrise ABS be liable for any direct, indirect, incidental, special, exemplary, or consequential damages which would include but not limited to: monetary, data, grain obligations, profits, or business interruptions. We have made every attempt to ensure the report is accurate and complete.

Although the data produced and reported is believed to be reliable, accurate, adequate, complete: this disclaimer serves as a release of any and all liability of Sunrise ABS. It is fully the recipient’s responsibility to verify and check the accuracy of the report(s).`

function DisclaimerView() {
  return <p className="mt-4 mb-4 text-sm">{DISCLAIMER_TEXT}</p>
}

export default DisclaimerView
