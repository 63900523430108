import { gql } from '@apollo/client'

const CREATE_SALE_ADJUSTMENT = gql`
  mutation createSaleAdjustment($input: SaleAdjustmentInput!) {
    createSaleAdjustment(input: $input) {
      saleId
      futuresMonth
      basisAmount
      totalProduction
    }
  }
`

export default CREATE_SALE_ADJUSTMENT
