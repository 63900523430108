import * as yup from 'yup'
import dayjs from 'dayjs'
import React from 'react'
import toast from 'react-hot-toast'
import { ErrorMessage, Form, Field, Formik } from 'formik'
import { useMutation } from '@apollo/client'

import BaseModal from '../modal/BaseModal'
import Button from '../buttons/Button'
import CREATE_STORAGE from '../../gql/mutations/createStorage'
import GET_STORAGES from '../../gql/queries/getStorages'
import SignedInput from '../SignedInput'
import UPDATE_STORAGE from '../../gql/mutations/updateStorage'
import CustomDateInput from '../inputs/CustomDateInput'
import AutocompleteBox from '../AutocompleteBox/AutocompleteBox'

const storageSchema = yup.object().shape({
  profileId: yup.string().ensure().required('Required'),
  createdAt: yup.date().required('Required'),
  updatedAt: yup
    .date()
    .min(yup.ref('createdAt'), 'Date updated should be after date entered')
    .required('Required'),
  quantity: yup
    .number()
    .min(1, 'Value must be greater than or equal to 1')
    .typeError('Value must be a number')
    .test(
      'maxDecimalPlaces',
      'Value can have maximum of 4 decimal places',
      (value) => value === null || value === undefined || /^\d+(\.\d{1,4})?$/.test(value)
    )
    .required('Required')
})

function StorageModal({
  locationOptions,
  closeModal,
  id,
  intialValues,
  profile,
  profilesData,
  currentStorageValues,
  isOpen,
  type
}) {
  const updatedStorageValues = {
    clientId: id,
    profileId: profile?.id,
    quantity: currentStorageValues?.quantity,
    location: currentStorageValues?.location,
    notes: currentStorageValues?.notes,
    createdAt: dayjs(currentStorageValues?.createdAt).format('MM/DD/YYYY'),
    updatedAt: dayjs(currentStorageValues?.updatedAt).format('MM/DD/YYYY')
  }

  const [createStorage] = useMutation(CREATE_STORAGE)
  const [updateStorage] = useMutation(UPDATE_STORAGE)

  const cropsProfileData = profilesData?.profiles?.filter((item) => item?.kind === 'CROP')

  const handleSaleSubmit = async (value) => {
    if (type === 'CREATE_STORAGE') {
      toast.promise(
        createStorage({
          variables: {
            input: {
              clientId: id,
              profileId: value?.profileId,
              quantity: value?.quantity,
              location: value?.location,
              notes: value?.notes,
              createdAt: value?.createdAt,
              updatedAt: value?.updatedAt
            }
          },
          onCompleted: () => {
            closeModal()
          },
          refetchQueries: [GET_STORAGES]
        }),
        {
          loading: 'Creating the storage...',
          success: 'Storage created successfully.',
          error: 'Something went wrong while creating the storage.'
        }
      )
    }
    if (type === 'EDIT_STORAGE') {
      toast.promise(
        updateStorage({
          variables: {
            input: {
              id: currentStorageValues?.id,
              clientId: id,
              profileId: value?.profileId,
              quantity: value?.quantity,
              location: value?.location,
              notes: value?.notes,
              createdAt: value?.createdAt,
              updatedAt: value?.updatedAt
            }
          },
          onCompleted: () => {
            closeModal()
          },
          refetchQueries: [GET_STORAGES]
        }),
        {
          loading: 'Updating the storage...',
          success: 'Storage updated successfully.',
          error: 'Something went wrong while updating the storage.'
        }
      )
    }
  }

  return (
    <BaseModal isOpen={isOpen} closeModal={closeModal}>
      <div className="space-y-6">
        <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-bold leading-6 text-gray-900">
                {type === 'EDIT_STORAGE' ? 'Edit Storage' : 'Add Storage'}
              </h3>
            </div>
            <div className="md:col-span-2">
              <Formik
                enableReinitialize
                initialValues={type === 'EDIT_STORAGE' ? updatedStorageValues : intialValues}
                validationSchema={storageSchema}
                onSubmit={handleSaleSubmit}
              >
                {({ setFieldValue }) => (
                  <Form>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6">
                          <label
                            htmlFor="kind"
                            className="mb-1 block text-sm font-bold text-gray-700"
                          >
                            Commodity
                          </label>
                          <Field
                            as="select"
                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                            type="profileId"
                            id="profileId"
                            name="profileId"
                          >
                            <option disabled value="">
                              Select Commodity
                            </option>
                            {cropsProfileData?.map((item) => (
                              <option value={item?.id} key={item?.id}>
                                {item?.name}&nbsp;({item?.productionYear?.name})
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="profileId"
                            component="p"
                            className="mt-1 text-sm font-medium text-red-600"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <CustomDateInput label="Date Entered" name="createdAt" />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <CustomDateInput label="Date Updated" name="updatedAt" />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <SignedInput label="Quantity" name="quantity" type="number" unit="bu" />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="location"
                            className="
            block text-sm font-bold
            text-gray-700
          "
                          >
                            Location
                          </label>
                          <AutocompleteBox
                            storageModalType={type}
                            initialValues={updatedStorageValues}
                            locationOptions={locationOptions}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                        <div className="sm:col-span-6">
                          <label htmlFor="about" className="block text-sm font-bold text-gray-700">
                            Notes
                          </label>
                          <div className="mt-1">
                            <Field
                              as="textarea"
                              rows={3}
                              type="notes"
                              id="notes"
                              name="notes"
                              defaultValue=""
                              className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                            />
                          </div>
                          <p className="mt-2 text-sm text-gray-500">
                            Any notes related to this storage
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-6 flex justify-end gap-x-2">
                      <Button type="button" color="neutral" label="Cancel" onClick={closeModal} />
                      <Button
                        type="submit"
                        color="info"
                        label={type === 'EDIT_STORAGE' ? ' Save' : 'Add Storage'}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  )
}

export default StorageModal
