import { useQuery } from '@apollo/client'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import GET_PRODUCTION_YEARS from '../../gql/queries/getProductionYears'
import { updateUrl } from '../../utils/helper'
import Button from '../buttons/Button'
import BaseModal from '../modal/BaseModal'

function ChangeProductionYearModal({ isOpen, closeModal }) {
  const { id: clientId, productionYearId: currentProductionYear } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const { data: productionYearsData } = useQuery(GET_PRODUCTION_YEARS, {
    variables: {
      clientId
    }
  })

  const productionYearsList = productionYearsData?.getProductionYears

  const initialValues = {
    productionYear: currentProductionYear || ''
  }

  const onSubmit = (formValues) => {
    const newUrl = updateUrl(clientId, formValues.productionYear, location.pathname)

    navigate(newUrl)
    closeModal()
  }

  return (
    <BaseModal isOpen={isOpen} closeModal={closeModal}>
      <div className="h-24v w-72">
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {() => (
            <Form>
              <label
                htmlFor="productionYear"
                className="
            block text-sm font-bold
            text-gray-700
          "
              >
                Change a production year
              </label>
              <Field
                as="select"
                name="productionYear"
                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
              >
                {productionYearsList?.map((productionYear) => (
                  <option value={productionYear.name} key={productionYear.id}>
                    {productionYear.name}
                  </option>
                ))}
              </Field>

              <div className="mt-6 flex justify-end gap-x-2">
                <Button type="button" color="neutral" label="Cancel" onClick={closeModal} />
                <Button type="submit" color="info" label="Change" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </BaseModal>
  )
}

export default ChangeProductionYearModal
