import { gql } from '@apollo/client'

const SAVE_PROFILE_PROFIT_GOAL = gql`
  mutation saveProfileProfitGoal($input: ProfitGoalInput!) {
    saveProfileProfitGoal(input: $input) {
      id
      profileId
      drylandProfitTarget
      irrigatedProfitTarget
      livestockProfitTarget
    }
  }
`

export default SAVE_PROFILE_PROFIT_GOAL
