import { gql } from '@apollo/client'

const CREATE_STORAGE = gql`
  mutation createStorage($input: CreateStorageInput!) {
    createStorage(input: $input) {
      id
      clientId
      profileId
      quantity
      location
      notes
      createdAt
      updatedAt
    }
  }
`

export default CREATE_STORAGE
