import React, { useEffect } from 'react'

import Loader from '../../components/Loader'
import { logout } from '../../client/errorLink'

export default function LogoutPage() {
  useEffect(() => {
    logout()
  }, [])
  return <Loader message="Logging out..." variant="fetchData" />
}
