import { gql } from '@apollo/client'

const GET_PROFILE_SUMMARIES = gql`
  query getProfileSummaries($clientId: String!, $productionYear: Float!) {
    getProfileSummaries(clientId: $clientId, productionYear: $productionYear) {
      profileId
      profileName
      production
      isDryland
      isIrrigated
      crop {
        id
        name
        colorCode
        unit
        tradingMonths
        futuresName
      }
      sold {
        quantity
        price
        value
      }
      cash {
        quantity
        price
        value
      }
      basis {
        quantity
        price
        value
      }
      hta {
        quantity
        price
        value
      }
      npe {
        quantity
        price
        value
      }
      unsold {
        quantity
        price
        value
      }
      total {
        quantity
        price
        value
      }
    }
  }
`

export default GET_PROFILE_SUMMARIES
