export const barChartInitialData = {
  labels: [],
  datasets: [
    {
      label: 'Current',
      backgroundColor: 'rgba(75,192,192,0.2)',
      borderColor: 'rgba(75,192,192,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(75,192,192,0.7)',
      data: []
    },
    {
      label: 'Budget',
      backgroundColor: 'rgba(153,102,255,0.2)',
      borderColor: 'rgba(153,102,255,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(153,102,255,0.7)',
      data: []
    }
  ]
}

export const barChartOptions = {
  responsive: true,
  legend: {
    display: true,
    position: 'bottom'
  },
  type: 'bar'
}

export const pieChartData = {
  labels: ['Unprotected', 'Protected'],
  datasets: [
    {
      label: 'Percentage of unprotected',
      data: [60, 40],
      backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
      borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
      borderWidth: 1
    }
  ]
}
