import { gql } from '@apollo/client'

const GET_BASIS_VALUATIONS = gql`
  query getBasisValuations($input: GetBasisValuationsInput!) {
    getBasisValuations(input: $input) {
      id
      futuresMonth
      totalProduction
      basisAmount
      profileId
    }
  }
`

export default GET_BASIS_VALUATIONS
