import React, { useState } from 'react'
import { ArrowLeftIcon } from '@heroicons/react/solid'
import { toast } from 'react-hot-toast'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import AddUSDAPriceModal from '../../components/modals/AddUSDAPriceModal'
import Button from '../../components/buttons/Button'
import DELETE_INSURANCE_PRICING from '../../gql/mutations/deleteInsurancePricing'
import DeleteModal from '../../components/modals/DeleteModal'
import EmptyState from '../../components/EmptyState'
import GET_CROPS from '../../gql/queries/getCrops'
import GET_INSURANCE_PRICINGS from '../../gql/queries/getInsurancePricings'
import Header from '../../components/Header'
import Loader from '../../components/Loader'
import Pagination from '../../components/Pagination'
import Table from '../../components/tables/Table'
import TableDataCell from '../../components/tables/TableDataCell'
import TableRow from '../../components/tables/TableRow'
import { USER_ROUTES } from '../../constants/routes'

const columns = ['Crop', 'Production year', 'Price', 'Actions']

export default function USDAPricesPage() {
  const [isOpen, setIsOpen] = useState(false)
  const [mode, setMode] = useState(undefined)
  const [currentPage, setCurrentPage] = useState(1)
  const [item, setItem] = useState(undefined)
  const [createUserModal, setCreateUserModal] = useState(undefined)
  const [postsPerPage] = useState(10)
  const { data: cropsData, loading } = useQuery(GET_CROPS)
  const { data: pricingsData, loading: pricingLoading } = useQuery(GET_INSURANCE_PRICINGS)

  const navigate = useNavigate()
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const cropsList = pricingsData?.getInsurancePricings.slice(indexOfFirstPost, indexOfLastPost)
  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  const [deleteInsurancePricing] = useMutation(DELETE_INSURANCE_PRICING)
  const handleCloseModal = () => {
    setCreateUserModal(undefined)
  }

  const onDelete = () => {
    toast.promise(
      deleteInsurancePricing({
        variables: {
          input: {
            id: item?.id
          }
        },
        refetchQueries: [GET_INSURANCE_PRICINGS]
      }),
      {
        loading: 'Deleting the price...',
        success: 'Price deleted successfully.',
        error: 'Error while deleting the price.'
      }
    )
  }

  return (
    <>
      <Header>USDA Prices</Header>
      {loading || pricingLoading ? (
        <Loader />
      ) : (
        <>
          <div className="mb-8 flex gap-x-2">
            <Button
              label="Back to Admin"
              classes="mr-4 items-center"
              icon={<ArrowLeftIcon className="h-5 w-5" aria-hidden="true" />}
              onClick={() => navigate(USER_ROUTES.ADMIN)}
            />
            <Button
              label="Add Price"
              onClick={() => {
                setIsOpen(true)
                setMode('ADD')
              }}
            />
          </div>
          {pricingsData?.getInsurancePricings?.length < 1 ? (
            <EmptyState disabled header="No prices" message="Get started by adding a price." />
          ) : (
            <>
              <Table columns={columns}>
                {cropsList?.map((pricing) => (
                  <TableRow key={pricing?.id}>
                    <TableDataCell type="title">{pricing?.crop?.name}</TableDataCell>
                    <TableDataCell>
                      <p className="mr-10">{pricing?.productionYear}</p>{' '}
                    </TableDataCell>
                    <TableDataCell>${pricing?.price}</TableDataCell>
                    <TableDataCell>
                      <Button
                        type="button"
                        color="action"
                        actionLabel="Edit"
                        onClick={() => {
                          setIsOpen(true)
                          setMode('EDIT')
                          setItem(pricing)
                        }}
                      />
                      <Button
                        type="button"
                        color="action"
                        actionLabel="Delete"
                        onClick={() => {
                          setCreateUserModal('DELETE_INSURANCE_PRICING')
                          setItem(pricing)
                        }}
                      />{' '}
                    </TableDataCell>
                  </TableRow>
                ))}
              </Table>
              {cropsList?.length > 0 && (
                <Pagination
                  data={cropsList}
                  postsPerPage={postsPerPage}
                  totalPosts={pricingsData?.getInsurancePricings?.length}
                  paginate={paginate}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
            </>
          )}
          <AddUSDAPriceModal
            isOpen={isOpen}
            closeModal={() => setIsOpen(false)}
            cropsData={cropsData}
            mode={mode}
            item={item}
          />
          {createUserModal === 'DELETE_INSURANCE_PRICING' && (
            <DeleteModal
              title={`${item?.crop?.name} (${item?.productionYear})`}
              description="Are you sure you want to delete this price?"
              onSubmit={onDelete}
              onClose={handleCloseModal}
              isOpen={createUserModal === 'DELETE_INSURANCE_PRICING'}
            />
          )}
        </>
      )}
    </>
  )
}
