import React, { Fragment } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

import { useAutocompleteBox } from './useAutocompleteBox'

function AutocompleteBox({ locationOptions, setFieldValue, storageModalType, initialValues }) {
  const { selected, setSelected, query, setQuery, filteredLocationOptions } = useAutocompleteBox({
    locationOptions,
    storageModalType,
    initialValues,
    setFieldValue
  })

  return (
    <Combobox value={selected} onChange={setSelected}>
      <div className="relative mt-1">
        <Combobox.Input
          className="
            block w-full appearance-none rounded-md border border-gray-300 py-2 placeholder-gray-400 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
          displayValue={(location) => location}
          onChange={(event) => setQuery(event.target.value)}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {query.length > 0 && (
              <Combobox.Option
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-10 pr-4',
                    active ? 'bg-teal-600 text-white' : 'text-gray-900'
                  )
                }
                value={query}
              >
                Add &quot;{query}&quot;
              </Combobox.Option>
            )}
            {filteredLocationOptions?.map((location) => (
              <Combobox.Option
                key={location}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-10 pr-4',
                    active ? 'bg-teal-600 text-white' : 'text-gray-900'
                  )
                }
                value={location}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={classNames(
                        'block truncate',
                        selected ? 'font-medium' : 'font-normal'
                      )}
                    >
                      {location}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 left-0 flex items-center pl-3',
                          active ? 'text-white' : 'text-teal-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}

export default AutocompleteBox
