/* eslint-disable no-case-declarations */
export const filterReducer = (state, action) => {
  const { allCashSalesData } = state
  const {
    buyer,
    commodity,
    type,
    futMonths,
    deliveryStartDate,
    deliveryEndDate,
    deliveredStatus,
    saleDate,
    searchContracts
  } = state.filters

  switch (action.type) {
    case 'LOAD_CASH_SALE_DATA':
      return {
        ...state,
        allCashSalesData: [...action.payload],
        filteredCashSalesData: [...action.payload]
      }

    case 'UPDATE_SELECTED_FILTER_VALUE':
      const { name, value } = action.payload

      return {
        ...state,
        filters: {
          ...state.filters,
          [name]: value
        }
      }
    case 'CLEAR_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          buyer: 'any',
          commodity: 'any',
          type: 'any',
          futMonths: 'any',
          deliveryStartDate: 'any',
          deliveryEndDate: 'any',
          deliveredStatus: 'any',
          saleDate: 'any',
          searchContracts: ''
        }
      }

    case 'UPDATE_FILTER_DATA':
      let tempFilterData = [...allCashSalesData]

      if (buyer !== 'any') {
        tempFilterData = tempFilterData.filter((data) => data.buyer.name.includes(buyer))
      }

      if (commodity !== 'any') {
        tempFilterData = tempFilterData.filter((data) => data.profile.id.includes(commodity))
      }

      if (type !== 'any') {
        tempFilterData = tempFilterData.filter((data) => data.subkind.includes(type))
      }

      if (futMonths !== 'any') {
        tempFilterData = tempFilterData.filter((data) => data.futuresMonth?.includes(futMonths))
      }

      if (deliveryStartDate && deliveryStartDate !== 'any') {
        tempFilterData = tempFilterData.filter((data) =>
          data.deliveryStartDate?.includes(deliveryStartDate)
        )
      }

      if (deliveryEndDate && deliveryEndDate !== 'any') {
        tempFilterData = tempFilterData.filter((data) =>
          data.deliveryEndDate?.includes(deliveryEndDate)
        )
      }

      if (deliveredStatus && deliveredStatus !== 'any') {
        if (deliveredStatus === 'yes') {
          tempFilterData = tempFilterData.filter((data) => data.delivered === true)
        } else {
          tempFilterData = tempFilterData.filter((data) => data.delivered !== true)
        }
      }

      if (saleDate && saleDate !== 'any') {
        tempFilterData = tempFilterData.filter((data) => data.saleDate?.includes(saleDate))
      }

      if (searchContracts) {
        tempFilterData = tempFilterData.filter((data) =>
          data?.referenceNumber?.includes(searchContracts)
        )
      }

      return {
        ...state,
        filteredCashSalesData: tempFilterData
      }

    default:
      return state
  }
}
