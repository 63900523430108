import React from 'react'

import ReportSubTitle from '../../reportPrimitives/ReportSubtitle'
import ReportTable from '../../reportPrimitives/ReportTable'
import { renderMoney, renderNumber, renderPrice } from '../../../utils/helper'

const revenueSummaryColumns = [
  'Profile',
  'Commodity',
  '% Hedged',
  'Target Price',
  'Current Revenue',
  'Target Revenue',
  'Difference'
]

function CropRevenueView({ groupedRevenueData }) {
  return (
    <>
      <ReportSubTitle break title="REVENUE SUMMARY" />
      {groupedRevenueData?.map((item) => (
        <ReportTable
          key={item.productionYear}
          title={<>{item.productionYear} Crops Revenue</>}
          columns={revenueSummaryColumns}
          rows={item.data.flatMap(
            (crop) =>
              crop.reports.map((report) => {
                const hedged =
                  ((report.profilesWithRevenue?.soldQuantity.reduce(
                    (acc, sold) =>
                      acc +
                      (['HTA', 'BASIS', 'CASH_SALE'].includes(sold.subkind) ? sold.quantity : 0),
                    0
                  ) || 0) /
                    (report.drylandAcres * report.drylandYield +
                      report.irrigatedAcres * report.irrigatedYield || 1)) *
                  100

                const currentRevenue =
                  report.profilesWithRevenue?.soldQuantity.reduce((acc, soldQuantity) => {
                    let amount
                    if (soldQuantity.subkind === 'CASH_SALE') {
                      amount = soldQuantity.amount
                        ? soldQuantity.amount - soldQuantity.feeAmount
                        : soldQuantity.basisAmount +
                            soldQuantity.futuresAmount -
                            soldQuantity.feeAmount || 0
                      return acc + amount * soldQuantity.quantity
                    }
                    if (soldQuantity.subkind === 'BASIS') {
                      amount =
                        soldQuantity.basisAmount +
                          soldQuantity.futuresAmount -
                          soldQuantity.feeAmount || 0
                      return acc + amount * soldQuantity.quantity
                    }
                    amount = soldQuantity.futuresAmount - soldQuantity.feeAmount || 0
                    return acc + amount * soldQuantity.quantity
                  }, 0) || 0

                const targetRevenue =
                  report.profilesWithRevenue?.soldQuantity.reduce(
                    (acc, soldQuantity) =>
                      acc + report.profilesWithRevenue.targetPrice * soldQuantity.quantity,
                    0
                  ) || 0

                const difference = currentRevenue - targetRevenue
                const differenceFormat = (
                  <>
                    {difference > 0 ? '+' : ''}
                    {renderMoney(difference)}
                  </>
                )

                return [
                  <>
                    {report.crop.name}&nbsp;
                    {report.description ? `(${report.description})` : null}
                  </>,
                  report.crop.name,
                  <>{renderNumber(hedged)}%</>,
                  renderPrice(report.profilesWithRevenue.targetPrice),
                  renderMoney(currentRevenue),
                  renderMoney(targetRevenue),
                  <span className={difference >= 0 ? 'text-green-500' : 'text-red-500'}>
                    {differenceFormat}
                  </span>
                ]
              })
            /* .concat([
                [
                  '',
                  crop.cropName,
                  <>
                    $
                    {renderNumber(
                      crop.reports.reduce((acc, report) => {
                        const soldQuantityTotal = report.profilesWithRevenue.soldQuantity.reduce(
                          (total, soldQuantity) => total + soldQuantity.quantity,
                          0
                        )
                        const revenueTotal = report.profilesWithRevenue.soldQuantity.reduce(
                          (acc, soldQuantity) => {
                            let amount
                            if (soldQuantity.subkind === 'CASH_SALE') {
                              amount = soldQuantity.amount
                                ? soldQuantity.amount - soldQuantity.feeAmount
                                : (soldQuantity.basisAmount || 0) +
                                  (soldQuantity.futuresAmount || 0) -
                                  (soldQuantity.feeAmount || 0)
                              return acc + amount
                            }
                            if (soldQuantity.subkind === 'BASIS') {
                              amount =
                                (soldQuantity.basisAmount || 0) +
                                (soldQuantity.futuresAmount || 0) -
                                (soldQuantity.feeAmount || 0)
                              return acc + amount
                            }
                            amount =
                              (soldQuantity.futuresAmount || 0) - (soldQuantity.feeAmount || 0)
                            return acc + amount
                          },
                          0
                        )
                        return acc + soldQuantityTotal * revenueTotal
                      }, 0)
                    )}
                  </>,
                  <>
                    {renderNumber(
                      ((crop.reports.reduce((acc, report) => {
                        const totalQuantity = report.profilesWithRevenue.soldQuantity.reduce(
                          (acc, sold) =>
                            acc +
                            (sold.subkind === 'HTA' || sold.subkind === 'BASIS'
                              ? sold.quantity
                              : 0),
                          0
                        )
                        return acc + totalQuantity
                      }, 0) || 0) /
                        (crop.reports.reduce((acc, report) => {
                          const production =
                            report.drylandAcres * report.drylandYield +
                            report.irrigatedAcres * report.irrigatedYield
                          return acc + production
                        }, 0) || 1)) *
                        100
                    )}
                    %
                  </>
                ]
              ]) */
          )}
        />
      ))}
    </>
  )
}

export default CropRevenueView
