import React from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import toast from 'react-hot-toast'

import BaseModal from '../modal/BaseModal'
import Button from '../buttons/Button'
import CREATE_EXPENSE from '../../gql/mutations/createExpense'
import Input from '../inputs/Input'
import GET_EXPENSES from '../../gql/queries/getExpenses'
import UPDATE_EXPENSE from '../../gql/mutations/updateExpense'

function AddExpenseModal({
  clientId,
  closeModal,
  expense,
  isOpen,
  initialValue,
  kind,
  length,
  type
}) {
  const [createExpense] = useMutation(CREATE_EXPENSE)
  const [updateExpense] = useMutation(UPDATE_EXPENSE)

  const initialValues = {
    expense: initialValue || ''
  }

  const createCustomExpense = (value) => {
    toast.promise(
      createExpense({
        variables: {
          input: {
            name: value.expense,
            kind,
            clientId,
            position: length + 1
          }
        },
        refetchQueries: [GET_EXPENSES]
      }),
      {
        loading: 'Creating the custom expense...',
        success: 'Custom expense created successfully.',
        error: 'Error while creating the custom expense.'
      }
    )
  }

  const updateCustomExpense = (value) => {
    toast.promise(
      updateExpense({
        variables: {
          input: {
            id: expense.id,
            name: value.expense,
            kind,
            clientId: expense ? expense.clientId : clientId,
            position: expense.position
          }
        },
        refetchQueries: [GET_EXPENSES]
      }),
      {
        loading: 'Updating the custom expense...',
        success: 'Custom expense updated successfully.',
        error: 'Error while updating the custom expense.'
      }
    )
  }

  const validationSchema = Yup.object({
    expense: Yup.string().required('Required!')
  })

  return (
    <BaseModal isOpen={isOpen} closeModal={closeModal}>
      <div className="h-24v w-72">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(value) => {
            type === 'ADD' ? createCustomExpense(value) : updateCustomExpense(value)
            closeModal()
          }}
        >
          {() => (
            <Form>
              <Input
                label={type === 'EDIT' ? 'Edit an expense' : 'Add Expense'}
                type="text"
                name="expense"
              />

              <div className="mt-6 flex justify-end">
                <Button type="button" color="neutral" label="Cancel" onClick={closeModal} />
                <Button type="submit" color="info" label="Save" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </BaseModal>
  )
}

export default AddExpenseModal
