import React, { useCallback } from 'react'
import { debounce } from 'lodash'

const DELAY = 200

function SearchInput({ onChange, placeholder, classNames = '' }) {
  function handleChange(event) {
    debouncedSearch(event.target.value)
  }

  const debouncedSearch = useCallback(
    debounce((string) => onChange(string), DELAY),
    []
  )

  return (
    <div className={`${classNames} relative float-left flex w-4/12 items-center`}>
      <input
        className="w-full rounded border border-gray-300 px-4 py-3 pr-9 text-left text-sm leading-none text-gray-600 outline-none"
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
      />
      <svg
        className="absolute right-3 z-10 cursor-pointer"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z"
          stroke="#4B5563"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 21L15 15"
          stroke="#4B5563"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export default SearchInput
