import React from 'react'
import Select from 'react-select'

function MultiSelect({ fieldName, setValue, options, placeholder = '' }) {
  const handleChange = (selectedOptions) => {
    const values = selectedOptions.map(({ value }) => value)
    setValue(fieldName, values)
  }

  const customStyles = {
    control: (defaultStyles, state) => ({
      ...defaultStyles,
      padding: '0',
      borderColor: '#D1D5DB',
      borderWidth: '1px',
      borderRadius: '0.375rem',
      marginTop: '0.25rem',
      border: state.isFocused && '2.3px solid #10B981',
      boxShadow: state.isFocused && 'none',
      '&:hover': {
        boxShadow: 'none'
      }
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      padding: '0'
    }),
    valueContainer: (defaultStyles) => ({
      ...defaultStyles,
      padding: '0'
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      padding: '8px 12px'
    }),
    dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      padding: '7px'
    }),
    indicatorSeparator: (defaultStyles) => ({
      ...defaultStyles,
      display: 'none'
    })
  }

  return (
    <div>
      <Select
        isSearchable
        options={options}
        onChange={handleChange}
        placeholder={placeholder}
        isMulti
        styles={customStyles}
        className="sm:text-sm"
      />
    </div>
  )
}

export default MultiSelect
