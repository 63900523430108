import { gql } from '@apollo/client'

const UPDATE_INSURANCE = gql`
  mutation updateInsurance($input: UpdateInsuranceInput!) {
    updateInsurance(input: $input) {
      id
      productKind
      state
      county
      dryYield
      price
      coverage
      protectionFactor
    }
  }
`

export default UPDATE_INSURANCE
