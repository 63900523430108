import React from 'react'
import { Dialog } from '@headlessui/react'
import { ExclamationCircleIcon } from '@heroicons/react/outline'

import BaseModal from '../modal/BaseModal'

function ErrorModal({ isOpen, closeModal, message, title }) {
  return (
    <BaseModal isOpen={isOpen} closeModal={closeModal}>
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center">
          <ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Dialog.Title as="h3" className="text-center text-lg font-medium leading-6 text-gray-900">
            {title}
          </Dialog.Title>
          <div className="mt-2 text-center">
            <p className="text-sm text-gray-500">{message || 'Something went wrong'}</p>
          </div>
        </div>
      </div>
      <div className="mt-5 text-center sm:mt-6">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={closeModal}
        >
          Go back
        </button>
      </div>
    </BaseModal>
  )
}

export default ErrorModal
