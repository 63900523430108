import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'

import Button from '../../components/buttons/Button'
import Input from '../../components/inputs/Input'
import UPDATE_EMAIL_ADDRESS from '../../gql/mutations/updateEmailAddress'
import AlertBox from '../../components/AlertBox'

const initialValues = {
  currentPassword: '',
  newEmailAddress: ''
}

const validationSchema = Yup.object({
  currentPassword: Yup.string().required('Current Password is required'),
  newEmailAddress: Yup.string()
    .email('Invalid email format')
    .required('New Email Address is required')
})

export default function ChangeEmailAddressPage() {
  const [updateEmailAddress, { loading, error }] = useMutation(UPDATE_EMAIL_ADDRESS)
  const [newEmailAddress, setNewEmailAddress] = useState('')
  const [updateNewEmailAddressStatus, setUpdateNewEmailAddressStatus] = useState(false)

  const onSubmit = async (values, { resetForm }) => {
    setUpdateNewEmailAddressStatus(false)

    await updateEmailAddress({
      variables: {
        input: {
          currentPassword: values?.currentPassword,
          newEmailAddress: values?.newEmailAddress
        }
      }
    })

    setNewEmailAddress(values?.newEmailAddress)
    setUpdateNewEmailAddressStatus(true)
    resetForm()
  }

  return (
    <div className="flex justify-center overflow-hidden">
      <div className="w-full rounded-lg sm:max-w-md">
        <h1 className="text-xl font-bold text-gray-900">Change Email Address</h1>
        <div className="mt-8">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              {error && (
                <AlertBox message="You have entered an incorrect current password or your requested new emaill address is your current email address. Please try again." />
              )}
              {updateNewEmailAddressStatus && (
                <AlertBox
                  type="success"
                  message={`A confirmation link has been sent to: ${newEmailAddress}. It might take a few minutes to appear in your inbox.`}
                />
              )}
              <div className="mb-6">
                <Input label="Current Password" type="password" name="currentPassword" />
              </div>
              <div className="mb-6">
                <Input label="New Email Address" type="email" name="newEmailAddress" />
              </div>
              <div className="mb-6">
                <Button type="submit" label="Change Email Address" color="info" loading={loading} />
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  )
}
