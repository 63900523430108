/* eslint-disable no-unused-vars */
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { groupBy, sortBy } from 'lodash'

import Button from '../../buttons/Button'
import SaleModal from '../../modals/SaleModal'
import DELETE_STORAGE from '../../../gql/mutations/deleteStorage'
import DeleteModal from '../../modals/DeleteModal'
import EmptyState from '../../EmptyState'
import GET_CROPS from '../../../gql/queries/getCrops'
import GET_STORAGES from '../../../gql/queries/getStorages'
import PROFILES from '../../../gql/queries/Profiles'
import StorageModal from '../../modals/StorageModal'
import SubTitle from '../../typography/SubTitle'
import TableWrapper from '../../wrappers/TableWrapper'
import ViewWrapper from '../../wrappers/ViewWrapper'
import SubTitleWrapper from '../../wrappers/SubTitleWrapper'
import { renderQuantity, sortHandler } from '../../../utils/helper.js'
import downArrow from '../../../assets/downArrow.png'

const storageInitialValues = {
  clientId: undefined,
  profileId: '',
  quantity: undefined,
  location: undefined,
  notes: undefined,
  createdAt: undefined,
  updatedAt: undefined
}

const saleIntialValues = {
  amount: undefined,
  basisAmount: undefined,
  buyerId: '',
  clientId: undefined,
  deliveryEndDate: undefined,
  deliveryStartDate: undefined,
  feeAmount: undefined,
  feeMode: undefined,
  futuresAmount: undefined,
  futuresMonth: undefined,
  kind: undefined,
  notes: undefined,
  orderExpiryDate: undefined,
  profileId: '',
  quantity: undefined,
  delivered: undefined,
  referenceNumber: undefined,
  unloadNumber: undefined,
  saleDate: '',
  subkind: '',
  unitWeight: undefined
}

function StoragesView({ buyers }) {
  const { id, productionYearId } = useParams()
  const name = Number(productionYearId)

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isStorageModalOpen, setIsStorageModalOpen] = useState(false)
  const [profile, setProfile] = useState({})
  const [storage, setStorage] = useState({})
  const [saleValues, setSaleValues] = useState(saleIntialValues)
  const [sortOrder, setSortOrder] = useState()
  const [sortKey, setSortKey] = useState()

  const { data: profilesData } = useQuery(PROFILES, {
    variables: {
      clientId: id,
      productionYearId: name
    },
    onCompleted: (profilesData) => {
      setProfile(profilesData?.profiles[0])
    }
  })

  const { data: storageData } = useQuery(GET_STORAGES, {
    variables: {
      clientId: id,
      productionYearId: name
    }
  })

  const { data: cropsData } = useQuery(GET_CROPS)
  const [deleteStorage] = useMutation(DELETE_STORAGE)

  const removeStorage = async () => {
    await deleteStorage({
      variables: {
        input: {
          id: storage.id
        }
      },
      refetchQueries: [GET_STORAGES]
    })
  }
  const handleSelect = (id) => {
    const selectedProfile = profilesData.profiles.find((profile) => profile.id === id)
    setProfile(selectedProfile)
    setSaleValues({ ...saleValues, profileId: selectedProfile?.id })
  }

  const groupedStorages = groupBy(
    sortBy(storageData?.getStorageProfile, (item) => item.profile?.crop?.name),
    (item) => item.profile?.crop?.name
  )

  const handleCloseSaleModal = () => {
    setIsDeleteModalOpen(false)
    setIsOpen(false)
    setProfile(profilesData?.profiles[0])

    setSaleValues(saleIntialValues)
  }

  const handleCloseStorageModal = () => {
    setIsStorageModalOpen(false)
    setStorage(storageInitialValues)
  }

  return (
    <>
      <ViewWrapper>
        <SubTitleWrapper
          title={<SubTitle color="gray">Storages</SubTitle>}
          button={
            <Button
              type="button"
              color="info"
              label="Add Storage"
              onClick={() => {
                setIsStorageModalOpen(true)
              }}
            />
          }
        />

        {storageData?.getStorageProfile?.length > 0 ? (
          <div className="-my-2 min-w-full overflow-x-auto">
            <div className="inline-block min-w-full py-2 px-0.5 align-middle">
              <TableWrapper
                columns={[
                  <div
                    className="flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      setSortKey('profile.crop.name')
                      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                    }}
                    aria-hidden="true"
                  >
                    <span>Commodity</span>
                    {sortKey && sortKey === 'profile.crop.name' && (
                      <div
                        className="w-[7px]"
                        style={{
                          rotate: sortOrder === 'asc' ? '180deg' : '0deg'
                        }}
                      >
                        <img src={downArrow} alt="" height={7} width={7} />
                      </div>
                    )}
                  </div>,
                  <div
                    className="flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      setSortKey('createdAt')
                      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                    }}
                    aria-hidden="true"
                  >
                    <span>Date Entered</span>
                    {sortKey && sortKey === 'createdAt' && (
                      <div
                        className="w-[7px]"
                        style={{
                          rotate: sortOrder === 'asc' ? '180deg' : '0deg'
                        }}
                      >
                        <img src={downArrow} alt="" height={7} width={7} />
                      </div>
                    )}
                  </div>,
                  <div
                    className="flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      setSortKey('updatedAt')
                      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                    }}
                    aria-hidden="true"
                  >
                    <span>Date Updated</span>
                    {sortKey && sortKey === 'updatedAt' && (
                      <div
                        className="w-[7px]"
                        style={{
                          rotate: sortOrder === 'asc' ? '180deg' : '0deg'
                        }}
                      >
                        <img src={downArrow} alt="" height={7} width={7} />
                      </div>
                    )}
                  </div>,
                  <div
                    className="flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      setSortKey('quantity')
                      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                    }}
                    aria-hidden="true"
                  >
                    <span>Quantity</span>
                    {sortKey && sortKey === 'quantity' && (
                      <div
                        className="w-[7px]"
                        style={{
                          rotate: sortOrder === 'asc' ? '180deg' : '0deg'
                        }}
                      >
                        <img src={downArrow} alt="" height={7} width={7} />
                      </div>
                    )}
                  </div>,
                  <div
                    className="flex cursor-pointer items-center gap-2"
                    onClick={() => {
                      setSortKey('location')
                      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                    }}
                    aria-hidden="true"
                  >
                    <span>Location</span>
                    {sortKey && sortKey === 'location' && (
                      <div
                        className="w-[7px]"
                        style={{
                          rotate: sortOrder === 'asc' ? '180deg' : '0deg'
                        }}
                      >
                        <img src={downArrow} alt="" height={7} width={7} />
                      </div>
                    )}
                  </div>,
                  'Notes',
                  'Actions'
                ]}
                rows={Object.values(groupedStorages).flatMap((group) =>
                  sortHandler(group, sortOrder, sortKey)
                    ?.map((item) => [
                      `${item.profile?.crop?.name} (${item.profile?.productionYear?.name})`,
                      dayjs(item?.createdAt).format('MM/DD/YYYY'),
                      dayjs(item?.updatedAt).format('MM/DD/YYYY'),
                      renderQuantity(item.quantity),
                      item.location,
                      item.notes,
                      <div>
                        <Button
                          color="action"
                          actionLabel="Convert"
                          onClick={() => {
                            setStorage(item)
                            setSaleValues({
                              amount: undefined,
                              basisAmount: undefined,
                              buyerId: '',
                              clientId: undefined,
                              deliveryEndDate: undefined,
                              deliveryStartDate: undefined,
                              feeAmount: undefined,
                              feeMode: undefined,
                              futuresAmount: undefined,
                              futuresMonth: undefined,
                              kind: undefined,
                              orderExpiryDate: undefined,
                              delivered: undefined,
                              referenceNumber: undefined,
                              unloadNumber: undefined,
                              saleDate: '',
                              subkind: '',
                              unitWeight: undefined,
                              notes: item?.notes,
                              quantity: item?.quantity,
                              profile: item?.profile,
                              profileId: item.profile.id
                            })
                            setIsOpen(true)
                          }}
                        />
                        <Button
                          color="action"
                          actionLabel="Edit"
                          onClick={() => {
                            setStorage(item)
                            handleSelect(item.profile.id)
                            setStorage({
                              ...item,
                              profileId: item.profile.id,
                              createdAt: dayjs(item?.createdAt).format('MM/DD/YYYY'),
                              updatedAt: dayjs(item?.updatedAt).format('MM/DD/YYYY')
                            })
                            setIsStorageModalOpen(true)
                          }}
                        />
                        <Button
                          color="action"
                          actionLabel="Delete"
                          onClick={() => {
                            setStorage(item)
                            setIsDeleteModalOpen(true)
                          }}
                        />
                      </div>
                    ])
                    .concat([
                      group.reduce(
                        (asc, item, index, { length }) => {
                          const sumOfQuantity = asc[3] + item.quantity
                          const format = (value) =>
                            index === length - 1 ? renderQuantity(value) : value

                          return ['Total', '', '', format(sumOfQuantity), '', '', '']
                        },
                        ['Total', 0, 0, 0, 0, 0, 0]
                      )
                    ])
                )}
              />
            </div>
          </div>
        ) : (
          <EmptyState header="No storage" message="Get started by adding a storage." disabled />
        )}
      </ViewWrapper>
      <SaleModal
        buyers={buyers}
        closeModal={handleCloseSaleModal}
        handleSelect={handleSelect}
        intialValues={saleValues}
        profile={profile}
        storage={storage}
        profiles={profilesData?.profiles}
        crops={cropsData?.getCrops}
        isOpen={isOpen}
        type="CONVERT_STORAGE"
        onCompleted={() => setIsDeleteModalOpen(true)}
        key={isOpen}
      />
      <StorageModal
        locationOptions={storageData}
        closeModal={handleCloseStorageModal}
        id={id}
        intialValues={storageInitialValues}
        profile={profile}
        profilesData={profilesData}
        currentStorageValues={storage}
        isOpen={isStorageModalOpen}
        type={storage?.id ? 'EDIT_STORAGE' : 'CREATE_STORAGE'}
      />
      <DeleteModal
        onClose={handleCloseSaleModal}
        isOpen={isDeleteModalOpen}
        description="Are you sure you want to delete this storage?"
        title={`${storage?.profile?.crop?.name} (${storage?.profile?.productionYear?.name})`}
        onSubmit={removeStorage}
      />
    </>
  )
}

export default StoragesView
