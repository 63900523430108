import React, { useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { LoaderIcon } from 'react-hot-toast'

import ACCEPT_INVITE from '../../gql/mutations/acceptInvite'
import ExternalParagraph from '../../components/typography/ExternalParagraph'
import { USER_ROUTES, VISITOR_ROUTES } from '../../constants/routes'
import { useUserContext } from '../../context/UserContext'
import AlertBox from '../../components/AlertBox'
import CURRENT_USER from '../../gql/mutations/currentUser'
import client from '../../client/client'

function AcceptInvitePage() {
  const [acceptInvite, { data, loading, error }] = useMutation(ACCEPT_INVITE)
  const { setToken } = useUserContext()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const token = searchParams.get('token')

  useEffect(() => {
    if (!token) {
      navigate(VISITOR_ROUTES.UNAUTHORIZED)
    }
  }, [token, navigate])

  useEffect(() => {
    acceptInvite({
      variables: {
        input: {
          token
        }
      },
      onCompleted: (data) => {
        const { accessToken, user } = data.acceptInvite

        client.writeQuery({
          query: CURRENT_USER,
          data: {
            currentUser: user
          }
        })

        setToken(accessToken)
        localStorage.setItem('token', accessToken)

        if (user.status === 'INVITED') {
          navigate(USER_ROUTES.SET_PASSWORD)
        } else {
          navigate(USER_ROUTES.HOME)
        }
      }
    })
  }, [acceptInvite, token, setToken, navigate])

  return (
    <>
      <Helmet>
        <title>Accept Invite</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center">
        {data?.acceptInvite?.accessToken && (
          <AlertBox type="success" message="Link verified successfully." />
        )}
        {error && <AlertBox message={error} />}
        {loading && (
          <>
            <LoaderIcon style={{ height: 40, width: 40 }} />
            <ExternalParagraph>Verifying the link...</ExternalParagraph>
          </>
        )}
      </div>
    </>
  )
}

export default AcceptInvitePage
