import { gql } from '@apollo/client'

const GET_SALE_ADJUSTMENTS = gql`
  query getSaleAdjustments($saleId: String!) {
    getSaleAdjustments(saleId: $saleId) {
      id
      saleId
      futuresMonth
      basisAmount
      totalProduction
    }
  }
`

export default GET_SALE_ADJUSTMENTS
