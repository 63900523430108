import { gql } from '@apollo/client'

const GET_USERS = gql`
  query GetUsers($kinds: [UserKind!], $search: String) {
    getUsers(kinds: $kinds, search: $search) {
      id
      kind
      firstName
      lastName
      fullName
      email
      businessName
      addressLine1
      addressLine2
      city
      state
      phone
      zipCode
      status
      reportSentAt
      notes
    }
  }
`

export default GET_USERS
