import { gql } from '@apollo/client'

const GET_INSURANCE_PRICINGS = gql`
  query GetInsurancePricings {
    getInsurancePricings {
      id
      price
      crop {
        id
        name
        unit
      }
      productionYear
    }
  }
`

export default GET_INSURANCE_PRICINGS
