import { gql } from '@apollo/client'

const GET_INCOMES = gql`
  query getIncomes($clientId: String!) {
    getIncomes(clientId: $clientId) {
      id
      clientId
      name
      position
    }
  }
`

export default GET_INCOMES
