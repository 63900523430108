import * as yup from 'yup'
import React from 'react'
import { useMutation } from '@apollo/client'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { Helmet } from 'react-helmet'

import AlertBox from '../../components/AlertBox'
import Button from '../../components/buttons/Button'
import client from '../../client/client'
import CURRENT_USER from '../../gql/mutations/currentUser'
import ExternalFieldWrapper from '../../components/wrappers/ExternalFieldWrapper'
import ExternalFormWrapper from '../../components/wrappers/ExternalFormWrapper'
import ExternalHeading from '../../components/typography/ExternalHeading'
import Input from '../../components/inputs/Input'
import LOGIN from '../../gql/mutations/login'
import roles from '../../constants/roles'
import { logout } from '../../client/errorLink'
import { USER_ROUTES, VISITOR_ROUTES } from '../../constants/routes'
import { useUserContext } from '../../context/UserContext'

export default function LoginPage() {
  const [login, { error, loading }] = useMutation(LOGIN)

  const { setToken } = useUserContext()

  const navigate = useNavigate()

  const onSubmit = async (values) => {
    login({
      variables: {
        input: {
          username: values.email,
          password: values.password
        }
      },
      onCompleted: (data) => {
        const { accessToken, user } = data.login
        client.writeQuery({
          query: CURRENT_USER,
          data: {
            currentUser: user
          }
        })

        setToken(accessToken)
        localStorage.setItem('token', accessToken)

        if (user.status === 'INVITED') {
          navigate(USER_ROUTES.SET_PASSWORD)
        } else if (user.status !== 'ACTIVE') {
          logout()
        } else if (user.kind === roles.CLIENT) {
          navigate(`/clients/${user.id}`)
        } else {
          navigate(USER_ROUTES.HOME)
        }
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <ExternalHeading>Sign in to your account</ExternalHeading>
      <ExternalFormWrapper>
        {error && <AlertBox message={error} />}
        <Formik
          initialValues={{
            email: '',
            password: ''
          }}
          validationSchema={yup.object({
            email: yup.string().email('Invalid email address').required('Email is required'),
            password: yup.string().required('Password is required')
          })}
          onSubmit={onSubmit}
        >
          <Form>
            <ExternalFieldWrapper>
              <Input label="Email" type="email" name="email" />
            </ExternalFieldWrapper>
            <ExternalFieldWrapper>
              <Input label="Password" type="password" name="password" />
            </ExternalFieldWrapper>
            <Button
              type="submit"
              classes="w-full"
              color="info"
              label="Sign in"
              loading={loading}
              message="Signing in"
            />
          </Form>
        </Formik>
        <div className="whitespace-nowrap py-4 text-sm text-base font-semibold text-gray-900">
          Forgot password?{'  '}
          <Link className="text-green-700" to={VISITOR_ROUTES.FORGOT_PASSWORD}>
            Click here
          </Link>
        </div>
      </ExternalFormWrapper>
    </>
  )
}
