import * as yup from 'yup'
import React from 'react'
import { Form, Formik } from 'formik'
import { Helmet } from 'react-helmet'
import { useMutation } from '@apollo/client'

import AlertBox from '../../components/AlertBox'
import Button from '../../components/buttons/Button'
import FORGOT_PASSWORD from '../../gql/mutations/forgotPassword'
import Input from '../../components/inputs/Input'
import ExternalHeading from '../../components/typography/ExternalHeading'
import ExternalParagraph from '../../components/typography/ExternalParagraph'
import ExternalFormWrapper from '../../components/wrappers/ExternalFormWrapper'
import ExternalFieldWrapper from '../../components/wrappers/ExternalFieldWrapper'

const validationSchema = yup.object({
  email: yup.string().email('Invalid email address').required('Email is required')
})

function ForgotPasswordPage() {
  const [forgotPassword, { data, loading, error }] = useMutation(FORGOT_PASSWORD)

  const onSubmit = async (values, { resetForm }) => {
    forgotPassword({
      variables: {
        input: {
          email: values?.email
        }
      },
      onCompleted: () => {
        resetForm()
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <ExternalHeading>Forgot Password</ExternalHeading>
      <ExternalParagraph>Please enter your email to reset your password.</ExternalParagraph>
      <ExternalFormWrapper>
        {data?.forgotPassword && (
          <AlertBox
            type="success"
            message="We have sent a verification link to your email. Please click on the link to reset your password."
          />
        )}
        {error && <AlertBox message={error} />}
        <Formik
          initialValues={{
            email: ''
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isValid }) => (
            <Form>
              <ExternalFieldWrapper>
                <Input label="Email" type="email" name="email" />
              </ExternalFieldWrapper>
              <Button
                type="submit"
                classes="w-full"
                color="info"
                label="Submit"
                loading={loading}
                disabled={!isValid || loading}
                message="Submitting"
              />
            </Form>
          )}
        </Formik>
      </ExternalFormWrapper>
    </>
  )
}

export default ForgotPasswordPage
