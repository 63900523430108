import React from 'react'

function UserAvatar({ user = {} }) {
  const { firstName, lastName, email, avatar } = user

  const getUserInitials = () => {
    if (firstName || lastName) {
      return [firstName, lastName].map((str) => str?.trim().charAt(0).toUpperCase()).join('')
    }

    return email?.split('@')[0].charAt(0)
  }

  const fullName = [firstName, lastName].filter((str) => !!str?.trim()).join(' ')

  if (avatar) return <img className="h-10 w-10 rounded-full" src={avatar} alt={fullName} />

  const userInitials = getUserInitials()

  return (
    <div className="relative inline-flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-gray-100">
      <span className="font-medium uppercase text-gray-600">{userInitials}</span>
    </div>
  )
}

export default UserAvatar
