import { useQuery } from '@apollo/client'

import GET_REPORTS from '../../gql/queries/getReports'
import GET_GLANCE_REPORTS from '../../gql/queries/getMonthlyReports'
import { reportKind } from '../../constants/reportKind'

export const useGetReports = ({ clientId, productionYears, kind }, options) => {
  const variables =
    kind === reportKind.Glance
      ? { clientId, productionYear: productionYears[0] }
      : { clientId, productionYears }

  const query = kind === reportKind.Glance ? GET_GLANCE_REPORTS : GET_REPORTS

  return useQuery(query, {
    variables,
    skip: !clientId || productionYears?.length < 1,
    ...options
  })
}
