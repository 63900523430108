/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useMemo } from 'react'
import { Field, Form, Formik } from 'formik'
import { Helmet } from 'react-helmet'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Header from '../../components/Header'
import ReportView from '../../components/views/report/ReportView'
import roles from '../../constants/roles'
import { getName } from '../../utils/helper'
import { useGetUsers } from '../../services/queries/useGetUsers'
import { USER_ROUTES } from '../../constants/routes'

function AdminReportsPage() {
  // URL Params
  const [searchParams] = useSearchParams()
  const reportsQueryParams = Object?.fromEntries([...searchParams])
  const reportsClients = reportsQueryParams?.clients
  const kind = reportsQueryParams?.kind
  const [clientId, setClientId] = useState('')

  const { data, loading: clientsLoading } = useGetUsers(roles.CLIENT)
  const clientsList = data?.getUsers

  const selectedClients = useMemo(() => {
    if (reportsClients === 'All-clients') {
      const clientDropdownOptions = clientsList?.map((client) => ({
        label: getName(client),
        value: client.id
      }))

      return clientDropdownOptions
    }
    const clientsListArray = reportsClients?.split(',')
    const clientsModifiedArr = clientsList
      ?.filter((client) => clientsListArray?.includes(client.id))
      ?.map((client) => ({
        label: getName(client),
        value: client.id
      }))

    return clientsModifiedArr
  }, [clientsList, reportsClients])

  const client = useMemo(
    () => clientsList?.find((client) => client.id === clientId),
    [clientsList, clientId]
  )

  useEffect(() => {
    setClientId(selectedClients?.[0]?.value)
  }, [selectedClients])

  const navigate = useNavigate()

  const initialValues = {
    clientId
  }

  const onSubmit = (values) => {
    setClientId(values.clientId)
  }

  return (
    <>
      <Helmet>
        <title>Admin Reports</title>
      </Helmet>

      <Header>Admin Reports</Header>
      <ReportView
        clientId={clientId}
        clientLoading={clientsLoading}
        client={client}
        kind={kind}
        onBack={() => navigate(USER_ROUTES.ADMIN)}
        clientSelector={
          <div>
            <Formik initialValues={initialValues} enableReinitialize>
              {({ setFieldValue }) => (
                <Form>
                  {selectedClients?.length > 1 && (
                    <>
                      <label
                        htmlFor="clientId"
                        className="mt-5 block text-lg font-bold text-gray-700"
                      >
                        Change client
                      </label>
                      <Field
                        as="select"
                        name="clientId"
                        className="mt-1 block w-48 rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                        onChange={(event) => {
                          setFieldValue('clientId', event.target.value)

                          onSubmit({ clientId: event.target.value })
                        }}
                      >
                        {selectedClients?.map((client) => (
                          <option value={client.value} key={client.value}>
                            {client.label}
                          </option>
                        ))}
                      </Field>
                    </>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        }
      />
    </>
  )
}

export default AdminReportsPage
