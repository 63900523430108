import { gql } from '@apollo/client'

const CREATE_PROFILE = gql`
  mutation createProfile($input: CreateProfileInput!) {
    createProfile(input: $input) {
      cropId
      isDryland
      isIrrigated
      irrigatedAcres
      irrigatedYield
      amountNotForSale
      drylandAcres
      drylandYield
      storageAvailable
      livestockKind
      cowsCount
      calvingDate
      saleDate
      bullsSaleCount
      heiferSaleCount
      headsCount
      deathsCount
      inDate
      bullsInWeight
      bullsSaleWeight
      heiferInWeight
      heiferSaleWeight
      inWeight
      outWeight
      dailyGain
    }
  }
`

export default CREATE_PROFILE
