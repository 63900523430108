import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import errorLink from './errorLink'

const cache = new InMemoryCache({})

const httpLink = new HttpLink({ uri: `${process.env.REACT_APP_API_URL}/graphql` })

const getTokenFromUrl = () => {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get('token')
}

const authLink = setContext(({ headers = {} }) => {
  const token = getTokenFromUrl() || localStorage.getItem('token') || null

  if (token) {
    headers.authorization = `Bearer ${token}`
  }

  return { headers }
})

const client = new ApolloClient({
  cache,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network'
    },
    query: {
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'none'
    }
  },
  link: from([authLink, errorLink, httpLink])
})

export { cache, getTokenFromUrl }

export default client
