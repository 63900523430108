import React from 'react'
import { Link, Outlet } from 'react-router-dom'

import coverImage from '../../assets/sunrise.jpeg'
import logoImage from '../../assets/logo-color.png'

function ExternalLayout() {
  return (
    <div className="flex min-h-full">
      {/* Left Side - Content */}
      <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <Link to="/" className="mb-6 inline-block	">
            <img className="h-12 w-auto" src={logoImage} alt="Sunrise 360" />
          </Link>
          <Outlet />
        </div>
      </div>
      {/* Right Side - Cover Image */}
      <div className="relative hidden w-0 flex-1 lg:block">
        <img className="absolute inset-0 h-full w-full object-cover" src={coverImage} alt="" />
      </div>
    </div>
  )
}

export default ExternalLayout
