import React, { useRef } from 'react'

import * as XLSX from 'xlsx'

import Button from '../buttons/Button'

export default function Table({ columns, children, exportable, headerClassName }) {
  const tableRef = useRef(null)

  const exportExcel = () => {
    const tableContent = tableRef.current.outerHTML
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = tableContent

    // Convert HTML table to XLSX file
    const workbook = XLSX.utils.table_to_book(tempDiv)
    XLSX.writeFile(workbook, `${exportable?.filename || 'Data table'}.xlsx`)
  }

  return (
    <div className="-my-2 min-w-full overflow-x-auto">
      {exportable && (
        <div className="flex justify-end">
          <Button type="button" color="neutral" label="Export to Excel" onClick={exportExcel} />
        </div>
      )}
      <div className="inline-block min-w-full py-2 px-0.5 align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table ref={tableRef} className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                {columns.map((column, index) => (
                  <th
                    scope="col"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className={[
                      index === 0
                        ? 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                        : 'px-6 py-3.5 text-right text-sm font-semibold text-gray-900',
                      headerClassName?.(column) || ''
                    ].join(' ')}
                  >
                    {column}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">{children}</tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
