import { gql } from '@apollo/client'

const UPDATE_SALE = gql`
  mutation updateSale($input: UpdateSaleInput!) {
    updateSale(input: $input) {
      id
      kind
      subkind
      profileId
      buyerId
      amount
      basisAmount
      futuresAmount
      feeAmount
      feeMode
      quantity
      orderExpiryDate
      futuresMonth
      saleDate
      delivered
      referenceNumber
      notes
    }
  }
`

export default UPDATE_SALE
