import React from 'react'

function SubTitleWrapper({ title, button }) {
  return (
    <div className="mb-3 sm:flex sm:items-center">
      <div className="sm:flex-auto">{title}</div>
      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">{button}</div>
    </div>
  )
}

export default SubTitleWrapper
