import 'yup-phone'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'

import AlertBox from '../components/AlertBox'
import ARCHIVE_USER from '../gql/mutations/archiveUser'
import Button from '../components/buttons/Button'
import DELETE_USER from '../gql/mutations/deleteUser'
import DeleteModal from '../components/modals/DeleteModal'
import GET_USERS from '../gql/queries/getUsers'
import Header from '../components/Header'
import INVITE_USER from '../gql/mutations/inviteUser'
import Loader from '../components/Loader'
import ManagerModal from '../components/modals/ManagerModal'
import Pagination from '../components/Pagination'
import RESTORE_ARCHIVED_USER from '../gql/mutations/restoreArchivedUser'
import SearchInput from '../components/SearchInput'
import TableWrapper from '../components/wrappers/TableWrapper'
import {
  getName,
  getUserActionStatus,
  getUserKind,
  getUserManagementStateLabel,
  getUserStatus,
  sortHandler
} from '../utils/helper'
import SetPasswordModal from '../components/modals/SetPasswordModal'
import downArrow from '../assets/downArrow.png'
import roles from '../constants/roles'

export default function ManagersPage() {
  const [user, setUser] = useState('')
  const [isCreate, setIsCreate] = useState(false)
  const [createusermodal, setCreateUserModal] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [deleteUser] = useMutation(DELETE_USER)
  const [inviteUser] = useMutation(INVITE_USER)
  const [archiveUser] = useMutation(ARCHIVE_USER)
  const [restoreArchivedUser] = useMutation(RESTORE_ARCHIVED_USER)
  const { error, loading, data, refetch } = useQuery(GET_USERS, {
    variables: {
      kinds: [roles.MANAGER, roles.SUPER_MANAGER],
      search: searchValue
    }
  })

  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(10)
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const currentPosts = data?.getUsers.slice(indexOfFirstPost, indexOfLastPost)
  const paginate = (pageNumber) => setCurrentPage(pageNumber)
  const [openSetPasswordModal, setOpenSetPasswordModal] = useState(false)
  const [sortOrder, setSortOrder] = useState()
  const [sortKey, setSortKey] = useState()

  useEffect(() => {
    if (data?.getUsers?.length > 0 && currentPage !== 1 && searchValue) {
      setCurrentPage(1)
    }
  }, [data, currentPage, searchValue])

  const [open, setOpen] = useState(false)

  const closeModal = () => {
    setOpen(false)
  }

  const handleCloseModal = () => {
    setCreateUserModal('[]')
  }

  const deleteManagerProfile = async () => {
    await deleteUser({
      variables: {
        input: {
          id: user?.id
        }
      },
      refetchQueries: [GET_USERS]
    })
  }

  const userAction = getUserActionStatus(user)

  const handleArchiveUser = async () => {
    toast.promise(
      archiveUser({
        variables: {
          clientId: user?.id
        },
        refetchQueries: [GET_USERS],
        onCompleted: () => refetch()
      }),
      {
        loading: "Archiving this user's account",
        success: 'Account archived successfully.',
        error: "Error while archiving this user's account"
      },
      { duration: 5000 }
    )
  }

  const handleRestoreArchiveUser = async () => {
    toast.promise(
      restoreArchivedUser({
        variables: {
          clientId: user?.id
        },
        refetchQueries: [GET_USERS]
      }),
      {
        loading: "Restoring this user's account",
        success: 'Account restored successfully.',
        error: "Error while restoring this user's account"
      }
    )
  }

  const handleUserManagement = async () => {
    let manageUser
    if (user.status === 'NOT_INVITED' || user?.status === 'INVITED') {
      manageUser = inviteUser
    }

    toast.promise(
      manageUser({
        variables: {
          input: {
            id: user?.id
          }
        }
      }),
      {
        loading: `${getUserManagementStateLabel('LOADING', userAction)} the  account...`,
        success: `Account ${getUserManagementStateLabel('SUCCESS', userAction)} successfully.`,
        error: `Error while  ${getUserManagementStateLabel('FAILED', userAction)} the account.`
      }
    )
  }

  return (
    <>
      <Helmet>
        <title>Managers</title>
      </Helmet>
      <Header>Managers</Header>
      <div className="relative flex-1">
        <div className="flex flow-root sm:flex-row sm:items-center">
          <SearchInput placeholder="Search for a manager" onChange={setSearchValue} />
          <div className="float-right mt-4 sm:mt-0 sm:ml-16">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
              onClick={() => {
                setCreateUserModal('MANAGER')
                setIsCreate(true)
                setOpen(!open)
              }}
            >
              New Manager
            </button>
          </div>
        </div>
        {loading && <Loader message="Fetching data..." variant="fetchData" />}
        {error && <AlertBox message="Oops! Something went wrong while fetching data." />}
        {!loading && !error && (
          <div className="sm:text-justify">
            <div className="mt-8 flex flex-col">
              {searchValue && !data?.getUsers.length ? (
                <AlertBox message="No results for this search." />
              ) : (
                <TableWrapper
                  columns={[
                    <div
                      className="flex cursor-pointer items-center gap-2"
                      onClick={() => {
                        setSortKey('businessName')
                        setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                      }}
                      aria-hidden="true"
                    >
                      <span>Name</span>
                      {sortKey && sortKey !== 'status' && (
                        <div
                          style={{
                            rotate: sortOrder === 'asc' && sortKey !== 'status' ? '180deg' : '0deg'
                          }}
                        >
                          <img src={downArrow} alt="" height={7} width={7} />
                        </div>
                      )}
                    </div>,
                    <div className="flex justify-start">Role</div>,
                    <div
                      className="flex cursor-pointer items-center gap-2"
                      onClick={() => {
                        setSortKey('status')
                        setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                      }}
                      aria-hidden="true"
                    >
                      <span>Status</span>
                      {sortKey && sortKey === 'status' && (
                        <div
                          style={{
                            rotate: sortOrder === 'asc' && sortKey === 'status' ? '180deg' : '0deg'
                          }}
                        >
                          <img src={downArrow} alt="" height={7} width={7} />
                        </div>
                      )}
                    </div>,
                    'Actions'
                  ]}
                  rows={sortHandler(currentPosts, sortOrder, sortKey)?.map((item) => [
                    <Link to={`/managers/${item?.id}`}>{getName(item)}</Link>,
                    <div className="flex justify-start">{getUserKind(item)}</div>,
                    <div className="flex justify-start pl-3">{getUserStatus(item)}</div>,
                    <div className="flex justify-end">
                      <Button
                        actionLabel="Edit"
                        color="action"
                        onClick={() => {
                          setUser(item)
                          setCreateUserModal('MANAGER')
                          setOpen(!open)
                        }}
                      />
                      <Button
                        actionLabel="Set Password"
                        color="action"
                        onClick={() => {
                          setUser(item)
                          setOpenSetPasswordModal(true)
                        }}
                      />
                      <Button
                        actionLabel="Delete"
                        color="action"
                        onClick={() => {
                          setUser(item)
                          setCreateUserModal('DELETE')
                        }}
                      />
                      {(item?.status === 'ACTIVE' || item?.status === 'ARCHIVED') && (
                        <Button
                          actionLabel={item?.status === 'ACTIVE' ? 'Archive' : 'Restore'}
                          color="action"
                          onClick={() => {
                            setUser(item)
                            setCreateUserModal(
                              item?.status === 'ACTIVE' ? 'ARCHIVE' : 'RESTORE_ARCHIVED'
                            )
                          }}
                        />
                      )}
                    </div>
                  ])}
                />
              )}
            </div>
            <SetPasswordModal
              user={user}
              isOpen={openSetPasswordModal}
              closeModal={() => {
                setOpenSetPasswordModal(false)
              }}
            />
            {currentPosts?.length > 0 && (
              <Pagination
                data={currentPosts}
                postsPerPage={postsPerPage}
                totalPosts={data?.getUsers.length}
                paginate={paginate}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
        )}
      </div>
      {createusermodal === 'MANAGER' && (
        <ManagerModal isOpen={open} closeModal={closeModal} isCreate={isCreate} user={user} />
      )}
      {(createusermodal === 'DELETE' || createusermodal === 'MANAGE_USER') && (
        <DeleteModal
          title={getName(user)}
          isOpen={createusermodal === 'DELETE' || createusermodal === 'MANAGE_USER'}
          onClose={handleCloseModal}
          onSubmit={createusermodal === 'MANAGE_USER' ? handleUserManagement : deleteManagerProfile}
          description={`Are you sure you want to ${
            createusermodal === 'MANAGE_USER' ? userAction?.toLowerCase() : 'delete'
          } this user?`}
        />
      )}
      <DeleteModal
        title={getName(user)}
        isOpen={createusermodal === 'ARCHIVE' || createusermodal === 'RESTORE_ARCHIVED'}
        onClose={handleCloseModal}
        onSubmit={user?.status === 'ACTIVE' ? handleArchiveUser : handleRestoreArchiveUser}
        description={`Are you sure you want to ${
          user?.status === 'ACTIVE' ? 'archive' : 'restore'
        } this user's account?`}
      />
    </>
  )
}
