import { monthKey } from '../constants/monthKey'
import roles from '../constants/roles'

export function arrayContains(arr1, arr2) {
  return arr1.some((r) => arr2.includes(r.type))
}

// Get User's full
export const getFullName = (arrayList) =>
  arrayList?.map((name) => {
    const fullName = `${name.firstName} ${name.lastName}`
    return fullName
  })

export function getNumberOfBushels(profile) {
  let totalBushels = 0
  if (profile?.irrigatedAcres && profile?.irrigatedYield) {
    totalBushels = profile.irrigatedAcres * profile.irrigatedYield
  }

  if (profile?.drylandAcres && profile?.drylandYield) {
    totalBushels += profile.drylandAcres * profile.drylandYield
  }

  return totalBushels
}

export const decimalHandler = (value) => {
  const splittedValue = String(value)?.split('.')
  if (splittedValue[1]?.length === 0) return Number(splittedValue[0])
  if (splittedValue[1]?.length === 1) {
    return Number(`${splittedValue[0]}.${splittedValue[1]}0`).toFixed(2)
  }
  if (splittedValue[1]?.length === 2) {
    return Number(`${splittedValue[0]}.${splittedValue[1]}`)
  }
  if (splittedValue[1]?.length >= 3) {
    return Number(`${splittedValue[0]}.${splittedValue[1]}`).toFixed(2)
  }
  return value
}

// Update URL with newly selected production year
export const updateUrl = (clientId, newProductionYear, currentPathName) => {
  const separatedUrlParams = currentPathName.split('/').slice(4)

  const updatedUrl = ['/clients', clientId, newProductionYear, ...separatedUrlParams].join('/')

  return updatedUrl
}

// get unique array items or filter duplicate array items
export const getUniqueArrayList = (arrayList) => [...new Set(arrayList)]

// get unique array items from array of objects
export const removeDuplicates = (array, key) =>
  array.reduce((arr, item) => {
    const removed = arr.filter((year) => year[key] !== item[key])
    return [...removed, item]
  }, [])

// Convert Number to two decimal
export const convertNumberTwoDecimal = (number) => decimalHandler(number)?.toLocaleString('en-US')

export const convertNumberTotoLocaleString = (number) =>
  Number(parseFloat(number)).toLocaleString('en-US')

export const convertNumberTotoLocaleStringPrice = (number) =>
  Number(parseFloat(number).toFixed(2)).toLocaleString('en-US', {
    minimumFractionDigits: 2
  })

// For Numbers that need to render nicely, removing zero if unnecessary
export const renderNumber = (number, fractionDigits = 2) =>
  Number(parseFloat(number || 0).toFixed(fractionDigits)).toLocaleString('en-US', {
    trailingZeroDisplay: 'stripIfInteger',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  }) || 0

// For Quantities and other decimal numbers within tabular formats
export const renderQuantity = (number, fractionDigits = 2) =>
  Number(parseFloat(number || 0).toFixed(fractionDigits)).toLocaleString('en-US', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  }) || 0

// For Revenue, Fees, Totals
export const renderMoney = (number, fractionDigits = 2) =>
  Number(parseFloat(number || 0).toFixed(fractionDigits)).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  }) || 0

// For Basis, Futures Prices, we use 4 decimals
export const renderPrice = (number) => renderMoney(number, 4)

export const getName = (user) => {
  if (!user?.firstName && !user?.lastName) {
    return ''
  }
  if (user?.businessName) {
    return `${user?.businessName} (${user?.firstName} ${user?.lastName})`
  }
  return `${user?.firstName} ${user?.lastName}`
}

export const getUserActionStatus = (user) => {
  switch (user?.status) {
    case 'INVITED':
      return 'Re-invite'
    case 'ACTIVE':
      return 'Archive'
    case 'NOT_INVITED':
      return 'Invite'
    default:
      return 'Invite'
  }
}

export const getUserStatus = (user) => {
  switch (user?.status) {
    case 'INVITED':
      return 'Invited'
    case 'ACTIVE':
      return 'Active'
    case 'NOT_INVITED':
      return 'Not invited'
    case 'ARCHIVED':
      return 'Archived'
    default:
      return 'Not invited'
  }
}

export const getUserKind = (user) => {
  switch (user?.kind) {
    case roles.MANAGER:
      return 'Manager'
    case roles.SUPER_MANAGER:
      return 'Super Manager'
    default:
      return ''
  }
}

export const getUserManagementStateLabel = (mode, userAction) => {
  if (mode === 'LOADING' || mode === 'FAILED') {
    return `${userAction.substring(0, userAction.length - 1)?.toLowerCase()}ing`
  }
  return `${userAction.substring(0, userAction.length - 1)?.toLowerCase()}ed`
}

export const sortHandler = (data = [], order = undefined, sortKey = 'businessName') => {
  const getValueByPath = (obj, path) => {
    const keys = path.split('.')
    const result = keys.reduce((asc, key) => asc?.[key], obj)

    return result
  }

  if (order === 'asc') {
    return data?.sort((a, b) => {
      if (
        (getValueByPath(a, sortKey) || a?.firstName || a?.lastName) >
        (getValueByPath(b, sortKey) || b?.firstName || b?.lastName)
      ) {
        return 1
      }
      if (
        (getValueByPath(a, sortKey) || a?.firstName || a?.lastName) <
        (getValueByPath(b, sortKey) || b?.firstName || b?.lastName)
      ) {
        return -1
      }
      return 0
    })
  }
  return order
    ? data?.sort((a, b) => {
        if (
          (getValueByPath(a, sortKey) || a?.firstName || a?.lastName) <
          (getValueByPath(b, sortKey) || b?.firstName || b?.lastName)
        ) {
          return 1
        }
        if (
          (getValueByPath(a, sortKey) || a?.firstName || a?.lastName) >
          (getValueByPath(b, sortKey) || b?.firstName || b?.lastName)
        ) {
          return -1
        }
        return 0
      })
    : data
}

export const generateFutureMonths = (tradingMonths) => {
  const futureMonthInterval = 2
  const startYear = 2014
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()

  const result = []

  const months = (tradingMonths || []).map((month) => month)
  const reverseMonths = [...months].reverse()

  result.push({ label: 'Upcoming Months', value: null })
  for (let year = currentYear; year <= currentYear + futureMonthInterval; year++) {
    months.forEach((month) => {
      if (year === currentYear && currentMonth >= monthKey[month]) {
        return
      }

      result.push({ label: `${month} ${year}`, value: `${month} ${year}` })
    })
  }

  result.push({ label: 'Past Months', value: null })
  for (let year = currentYear; year >= startYear; year--) {
    reverseMonths.forEach((month) => {
      if (year === currentYear && currentMonth < monthKey[month]) {
        return
      }

      result.push({ label: `${month} ${year}`, value: `${month} ${year}` })
    })
  }

  return result
}
