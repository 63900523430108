/* eslint-disable no-unsafe-optional-chaining */
import React, { useState } from 'react'
import { groupBy, sortBy } from 'lodash'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import Button from '../../buttons/Button'
import CropProfileModal from '../../modals/CropProfileModal'
import CopyCropProfilesModal from '../../modals/CopyCropProfilesModal'
import DELETE_PROFILE from '../../../gql/mutations/deleteProfile'
import DeleteModal from '../../modals/DeleteModal'
import EmptyState from '../../EmptyState'
import GET_PRODUCTION_YEARS from '../../../gql/queries/getProductionYears'
import PROFILES from '../../../gql/queries/Profiles'
import TableWrapper from '../../wrappers/TableWrapper'
import Title from '../../typography/Title'
import { renderNumber } from '../../../utils/helper'
import GET_SALES_PROFILES from '../../../gql/queries/getSalesProfile'
import Loader from '../../Loader'
import GET_STORAGES from '../../../gql/queries/getStorages'

function CropView({ profiles }) {
  const { id, productionYearId } = useParams()
  const name = Number(productionYearId)

  const { data: productionYearData } = useQuery(GET_PRODUCTION_YEARS, {
    variables: {
      clientId: id
    }
  })

  const totalAcres = profiles?.reduce((acc, item) => {
    const acres = item?.drylandAcres + item?.irrigatedAcres
    return acc + acres
  }, 0)

  const [isOpen, setIsOpen] = useState(false)
  const [isCopyCropProfilesOpen, setIsCopyCropProfilesOpen] = useState(false)
  const [profile, setProfile] = useState(undefined)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deleteProfile] = useMutation(DELETE_PROFILE)

  const year = productionYearData?.getProductionYears?.filter((item) => item?.name === name)

  const cropProfile = profiles?.filter((item) => item?.kind === 'CROP')

  const { data: saleProfileData, loading: loadingSaleProfiles } = useQuery(GET_SALES_PROFILES, {
    variables: {
      clientId: id,
      productionYearId: name
    }
  })

  const { data: storageData } = useQuery(GET_STORAGES, {
    variables: {
      clientId: id,
      productionYearId: name
    }
  })

  const allSaleProfiles = saleProfileData?.getSaleProfile || []
  const allStorageProfile = storageData?.getStorageProfile || []

  const saleProfiles = allSaleProfiles?.filter(
    (item) => item?.kind === 'SALE' && item.profile.id === profile?.id
  )
  const orderProfiles = allSaleProfiles?.filter(
    (item) => item?.kind === 'ORDER' && item.profile.id === profile?.id
  )
  const storageProfiles = allStorageProfile?.filter((item) => item.profile.id === profile?.id)

  const closeModal = () => {
    setIsOpen(false)
    setProfile(undefined)
  }

  const closeCopyCropProfilesModal = () => {
    setIsCopyCropProfilesOpen(false)
  }

  const handleCloseModal = () => {
    setIsDeleteModalOpen(false)
  }

  const deleteCropProfile = () =>
    deleteProfile({
      variables: {
        input: {
          id: profile?.id
        }
      },
      refetchQueries: [
        {
          query: PROFILES,
          variables: {
            clientId: id,
            productionYearId: name
          }
        }
      ]
    })

  const groupedCropProfile = groupBy(
    sortBy(cropProfile, (item) => item.crop?.name),
    (item) => item.crop?.name
  )

  const renderRow = (row, landType) =>
    typeof row[`${landType}Acres`] === 'number'
      ? [
          [
            <>
              {row.name} <span className="text-xs text-gray-500">({landType.slice(0, 3)})</span>
            </>,
            renderNumber(row[`${landType}Acres`]),
            renderNumber((row[`${landType}Acres`] / totalAcres) * 100),
            renderNumber(row[`${landType}Yield`]),
            renderNumber(row[`${landType}Acres`] * row[`${landType}Yield`]),
            <div>
              <Button
                actionLabel="Edit"
                color="action"
                onClick={() => {
                  setProfile(row)
                  setIsOpen(true)
                }}
              />
              <Button
                actionLabel="Delete"
                color="action"
                onClick={() => {
                  setProfile(row)
                  setIsDeleteModalOpen(true)
                }}
              />
            </div>
          ]
        ]
      : []

  return (
    <>
      <div className="flex flex-col">
        <div className="mb-4 sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <Title>Crop</Title>
          </div>
          <div className="mt-4 flex space-x-2 sm:mt-0 sm:ml-16 sm:flex-none">
            <Button
              type="button"
              color="neutral"
              label="Copy existing"
              onClick={() => {
                setIsCopyCropProfilesOpen(true)
              }}
            />
            <Button
              type="button"
              color="info"
              label="Add Crop"
              onClick={() => {
                setIsOpen(true)
              }}
            />
          </div>
        </div>
        {cropProfile?.length > 0 ? (
          <div className="-my-2 overflow-x-auto">
            <div className="inline-block min-w-full py-2 px-0.5 align-middle">
              <TableWrapper
                columns={[
                  'Crop',
                  'Acres',
                  'Percentage',
                  'Yield (expected)',
                  'Production',
                  'Actions'
                ]}
                rows={Object.values(groupedCropProfile).flatMap((group) =>
                  group
                    .flatMap((item) => [
                      ...renderRow(item, 'dryland'),
                      ...renderRow(item, 'irrigated')
                    ])
                    .concat([
                      group.reduce(
                        (acc, item, index, { length }) => {
                          if (
                            typeof item.drylandAcres !== 'number' &&
                            typeof item.irrigatedAcres !== 'number'
                          ) {
                            return []
                          }
                          const sumOfAcres = acc[1] + item.drylandAcres + item.irrigatedAcres
                          const sumOfProduction =
                            acc[4] +
                            item.drylandAcres * item.drylandYield +
                            item.irrigatedAcres * item.irrigatedYield
                          const sumOfPercentage = (sumOfAcres / totalAcres) * 100
                          const sumOfAverageYield = sumOfProduction / sumOfAcres

                          const format = (value) =>
                            index === length - 1 ? renderNumber(value) : value

                          return [
                            'Total',
                            format(sumOfAcres),
                            format(sumOfPercentage),
                            format(sumOfAverageYield),
                            format(sumOfProduction),
                            ''
                          ]
                        },
                        ['Total', 0, 0, 0, 0]
                      )
                    ])
                )}
                rowClassName={(row) => (row[0] === 'Total' ? 'total-bg bg-[#f0f0f0]' : 'white')}
              />
            </div>
          </div>
        ) : (
          <EmptyState header="No crops" message="Get started by adding crop." disabled />
        )}
      </div>
      <CropProfileModal
        clientId={id}
        year={year}
        isOpen={isOpen}
        profile={profile}
        onClose={closeModal}
      />
      <CopyCropProfilesModal
        key={isCopyCropProfilesOpen}
        isOpen={isCopyCropProfilesOpen}
        onClose={closeCopyCropProfilesModal}
        productionYears={productionYearData?.getProductionYears || []}
        year={year}
      />
      <DeleteModal
        title={profile?.crop?.name}
        description="Are you sure you want to delete this crop?"
        onSubmit={deleteCropProfile}
        onClose={handleCloseModal}
        isOpen={isDeleteModalOpen}
      >
        {/* Loader */}
        {!saleProfileData && loadingSaleProfiles && (
          <Loader variant="fetchData" message="Fetching sale profiles" />
        )}

        {/* No profile */}
        {!loadingSaleProfiles && saleProfiles?.length === 0 && orderProfiles?.length === 0 && (
          <p className="mt-2 rounded-md bg-gray-100 p-2 text-center text-sm font-normal text-gray-500">
            No contracts linked to this commodity.
          </p>
        )}

        {/* Profiles */}
        {!loadingSaleProfiles && (saleProfiles?.length > 0 || orderProfiles?.length > 0) && (
          <>
            <p className="mt-2 text-sm text-gray-500">
              Deleting this commodity will also delete the following contracts:
            </p>
            <ul className="mt-2 w-full">
              {saleProfiles?.length > 0 && (
                <li className="mt-4 text-sm font-bold text-gray-500">Sales</li>
              )}
              {saleProfiles?.map(({ id, saleDate, buyer }) => (
                <li
                  key={id}
                  className="flex justify-between border-b py-2 px-1 text-sm font-semibold text-gray-500"
                >
                  <span>{buyer.name}</span>
                  <span>{saleDate}</span>
                </li>
              ))}
              {storageProfiles?.length > 0 && (
                <li className="mt-4 text-sm font-bold text-gray-500">Storage</li>
              )}
              {storageProfiles?.map(({ id, createdAt }) => (
                <li
                  key={id}
                  className="flex justify-between border-b py-2 px-1 text-sm font-semibold text-gray-500"
                >
                  <span>-</span>
                  <span>{dayjs(createdAt).format('YYYY-MM-DD')}</span>
                </li>
              ))}
              {orderProfiles?.length > 0 && (
                <li className="mt-4 text-sm font-bold text-gray-500">Orders</li>
              )}
              {orderProfiles?.map(({ id, saleDate, buyer }) => (
                <li
                  key={id}
                  className="flex justify-between border-b py-2 px-1 text-sm font-semibold text-gray-500"
                >
                  <span>{buyer?.name}</span>
                  <span>{saleDate}</span>
                </li>
              ))}
            </ul>
          </>
        )}
      </DeleteModal>
    </>
  )
}

export default CropView
