import React, { useState, useRef, useEffect, useCallback } from 'react'
import ReactPaginate from 'react-paginate'
import uniqBy from 'lodash/uniqBy'
import { ArrowLeftIcon } from '@heroicons/react/solid'
import { toast } from 'react-hot-toast'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import Button from '../components/buttons/Button'
import DELETE_DEFAULT_BASIS_VALUATION from '../gql/mutations/deleteDefaultBasisValuation'
import DeleteModal from '../components/modals/DeleteModal'
import EmptyState from '../components/EmptyState'
import GET_CROPS from '../gql/queries/getCrops'
import GET_DEFAULT_BASIS_VALUATIONS from '../gql/queries/getDefaultBasisValuations'
import Header from '../components/Header'
import Loader from '../components/Loader'
import SetDefaultBasisValuationModal from '../components/modals/SetDefaultBasisValuationModal'
import Table from '../components/tables/Table'
import TableDataCell from '../components/tables/TableDataCell'
import TableRow from '../components/tables/TableRow'
import { onwardsYears } from '../constants/years'
import { USER_ROUTES } from '../constants/routes'

export default function DefaultBasisValuationPage() {
  const [isOpen, setIsOpen] = useState(false)
  const [item, setItem] = useState(undefined)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const { data: cropsData, loading } = useQuery(GET_CROPS)
  const { data: basisData, loading: basisLoading } = useQuery(GET_DEFAULT_BASIS_VALUATIONS)
  const [filteredBasisValuations, setFilteredBasisValuations] = useState([])
  const cropRef = useRef()
  const yearRef = useRef()

  const itemsPerPage = 10
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage
  const currentItems = filteredBasisValuations.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(filteredBasisValuations.length / itemsPerPage)

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredBasisValuations.length
    setItemOffset(newOffset)
  }

  const crops = cropsData?.getCrops || []
  const cropOptions = uniqBy(crops, 'futuresName').filter((item) => !!item.futuresName)

  const yearOptions = onwardsYears()

  const columns = ['Crop', 'Month', 'Year', 'Basis Amount', 'Actions']

  const navigate = useNavigate()
  const [deleteDefaultBaisValuation] = useMutation(DELETE_DEFAULT_BASIS_VALUATION)
  const handleCloseModal = () => {
    setIsDeleteModalOpen(false)
    setItem(undefined)
  }

  const onDelete = () => {
    toast.promise(
      deleteDefaultBaisValuation({
        variables: {
          input: {
            id: item?.id
          }
        },
        refetchQueries: [GET_DEFAULT_BASIS_VALUATIONS]
      }),
      {
        loading: 'Deleting the basis valuation...',
        success: 'Basis valuation deleted successfully.',
        error: 'Error while deleting the basis valuation.'
      }
    )
  }

  const updateFilter = useCallback(() => {
    const crop = cropRef?.current?.value
    const year = yearRef?.current?.value
    const searchResults = basisData?.getDefaultBasisValuations.filter(
      (item) =>
        (!crop || item.futuresName === crop) &&
        (!year || item.productionYear.toString() === year.toString())
    )
    setItemOffset(0)
    setFilteredBasisValuations(searchResults)
  }, [basisData])

  useEffect(() => {
    if (basisData?.getDefaultBasisValuations) {
      updateFilter()
    }
  }, [basisData, updateFilter])

  return (
    <>
      <Header>Default Basis</Header>
      {(loading && !cropsData) || (basisLoading && !basisData) ? (
        <Loader />
      ) : (
        <>
          <div className="flex flow-root sm:flex-row sm:items-center">
            <div className="relative float-left flex w-4/12 items-center">
              <Button
                label="Back to Admin"
                classes="mr-4 items-center"
                icon={<ArrowLeftIcon className="h-5 w-5" aria-hidden="true" />}
                onClick={() => navigate(USER_ROUTES.ADMIN)}
              />
            </div>
            <div className="relative float-left flex w-4/12 items-center">
              <div className="mb-8 flex gap-x-2">
                <select
                  className="block w-fit rounded-md border border-gray-300 bg-white py-2 pl-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                  id="crop"
                  name="crop"
                  ref={cropRef}
                  onChange={updateFilter}
                >
                  <option value="">All crops</option>
                  {cropOptions.map(({ futuresName }) => (
                    <option key={futuresName} value={futuresName}>
                      {futuresName}
                    </option>
                  ))}
                </select>
                <select
                  className="block w-fit rounded-md border border-gray-300 bg-white py-2 pl-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                  id="year"
                  name="year"
                  ref={yearRef}
                  onChange={updateFilter}
                >
                  <option value="">All years</option>
                  {yearOptions?.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="float-right mt-4 flex flex-row gap-2 sm:mt-0 sm:ml-16">
              <Button
                label="Add Basis Valuation"
                onClick={() => {
                  setIsOpen(true)
                }}
              />
            </div>
          </div>
          {currentItems.length < 1 ? (
            <EmptyState
              disabled
              header="No Default Basis Valuations"
              message="Get started by adding a basis valuation."
            />
          ) : (
            <>
              <Table columns={columns}>
                {currentItems.map((defaultBasisValuation) => (
                  <TableRow key={defaultBasisValuation?.id}>
                    <TableDataCell type="title">{defaultBasisValuation.futuresName}</TableDataCell>
                    <TableDataCell>{defaultBasisValuation.futuresMonth}</TableDataCell>
                    <TableDataCell>{defaultBasisValuation.productionYear}</TableDataCell>
                    <TableDataCell>
                      {defaultBasisValuation.basisAmount
                        ? `$${defaultBasisValuation.basisAmount}`
                        : ''}
                    </TableDataCell>
                    <TableDataCell>
                      <Button
                        type="button"
                        color="action"
                        actionLabel="Edit"
                        onClick={() => {
                          setIsOpen(true)
                          setItem(defaultBasisValuation)
                        }}
                      />
                      <Button
                        type="button"
                        color="action"
                        actionLabel="Delete"
                        onClick={() => {
                          setIsDeleteModalOpen(true)
                          setItem(defaultBasisValuation)
                        }}
                      />{' '}
                    </TableDataCell>
                  </TableRow>
                ))}
              </Table>

              <div className="mt-4 flex items-center justify-between border-gray-200 bg-white px-4 pt-3 sm:px-6">
                <p className="text-sm text-gray-700">
                  <span className="font-medium">{filteredBasisValuations.length}</span>&nbsp;results
                </p>
                <div className="paginates relative">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    className="inline-flex"
                    previousClassName="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
                    nextClassName="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
                    pageLinkClassName="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-blue-200"
                    activeLinkClassName="relative z-10 inline-flex items-center border border-emerald-500 bg-emerald-50 px-4 py-2 text-sm font-medium text-emerald-600"
                    breakClassName="px-4"
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </>
          )}
          <SetDefaultBasisValuationModal
            isOpen={isOpen}
            closeModal={() => {
              setIsOpen(false)
              setItem({})
            }}
            crops={cropsData?.getCrops || []}
            item={item}
          />

          <DeleteModal
            title={`${item?.futuresName} (${item?.futuresMonth})`}
            description="Are you sure you want to delete this basis valuation?"
            onSubmit={onDelete}
            onClose={handleCloseModal}
            isOpen={isDeleteModalOpen}
          />
        </>
      )}
    </>
  )
}
