import { gql } from '@apollo/client'

const SAVE_GROSS_EXPENSE = gql`
  mutation saveGrossExpense($input: GrossExpenseInput!) {
    saveGrossExpense(input: $input) {
      id
      productionYearId
      expenseId
      cost
    }
  }
`

export default SAVE_GROSS_EXPENSE
