/* eslint-disable no-unsafe-optional-chaining */
import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import AlertBox from '../../../components/AlertBox'
import CropProfilesView from '../../../components/views/expenses/sumary/CropProfilesView'
import EmptyState from '../../../components/EmptyState'
import Header from '../../../components/Header'
import LivestockProfilesView from '../../../components/views/expenses/sumary/LivestockProfilesView'
import Loader from '../../../components/Loader'
import PROFILES from '../../../gql/queries/Profiles'
import TargetPricesView from '../../../components/views/expenses/sumary/TargetPricesView'

export default function PlanTargetsPage() {
  const { id, productionYearId } = useParams()
  const name = Number(productionYearId)
  const { data, error, loading } = useQuery(PROFILES, {
    variables: {
      clientId: id,
      productionYearId: name
    }
  })

  const cropProfiles = data?.profiles?.filter((item) => item?.kind === 'CROP')
  const livestockProfiles = data?.profiles?.filter((item) => item?.kind === 'LIVESTOCK')

  return (
    <>
      <Header>Summary</Header>
      {loading && !data && <Loader message="Fetching data..." variant="fetchData" />}
      {error && <AlertBox message="Oops! Something went wrong while fetching the profiles." />}

      <TargetPricesView profiles={cropProfiles} />

      <CropProfilesView profiles={cropProfiles} />

      <LivestockProfilesView profiles={livestockProfiles} />

      {!cropProfiles?.length && !livestockProfiles?.length && !loading && (
        <EmptyState header="No commodities" message="Get started by adding commodity." disabled />
      )}
    </>
  )
}
