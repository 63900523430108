import React from 'react'
import { PlusIcon } from '@heroicons/react/solid'

import { useToggle } from '../hooks/useToggle'
import ProductionYearModal from './modals/ProductionYearModal'

export default function EmptyState({ header, message, label, clientId, disabled }) {
  const [isOpen, toggleIsOpen] = useToggle()
  return (
    <div className="flex h-96 flex-col items-center justify-center">
      <ProductionYearModal
        isOpen={isOpen}
        closeModal={toggleIsOpen}
        type="ADD"
        clientId={clientId}
      />
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">{header}</h3>
      <p className="mt-1 text-sm text-gray-500">{message}</p>
      {!disabled && (
        <div className="mt-6">
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
            onClick={() => toggleIsOpen()}
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            {label}
          </button>
        </div>
      )}
    </div>
  )
}
