import React from 'react'

import { CheckCircleIcon } from '@heroicons/react/solid'

function ActionComplete({ header, message }) {
  return (
    <div>
      <div className="flex h-40 items-center justify-center rounded-md bg-green-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon className="h-8 w-7 text-green-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-lg font-bold text-green-800">{header}</h3>
            <div className="mt-2 text-base font-medium text-green-700">
              <p>{message}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActionComplete
