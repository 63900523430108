import React from 'react'

import Table from '../tables/Table'
import TableRow from '../tables/TableRow'
import TableDataCell from '../tables/TableDataCell'

function TableWrapper({ columns = [], rows = [], rowClassName, exportable, headerClassName }) {
  return (
    <Table columns={columns} exportable={exportable} headerClassName={headerClassName}>
      {rows.map((row, i) => (
        <TableRow
          className={rowClassName?.(row)}
          // eslint-disable-next-line react/no-array-index-key
          key={i}
        >
          {row.map((cell, j) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableDataCell type={j === 0 ? 'title' : 'value'} key={j}>
              {cell}
            </TableDataCell>
          ))}
        </TableRow>
      ))}
    </Table>
  )
}

export default TableWrapper
