import React from 'react'
import { ApolloError } from '@apollo/client'

import formatErrors from '../utils/formatErrors'

const getAlertClass = (type) => {
  if (type === 'success') {
    return 'text-green-700 bg-green-100 dark:bg-green-200 dark:text-green-800'
  }

  if (type === 'warning') {
    return 'text-yellow-700 bg-yellow-100 dark:bg-yellow-200 dark:text-yellow-800'
  }

  return 'text-red-700 bg-red-100 dark:bg-red-200 dark:text-red-800'
}

function AlertBox({ type = 'error', message }) {
  let alertMessage = message
  let alertType = type

  if (message instanceof ApolloError) {
    alertMessage = formatErrors(message)
    alertType = 'error'
  }

  return (
    <div
      className={`m-2 mt-5 mb-4 rounded-lg p-4 text-sm sm:mx-0 ${getAlertClass(alertType)}`}
      role="alert"
    >
      <span className="font-medium">{alertMessage}</span>
    </div>
  )
}

export default AlertBox
