import { gql } from '@apollo/client'

const GET_COMMENTARIES = gql`
  query getCommentaries {
    getCommentaries {
      id
      createdAt
      updatedAt
      title
      audio
      audioUrl
      postedAt
    }
  }
`

export default GET_COMMENTARIES
