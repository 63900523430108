import * as yup from 'yup'
import 'yup-phone'
import React from 'react'
import { Field, Form, Formik } from 'formik'
import { useMutation } from '@apollo/client'

import BaseModal from '../modal/BaseModal'
import Button from '../buttons/Button'
import CREATE_USER from '../../gql/mutations/createUser'
import GET_USERS from '../../gql/queries/getUsers'
import Input from '../inputs/Input'
import UPDATE_USER from '../../gql/mutations/updateUser'
import { phoneRegExp, usZipcodeRegExp } from '../../utils/validationUtils'
import roles from '../../constants/roles'

const initialValues = {
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  password: undefined,
  addressline1: undefined,
  addressline2: undefined,
  phone: undefined,
  city: undefined,
  state: undefined,
  zipcode: undefined,
  kind: roles.MANAGER,
  businessName: undefined,
  additionalEmails: undefined
}

const clientsSchema = yup.object({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  email: yup.string().email('Invalid email address').required('Email is required'),
  addressline1: yup.string(),
  phone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(10, 'Phone number too short')
    .max(10, 'Phone number too long'),
  city: yup.string(),
  state: yup.string(),
  zipcode: yup
    .string()
    .matches(usZipcodeRegExp, 'Invalid zipcode')
    .typeError('Zipcode must be a number')
})

const updatedClientSchema = yup.object({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  email: yup.string().email('Invalid email address').required('Email is required'),
  addressline1: yup.string().nullable(),
  city: yup.string().nullable(),
  state: yup.string().nullable(),
  zipcode: yup
    .string()
    .matches(usZipcodeRegExp, 'Invalid zipcode')
    .typeError('Zipcode must be a number')
    .nullable()
})

function ManagerModal({ isOpen, closeModal, isCreate, user }) {
  const updatedInitialValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    password: user.password,
    addressline1: user.addressLine1,
    addressline2: user.addressLine2,
    phone: user.phone,
    city: user.city,
    state: user.state,
    zipcode: user.zipCode,
    kind: user.kind,
    businessName: user.businessName,
    additionalEmails: user.additionalEmails
  }

  const [createUser] = useMutation(CREATE_USER)
  const [updateUser] = useMutation(UPDATE_USER)

  const createUserProfile = async (value, { setFieldError }) => {
    await createUser({
      variables: {
        input: {
          firstName: value.firstName,
          lastName: value.lastName,
          email: value.email,
          phone: value.phone,
          kind: value.kind,
          addressLine1: value.addressline1,
          addressLine2: value.addressline2,
          city: value.city,
          state: value.state,
          zipCode: value.zipcode,
          businessName: value.businessName,
          timeZone: ''
        }
      },
      onCompleted: () => closeModal(),
      onError: (error) => {
        const errorMessage =
          error?.graphQLErrors[0]?.extensions?.exception?.response?.error || 'An error occurred'
        const errorField = error?.graphQLErrors[0]?.extensions?.exception?.response?.field || null

        if (errorField) {
          setFieldError(errorField, errorMessage)
        }
      },
      refetchQueries: [GET_USERS]
    })
  }

  const updateUserProfile = async (value, { setFieldError }) => {
    await updateUser({
      variables: {
        input: {
          id: user.id,
          firstName: value.firstName,
          lastName: value.lastName,
          password: value.email,
          email: value.email,
          phone: value.phone,
          kind: value.kind,
          addressLine1: value.addressline1,
          addressLine2: value.addressline2,
          city: value.city,
          state: value.state,
          zipCode: value.zipcode,
          businessName: value.businessName,
          timeZone: ''
        }
      },
      onCompleted: () => closeModal(),
      onError: (error) => {
        const errorMessage =
          error?.graphQLErrors[0]?.extensions?.exception?.response?.error || 'An error occurred'
        const errorField = error?.graphQLErrors[0]?.extensions?.exception?.response?.field || null

        if (errorField) {
          setFieldError(errorField, errorMessage)
        }
      },
      refetchQueries: [GET_USERS]
    })
  }
  return (
    <BaseModal isOpen={isOpen} closeModal={closeModal}>
      <div className="space-y-6">
        <Formik
          initialValues={isCreate ? initialValues : updatedInitialValues}
          validationSchema={isCreate ? clientsSchema : updatedClientSchema}
          onSubmit={isCreate ? createUserProfile : updateUserProfile}
        >
          <Form>
            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {isCreate ? 'Create' : 'Update'} manager
                  </h3>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="kind" className="block text-sm font-bold text-gray-700">
                        Role
                      </label>
                      <Field
                        as="select"
                        id="kind"
                        name="kind"
                        className="mt-1 block w-full appearance-none rounded-md border border-gray-300 py-2 placeholder-gray-400 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                      >
                        <option value={roles.MANAGER}>Manager</option>
                        <option value={roles.SUPER_MANAGER}>Super Manager</option>
                      </Field>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <Input label="First name" type="text" name="firstName" />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <Input label="Last name" type="text" name="lastName" />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <Input label="Email" type="email" name="email" />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <Input label="Contact Number" type="tel" name="phone" />
                    </div>
                    <div className="col-span-6">
                      <Input label="Address Line 1" type="text" name="addressline1" />
                    </div>
                    <div className="col-span-6">
                      <Input label="Address Line 2" type="text" name="addressline2" />
                    </div>
                    <div className="col-span-6">
                      <Input label="Business name" type="text" name="businessName" />
                    </div>
                    <div className="col-span-6">
                      <Input label="Additional email" type="text" name="additionalEmails" />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <Input label="City" type="text" name="city" />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <Input label="State / Province" type="text" name="state" />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <Input
                        label="ZIP / Postal code"
                        type="text"
                        name="zipcode"
                        width="w-[180px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex justify-end gap-x-2">
              <Button type="button" color="neutral" label="Cancel" onClick={closeModal} />
              <Button type="submit" color="info" label="Save" />
            </div>
          </Form>
        </Formik>
      </div>
    </BaseModal>
  )
}

export default ManagerModal
