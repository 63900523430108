import { gql } from '@apollo/client'

const CREATE_INSURANCE_PRICING = gql`
  mutation createInsurancePricing($input: CreateInsurancePricingInput!) {
    createInsurancePricing(input: $input) {
      id
    }
  }
`

export default CREATE_INSURANCE_PRICING
