import { gql } from '@apollo/client'

const UPDATE_BUYER = gql`
  mutation updateBuyer($input: UpdateBuyerInput!) {
    updateBuyer(input: $input) {
      id
      name
      state
      city
    }
  }
`

export default UPDATE_BUYER
