import { gql } from '@apollo/client'

import USER_FRAGMENT from '../fragments/user'

const GET_USER = gql`
  query GetUser($id: String!) {
    getUser(id: $id) {
      ...User
    }
  }
  ${USER_FRAGMENT}
`

export default GET_USER
