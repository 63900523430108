import React from 'react'
import { ErrorMessage, useField } from 'formik'

const inputClass =
  'focus:ring-emerald-500 focus:border-emerald-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md'

const labelClass = 'block text-sm text-gray-700'

function SignedInput({ containerStyle, ...props }) {
  const [field, meta] = useField(props)
  const {
    className,
    name,
    label,
    type,
    min,
    step = 'any',
    variant,
    unit,
    width,
    inputstyle = '',
    isDisabled = false,
    innerRef
  } = props
  const errorClass = meta.touched && meta.error ? 'error' : ''

  return (
    <div style={containerStyle}>
      <label
        htmlFor={name}
        className={`
            ${labelClass}
            ${variant ? 'font-medium' : 'font-bold'}
          `}
      >
        {label}
      </label>
      <div className="mt-1">
        <div className="relative mt-1 rounded-md shadow-sm">
          <input
            ref={innerRef}
            type={type}
            min={min}
            step={step}
            {...field}
            value={typeof field?.value === Number ? field?.value?.toFixed(4) : field?.value}
            autoComplete="off"
            {...props}
            disabled={isDisabled}
            className={`
              ${className}
              ${inputClass} ${inputstyle}
              ${errorClass ? 'border border-red-500' : ''}
            `}
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span className="text-gray-500 sm:text-sm" id="drylandAcres">
              {unit?.toLowerCase()}
            </span>
          </div>
        </div>
      </div>
      <ErrorMessage
        name={name}
        component="p"
        className={`mt-1 ${width} text-sm font-medium text-red-600`}
      />
    </div>
  )
}

export default SignedInput
