import { gql } from '@apollo/client'

const GET_STORAGES = gql`
  query getStorageProfile($clientId: String!, $productionYearId: Float!) {
    getStorageProfile(clientId: $clientId, productionYearId: $productionYearId) {
      id
      location
      quantity
      notes
      createdAt
      updatedAt
      profile {
        id
        cropId
        crop {
          id
          name
          unit
        }
        productionYear {
          id
          name
        }
      }
    }
  }
`

export default GET_STORAGES
