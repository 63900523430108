import { gql } from '@apollo/client'

const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      firstName
      phone
      lastName
      addressLine1
      addressLine2
      state
      city
      zipCode
      businessName
      kind
    }
  }
`

export default CREATE_USER
