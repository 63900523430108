import React from 'react'

import AlertBox from '../../components/AlertBox'
import Header from '../../components/Header'
import Loader from '../../components/Loader'
import useSummary from '../../hooks/useSummary'
import StatsView from '../../components/views/summary/StatsView'
import CommoditiesView from '../../components/views/summary/CommoditiesView'
import ContractsView from '../../components/views/summary/ContractsView'

function SummaryPage() {
  const {
    summaryData,
    error,
    loading,
    profileSummary,
    profileSummaryError,
    profileSummaryLoading
  } = useSummary()

  const profileSummaries = profileSummary?.getProfileSummaries || []

  return (
    <>
      <Header>Overview</Header>
      {((loading && !summaryData) || (profileSummaryLoading && !profileSummaries)) && (
        <Loader message="Fetching data..." variant="fetchData" />
      )}
      {error && <AlertBox message="Oops! Something went wrong while fetching data." />}
      {summaryData && profileSummaries && !error && !profileSummaryError && (
        <>
          <StatsView summary={summaryData?.getSummary} />
          <ContractsView profileSummaries={profileSummaries} loading={loading} />
          <CommoditiesView profileSummaries={profileSummaries} />
        </>
      )}
    </>
  )
}

export default SummaryPage
