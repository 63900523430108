import React from 'react'

import ReportSubTitle from '../../reportPrimitives/ReportSubtitle'
import ReportTable from '../../reportPrimitives/ReportTable'
import { renderNumber } from '../../../utils/helper'

const revenueSummaryColumns = ['Profile', 'Commodity', 'Current Revenue']

function LivestockRevenueView({
  variant,
  hasLiveCattleData,
  groupedRevenueDataLiveCattle,
  hasCowCalfData,
  groupedRevenueDataCowCalf,
  hasFeederCattleData,
  groupedRevenueDataFeederCattle
}) {
  return (
    <>
      <ReportSubTitle break variant={variant} title="LIVESTOCK REVENUE" />

      {hasLiveCattleData &&
        groupedRevenueDataLiveCattle?.map((item) => (
          <ReportTable
            key={item.productionYear}
            variant={variant}
            title={<>{item.productionYear} Live Cattle Revenue</>}
            columns={revenueSummaryColumns}
            rows={item.data.flatMap((crop) =>
              crop.reports
                .map((innerArrData) => [
                  <>
                    Live Cattle&nbsp;
                    {innerArrData.description ? `(${innerArrData.description})` : null}
                  </>,
                  'Live Cattle',
                  <>
                    $
                    {renderNumber(
                      (innerArrData.profilesWithRevenue?.soldQuantity.reduce(
                        (total, soldQuantity) => total + soldQuantity.quantity,
                        0
                      ) || 0) *
                        (innerArrData.profilesWithRevenue?.soldQuantity.reduce(
                          (acc, soldQuantity) => {
                            let amount
                            if (soldQuantity.subkind === 'CASH_SALE') {
                              amount = soldQuantity.amount
                                ? soldQuantity.amount - soldQuantity.feeAmount
                                : soldQuantity.basisAmount +
                                    soldQuantity.futuresAmount -
                                    soldQuantity.feeAmount || 0
                              return acc + amount
                            }
                            if (soldQuantity.subkind === 'BASIS') {
                              amount =
                                soldQuantity.basisAmount +
                                  soldQuantity.futuresAmount -
                                  soldQuantity.feeAmount || 0
                              return acc + amount
                            }
                            amount = soldQuantity.futuresAmount - soldQuantity.feeAmount || 0
                            return acc + amount
                          },
                          0
                        ) || 0)
                    )}
                  </>
                ])
                .concat([
                  [
                    'Total',
                    '',
                    <>
                      $
                      {renderNumber(
                        item?.data.reduce((acc, crop) => {
                          const revenueTotal = crop.reports.reduce((innerAcc, report) => {
                            const soldQuantityTotal =
                              report.profilesWithRevenue.soldQuantity.reduce(
                                (total, soldQuantity) => total + soldQuantity.quantity,
                                0
                              )
                            const revenuePerQuantity =
                              report.profilesWithRevenue.soldQuantity.reduce(
                                (acc, soldQuantity) => {
                                  let amount
                                  if (soldQuantity.subkind === 'CASH_SALE') {
                                    amount = soldQuantity.amount
                                      ? soldQuantity.amount - soldQuantity.feeAmount
                                      : (soldQuantity.basisAmount || 0) +
                                        (soldQuantity.futuresAmount || 0) -
                                        (soldQuantity.feeAmount || 0)
                                    return acc + amount
                                  }
                                  if (soldQuantity.subkind === 'BASIS') {
                                    amount =
                                      (soldQuantity.basisAmount || 0) +
                                      (soldQuantity.futuresAmount || 0) -
                                      (soldQuantity.feeAmount || 0)
                                    return acc + amount
                                  }
                                  amount =
                                    (soldQuantity.futuresAmount || 0) -
                                    (soldQuantity.feeAmount || 0)
                                  return acc + amount
                                },
                                0
                              )
                            return innerAcc + soldQuantityTotal * revenuePerQuantity
                          }, 0)
                          return acc + revenueTotal
                        }, 0)
                      )}
                    </>
                  ]
                ])
            )}
          />
        ))}

      {/* Cow calf revenue data */}
      {hasCowCalfData &&
        groupedRevenueDataCowCalf?.map((item) => (
          <ReportTable
            key={item.productionYear}
            variant={variant}
            title={<>{item.productionYear} Cow Calf Revenue</>}
            columns={revenueSummaryColumns}
            rows={item.data.flatMap((crop) =>
              crop.reports
                .map((innerArrData) => [
                  <>
                    Cow Calf&nbsp;
                    {innerArrData.description ? `(${innerArrData.description})` : null}
                  </>,
                  'Cow Calf',
                  <>
                    $
                    {renderNumber(
                      (innerArrData.profilesWithRevenue?.soldQuantity.reduce(
                        (total, soldQuantity) => total + soldQuantity.quantity,
                        0
                      ) || 0) *
                        (innerArrData.profilesWithRevenue?.soldQuantity.reduce(
                          (acc, soldQuantity) => {
                            let amount
                            if (soldQuantity.subkind === 'CASH_SALE') {
                              amount = soldQuantity.amount
                                ? soldQuantity.amount - soldQuantity.feeAmount
                                : soldQuantity.basisAmount +
                                    soldQuantity.futuresAmount -
                                    soldQuantity.feeAmount || 0
                              return acc + amount
                            }
                            if (soldQuantity.subkind === 'BASIS') {
                              amount =
                                soldQuantity.basisAmount +
                                  soldQuantity.futuresAmount -
                                  soldQuantity.feeAmount || 0
                              return acc + amount
                            }
                            amount = soldQuantity.futuresAmount - soldQuantity.feeAmount || 0
                            return acc + amount
                          },
                          0
                        ) || 0)
                    )}
                  </>
                ])
                .concat([
                  [
                    'Total',
                    '',
                    <>
                      $
                      {renderNumber(
                        item?.data.reduce((acc, crop) => {
                          const revenueTotal = crop.reports.reduce((innerAcc, report) => {
                            const soldQuantityTotal =
                              report.profilesWithRevenue.soldQuantity.reduce(
                                (total, soldQuantity) => total + soldQuantity.quantity,
                                0
                              )
                            const revenuePerQuantity =
                              report.profilesWithRevenue.soldQuantity.reduce(
                                (acc, soldQuantity) => {
                                  let amount
                                  if (soldQuantity.subkind === 'CASH_SALE') {
                                    amount = soldQuantity.amount
                                      ? soldQuantity.amount - soldQuantity.feeAmount
                                      : (soldQuantity.basisAmount || 0) +
                                        (soldQuantity.futuresAmount || 0) -
                                        (soldQuantity.feeAmount || 0)
                                    return acc + amount
                                  }
                                  if (soldQuantity.subkind === 'BASIS') {
                                    amount =
                                      (soldQuantity.basisAmount || 0) +
                                      (soldQuantity.futuresAmount || 0) -
                                      (soldQuantity.feeAmount || 0)
                                    return acc + amount
                                  }
                                  amount =
                                    (soldQuantity.futuresAmount || 0) -
                                    (soldQuantity.feeAmount || 0)
                                  return acc + amount
                                },
                                0
                              )
                            return innerAcc + soldQuantityTotal * revenuePerQuantity
                          }, 0)
                          return acc + revenueTotal
                        }, 0)
                      )}
                    </>
                  ]
                ])
            )}
          />
        ))}

      {/* Feeder cattle revenue data */}

      {hasFeederCattleData &&
        groupedRevenueDataFeederCattle?.map((item) => (
          <ReportTable
            key={item.productionYear}
            variant={variant}
            title={<>{item.productionYear} Feeder Cattle Revenue</>}
            columns={revenueSummaryColumns}
            rows={item.data.flatMap((crop) =>
              crop.reports
                .map((innerArrData) => [
                  <>
                    Feeder Cattle &nbsp;
                    {innerArrData.description ? `(${innerArrData.description})` : null}
                  </>,
                  'Feeder Cattle',
                  '$0.0000'
                ])
                .concat([
                  [
                    'Total',
                    '',
                    <>
                      $
                      {renderNumber(
                        item?.data.reduce((acc, crop) => {
                          const revenueTotal = crop.reports.reduce((innerAcc, report) => {
                            const soldQuantityTotal =
                              report.profilesWithRevenue.soldQuantity.reduce(
                                (total, soldQuantity) => total + soldQuantity.quantity,
                                0
                              )
                            const revenuePerQuantity =
                              report.profilesWithRevenue.soldQuantity.reduce(
                                (acc, soldQuantity) => {
                                  let amount
                                  if (soldQuantity.subkind === 'CASH_SALE') {
                                    amount = soldQuantity.amount
                                      ? soldQuantity.amount - soldQuantity.feeAmount
                                      : (soldQuantity.basisAmount || 0) +
                                        (soldQuantity.futuresAmount || 0) -
                                        (soldQuantity.feeAmount || 0)
                                    return acc + amount
                                  }
                                  if (soldQuantity.subkind === 'BASIS') {
                                    amount =
                                      (soldQuantity.basisAmount || 0) +
                                      (soldQuantity.futuresAmount || 0) -
                                      (soldQuantity.feeAmount || 0)
                                    return acc + amount
                                  }
                                  amount =
                                    (soldQuantity.futuresAmount || 0) -
                                    (soldQuantity.feeAmount || 0)
                                  return acc + amount
                                },
                                0
                              )
                            return innerAcc + soldQuantityTotal * revenuePerQuantity
                          }, 0)
                          return acc + revenueTotal
                        }, 0)
                      )}
                    </>
                  ]
                ])
            )}
          />
        ))}
    </>
  )
}

export default LivestockRevenueView
