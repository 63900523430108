/* eslint-disable no-param-reassign */
import { ArrowLeftIcon } from '@heroicons/react/outline'
import React, { useState, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import dayjs from 'dayjs'
import toast from 'react-hot-toast'

import AlertBox from '../../AlertBox'
import Button from '../../buttons/Button'
import CropRevenueView from './CropRevenueView'
import DetailedProfileView from './DetailedProfileView'
import DisclaimerView from './DisclaimerView'
import DropdownButton from '../../buttons/DropdownButton'
import GET_PROFILE_SUMMARIES from '../../../gql/queries/getProfileSummaries'
import GET_SALES_PROFILES from '../../../gql/queries/getSalesProfile'
import LivestockRevenueView from './LivestockRevenueView'
import LivestockView from './LivestockView'
import Loader from '../../Loader'
import ProfilesView from './ProfilesView'
import SummaryView from './SummaryView'
import SendReportModal from './SendReportModal'
import { getUniqueArrayList } from '../../../utils/helper'
import { useAuth } from '../../../hooks/useAuth'
import { useGetAllProductionYears } from '../../../services/queries/useGetAllProductionYears'
import { useGetReports } from '../../../services/queries/useGetReports'
import { reportKind } from '../../../constants/reportKind'
import DOWNLOAD_REPORT_FILE from '../../../gql/queries/downloadReportFile'

const timezone = require('dayjs/plugin/timezone')
const advanced = require('dayjs/plugin/advancedFormat')

dayjs.extend(timezone)
dayjs.extend(advanced)

function useFetchProfileSummaries({ clientId, productionYears }) {
  const client = useApolloClient()
  const [profileSummariesData, setProfileSummariesData] = useState({})
  const [profileSummariesLoading, setProfileSummariesLoading] = useState(false)

  useEffect(() => {
    if (!clientId || !productionYears) return

    setProfileSummariesLoading(true)

    Promise.all(
      productionYears.map((productionYear) =>
        client
          .query({
            query: GET_PROFILE_SUMMARIES,
            variables: {
              clientId,
              productionYear: Number(productionYear)
            }
          })
          .then(({ data }) => {
            setProfileSummariesData((curr) => ({ ...curr, [productionYear]: data }))
          })
      )
    ).finally(() => {
      setProfileSummariesLoading(false)
    })
  }, [clientId, client, productionYears])

  return { profileSummariesLoading, profileSummariesData }
}

function useFetchCashSales({ clientId, productionYears }) {
  const client = useApolloClient()
  const [salesProfilesData, setSalesProfilesData] = useState({})
  const [salesProfilesLoading, setSalesProfilesLoading] = useState(false)

  useEffect(() => {
    if (!clientId || !productionYears) return

    setSalesProfilesLoading(true)

    Promise.all(
      productionYears.map((productionYear) =>
        client
          .query({
            query: GET_SALES_PROFILES,
            variables: {
              clientId,
              productionYearId: Number(productionYear)
            }
          })
          .then(({ data }) => {
            setSalesProfilesData((curr) => ({ ...curr, [productionYear]: data }))
          })
      )
    ).finally(() => {
      setSalesProfilesLoading(false)
    })
  }, [clientId, client, productionYears])

  return { salesProfilesLoading, salesProfilesData }
}

function ReportView({ kind, clientId, client, clientLoading, onBack, clientSelector }) {
  // URL Params
  const [searchParams] = useSearchParams()
  const { productionYears } = Object.fromEntries(searchParams)

  const apolloClient = useApolloClient()

  const { user } = useAuth() || {}
  const loggedInUserId = user?.id
  const { allProductionYears } = useGetAllProductionYears(loggedInUserId, {
    skip: productionYears !== 'All-productionYears'
  })
  const uniqueProductionYearsList = useMemo(
    () => getUniqueArrayList(allProductionYears?.map(({ name: productionYear }) => productionYear)),
    [allProductionYears]
  )

  const selectedProductionYears = useMemo(
    () =>
      productionYears === 'All-productionYears'
        ? uniqueProductionYearsList.sort().reverse()
        : productionYears?.split(',')?.map(Number),
    [productionYears, uniqueProductionYearsList]
  )

  const { data, loading } = useGetReports({
    kind,
    clientId,
    productionYears: selectedProductionYears
  })

  const reports = kind === reportKind.Glance ? data?.getMonthlyReports : data?.getReports

  const hasData = reports?.length > 0

  const { profileSummariesLoading, profileSummariesData } = useFetchProfileSummaries({
    clientId,
    productionYears: selectedProductionYears
  })

  const { salesProfilesLoading, salesProfilesData } = useFetchCashSales({
    clientId,
    productionYears: selectedProductionYears
  })

  const downloadPdf = async (orientation) => {
    try {
      const { data } = await apolloClient.query({
        query: DOWNLOAD_REPORT_FILE,
        variables: {
          clientId,
          productionYear: selectedProductionYears,
          kind,
          orientation
        }
      })

      const { downloadReportFile } = data
      const { fileName, pdfStringValue } = downloadReportFile

      const link = window.document.createElement('a')
      link.href = `data:application/pdf;base64,${pdfStringValue}`
      link.download = fileName
      link.click()
    } catch (error) {
      toast.error(`Error while downloading report.`)
    }
  }

  const [isOpenSendReport, setIsOpenSendReport] = useState(false)

  const summaryLoader =
    ((profileSummariesLoading && !Object.values(profileSummariesData).filter(Boolean).length) ||
      (salesProfilesLoading && !Object.values(salesProfilesData).filter(Boolean).length)) &&
    !loading ? (
      <Loader message="Fetching profile data...." variant="fetchData" />
    ) : null

  if (
    (loading && !data) ||
    (clientLoading && !client) ||
    profileSummariesLoading ||
    salesProfilesLoading
  ) {
    return <Loader message="Fetching reports data...." variant="fetchData" />
  }

  return (
    <>
      <div>
        <div className="flex gap-x-2">
          {onBack && (
            <div>
              <Button
                label="Back"
                classes="mr-6 items-center"
                icon={<ArrowLeftIcon className="h-5 w-5" aria-hidden="true" />}
                onClick={onBack}
              />
            </div>
          )}

          {hasData && (
            <div>
              <DropdownButton
                buttonHead="Send Report"
                options={[
                  {
                    label: 'Email report',
                    onClick: () => setIsOpenSendReport(true)
                  }
                ]}
              />
              <DropdownButton
                buttonHead="Download Report"
                options={[
                  {
                    label: 'Portrait',
                    onClick: () => {
                      downloadPdf('portrait')
                    }
                  },
                  {
                    label: 'Landscape',
                    onClick: () => {
                      downloadPdf('landscape')
                    }
                  }
                ]}
              />
            </div>
          )}
        </div>
        {client?.reportSentAt && (
          <p className="mt-4 block text-sm text-gray-700">
            Last sent at:{' '}
            <span className="text-gray-500">
              {dayjs(client.reportSentAt).format('MM/DD/YYYY hh:mma z')}
            </span>
          </p>
        )}
      </div>
      {data && clientSelector}
      {!hasData && <AlertBox message="No data found." />}
      {hasData &&
        renderReport({
          kind,
          client,
          reports,
          priceFetchedOn: data?.getPriceFetchedOn,
          profileSummariesData,
          salesProfilesData,
          variant: 'web'
        })}
      {summaryLoader}
      <SendReportModal
        isOpen={isOpenSendReport}
        closeModal={() => setIsOpenSendReport(false)}
        clientId={clientId}
        kind={kind}
        productionYears={selectedProductionYears.join(',')}
      />
    </>
  )
}

const renderReport = ({
  kind,
  client,
  reports,
  priceFetchedOn,
  profileSummariesData,
  salesProfilesData,
  variant
}) => {
  const cropProfile = reports?.filter((item) => item?.kind === 'CROP')
  const livestockProfile = reports?.filter((item) => item?.kind === 'LIVESTOCK')
  const cowCalfProfile = livestockProfile?.filter((item) => item?.livestockKind === 'COW_CALF')
  const liveCattleProfile = livestockProfile?.filter(
    (item) => item?.livestockKind === 'LIVE_CATTLE'
  )
  const feederCattleProfile = livestockProfile?.filter(
    (item) => item?.livestockKind === 'FEEDER_CATTLE'
  )

  // Crop Profile data
  const groupedByName = (cropProfile || []).reduce((groups, report) => {
    const key = report.productionYear.name
    if (!groups[key]) {
      groups[key] = []
    }
    groups[key].push(report)
    return groups
  }, {})

  const cropProfileMappedByProductionYear = Object?.keys(groupedByName)?.map((key) => ({
    productionYear: key,
    reports: groupedByName[key]
  }))

  // Live stock Profile

  const groupedByNameLiveStock = (livestockProfile || []).reduce((groups, report) => {
    const key = report.productionYear.name
    if (!groups[key]) {
      groups[key] = []
    }
    groups[key].push(report)
    return groups
  }, {})

  const liveStockProfileMappedByProductionYear = Object?.keys(groupedByNameLiveStock)?.map(
    (key) => ({
      productionYear: key,
      reports: groupedByNameLiveStock[key]
    })
  )

  // Live Cattle data
  const groupedByNameLiveCattle = (liveCattleProfile || []).reduce((groups, report) => {
    const key = report.productionYear.name
    if (!groups[key]) {
      groups[key] = []
    }
    groups[key].push(report)
    return groups
  }, {})

  const liveCattleMappedByProductionYear = Object?.keys(groupedByNameLiveCattle)?.map((key) => ({
    productionYear: key,
    reports: groupedByNameLiveCattle[key]
  }))

  // Cow Calf data
  const groupedByNameCowCalf = (cowCalfProfile || []).reduce((groups, report) => {
    const key = report.productionYear.name
    if (!groups[key]) {
      groups[key] = []
    }
    groups[key].push(report)
    return groups
  }, {})

  const cowCalfMappedByProductionYear = Object?.keys(groupedByNameCowCalf)?.map((key) => ({
    productionYear: key,
    reports: groupedByNameCowCalf[key]
  }))

  // Feeder Cattle data
  const groupedByNameFeederCattle = (feederCattleProfile || []).reduce((groups, report) => {
    const key = report.productionYear.name
    if (!groups[key]) {
      groups[key] = []
    }
    groups[key].push(report)
    return groups
  }, {})

  const feederCattleMappedByProductionYear = Object?.keys(groupedByNameFeederCattle)?.map(
    (key) => ({
      productionYear: key,
      reports: groupedByNameFeederCattle[key]
    })
  )

  const hasCropData = cropProfileMappedByProductionYear.some((report) => report.reports.length > 0)

  const hasLiveStockData = liveStockProfileMappedByProductionYear.some(
    (report) => report.reports.length > 0
  )

  const hasLiveCattleData = liveCattleMappedByProductionYear.some(
    (report) => report.reports.length > 0
  )

  const hasCowCalfData = cowCalfMappedByProductionYear.some((report) => report.reports.length > 0)

  const hasFeederCattleData = feederCattleMappedByProductionYear.some(
    (report) => report.reports.length > 0
  )

  // Crops revenue data
  const groupedData = cropProfileMappedByProductionYear.reduce((acc, cur) => {
    cur.reports.forEach((report) => {
      const key = `${cur.productionYear}_${report.crop.name}`
      if (!acc[key]) {
        acc[key] = {
          productionYear: cur.productionYear,
          cropName: report.crop.name,
          reports: []
        }
      }
      acc[key].reports.push(report)
    })
    return acc
  }, {})

  const groupedArray = Object.values(groupedData)

  const modifiedGroupedData = groupedArray?.reduce((acc, curr) => {
    const { productionYear, ...rest } = curr
    if (!acc[productionYear]) {
      acc[productionYear] = { productionYear, data: [] }
    }
    acc[productionYear].data.push(rest)
    return acc
  }, {})
  const groupedRevenueData = Object.values(modifiedGroupedData)

  // Live cattle revenue data
  const groupedArrayLiveCattle = liveCattleMappedByProductionYear

  const modifiedGroupedDataLiveCattle = groupedArrayLiveCattle?.reduce((acc, curr) => {
    const { productionYear, ...rest } = curr
    if (!acc[productionYear]) {
      acc[productionYear] = { productionYear, data: [] }
    }
    acc[productionYear].data.push(rest)
    return acc
  }, {})

  const groupedRevenueDataLiveCattle = Object.values(modifiedGroupedDataLiveCattle)

  //  Cow Calf revenue data
  const groupedArrayCowCalf = cowCalfMappedByProductionYear

  const modifiedGroupedDataCowCalf = groupedArrayCowCalf?.reduce((acc, curr) => {
    const { productionYear, ...rest } = curr
    if (!acc[productionYear]) {
      acc[productionYear] = { productionYear, data: [] }
    }
    acc[productionYear].data.push(rest)
    return acc
  }, {})

  const groupedRevenueDataCowCalf = Object.values(modifiedGroupedDataCowCalf)

  //  Feeder cattle revenue data
  const groupedArrayFeederCattle = feederCattleMappedByProductionYear

  const modifiedGroupedDataFeederCattle = groupedArrayFeederCattle?.reduce((acc, curr) => {
    const { productionYear, ...rest } = curr
    if (!acc[productionYear]) {
      acc[productionYear] = { productionYear, data: [] }
    }
    acc[productionYear].data.push(rest)
    return acc
  }, {})

  const groupedRevenueDataFeederCattle = Object.values(modifiedGroupedDataFeederCattle)
  return (
    <>
      {reports && (
        <SummaryView
          kind={kind}
          priceFetchedOn={priceFetchedOn}
          client={client}
          variant={variant}
        />
      )}
      {hasCropData && kind !== reportKind.Glance && (
        <ProfilesView
          variant={variant}
          cropProfileMappedByProductionYear={cropProfileMappedByProductionYear}
        />
      )}
      {hasLiveStockData && kind !== reportKind.Glance && (
        <LivestockView
          {...{
            variant,
            hasLiveCattleData,
            hasCowCalfData,
            hasFeederCattleData,
            liveCattleMappedByProductionYear,
            cowCalfMappedByProductionYear,
            feederCattleMappedByProductionYear
          }}
        />
      )}
      {hasCropData && (
        <CropRevenueView kind={kind} variant={variant} groupedRevenueData={groupedRevenueData} />
      )}
      {hasLiveStockData && (
        <LivestockRevenueView
          {...{
            variant,
            hasLiveCattleData,
            groupedRevenueDataLiveCattle,
            hasCowCalfData,
            groupedRevenueDataCowCalf,
            hasFeederCattleData,
            groupedRevenueDataFeederCattle
          }}
        />
      )}

      {cropProfileMappedByProductionYear.map((data) => (
        <DetailedProfileView
          key={data.productionYear}
          profileSummariesData={profileSummariesData[data.productionYear]}
          salesProfilesData={salesProfilesData[data.productionYear]}
          productionYear={Number(data.productionYear)}
          reports={data.reports}
          variant={variant}
          kind={kind}
        />
      ))}
      <DisclaimerView variant={variant} />
    </>
  )
}

export default ReportView
