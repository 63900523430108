import * as yup from 'yup'
import React, { useState, useEffect, useCallback } from 'react'
import uniqBy from 'lodash/uniqBy'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { toast } from 'react-hot-toast'
import { useMutation } from '@apollo/client'

import BaseModal from '../modal/BaseModal'
import Button from '../buttons/Button'
import SignedInput from '../SignedInput'
import MODIFY_DEFAULT_BASIS_VALUATION from '../../gql/mutations/updateDefaultBasisValuation'
import { onwardsYears } from '../../constants/years'
import GET_DEFAULT_BASIS_VALUATIONS from '../../gql/queries/getDefaultBasisValuations'

const validationSchema = yup.object({
  basisAmount: yup.number().required('Basis Amount is required'),
  futuresName: yup.string().required('Futures Name is required'),
  futuresMonth: yup.string().required('Month is required'),
  productionYear: yup.string().required('Year is required')
})

export default function SetDefaultBasisValuationModal({ isOpen, closeModal, crops, item }) {
  const years = onwardsYears()
  const [months, setMonths] = useState([])

  const handleChangeMonth = useCallback(
    (cropId) => {
      const crop = crops.find((item) => item.id === cropId)
      setMonths(crop?.tradingMonths || [])
    },
    [crops]
  )

  useEffect(() => {
    handleChangeMonth(item?.crop?.id || crops[0]?.id)
  }, [crops, handleChangeMonth, item?.crop?.id])

  const [updateDefaultBasisValuation] = useMutation(MODIFY_DEFAULT_BASIS_VALUATION)

  const mode = item?.id ? 'EDIT' : 'ADD'

  const onSubmit = async (values) => {
    toast.promise(
      updateDefaultBasisValuation(
        {
          variables: {
            input: {
              futuresName: values.futuresName,
              futuresMonth: values.futuresMonth,
              basisAmount: values.basisAmount,
              productionYear: Number(values.productionYear)
            }
          },
          onCompleted: () => closeModal(),
          refetchQueries: [GET_DEFAULT_BASIS_VALUATIONS]
        },
        {
          loading: 'Updating the price...',
          success: 'Price updated successfully.',
          error: 'Error while updating the price.'
        }
      )
    )
  }

  return (
    <BaseModal isOpen={isOpen} closeModal={closeModal}>
      <div className="space-y-6">
        <Formik
          initialValues={{
            futuresName: crops[0]?.futuresName,
            futuresMonth: months[0],
            productionYear: Number(years[0]),
            ...item
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => onSubmit(values)}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      {`${mode === 'EDIT' ? 'Edit' : 'Set'}`} Basis Valuation
                    </h3>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-12 sm:col-span-12">
                        <label
                          htmlFor="futuresName"
                          className="block text-sm font-bold text-gray-700"
                        >
                          Futures name
                        </label>
                        <Field
                          as="select"
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                          type="futuresName"
                          id="futuresName"
                          name="futuresName"
                          onChange={(obj) => {
                            setFieldValue('futuresName', obj.target.value)
                            setMonths(
                              crops.find((crop) => crop.futuresName === obj.target.value)
                                ?.tradingMonths || []
                            )
                          }}
                        >
                          {uniqBy(crops, 'futuresName')
                            .filter((item) => !!item.futuresName)
                            .map((item) => (
                              <option key={item.futuresName} value={item.futuresName}>
                                {item.futuresName}
                              </option>
                            ))}
                        </Field>
                      </div>
                      <div className="col-span-12 sm:col-span-12">
                        <label htmlFor="year" className="block text-sm font-bold text-gray-700">
                          Year
                        </label>
                        <Field
                          as="select"
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                          type="year"
                          id="year"
                          name="productionYear"
                        >
                          <option value="">Select Year</option>
                          {years.map((item) => (
                            <option value={item} key={item}>
                              {item}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="year"
                          component="p"
                          className="mt-1 text-sm font-medium text-red-600"
                        />
                      </div>
                      <div className="col-span-12 sm:col-span-12">
                        <label htmlFor="month" className="block text-sm font-bold text-gray-700">
                          Month
                        </label>
                        <Field
                          as="select"
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                          type="month"
                          id="month"
                          name="futuresMonth"
                        >
                          <option value="">Select month</option>
                          {months.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="month"
                          component="p"
                          className="mt-1 text-sm font-medium text-red-600"
                        />
                      </div>
                      <div className="col-span-12 sm:col-span-12">
                        <SignedInput
                          label="Basis Amount"
                          type="number"
                          min="0"
                          name="basisAmount"
                          unit="$"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex justify-end gap-x-2">
                <Button type="button" color="neutral" label="Cancel" onClick={closeModal} />
                <Button type="submit" color="info" label="Save" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </BaseModal>
  )
}
