/* eslint-disable no-unsafe-optional-chaining */
import { Field, FieldArray, Form, Formik } from 'formik'
import { useQuery, useMutation } from '@apollo/client'
import React, { useState } from 'react'
import toast from 'react-hot-toast'

import AlertBox from '../AlertBox'
import BaseModal from '../modal/BaseModal'
import BULK_CREATE_PROFILES from '../../gql/mutations/bulkCreateProfiles'
import Button from '../buttons/Button'
import GET_CROPS from '../../gql/queries/getCrops'
import Input from '../inputs/Input'
import Loader from '../Loader'
import PROFILES from '../../gql/queries/Profiles'
import SignedInput from '../SignedInput'
import TableWrapper from '../wrappers/TableWrapper'

function CopyCropProfilesModal({ isOpen, onClose, productionYears, year }) {
  const { clientId } = productionYears[0]
  const [selectedProductionYear, setSelectedProductionYear] = useState(null)
  const { data: cropsData } = useQuery(GET_CROPS)
  const crops = cropsData?.getCrops
  const { error, loading, data } = useQuery(PROFILES, {
    variables: {
      clientId,
      productionYearId: Number(selectedProductionYear)
    },
    skip: !Number(selectedProductionYear)
  })
  const [bulkCreateProfiles] = useMutation(BULK_CREATE_PROFILES)

  const initialValues = {
    profiles: data?.profiles?.filter((profile) => profile.kind === 'CROP') || []
  }

  const createBulkProfiles = async (value) => {
    const profiles = value?.profiles
      ?.filter((profile) => profile?.copy)
      .map((profile) => ({
        clientId,
        productionYearId: year[0]?.id,
        kind: 'CROP',
        cropId: profile.cropId,
        description: profile.description,
        isDryland: !!profile?.drylandAcres,
        drylandAcres: profile.drylandAcres,
        drylandYield: profile.drylandYield,
        isIrrigated: !!profile?.irrigatedAcres,
        irrigatedAcres: profile.irrigatedAcres,
        irrigatedYield: profile.irrigatedYield
      }))

    toast.promise(
      bulkCreateProfiles({
        variables: {
          input: profiles
        },
        onCompleted: () => onClose(),
        refetchQueries: [PROFILES]
      }),
      {
        loading: 'Creating profiles...',
        success: 'Profiles created successfully.',
        error: 'Error while creating the profiles.'
      }
    )
  }

  return (
    <BaseModal isOpen={isOpen} closeModal={onClose}>
      <div className="space-y-6">
        <div className="flex flex-col space-y-4">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Copy crop profiles</h3>
          <div className="mb-4">
            <select
              className="mt-1 block w-64 rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
              onChange={(event) => setSelectedProductionYear(event.target.value)}
            >
              <option defaultValue>Select production year</option>
              {productionYears.map((year) => (
                <option value={year.name}>{year.name}</option>
              ))}
            </select>
          </div>
        </div>
        {loading && !data && <Loader message="Fetching data..." variant="fetchData" />}
        {error && <AlertBox message="Oops! Something went wrong while fetching data." />}
        {!loading && !error && (
          <Formik initialValues={initialValues} onSubmit={createBulkProfiles}>
            {({ values }) => (
              <Form>
                {data && data?.profiles && (
                  <FieldArray name="profiles">
                    {({}) => (
                      <TableWrapper
                        columns={['', 'Crop', 'Description', 'Dryland', 'Irrigated']}
                        headerClassName={() => '!text-left !px-3'}
                        rows={values?.profiles?.map((profile, index) => [
                          <Field
                            type="checkbox"
                            name={`profiles.${index}.copy`}
                            className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                          />,
                          <Field
                            disabled={!profile.copy}
                            as="select"
                            className="mt-1 block w-52 rounded-md border border-gray-300 bg-white py-2 pl-3 pr-8 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                            type="crop"
                            name={`profiles.${index}.cropId`}
                          >
                            {crops?.map((item) => (
                              <option value={item?.id}>{item?.name}</option>
                            ))}
                          </Field>,
                          <div className="w-80">
                            <Input
                              disabled={!profile.copy}
                              type="text"
                              name={`profiles.${index}.description`}
                              value={profile.description}
                            />
                          </div>,
                          <div className="flex">
                            <div className="mr-4 w-1/2">
                              <SignedInput
                                isDisabled={!profile.copy}
                                type="number"
                                min="0"
                                name={`profiles.${index}.drylandAcres`}
                                unit="acres"
                              />
                            </div>
                            <div className="w-1/2">
                              <SignedInput
                                isDisabled={!profile.copy}
                                type="number"
                                min="0"
                                name={`profiles.${index}.drylandYield`}
                                unit={profile?.crop?.unit.toLowerCase()}
                              />
                            </div>
                          </div>,
                          <div className="flex">
                            <div className="mr-4 w-1/2">
                              <SignedInput
                                isDisabled={!profile.copy}
                                type="number"
                                min="0"
                                name={`profiles.${index}.irrigatedAcres`}
                                unit="acres"
                              />
                            </div>
                            <div className="w-1/2">
                              <SignedInput
                                isDisabled={!profile.copy}
                                type="number"
                                min="0"
                                name={`profiles.${index}.irrigatedYield`}
                                unit={profile?.crop?.unit.toLowerCase()}
                              />
                            </div>
                          </div>
                        ])}
                      />
                    )}
                  </FieldArray>
                )}

                <div className="mt-6 flex justify-end gap-x-2">
                  <Button type="button" color="neutral" label="Cancel" onClick={onClose} />
                  {data?.profiles && (
                    <Button type="submit" color="info" label="Copy Crop Profiles" />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </BaseModal>
  )
}

export default CopyCropProfilesModal
