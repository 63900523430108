import roles from '../constants/roles'
import { useGetAllProductionYears } from '../services/queries/useGetAllProductionYears'
import { useGetUsers } from '../services/queries/useGetUsers'
import { getName, getUniqueArrayList } from '../utils/helper'
import { getReactSelectOptions } from '../utils/selectUtils'
import { useAuth } from './useAuth'

export function useReports() {
  const { user } = useAuth() || {}
  const loggedInUserId = user?.id

  const { allProductionYears, loading: productionYearsLoadingState } =
    useGetAllProductionYears(loggedInUserId)

  const { data, loading: clientsListLoadingState } = useGetUsers(roles.CLIENT)

  const clientsNameWithId = data?.getUsers?.map((client) => ({
    label: getName(client),
    value: client.id
  }))

  let uniqueProductionyearsList = getUniqueArrayList(
    allProductionYears?.map(({ name: productionYear }) => productionYear)
  )

  uniqueProductionyearsList = uniqueProductionyearsList.sort((a, b) =>
    // eslint-disable-next-line no-nested-ternary
    parseInt(a, 10) < parseInt(b, 10) ? -1 : parseInt(a, 10) > parseInt(b, 10) ? 1 : 0
  )
  const AdminReportsSelectOptions = {
    clientsList: clientsNameWithId?.sort((a, b) => {
      // eslint-disable-next-line no-unsafe-optional-chaining
      if (a?.label < b?.label) {
        return -1
      }
      if (a?.label > b?.label) {
        return 1
      }
      return 0
    }),
    productionYears: getReactSelectOptions(uniqueProductionyearsList)
  }

  return [AdminReportsSelectOptions, productionYearsLoadingState, clientsListLoadingState]
}
