import { gql } from '@apollo/client'

const UPDATE_EXPENSE = gql`
  mutation updateExpense($input: UpdateExpenseInput!) {
    updateExpense(input: $input) {
      id
      name
      position
      kind
      clientId
    }
  }
`

export default UPDATE_EXPENSE
