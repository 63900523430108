import React from 'react'
import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'

import GET_CROPS from '../../../../gql/queries/getCrops'
import TableWrapper from '../../../wrappers/TableWrapper'
import Title from '../../../typography/Title'
import ViewWrapper from '../../../wrappers/ViewWrapper'
import { renderPrice } from '../../../../utils/helper'

const getName = (profile) => `${profile.name} (${profile.productionYear.name})`

function TargetPricesView({ profiles }) {
  const { data: { getCrops: crops } = {} } = useQuery(GET_CROPS)

  return profiles?.length ? (
    <ViewWrapper>
      <Title title="Target Prices" />
      <TableWrapper
        exportable={{
          filename: `Expenses-Summary-Target-Prices-${dayjs().format('MM-DD-YYYY')}`
        }}
        columns={['Commodity', 'Target']}
        rows={profiles?.map((item) => [
          getName(item),
          <>
            <span className="font-semibold">{renderPrice(item.targetPrice)}</span>{' '}
            <span className="text-xs">
              per {crops?.find((crop) => crop.id === item.cropId)?.unit?.toLowerCase() || 'unit'}
            </span>{' '}
            /{' '}
            <span className="font-semibold">
              {renderPrice(
                (item.targetPrice *
                  ((item.drylandAcres || 0) +
                    (item.drylandYield || 0) +
                    (item.irrigatedAcres || 0) * (item.irrigatedYield || 0))) /
                  ((item.drylandAcres || 0) + (item.irrigatedAcres || 0) || 1)
              )}
            </span>{' '}
            <span className="text-xs">per acre</span>
          </>
        ])}
      />
    </ViewWrapper>
  ) : null
}

export default TargetPricesView
