import React from 'react'

import Loader from '../Loader'

const infoButtonClass =
  'group relative flex hover:bg-emerald-500 text-white gap-3 justify-center py-2 px-4 border border-transparent text-sm font-medium text-base rounded-md hover:text-white focus:outline-none focus:ring-1 focus:ring-emerald-500 transition duration-300 bg-green_medium'
const neutralButtonClass =
  'rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2'
const dangerButtonClass =
  'rounded-md border py-2 bg-red-600 px-4 text-sm text-white font-medium hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800'
const actionButtonClass =
  'flex-shrink-0 p-1 text-emerald-200 hover:text-emerald-600 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2'

function Button(
  {
    actionLabel,
    children,
    classes = '',
    color,
    label,
    icon,
    loading,
    message,
    onClick,
    disabled,
    type = 'button'
  },
  ref
) {
  let buttonClass

  switch (color) {
    case 'neutral':
      buttonClass = neutralButtonClass
      break
    case 'danger':
      buttonClass = dangerButtonClass
      break
    case 'action':
      buttonClass = actionButtonClass
      break
    default:
      buttonClass = infoButtonClass
  }
  return (
    <button
      className={` ${buttonClass} ${classes} `}
      // eslint-disable-next-line react/button-has-type
      type={type}
      ref={ref}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {icon || null}
      {loading ? <Loader message={message} /> : <p className="text-sm">{label}</p>}
      {actionLabel && <div className="text-emerald-600 hover:text-emerald-900">{actionLabel}</div>}
      {children}
    </button>
  )
}

export default React.forwardRef(Button)
