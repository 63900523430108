import React from 'react'

function ReportStats({ values }) {
  return (
    <div className="mb-4 flex gap-4">
      {values.map(([title, value, subvalue]) => (
        <div key={title} className="flex-1 rounded-md border border-gray-300 px-4 py-3">
          {title}
          <div className="font-semibold">{value}</div>
          <div className="text-sm font-semibold text-gray-500">{subvalue}</div>
        </div>
      ))}
    </div>
  )
}

export default ReportStats
