import { gql } from '@apollo/client'

import USER_FRAGMENT from '../fragments/user'

const CURRENT_USER = gql`
  query CurrentUser {
    currentUser @client {
      ...User
    }
  }
  ${USER_FRAGMENT}
`

export default CURRENT_USER
