import { gql } from '@apollo/client'

const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      firstName
      phone
      lastName
      addressLine1
      addressLine2
      state
      city
      zipCode
      businessName
      kind
    }
  }
`

export default UPDATE_USER
