/* eslint-disable no-unsafe-optional-chaining */
import * as yup from 'yup'
import dayjs from 'dayjs'
import React from 'react'
import toast from 'react-hot-toast'
import { Field, Form, Formik } from 'formik'
import { useMutation } from '@apollo/client'

import BaseModal from '../modal/BaseModal'
import Button from '../buttons/Button'
import CREATE_PROFILE from '../../gql/mutations/createProfile'
import GET_SUMMARY from '../../gql/queries/getSummary'
import Input from '../inputs/Input'
import PROFILES from '../../gql/queries/Profiles'
import SignedInput from '../SignedInput'
import UPDATE_PROFILE from '../../gql/mutations/updateProfile'
import CustomDateInput from '../inputs/CustomDateInput'

const intialValues = {
  livestockKind: undefined,
  cowsCount: undefined,
  calvingDate: undefined,
  saleDate: undefined,
  bullsSaleCount: undefined,
  bullsInWeight: undefined,
  bullsSaleWeight: undefined,
  heiferSaleCount: undefined,
  heiferInWeight: undefined,
  heiferSaleWeight: undefined,
  heiferRetainCount: undefined,
  description: '',
  headsCount: undefined,
  deathsCount: undefined,
  inWeight: undefined,
  inDate: 0,
  dailyGain: undefined,
  outWeight: undefined,
  notes: ''
}

const cowCalfClientsSchema = yup.object({
  // description: yup.string().required('Description is required'),
  calvingDate: yup.date().required('Calving date is required'),
  saleDate: yup
    .date()
    .min(yup.ref('calvingDate'), 'Sale date must be after calving date')
    .required('Sale date is required'),
  bullsInWeight: yup.number().required('In weight is required'),
  bullsSaleWeight: yup
    .number()
    .min(yup.ref('bullsInWeight'), 'Sale weight must be greater than in weight')
    .required('Sale weight is required'),
  heiferInWeight: yup.number().required('In weight is required'),
  heiferSaleWeight: yup
    .number()
    .min(yup.ref('heiferInWeight'), 'Sale weight must be greater than in weight')
    .required('Sale weight is required'),
  cowsCount: yup.number().required('Required'),
  bullsSaleCount: yup
    .number()
    .max(yup.ref('cowsCount'), `Total calves should not exceed all cows`)
    .typeError('Bulls/Steers to sale must be a number'),
  heiferSaleCount: yup
    .number()
    // eslint-disable-next-line func-names
    .test('max', 'Total calves should not exceed all cows', function (value, context) {
      const { bullsSaleCount } = this.parent
      if (bullsSaleCount) return value <= context.parent.cowsCount - bullsSaleCount
      return value <= context.parent.cowsCount
    })
})

const clientsSchema = yup.object({
  // description: yup.string().required('Description is required'),
  inDate: yup.date().required('In date is required'),
  inWeight: yup.number().required('In weight is required'),
  outWeight: yup
    .number()
    .min(yup.ref('inWeight'), 'Out weight must be greater than in weight')
    .required('Out weight is required'),
  deathsCount: yup.number(),
  headsCount: yup
    .number()
    .min(yup.ref('deathsCount'), 'Head count must be greater than death count')
})

function LivestockProfileModal({
  clientId,
  year,
  profile,
  isOpen,
  livestockKind,
  isLiveCattle,
  onClose
}) {
  const [createProfile] = useMutation(CREATE_PROFILE)
  const [updateProfile] = useMutation(UPDATE_PROFILE)
  const isCreate = !profile?.id

  const updatedInitialValues = {
    livestockKind: profile?.livestockKind,
    cowsCount: profile?.cowsCount,
    calvingDate: dayjs(profile?.calvingDate).format('MM/DD/YYYY'),
    saleDate: dayjs(profile?.saleDate).format('MM/DD/YYYY'),
    bullsSaleCount: +profile?.bullsSaleCount,
    bullsInWeight: +profile?.bullsInWeight,
    bullsSaleWeight: +profile?.bullsSaleWeight,
    heiferSaleCount: +profile?.heiferSaleCount,
    heiferInWeight: +profile?.heiferInWeight,
    heiferSaleWeight: +profile?.heiferSaleWeight,
    heiferRetainCount: +profile?.heiferRetainCount,
    description: profile?.description,
    headsCount: profile?.headsCount,
    deathsCount: profile?.deathsCount,
    inWeight: profile?.inWeight,
    inDate: dayjs(profile?.inDate).format('MM/DD/YYYY'),
    dailyGain: profile?.dailyGain,
    outWeight: profile?.outWeight,
    notes: profile?.notes
  }

  const createCowCalfProfile = async (value) => {
    toast.promise(
      await createProfile({
        variables: {
          input: {
            clientId,
            productionYearId: year[0]?.id,
            kind: 'LIVESTOCK',
            livestockKind: 'COW_CALF',
            cowsCount: value?.cowsCount,
            calvingDate: value?.calvingDate,
            saleDate: value?.saleDate,
            bullsSaleCount: +value?.bullsSaleCount,
            bullsInWeight: +value?.bullsInWeight,
            bullsSaleWeight: +value?.bullsSaleWeight,
            heiferSaleCount: +value?.heiferSaleCount,
            heiferInWeight: +value?.heiferInWeight,
            heiferSaleWeight: +value?.heiferSaleWeight,
            heiferRetainCount: +value?.heiferRetainCount,
            description: value?.description,
            notes: value?.notes
          }
        },
        refetchQueries: [PROFILES, GET_SUMMARY]
      }),
      {
        loading: 'Creating the Cow-Calf...',
        success: 'Cow-Calf created successfully.',
        error: 'Error while creating the Cow-Calf.'
      }
    )
  }

  const createLiveCattleProfile = async (value) => {
    toast.promise(
      await createProfile({
        variables: {
          input: {
            clientId,
            productionYearId: year[0]?.id,
            kind: 'LIVESTOCK',
            livestockKind: 'LIVE_CATTLE',
            headsCount: value?.headsCount,
            deathsCount: value?.deathsCount,
            inWeight: value?.inWeight,
            inDate: value?.inDate,
            dailyGain: value?.dailyGain,
            outWeight: value?.outWeight,
            description: value?.description,
            notes: value?.notes
          }
        },
        refetchQueries: [PROFILES, GET_SUMMARY]
      }),
      {
        loading: 'Creating the Live Cattle...',
        success: 'Live Cattle created successfully.',
        error: 'Error while creating the Live Cattle.'
      }
    )
  }

  const createFeederCattleProfile = async (value) => {
    toast.promise(
      await createProfile({
        variables: {
          input: {
            clientId,
            productionYearId: year[0]?.id,
            kind: 'LIVESTOCK',
            livestockKind: 'FEEDER_CATTLE',
            headsCount: value?.headsCount,
            deathsCount: value?.deathsCount,
            inWeight: value?.inWeight,
            inDate: value?.inDate,
            dailyGain: value?.dailyGain,
            outWeight: value?.outWeight,
            description: value?.description,
            notes: value?.notes
          }
        },
        refetchQueries: [PROFILES, GET_SUMMARY]
      }),
      {
        loading: 'Creating the Feeder Cattle...',
        success: 'Feeder Cattle created successfully.',
        error: 'Error while creating the Feeder Cattle.'
      }
    )
  }

  const updateCowCalfProfile = async (value) => {
    toast.promise(
      await updateProfile({
        variables: {
          input: {
            id: profile.id,
            kind: 'LIVESTOCK',
            livestockKind: 'COW_CALF',
            cowsCount: value?.cowsCount,
            calvingDate: value?.calvingDate,
            saleDate: value?.saleDate,
            bullsSaleCount: +value?.bullsSaleCount,
            bullsInWeight: +value?.bullsInWeight,
            bullsSaleWeight: +value?.bullsSaleWeight,
            heiferSaleCount: +value?.heiferSaleCount,
            heiferInWeight: +value?.heiferInWeight,
            heiferSaleWeight: +value?.heiferSaleWeight,
            heiferRetainCount: +value?.heiferRetainCount,
            description: value?.description,
            notes: value?.notes
          }
        },
        refetchQueries: [PROFILES]
      }),
      {
        loading: 'Updating the Cow-Calf...',
        success: 'Cow-Calf updated successfully.',
        error: 'Error while updating the Cow-Calf.'
      }
    )
  }

  const updateLiveCattleProfile = async (value) => {
    toast.promise(
      await updateProfile({
        variables: {
          input: {
            id: profile.id,
            kind: 'LIVESTOCK',
            livestockKind: 'LIVE_CATTLE',
            headsCount: value?.headsCount,
            deathsCount: value?.deathsCount,
            inWeight: value?.inWeight,
            inDate: value?.inDate,
            dailyGain: value?.dailyGain,
            outWeight: value?.outWeight,
            description: value?.description,
            notes: value?.notes
          }
        },
        refetchQueries: [PROFILES]
      }),
      {
        loading: 'Updating the Live Cattle...',
        success: 'Live Cattle updated successfully.',
        error: 'Error while updating the Live Cattle.'
      }
    )
  }

  const updateFeederCattleProfile = async (value) => {
    toast.promise(
      await updateProfile({
        variables: {
          input: {
            id: profile.id,
            kind: 'LIVESTOCK',
            livestockKind: 'FEEDER_CATTLE',
            headsCount: value?.headsCount,
            deathsCount: value?.deathsCount,
            inWeight: value?.inWeight,
            inDate: value?.inDate,
            dailyGain: value?.dailyGain,
            outWeight: value?.outWeight,
            description: value?.description,
            notes: value?.notes
          }
        },
        refetchQueries: [PROFILES]
      }),
      {
        loading: 'Updating the Feeder Cattle...',
        success: 'Feeder Cattle updated successfully.',
        error: 'Error while updating the Feeder Cattle.'
      }
    )
  }

  const onSubmitCowCalf = (value, { resetForm }) => {
    if (isCreate) {
      createCowCalfProfile(value)
    } else {
      updateCowCalfProfile(value)
    }
    onClose()
    resetForm()
  }

  const onSubmitCattle = (value, { resetForm }) => {
    if (isCreate) {
      if (isLiveCattle) {
        createLiveCattleProfile(value)
      } else {
        createFeederCattleProfile(value)
      }
    } else if (isLiveCattle) {
      updateLiveCattleProfile(value)
    } else {
      updateFeederCattleProfile(value)
    }
    onClose()
    resetForm()
  }

  let modal
  let daysOnFeed
  if (livestockKind === 'COW_CALF') {
    modal = (
      <Formik
        initialValues={isCreate ? intialValues : updatedInitialValues}
        validationSchema={cowCalfClientsSchema}
        onSubmit={onSubmitCowCalf}
      >
        <Form>
          <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="flex-col md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {isCreate ? 'Add' : 'Edit'}&nbsp;cow-calf
                </h3>
              </div>
              <div className="mt-5 md:col-span-2 md:mt-0">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <Input label="Description" type="text" name="description" />
                  </div>
                  <div className="col-span-6">
                    <SignedInput
                      label="All cows"
                      type="number"
                      min="0"
                      name="cowsCount"
                      unit="cows"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <CustomDateInput
                      label="Estimated calving date"
                      placeholderText="Select Calving date"
                      width="w-[180px]"
                      name="calvingDate"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <CustomDateInput
                      label="Estimated sale date"
                      placeholderText="Select Sale date"
                      width="w-[180px]"
                      name="saleDate"
                    />
                  </div>
                  <div className="col-span-6">
                    <label htmlFor="notes" className="block text-sm font-bold text-gray-700">
                      Notes
                    </label>
                    <div className="mt-1">
                      <Field
                        as="textarea"
                        rows={3}
                        type="notes"
                        id="notes"
                        name="notes"
                        className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                      />
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      Any notes related to this commodity
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 md:grid md:grid-cols-3 md:gap-6">
              <div className="flex-col md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Calves</h3>
                {/* <div className=" mt-16 flex-col bg-gray-300 rounded-lg shadow px-5 py-6">
                              <div className=" flex">Heifer : 0.00</div>
                              <div className=" flex">Bulls/Steers : 0.00</div>
                              <div className=" flex justify-center text-gray-500 text-xs">
                                DAILY GAIN
                              </div>
                            </div> */}
              </div>
              <div className="mt-5 md:col-span-2 md:mt-0">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <Input
                      label="Bulls/Steers to sell"
                      type="number"
                      min="0"
                      width="w-[180px]"
                      name="bullsSaleCount"
                    />
                    <div className="mt-2">
                      <Input
                        label="In weight"
                        type="number"
                        min="0"
                        step="0.01"
                        width="w-[180px]"
                        name="bullsInWeight"
                      />
                    </div>
                    <div className="mt-2">
                      <Input
                        label="Sale weight"
                        type="number"
                        min="0"
                        step="0.01"
                        width="w-[180px]"
                        name="bullsSaleWeight"
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Input
                      label="Heifers to sell"
                      type="number"
                      min="0"
                      width="w-[180px]"
                      name="heiferSaleCount"
                    />
                    <div className="mt-2">
                      <Input
                        label="In weight"
                        type="number"
                        min="0"
                        step="0.01"
                        width="w-[180px]"
                        name="heiferInWeight"
                      />
                    </div>
                    <div className="mt-2">
                      <Input
                        label="Sale weight"
                        type="number"
                        min="0"
                        step="0.01"
                        width="w-[180px]"
                        name="heiferSaleWeight"
                      />
                    </div>
                    <div className="mt-2">
                      <Input
                        label="Heifers to retain"
                        type="number"
                        min="0"
                        name="heiferRetainCount"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex justify-end gap-x-2">
            <Button type="button" color="neutral" label="Cancel" onClick={onClose} />
            <Button type="submit" color="info" label="Save" />
          </div>
        </Form>
      </Formik>
    )
  } else if (livestockKind === 'CATTLE') {
    modal = (
      <Formik
        initialValues={isCreate ? intialValues : updatedInitialValues}
        validationSchema={clientsSchema}
        onSubmit={onSubmitCattle}
      >
        {({ values }) => {
          const sellable = values?.headsCount - values?.deathsCount
          const feedCount = (values?.outWeight - values?.inWeight) / values?.dailyGain
          const date = new Date(values?.inDate)
          const outDate = date.setDate(date.getDate() + feedCount)
          const totalWeight = values?.outWeight * sellable
          const contractUnit = isLiveCattle ? 40000 : 50000
          const contract = totalWeight / contractUnit
          const deathPercentage = values?.headsCount
            ? (values?.deathsCount / values.headsCount) * 100
            : 0
          if (feedCount === Infinity) {
            daysOnFeed = 0
          } else if (feedCount > 0) {
            daysOnFeed = Math.round(feedCount)
          } else {
            daysOnFeed = 0
          }
          return (
            <Form>
              <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="flex-col md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      {isCreate ? 'Add' : 'Edit'}&nbsp;{isLiveCattle ? 'live' : 'feeder'}
                      &nbsp;cattle
                    </h3>
                    <div className="mt-8 flex flex-col">
                      <div className="-my-2 min-w-full overflow-x-auto">
                        <div className="inline-block min-w-full py-2 px-0.5 align-middle">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <tbody className="divide-y divide-gray-200 bg-gray-300">
                                <tr>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-500 sm:pl-6">
                                    TOTAL TO SELL
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                                    <div className="flex items-baseline">
                                      <p className="flex items-end text-base">
                                        {Number.isNaN(sellable) ? 0 : sellable}&nbsp;
                                      </p>
                                      <p className="flex items-end text-xs text-gray-500">
                                        {sellable > 1 ? 'heads' : 'head'}
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-500 sm:pl-6">
                                    OUT DATE
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-base font-medium text-gray-900">
                                    {outDate ? dayjs(outDate).format('MM/DD/YYYY') : '-'}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-500 sm:pl-6">
                                    DAYS ON FEED
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                                    <div className="flex items-baseline">
                                      <p className="flex items-end text-base">{daysOnFeed}&nbsp;</p>
                                      <p className="flex items-end text-xs text-gray-500">days</p>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-gray-500 sm:pl-6">
                                    TOTAL WEIGHT
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                                    <div className="flex items-baseline">
                                      <p className="flex items-end text-base">
                                        {totalWeight > 0
                                          ? new Intl.NumberFormat().format(totalWeight)
                                          : 0}
                                        &nbsp;
                                      </p>
                                      <p className="flex items-end text-xs text-gray-500">lb</p>
                                    </div>
                                    <div className="mt-2 text-xs text-gray-500">
                                      {Number.isNaN(contract) ? 0 : contract.toFixed(2)} contracts
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6">
                        <Input label="Description" type="text" name="description" />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <SignedInput
                          label="Number of heads"
                          type="number"
                          min="0"
                          name="headsCount"
                          unit="head"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <SignedInput
                          label="Death loss"
                          type="number"
                          min="0"
                          name="deathsCount"
                          unit="head"
                        />
                        <div className="mt-1 text-xs text-gray-500">
                          ({deathPercentage > 0 ? deathPercentage.toFixed(2) : 0}
                          &nbsp;%)
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <SignedInput
                          label="In weight"
                          type="number"
                          min="0"
                          step="0.01"
                          name="inWeight"
                          unit="lb"
                          width="w-[260px]"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <CustomDateInput
                          label="In date"
                          placeholderText="Select Indate"
                          name="inDate"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <SignedInput label="Daily gain" type="number" name="dailyGain" unit="lb" />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <SignedInput
                          label="Out weight"
                          type="number"
                          min="0"
                          step="0.01"
                          name="outWeight"
                          unit="lb"
                          width="w-[260px]"
                        />
                      </div>
                      <div className="col-span-6">
                        <label htmlFor="notes" className="block text-sm font-bold text-gray-700">
                          Notes
                        </label>
                        <div className="mt-1">
                          <Field
                            as="textarea"
                            rows={3}
                            type="notes"
                            id="notes"
                            name="notes"
                            className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                          />
                        </div>
                        <p className="mt-2 text-sm text-gray-500">
                          Any notes related to this commodity
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-6 flex justify-end gap-x-2">
                <Button type="button" color="neutral" label="Cancel" onClick={onClose} />
                <Button type="submit" color="info" label="Save" />
              </div>
            </Form>
          )
        }}
      </Formik>
    )
  }

  return (
    <BaseModal isOpen={isOpen} closeModal={onClose}>
      <div className="space-y-6">{modal}</div>
    </BaseModal>
  )
}

export default LivestockProfileModal
