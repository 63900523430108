import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

function Header({ children }) {
  const headerRef = useRef()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    headerRef.current = document.getElementById('header')
    setMounted(true)
  }, [])

  if (!mounted) return null

  return ReactDOM.createPortal(
    <h1 className="text-3xl font-bold text-white">{children}</h1>,
    headerRef.current
  )
}

export default Header
