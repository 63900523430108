import { gql } from '@apollo/client'

const GET_PROFILE_PROFIT_GOALS = gql`
  query getProfileProfitGoals($profileIds: [String!]!) {
    getProfileProfitGoals(profileIds: $profileIds) {
      id
      profileId
      drylandProfitTarget
      irrigatedProfitTarget
      livestockProfitTarget
    }
  }
`

export default GET_PROFILE_PROFIT_GOALS
