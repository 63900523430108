import * as yup from 'yup'
import React from 'react'
import { Form, Formik } from 'formik'
import { Helmet } from 'react-helmet'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import Button from '../../components/buttons/Button'
import Input from '../../components/inputs/Input'
import SET_PASSWORD from '../../gql/mutations/setPassword'
import AlertBox from '../../components/AlertBox'
import { useAuth } from '../../hooks/useAuth'
import ExternalHeading from '../../components/typography/ExternalHeading'
import ExternalFormWrapper from '../../components/wrappers/ExternalFormWrapper'
import ExternalFieldWrapper from '../../components/wrappers/ExternalFieldWrapper'

const validationSchema = yup.object({
  password: yup.string().required('Password is required'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
})

function SetPasswordPage() {
  const [setPassword, { loading, error }] = useMutation(SET_PASSWORD)
  const navigate = useNavigate()
  const auth = useAuth()

  const onSubmit = async (values) => {
    setPassword({
      variables: {
        password: values?.password
      },
      onCompleted: () => {
        navigate(`/clients/${auth?.user?.id}`)
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>Set Password</title>
      </Helmet>
      <ExternalHeading>Set Password</ExternalHeading>
      <ExternalFormWrapper>
        <Formik
          initialValues={{
            confirmPassword: '',
            password: ''
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isValid }) => (
            <Form>
              {error && (
                <AlertBox message="Some error occured while setting your password. Please try again." />
              )}
              <ExternalFieldWrapper>
                <Input label="Password" type="password" name="password" />
              </ExternalFieldWrapper>
              <ExternalFieldWrapper>
                <Input label="Confirm Password" type="password" name="confirmPassword" />
              </ExternalFieldWrapper>
              <Button
                type="submit"
                classes="w-full"
                color="info"
                label="Set Password"
                loading={loading}
                disabled={!isValid || loading}
                message="Submitting"
              />
            </Form>
          )}
        </Formik>
      </ExternalFormWrapper>
    </>
  )
}

export default SetPasswordPage
