import React, { useState, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { useMutation, useQuery } from '@apollo/client'

import AlertBox from '../components/AlertBox'
import ARCHIVE_USER from '../gql/mutations/archiveUser'
import Button from '../components/buttons/Button'
import ClientModal from '../components/modals/ClientModal'
import DELETE_USER from '../gql/mutations/deleteUser'
import DeleteModal from '../components/modals/DeleteModal'
import GET_USERS from '../gql/queries/getUsers'
import Header from '../components/Header'
import INVITE_USER from '../gql/mutations/inviteUser'
import Loader from '../components/Loader'
import Pagination from '../components/Pagination'
import RESTORE_ARCHIVED_USER from '../gql/mutations/restoreArchivedUser'
import SearchInput from '../components/SearchInput'
import TableWrapper from '../components/wrappers/TableWrapper'
import {
  getName,
  getUserActionStatus,
  getUserManagementStateLabel,
  getUserStatus,
  sortHandler
} from '../utils/helper'
import downArrow from '../assets/downArrow.png'
import SetPasswordModal from '../components/modals/SetPasswordModal'
import MultiSelect from '../components/MultiSelect'
import roles from '../constants/roles'

const userListStatuses = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED'
}

const userStatuses = [
  {
    label: 'Active',
    value: 'ACTIVE'
  },
  {
    label: 'Archived',
    value: 'ARCHIVED'
  },
  {
    label: 'Invited',
    value: 'INVITED'
  },
  {
    label: 'Not Invited',
    value: 'NOT_INVITED'
  }
]

export default function ClientsPage() {
  const [user, setUser] = useState({})
  const [createusermodal, setCreateUserModal] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [deleteUser] = useMutation(DELETE_USER)
  const [archiveUser] = useMutation(ARCHIVE_USER)
  const [restoreArchivedUser] = useMutation(RESTORE_ARCHIVED_USER)
  const [inviteUser] = useMutation(INVITE_USER)

  const { error, loading, data, refetch } = useQuery(GET_USERS, {
    variables: {
      kinds: [roles.CLIENT],
      search: searchValue
    }
  })

  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(10)
  const [statuses, setStatuses] = useState([])
  const statusFilteredUsers = useMemo(() => {
    const users = data?.getUsers ?? []

    if (statuses.length) {
      return users.filter((user) => statuses.includes(user?.status))
    }

    return users.filter((user) => !['ARCHIVED'].includes(user?.status))
  }, [data?.getUsers, statuses])

  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const usersList = statusFilteredUsers.slice(indexOfFirstPost, indexOfLastPost)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)
  const [sortOrder, setSortOrder] = useState()
  const [sortKey, setSortKey] = useState()
  const [openSetPasswordModal, setOpenSetPasswordModal] = useState(false)

  const totalUsers = statusFilteredUsers.length
  const userAction = getUserActionStatus(user)

  const [open, setOpen] = useState(false)

  const closeModal = () => {
    setUser({})
    setOpen(false)
  }

  const handleCloseModal = () => {
    setUser(undefined)
    setCreateUserModal(false)
  }

  const deleteUserProfile = async () => {
    toast.promise(
      await deleteUser({
        variables: {
          input: {
            id: user?.id
          }
        },
        refetchQueries: [GET_USERS]
      }),
      {
        loading: "Deleting this user's account",
        success: 'Account deleted successfully.',
        error: "Error while deleting this user's account"
      }
    )
  }

  const handleArchiveUser = async () => {
    toast.promise(
      archiveUser({
        variables: {
          clientId: user?.id
        },
        refetchQueries: [GET_USERS],
        onCompleted: () => refetch()
      }),
      {
        loading: "Archiving this user's account",
        success: 'Account archived successfully.',
        error: "Error while archiving this user's account"
      },
      { duration: 5000 }
    )
  }

  const handleRestoreArchiveUser = async () => {
    toast.promise(
      restoreArchivedUser({
        variables: {
          clientId: user?.id
        },
        refetchQueries: [GET_USERS]
      }),
      {
        loading: "Restoring this user's account",
        success: 'Account restored successfully.',
        error: "Error while restoring this user's account"
      }
    )
  }

  const handleUserManagement = async () => {
    let manageUser
    if (user.status === 'NOT_INVITED' || user?.status === 'INVITED') {
      manageUser = inviteUser
    }

    toast.promise(
      manageUser({
        variables: {
          input: {
            id: user?.id
          }
        }
      }),
      {
        // eslint-disable-next-line prettier/prettier
        loading: `${getUserManagementStateLabel('LOADING', userAction)
          ?.charAt(0)
          ?.toUpperCase()}${getUserManagementStateLabel('LOADING', userAction)
          ?.slice(1)
          ?.toLowerCase()} the  account...`,
        success: `Account ${getUserManagementStateLabel('SUCCESS', userAction)} successfully.`,
        error: `Error while  ${getUserManagementStateLabel('FAILED', userAction)} the account.`
      }
    )
  }

  return (
    <>
      <Helmet>
        <title>Customers</title>
      </Helmet>
      <Header>Customers </Header>
      <div className="relative">
        <div className="flex flow-root sm:flex-row sm:items-center">
          <SearchInput placeholder="Search for a customer" onChange={setSearchValue} />
          <div className="float-right mt-4 flex flex-row gap-2 sm:mt-0 sm:ml-16">
            <div className="-mt-1">
              <MultiSelect
                setValue={(name, values) => {
                  setStatuses(values)
                  setCurrentPage(1)
                }}
                fieldName="statuses"
                options={userStatuses}
                placeholder="Filter by status"
              />
            </div>
            <div>
              <Button
                type="button"
                color="info"
                label="New Client"
                onClick={() => {
                  setCreateUserModal('CLIENT')
                  setOpen(!open)
                }}
              />
            </div>
          </div>
        </div>
        {loading && !data && <Loader message="Fetching data..." variant="fetchData" />}
        {error && <AlertBox message="Oops! Something went wrong while fetching data." />}

        {data && (
          <div className="sm:text-justify">
            <div className="mt-8 flex flex-col">
              {searchValue && !data?.getUsers.length ? (
                <AlertBox message="No results for this search." />
              ) : (
                <div className="-my-2 min-w-full overflow-x-auto">
                  <div className="inline-block min-w-full py-2 px-0.5 align-middle">
                    {usersList?.length > 0 ? (
                      <TableWrapper
                        columns={[
                          <div
                            className="flex cursor-pointer items-center gap-2"
                            onClick={() => {
                              setSortKey('businessName')
                              setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                            }}
                            aria-hidden="true"
                          >
                            <span>Name</span>
                            {sortKey && sortKey !== 'status' && (
                              <div
                                style={{
                                  rotate:
                                    sortOrder === 'asc' && sortKey !== 'status' ? '180deg' : '0deg'
                                }}
                              >
                                <img src={downArrow} alt="" height={7} width={7} />
                              </div>
                            )}
                          </div>,
                          <div
                            className="flex cursor-pointer items-center gap-2"
                            onClick={() => {
                              setSortKey('status')
                              setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                            }}
                            aria-hidden="true"
                          >
                            <span>Status</span>
                            {sortKey && sortKey === 'status' && (
                              <div
                                style={{
                                  rotate:
                                    sortOrder === 'asc' && sortKey === 'status' ? '180deg' : '0deg'
                                }}
                              >
                                <img src={downArrow} alt="" height={7} width={7} />
                              </div>
                            )}
                          </div>,
                          'Actions'
                        ]}
                        rows={sortHandler(usersList, sortOrder, sortKey)?.map((item) => [
                          <Link to={`/clients/${item?.id}`}>{getName(item)}</Link>,
                          <div className="flex justify-start pl-3">{getUserStatus(item)}</div>,
                          <div className="flex justify-end">
                            <Button
                              actionLabel="Edit"
                              color="action"
                              onClick={() => {
                                setUser(item)
                                setCreateUserModal('CLIENT')
                                setOpen(!open)
                              }}
                            />
                            <Button
                              actionLabel="Set Password"
                              color="action"
                              onClick={() => {
                                setUser(item)
                                setOpenSetPasswordModal(true)
                              }}
                            />
                            <Button
                              actionLabel="Delete"
                              color="action"
                              onClick={() => {
                                setUser(item)
                                setCreateUserModal('DELETE')
                              }}
                            />
                            {(item?.status === userListStatuses.ACTIVE ||
                              item?.status === userListStatuses.ARCHIVED) && (
                              <Button
                                actionLabel={
                                  item?.status === userListStatuses.ACTIVE ? 'Archive' : 'Restore'
                                }
                                color="action"
                                onClick={() => {
                                  setUser(item)
                                  setCreateUserModal(
                                    item?.status === userListStatuses.ACTIVE
                                      ? 'ARCHIVE'
                                      : 'RESTORE_ARCHIVED'
                                  )
                                }}
                              />
                            )}
                          </div>
                        ])}
                      />
                    ) : (
                      <div>No users found</div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {usersList?.length > 0 && (
              <Pagination
                data={usersList}
                postsPerPage={postsPerPage}
                totalPosts={totalUsers}
                paginate={paginate}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
        )}
      </div>
      <SetPasswordModal
        user={user}
        isOpen={openSetPasswordModal}
        closeModal={() => {
          setOpenSetPasswordModal(false)
        }}
      />

      <ClientModal isOpen={open} closeModal={closeModal} user={user} />

      {(createusermodal === 'DELETE' || createusermodal === 'MANAGE_USER') && (
        <DeleteModal
          title={getName(user)}
          isOpen={createusermodal === 'DELETE' || createusermodal === 'MANAGE_USER'}
          onClose={handleCloseModal}
          onSubmit={createusermodal === 'MANAGE_USER' ? handleUserManagement : deleteUserProfile}
          description={`Are you sure you want to ${
            createusermodal === 'MANAGE_USER' ? userAction?.toLowerCase() : 'delete'
          } this user?`}
        />
      )}
      <DeleteModal
        title={getName(user)}
        isOpen={createusermodal === 'ARCHIVE' || createusermodal === 'RESTORE_ARCHIVED'}
        onClose={handleCloseModal}
        onSubmit={
          user?.status === userListStatuses.ACTIVE ? handleArchiveUser : handleRestoreArchiveUser
        }
        description={`Are you sure you want to ${
          user?.status === userListStatuses.ACTIVE ? 'archive' : 'restore'
        } this user's account?`}
      />
    </>
  )
}
