import * as Sentry from '@sentry/react'
import React, { createContext, useEffect, useState, useMemo, useContext } from 'react'
import { useQuery } from '@apollo/client'

import CURRENT_USER from '../gql/mutations/currentUser'
import Loader from '../components/Loader'

const UserContext = createContext()

function UserProvider({ children }) {
  const [token, setToken] = useState(() => localStorage.getItem('token'))
  const { data, loading } = useQuery(CURRENT_USER, { fetchPolicy: 'cache-first', skip: !token })

  const value = useMemo(
    () => ({
      currentUser: data?.currentUser ? { user: data?.currentUser, token } : null,
      setToken
    }),
    [data?.currentUser, token]
  )

  useEffect(() => {
    if (value.user) {
      Sentry.setUser({
        id: value.user.id,
        email: value.user.email
      })
    } else {
      Sentry.setUser(null)
    }
  }, [value])

  if (loading && !data) {
    return <Loader variant="fetchData" />
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

const useUserContext = () => useContext(UserContext)

export { useUserContext, UserProvider }
