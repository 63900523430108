import { gql } from '@apollo/client'

const RESTORE_ARCHIVED_USER = gql`
  mutation RestoreArchivedUser($clientId: String!) {
    restoreArchivedUser(clientId: $clientId) {
      id
      firstName
      phone
      lastName
      addressLine1
      addressLine2
      state
      city
      zipCode
      businessName
      kind
    }
  }
`

export default RESTORE_ARCHIVED_USER
