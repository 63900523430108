const saleTabs = [
  {
    label: 'Details'
  },
  {
    label: 'Deliveries'
  },
  {
    label: 'Adjustments'
  }
]

export default saleTabs
