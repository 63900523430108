import { gql } from '@apollo/client'

const CREATE_BUYER = gql`
  mutation createBuyer($input: CreateBuyerInput!) {
    createBuyer(input: $input) {
      id
      name
      state
      city
    }
  }
`

export default CREATE_BUYER
