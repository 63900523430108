import React from 'react'
import { Outlet, Navigate, useLocation } from 'react-router-dom'

import { VISITOR_ROUTES } from '../../constants/routes'
import { useAuth } from '../../hooks/useAuth'

export default function RequireUser({ allowedRoles }) {
  const auth = useAuth()
  const location = useLocation()

  if (!auth?.user?.id) {
    return <Navigate to={VISITOR_ROUTES.LOGIN} state={{ from: location }} replace />
  }

  const userRole = auth.user.kind
  const userHasRequiredRole = allowedRoles.includes(userRole)

  if (!userHasRequiredRole) {
    return <Navigate to={VISITOR_ROUTES.UNAUTHORIZED} state={{ from: location }} replace />
  }

  return <Outlet />
}
