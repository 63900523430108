import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { USER_ROUTES } from '../constants/routes'
import Button from '../components/buttons/Button'

function NotFoundPage() {
  const navigate = useNavigate()

  const routeHome = () => {
    navigate(USER_ROUTES.HOME)
  }

  return (
    <>
      <Helmet>
        <title>Not Found</title>
      </Helmet>
      <div className="flex h-screen w-screen flex-col items-center justify-center ">
        <h1 className="text-6xl font-bold md:text-7xl lg:text-9xl">404</h1>
        <p className="text-2xl md:text-3xl">The URL of the page was not found.</p>
        <Button type="button" classes="mt-6" color="info" label="Go Home" onClick={routeHome} />
      </div>
    </>
  )
}

export default NotFoundPage
