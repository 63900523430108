import React, { useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { PauseIcon, PlayIcon } from '@heroicons/react/outline'
import toast from 'react-hot-toast'
import MuxAudio from '@mux/mux-audio-react'

import Header from '../components/Header'
import GET_COMMENTARIES from '../gql/queries/getCommentaries'
import Loader from '../components/Loader'

const PlayerState = {
  PAUSED: 'PAUSED',
  PLAYING: 'PLAYING'
}

function ActionIcon({ playerState }) {
  switch (playerState) {
    case PlayerState.PAUSED:
      return <PlayIcon className="h-8 w-8 text-green_medium" />
    case PlayerState.PLAYING:
      return <PauseIcon className="h-8 w-8 text-gray-400" />
    default:
      return null
  }
}

function MarketCommentaryPage() {
  const { data, loading } = useQuery(GET_COMMENTARIES)
  const commentaries = data?.getCommentaries || []

  const [nowPlayingAudioUrl, setNowPlayingAudioUrl] = useState(null)
  const [playerState, setPlayerState] = useState(PlayerState.PAUSED)
  const playerRef = useRef()

  const playPauseAudio = (url) => {
    if (!url) {
      toast.error('Something went wrong.')
      setNowPlayingAudioUrl(null)
      return
    }

    if (nowPlayingAudioUrl === url && playerState === PlayerState.PLAYING) {
      setPlayerState(PlayerState.PAUSED)
      playerRef.current.pause()
      return
    }

    setPlayerState(PlayerState.PLAYING)
    playerRef.current.play()
    setNowPlayingAudioUrl(url)
  }

  if (loading) {
    return <Loader message="Fetching data..." variant="fetchData" />
  }

  return (
    <>
      <Helmet>
        <title>Market Commentary</title>
      </Helmet>
      <Header>Market Commentary</Header>
      <h1 className="text-xl font-semibold text-gray-900">Market Commentary</h1>
      <div className="flex flex-col divide-y py-4">
        {commentaries.map((commentary) => (
          <div key={commentary.id} className="flex items-center py-2 px-1">
            <div>
              <h2 className="font-bold">{commentary.title}</h2>
              <p className="text-xs">{dayjs(commentary.postedAt).format('MMM DD, YYYY')}</p>
            </div>

            <button
              type="button"
              className="ml-auto"
              disabled={[PlayerState.BUFFERING, PlayerState.LOADING].includes(playerState)}
              onClick={() => playPauseAudio(commentary.audioUrl)}
            >
              <ActionIcon
                playerState={
                  nowPlayingAudioUrl === commentary.audioUrl ? playerState : PlayerState.PAUSED
                }
              />
            </button>
          </div>
        ))}
      </div>

      <div
        className={`fixed bottom-0 left-0 right-0 border-t md:left-64 ${
          !nowPlayingAudioUrl ? 'hidden' : ''
        }`}
      >
        <MuxAudio
          ref={playerRef}
          src={nowPlayingAudioUrl}
          type="hls"
          className="w-full [&::-webkit-media-controls-panel]:bg-white"
          autoPlay
          controls
        />
      </div>
    </>
  )
}

export default MarketCommentaryPage
