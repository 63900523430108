import { gql } from '@apollo/client'

import USER_FRAGMENT from '../fragments/user'

const INVITE_USER = gql`
  mutation inviteUser($input: UserManagementInput!) {
    inviteUser(input: $input) {
      ...User
    }
  }
  ${USER_FRAGMENT}
`

export default INVITE_USER
