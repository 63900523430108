import * as yup from 'yup'
import React, { useState, useEffect } from 'react'
import uniqBy from 'lodash/uniqBy'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { toast } from 'react-hot-toast'
import { useMutation } from '@apollo/client'

import BaseModal from '../modal/BaseModal'
import Button from '../buttons/Button'
import SignedInput from '../SignedInput'
import GET_FUTURE_MONTHS from '../../gql/queries/getFutureMonths'
import MODIFY_FUTURE_MONTH from '../../gql/mutations/updateFutureMonth'
import { onwardsYears } from '../../constants/years'
import BULK_MODIFY_FUTURE_MONTH from '../../gql/mutations/bulkUpdateFutureMonth'

const validationSchema = yup.object({
  price: yup.number().required('Price is required'),
  cropId: yup.string().required('Crop is required'),
  year: yup.string().required('Year is required'),
  month: yup.string().required('Month is required')
})

export default function SetFuturesPriceModal({ isOpen, closeModal, crops, mode = 'ADD', item }) {
  const years = onwardsYears()
  const [months, setMonths] = useState([])

  const futuresNames = uniqBy(crops, 'futuresName').filter((item) => !!item.futuresName)

  useEffect(() => {
    handleChangeMonth(
      mode === 'BULK_ADD' ? futuresNames[0]?.futuresName : item?.crop?.id || crops[0]?.id
    )
  }, [crops, item, mode])

  const initialValues = {
    cropId: item?.crop?.id || crops[0]?.id,
    year: item?.year,
    month: item?.month,
    price: item?.price || '',
    futuresName: futuresNames?.[0]?.futuresName || ''
  }

  const handleChangeMonth = (cropIdOrFutureName) => {
    if (mode === 'BULK_ADD') {
      setMonths(crops.find((crop) => crop.futuresName === cropIdOrFutureName)?.tradingMonths || [])
    } else {
      const crop = crops.find((item) => item.id === cropIdOrFutureName)
      setMonths(crop?.tradingMonths || [])
    }
  }

  const [updateFuturePricing] = useMutation(MODIFY_FUTURE_MONTH)
  const [bulkUpdateFuturePricing] = useMutation(BULK_MODIFY_FUTURE_MONTH)

  const onSubmit = async (values) => {
    if (mode === 'BULK_ADD') {
      toast.promise(
        bulkUpdateFuturePricing(
          {
            variables: {
              input: {
                futuresName: values?.futuresName,
                month: values?.month,
                price: values?.price,
                year: parseInt(values?.year, 10)
              }
            },
            onCompleted: () => closeModal(),
            refetchQueries: [GET_FUTURE_MONTHS]
          },
          {
            loading: 'Updating the price...',
            success: 'Price updated successfully.',
            error: 'Error while updating the price.'
          }
        )
      )
    } else {
      toast.promise(
        updateFuturePricing(
          {
            variables: {
              input: {
                cropId: values?.cropId,
                month: values?.month,
                price: values?.price,
                year: parseInt(values?.year, 10)
              }
            },
            onCompleted: () => closeModal(),
            refetchQueries: [GET_FUTURE_MONTHS]
          },
          {
            loading: 'Updating the price...',
            success: 'Price updated successfully.',
            error: 'Error while updating the price.'
          }
        )
      )
    }
  }

  return (
    <BaseModal isOpen={isOpen} closeModal={closeModal}>
      <div className="space-y-6">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => onSubmit(values)}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      {`${mode === 'EDIT' ? 'Edit' : 'Set'}`} Futures Price
                    </h3>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="grid grid-cols-12 gap-6">
                      {mode === 'BULK_ADD' ? (
                        <div className="col-span-12 sm:col-span-12">
                          <label
                            htmlFor="futuresName"
                            className="block text-sm font-bold text-gray-700"
                          >
                            Futures name
                          </label>
                          <Field
                            as="select"
                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                            type="futuresName"
                            id="futuresName"
                            name="futuresName"
                            onChange={(obj) => {
                              setFieldValue('futuresName', obj.target.value)
                              handleChangeMonth(obj.target.value)
                            }}
                          >
                            {futuresNames.map((item) => (
                              <option value={item.futuresName}>{item.futuresName}</option>
                            ))}
                          </Field>
                        </div>
                      ) : (
                        <div className="col-span-12 sm:col-span-12">
                          <label htmlFor="cropId" className="block text-sm font-bold text-gray-700">
                            Commodity name
                          </label>
                          <Field
                            as="select"
                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                            type="crop"
                            id="cropId"
                            name="cropId"
                            onChange={(obj) => {
                              setFieldValue('cropId', obj.target.value)
                              handleChangeMonth(obj.target.value)
                            }}
                          >
                            {crops.map((item) => (
                              <option value={item.id}>{item.name}</option>
                            ))}
                          </Field>
                        </div>
                      )}
                      <div className="col-span-12 sm:col-span-12">
                        <label htmlFor="year" className="block text-sm font-bold text-gray-700">
                          Year
                        </label>
                        <Field
                          as="select"
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                          type="year"
                          id="year"
                          name="year"
                        >
                          <option value="">Select Year</option>
                          {years.map((item) => (
                            <option value={item} key={item}>
                              {item}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="year"
                          component="p"
                          className="mt-1 text-sm font-medium text-red-600"
                        />
                      </div>
                      <div className="col-span-12 sm:col-span-12">
                        <label htmlFor="month" className="block text-sm font-bold text-gray-700">
                          Month
                        </label>
                        <Field
                          as="select"
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                          type="month"
                          id="month"
                          name="month"
                        >
                          <option value="">Select month</option>
                          {months.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="month"
                          component="p"
                          className="mt-1 text-sm font-medium text-red-600"
                        />
                      </div>
                      <div className="col-span-12 sm:col-span-12">
                        <SignedInput label="Price" type="number" min="0" name="price" unit="$" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex justify-end gap-x-2">
                <Button type="button" color="neutral" label="Cancel" onClick={closeModal} />
                <Button type="submit" color="info" label="Save" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </BaseModal>
  )
}
