import { gql } from '@apollo/client'

const UPDATE_SALE_ADJUSTMENT = gql`
  mutation updateSaleAdjustment($input: UpdateSaleAdjustmentInput!) {
    updateSaleAdjustment(input: $input) {
      id
      futuresMonth
      basisAmount
      totalProduction
    }
  }
`

export default UPDATE_SALE_ADJUSTMENT
