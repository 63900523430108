import React from 'react'
import { Form, Field, Formik } from 'formik'

import { useMutation } from '@apollo/client'
import * as yup from 'yup'
import toast from 'react-hot-toast'

import Input from '../inputs/Input'
import Button from '../buttons/Button'
import BaseModal from '../modal/BaseModal'

import CREATE_BUYER from '../../gql/mutations/createBuyer'
import GET_BUYERS from '../../gql/queries/getBuyers'
import UPDATE_BUYER from '../../gql/mutations/updateBuyer'

import states from '../../constants/states'

const renderList = (items) => (
  <div className="col-span-6 sm:col-span-3">
    <label htmlFor="kind" className="block text-sm font-bold text-gray-700">
      State
    </label>
    <Field
      as="select"
      className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
      type="state"
      id="state"
      name="state"
    >
      {items.map((state) => (
        <option key={state.ISO} value={state.name}>
          {state.name}
        </option>
      ))}
    </Field>
  </div>
)

function BuyerModal({ closeModal, initialValues, onCompleted, clientId, isOpen }) {
  const [createBuyer] = useMutation(CREATE_BUYER)
  const [updateBuyer] = useMutation(UPDATE_BUYER)

  const open = isOpen
  const type = initialValues?.id ? 'EDIT_BUYER' : 'CREATE_BUYER'
  const clientsSchema = yup.object({
    name: yup.string().required('Required'),
    city: yup.string().required('Required')
  })

  const handleBuyerSubmit = async (values) => {
    if (type === 'CREATE_BUYER') {
      if (!values.state) values.state = states[0].name

      toast.promise(
        createBuyer({
          variables: {
            input: {
              name: values?.name,
              city: values?.city,
              state: values?.state,
              clientId
            }
          },
          onCompleted: (buyersData) => {
            onCompleted?.(buyersData?.createBuyer)
          },
          refetchQueries: [GET_BUYERS]
        }),
        {
          loading: 'Creating the buyer...',
          success: 'Buyer created successfully.',
          error: 'Error while creating the buyer.'
        }
      )
    }
    if (type === 'EDIT_BUYER') {
      toast.promise(
        updateBuyer({
          variables: {
            input: {
              id: initialValues?.id,
              name: values?.name,
              city: values?.city,
              state: values?.state
            }
          },
          refetchQueries: [GET_BUYERS]
        }),
        {
          loading: 'Updating the buyer...',
          success: 'Buyer updated successfully.',
          error: 'Error while updating the buyer.'
        }
      )
    }

    closeModal()
  }

  return (
    <BaseModal isOpen={open} closeModal={closeModal}>
      <div className="space-y-6">
        <Formik
          initialValues={initialValues}
          validationSchema={clientsSchema}
          onSubmit={handleBuyerSubmit}
        >
          <Form>
            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-bold leading-6 text-gray-900">
                    {type === 'EDIT_BUYER' ? 'Edit buyer' : 'Add new buyer'}
                  </h3>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6">
                      <Input label="Buyer name" type="text" name="name" />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <Input label="City" type="text" name="city" />
                    </div>
                    {renderList(states)}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex justify-end gap-x-2">
              <Button type="button" color="neutral" label="Cancel" onClick={() => closeModal()} />
              <Button
                type="submit"
                color="info"
                label={type === 'EDIT_BUYER' ? ' Save' : 'Add new buyer'}
              />
            </div>
          </Form>
        </Formik>
      </div>
    </BaseModal>
  )
}

export default BuyerModal
