import React from 'react'
import dayjs from 'dayjs'

import TableWrapper from '../../../wrappers/TableWrapper'
import Title from '../../../typography/Title'
import ViewWrapper from '../../../wrappers/ViewWrapper'
import { renderMoney, renderPrice } from '../../../../utils/helper'

const columns = ['Commodity', 'Expenses', 'Income', 'Profit Goal', 'Breakeven', 'Target Price']

const getName = (profile) => `${profile.name} (${profile.productionYear.name})`

function CropProfilesView({ profiles }) {
  return profiles?.length ? (
    <ViewWrapper>
      <Title title="Crops" />
      <TableWrapper
        exportable={{
          filename: `Expenses-Summary-Crops-${dayjs().format('MM-DD-YYYY')}`
        }}
        columns={columns}
        rows={profiles
          ?.map((item) => [
            getName(item),
            item.totalExpense,
            item.totalIncome,
            item.totalTargets,
            item.breakeven,
            item.targetPrice
          ])
          .reduce(
            (acc, item, index, { length }) => {
              acc[0] = acc[0].map((value, i) => (i > 0 && i < 4 ? value + item[i] : value))

              const format = (item) =>
                item.map((item, index) => {
                  if (index === 1 || index === 2 || index === 3) {
                    return renderMoney(item)
                  }
                  if (index === 4 || index === 5) {
                    return item >= 0 ? renderPrice(item) : '-'
                  }
                  return item
                })

              if (index === length - 1) {
                return [...acc.slice(1), format(item), format(acc[0])]
              }
              return [...acc, format(item)]
            },
            [['Total', 0, 0, 0]]
          )}
      />
    </ViewWrapper>
  ) : null
}

export default CropProfilesView
