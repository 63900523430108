import { gql } from '@apollo/client'

const SAVE_PROFILE_EXPENSE = gql`
  mutation saveProfileExpense($input: ProfileExpenseInput!) {
    saveProfileExpense(input: $input) {
      id
      profileId
      expenseId
      drylandCost
      irrigatedCost
      perHeadCost
      allocation
      isAutoAllocated
    }
  }
`

export default SAVE_PROFILE_EXPENSE
