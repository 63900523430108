import { gql } from '@apollo/client'

import PROFILE_FRAGMENT from '../fragments/profile'

const PROFILES = gql`
  query Profiles($clientId: String!, $productionYearId: Float!) {
    profiles(clientId: $clientId, productionYearId: $productionYearId) {
      ...Profile
      totalExpense
      totalIncome
      totalTargets
      breakeven
      targetPrice
    }
  }
  ${PROFILE_FRAGMENT}
`

export default PROFILES
