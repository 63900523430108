import * as Yup from 'yup'
import React from 'react'
import { Helmet } from 'react-helmet'
import { DocumentTextIcon } from '@heroicons/react/outline'
import { Field, Form, Formik } from 'formik'
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Select from 'react-select'

import Header from '../components/Header'
import MultiSelect from '../components/MultiSelect'
import Button from '../components/buttons/Button'
import GET_PRODUCTION_YEARS from '../gql/queries/getProductionYears'
import { getUniqueArrayList } from '../utils/helper'
import { getReactSelectOptions } from '../utils/selectUtils'

const initialValues = {
  productionYears: []
}

const initialValuesMonthlyReport = {
  productionYears: ''
}

function ReportsPage() {
  const { id: clientId } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { data: productionYearData } = useQuery(GET_PRODUCTION_YEARS, {
    variables: {
      clientId
    }
  })

  const uniqueProductionyearsList = getUniqueArrayList(
    productionYearData?.getProductionYears?.map(({ name: productionYear }) => productionYear)
  )

  const options = getReactSelectOptions(uniqueProductionyearsList)

  const onSubmit = ({ productionYears }) => {
    const isProductionYearsSelected = productionYears.length > 0

    const reportParams = {
      productionYears: isProductionYearsSelected ? productionYears.join(',') : 'All-productionYears'
    }
    const clientReportsRoute = `${pathname}/generated`

    navigate({
      pathname: clientReportsRoute,
      search: `?${createSearchParams(reportParams)}`
    })
  }

  const onMonthlyReportSubmit = (value) => {
    const clientReportsRoute = `${pathname}/monthly/generated`

    navigate({
      pathname: clientReportsRoute,
      search: `?${createSearchParams(value)}`
    })
  }

  return (
    <>
      <Helmet>
        <title>Reports</title>
      </Helmet>
      <Header>Client Reports</Header>
      <h1 className="mt-3 text-2xl font-bold text-gray-900">Reports</h1>
      <h2 className="mt-4 text-lg font-bold text-gray-500">Risk Management</h2>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ setFieldValue }) => (
          <Form className="w-80">
            <div className="mt-2">
              <label className="text-sm font-medium text-gray-900" htmlFor="productionYears">
                Select production year
              </label>
              <Field
                name="productionYears"
                component={MultiSelect}
                setValue={setFieldValue}
                fieldName="productionYears"
                options={options}
                placeholder="All production years"
              />
            </div>
            <div>
              <Button
                classes="mt-3 gap-2"
                type="submit"
                color="info"
                label="Run report"
                icon={<DocumentTextIcon className="h-5 w-5" aria-hidden="true" />}
              />
            </div>
          </Form>
        )}
      </Formik>

      <h2 className="mt-4 text-lg font-bold text-gray-500">360 At A Glance</h2>
      <Formik
        validationSchema={Yup.object({
          productionYears: Yup.string().required('Required!')
        })}
        initialValues={initialValuesMonthlyReport}
        onSubmit={onMonthlyReportSubmit}
      >
        {({ setFieldValue }) => (
          <Form className="w-full">
            <div className="mt-2 flex gap-4">
              <div className="w-60">
                <label className="text-sm font-medium text-gray-900" htmlFor="productionYears">
                  Select production year
                </label>
                <Field
                  name="productionYears"
                  component={Select}
                  setValue={setFieldValue}
                  options={options}
                  placeholder="Production year"
                  onChange={({ value }) => setFieldValue('productionYears', value)}
                />
              </div>
            </div>
            <div>
              <Button
                classes="mt-3 gap-2"
                type="submit"
                color="info"
                label="Run report"
                icon={<DocumentTextIcon className="h-5 w-5" aria-hidden="true" />}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ReportsPage
