import { gql } from '@apollo/client'

const UPDATE_PROFILE_ACCOUNT = gql`
  mutation updateUserProfileAccount($input: UpdateUserProfileAccountInput!) {
    updateUserProfileAccount(input: $input) {
      id
      firstName
      lastName
      state
      city
      phone
    }
  }
`

export default UPDATE_PROFILE_ACCOUNT
