import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import LoadingBar from 'react-top-loading-bar'

function ProgressBar() {
  const progressBarRef = useRef(false)
  const [previousLocation, setPreviousLocation] = useState({})

  const location = useLocation()

  // TODO: Needs refactoring
  useEffect(() => {
    setPreviousLocation(location)
    progressBarRef.current.continuousStart()
  }, [location])

  useEffect(() => {
    progressBarRef.current.complete()
  }, [previousLocation])

  return <LoadingBar color="#C8FFD4" height={3} ref={progressBarRef} shadow />
}

export default ProgressBar
