import * as yup from 'yup'
import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import { Helmet } from 'react-helmet'
import { useMutation } from '@apollo/client'
import { useNavigate, useSearchParams } from 'react-router-dom'

import AlertBox from '../../components/AlertBox'
import Button from '../../components/buttons/Button'
import ExternalFieldWrapper from '../../components/wrappers/ExternalFieldWrapper'
import ExternalFormWrapper from '../../components/wrappers/ExternalFormWrapper'
import ExternalHeading from '../../components/typography/ExternalHeading'
import Input from '../../components/inputs/Input'
import RESET_PASSWORD from '../../gql/mutations/resetPassword'
import { VISITOR_ROUTES } from '../../constants/routes'

const validationSchema = yup.object({
  password: yup.string().required('Password is required'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
})

function ResetPasswordPage() {
  const [resetPassword, { loading, error }] = useMutation(RESET_PASSWORD)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const token = searchParams.get('token')

  useEffect(() => {
    if (!token) {
      navigate(VISITOR_ROUTES.UNAUTHORIZED)
    }
  }, [token, navigate])

  const onSubmit = async (values) => {
    resetPassword({
      variables: {
        input: {
          token,
          password: values.password
        }
      },
      onCompleted: () => {
        navigate('/')
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <ExternalHeading>Reset Password</ExternalHeading>
      <ExternalFormWrapper>
        {error && <AlertBox message={error} />}
        <Formik
          initialValues={{
            confirmPassword: '',
            password: ''
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isValid }) => (
            <Form>
              <ExternalFieldWrapper>
                <Input label="Password" type="password" name="password" />
              </ExternalFieldWrapper>
              <ExternalFieldWrapper>
                <Input label="Confirm Password" type="password" name="confirmPassword" />
              </ExternalFieldWrapper>
              <Button
                type="submit"
                classes="w-full"
                color="info"
                label="Set Password"
                loading={loading}
                disabled={!isValid || loading}
                message="Submitting"
              />
            </Form>
          )}
        </Formik>
      </ExternalFormWrapper>
    </>
  )
}

export default ResetPasswordPage
