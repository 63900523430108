import { gql } from '@apollo/client'

import USER_FRAGMENT from '../fragments/user'

const LOGIN = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      user {
        ...User
      }
      accessToken
    }
  }
  ${USER_FRAGMENT}
`

export default LOGIN
