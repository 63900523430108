import * as Yup from 'yup'
import React from 'react'
import { Form, Formik } from 'formik'
import { toast } from 'react-hot-toast'
import { useMutation } from '@apollo/client'

import BaseModal from '../modal/BaseModal'
import Button from '../buttons/Button'
import Input from '../inputs/Input'
import SET_PASSWORD from '../../gql/mutations/setPassword'
import { getName } from '../../utils/helper'

function SetPasswordModal({ isOpen, closeModal, user }) {
  const initialValues = {
    password: null,
    id: user?.id
  }

  const [setPassword] = useMutation(SET_PASSWORD)
  const handleSetPassword = async (values, { resetForm }) => {
    toast.promise(
      setPassword({
        variables: {
          id: values?.id,
          password: values?.password
        },
        onCompleted: () => {
          setTimeout(() => {
            closeModal()
            resetForm()
          }, 1000)
        }
      }),
      {
        loading: `Setting password...`,
        success: `Password set successfully.`,
        error: `Error while  setting password.`
      }
    )
  }

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Required!')
      .min(4, 'Password must be atleast of 4 letters')
      .max(32, 'Password must be less than 32 letters')
  })

  return (
    <BaseModal isOpen={isOpen} closeModal={closeModal}>
      <div className="w-100 box-border px-5">
        <p className="mb-4  mt-5 text-xl font-semibold ">{`Set password for ${getName(user)}`}</p>
        <div className="">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSetPassword}
          >
            {() => (
              <Form>
                <Input label="Password" type="text" name="password" />
                <div className="mt-6 flex justify-end gap-x-2">
                  <Button type="button" color="neutral" label="Cancel" onClick={closeModal} />
                  <Button type="submit" color="info" label="Set Password" />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </BaseModal>
  )
}

export default SetPasswordModal
