import { gql } from '@apollo/client'

const CREATE_SALE = gql`
  mutation createSale($input: CreateSaleInput!) {
    createSale(input: $input) {
      id
      kind
      subkind
      clientId
      profileId
      buyerId
      amount
      basisAmount
      futuresAmount
      feeAmount
      feeMode
      quantity
      orderExpiryDate
      futuresMonth
      saleDate
      delivered
      referenceNumber
      notes
    }
  }
`

export default CREATE_SALE
