import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { ErrorMessage, useField } from 'formik'
import React, { Fragment } from 'react'

import { generateFutureMonths } from '../../utils/helper'

function SelectInput({ ...props }) {
  const [field] = useField(props)
  const { name, crop, width } = props
  const tradingMonths = crop?.tradingMonths?.length ? generateFutureMonths(crop?.tradingMonths) : []

  return (
    <div>
      <Listbox
        value={field.value}
        onChange={(value) => {
          field.onChange({ target: { value, name } })
        }}
        {...props}
      >
        {({ open }) => (
          <div className="relative mt-1">
            <Listbox.Button className="focus:ring-emerlad-500 relative h-[38px] w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-1 sm:text-sm">
              <span className="block truncate">{field.value}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {tradingMonths?.length > 0 ? (
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {tradingMonths?.map((item) =>
                    item?.value ? (
                      <Listbox.Option
                        key={item?.label}
                        className={({ active }) =>
                          classNames(
                            active ? 'bg-emerald-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={item?.value}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate'
                              )}
                            >
                              {item?.value}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-emerald-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ) : (
                      <Listbox.Label className="ml-3 block text-base font-bold text-gray-700">
                        {item?.label}
                      </Listbox.Label>
                    )
                  )}
                </Listbox.Options>
              ) : (
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <Listbox.Label className="ml-3 block text-base font-semibold text-gray-700">
                    {crop
                      ? 'The selected commodity does not have future months'
                      : 'Select commodity'}
                  </Listbox.Label>
                </Listbox.Options>
              )}
            </Transition>
          </div>
        )}
      </Listbox>
      <ErrorMessage
        name={name}
        component="p"
        className={`mt-1 ${width} text-sm font-medium text-red-600`}
      />
    </div>
  )
}

export default SelectInput
