import * as Yup from 'yup'
import React from 'react'
import { Field, Form, Formik } from 'formik'
import { toast } from 'react-hot-toast'
import { useMutation } from '@apollo/client'

import BaseModal from '../modal/BaseModal'
import Button from '../buttons/Button'
import CREATE_PRODUCTION_YEAR from '../../gql/mutations/createProductionYear'
import GET_PRODUCTION_YEARS from '../../gql/queries/getProductionYears'
import Input from '../inputs/Input'
import UPDATE_PRODUCTION_YEAR from '../../gql/mutations/updateProductionYear'

function ProductionYearModal({
  isOpen,
  closeModal,
  intialValue,
  type,
  user,
  clientId,
  productionYearsData
}) {
  const [createProductionYear] = useMutation(CREATE_PRODUCTION_YEAR)
  const [updateProductionYear] = useMutation(UPDATE_PRODUCTION_YEAR)

  const initialValues = {
    productionYear: intialValue || '',
    copyData: 'NO'
  }

  const productionYearList = productionYearsData?.map((year) => ({
    label: year?.name,
    value: year?.id,
    copyFromProductionYearId: ''
  }))
  const onSubmit = async (values, { resetForm }) => {
    if (type === 'ADD') {
      toast.promise(
        createProductionYear({
          variables: {
            input: {
              name: Number(values.productionYear),
              clientId,
              copyFromProductionYearId:
                values?.copyData === 'YES' ? values.copyFromProductionYearId : null
            }
          },
          refetchQueries: [GET_PRODUCTION_YEARS],
          onCompleted: () => {
            closeModal()
            resetForm()
          }
        }),
        {
          loading: 'Adding the production year...',
          success: 'Production year created successfully.',
          error: (error) =>
            error?.graphQLErrors[0]?.message || 'Error while creating the production year.'
        }
      )
    }
    if (type === 'EDIT') {
      updateProductionYear({
        variables: {
          input: {
            name: Number(values.productionYear),
            id: user.id,
            clientId
          }
        },
        refetchQueries: [GET_PRODUCTION_YEARS],
        onCompleted: () => {
          toast.success('Production year updated successfully.')
          resetForm()
          closeModal()
        }
      }).catch((error) => window.alert(error?.graphQLErrors[0]?.message))
    }
  }

  const validationSchema = Yup.object({
    productionYear: Yup.string()
      .required('Required!')
      .min(4, 'Production year must be of 4 digits')
      .max(4, 'Production year must be at most 4 digits')
  })

  return (
    <BaseModal isOpen={isOpen} closeModal={closeModal}>
      <div className="h-24v">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values }) => (
            <Form className="w-96">
              <Input
                label={type === 'EDIT' ? 'Edit a production year' : 'Add Production Year'}
                type="number"
                name="productionYear"
                min="0"
              />
              {type === 'ADD' && productionYearList?.length > 0 && (
                <div className="mt-4 ">
                  <label className="text-sm font-bold text-gray-700">
                    Do you wish to copy data from a previous year?
                  </label>
                  <div role="group" className="mt-2">
                    <label className="mr-4">
                      <Field type="radio" name="copyData" value="YES" className="mr-2" />
                      Yes
                    </label>
                    <label>
                      <Field type="radio" name="copyData" value="NO" className="mr-2" />
                      No
                    </label>
                  </div>
                  {values?.copyData === 'YES' && (
                    <div className="mt-4">
                      <label
                        className="text-sm font-bold text-gray-700"
                        htmlFor="copyFromProductionYearId"
                      >
                        Copy from production year
                      </label>
                      <Field
                        as="select"
                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                        type="copyFromProductionYearId"
                        id="copyFromProductionYearId"
                        name="copyFromProductionYearId"
                      >
                        <option defaultValue>Select year</option>
                        {productionYearList.map((year) => (
                          <option value={year?.value}>{year?.label}</option>
                        ))}
                      </Field>
                    </div>
                  )}
                </div>
              )}
              <div className="mt-6 flex justify-end gap-x-2">
                <Button type="button" color="neutral" label="Cancel" onClick={closeModal} />
                <Button type="submit" color="info" label="Save" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </BaseModal>
  )
}

export default ProductionYearModal
