import { gql } from '@apollo/client'

const UPDATE_STORAGE = gql`
  mutation updateStorage($input: UpdateStorageInput!) {
    updateStorage(input: $input) {
      id
      clientId
      profileId
      quantity
      location
      notes
      createdAt
      updatedAt
    }
  }
`

export default UPDATE_STORAGE
