import { gql } from '@apollo/client'

import PROFILE_FRAGMENT from '../fragments/profile'

const GET_INSURANCES = gql`
  query getInsurances($clientId: String!) {
    getInsurances(clientId: $clientId) {
      id
      productKind
      state
      county
      coverage
      dryYield
      price
      protectionFactor
      profile {
        ...Profile
      }
    }
  }
  ${PROFILE_FRAGMENT}
`

export default GET_INSURANCES
