import React, { useState } from 'react'
import classNames from 'classnames'
import { ErrorMessage, useField } from 'formik'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline'

const inputClass =
  'appearance-none block w-full py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm'

const labelClass = 'block text-sm text-gray-700'

function Input({ disabled, ...props }) {
  const [field, meta] = useField(props)
  const { name, label, type, min, step, variant, width } = props
  const errorClass = meta.touched && meta.error ? 'error' : ''

  const [showPassword, setShowPassword] = useState(false)

  if (type === 'checkbox') {
    return (
      <div>
        <input className="mb-0.5 rounded" id={name} type={type} {...field} disabled={disabled} />
        <label
          htmlFor={name}
          className={classNames(
            'ml-2 text-sm text-gray-700',
            variant ? 'font-medium' : 'font-bold'
          )}
        >
          {label}
        </label>
      </div>
    )
  }

  const isPassword = type === 'password'
  const PasswordIcon = showPassword ? EyeOffIcon : EyeIcon
  const toggleShowPassword = () => setShowPassword((bool) => !bool)

  return (
    <div>
      <label
        htmlFor={name}
        className={`
          ${labelClass}
          ${variant ? 'font-medium' : 'font-bold'}
        `}
      >
        {label}
      </label>
      <div className="relative mt-1">
        <input
          className={classNames(
            inputClass,
            errorClass ? 'border border-red-500' : '',
            isPassword && 'pr-[36px]'
          )}
          id={name}
          type={isPassword && !showPassword ? type : 'text'}
          min={min}
          step={step}
          autoComplete="off"
          disabled={disabled}
          {...field}
        />
        {isPassword && (
          <button type="button" onClick={toggleShowPassword}>
            <PasswordIcon className="absolute top-[7px] right-[8px] h-6 w-6 text-gray-700" />
          </button>
        )}
      </div>
      <ErrorMessage
        name={name}
        component="p"
        className={`mt-1 ${width} text-sm font-medium text-red-600`}
      />
    </div>
  )
}

export default Input
