import { gql } from '@apollo/client'

const CREATE_DELIVERY = gql`
  mutation createDelivery($input: CreateDeliveryInput!) {
    createDelivery(input: $input) {
      saleId
      amount
      date
      file
      notes
    }
  }
`

export default CREATE_DELIVERY
