import { gql } from '@apollo/client'

const UPDATE_INSURANCE_PRICING = gql`
  mutation updateInsurancePricing($input: UpdateInsurancePricingInput!) {
    updateInsurancePricing(input: $input) {
      id
    }
  }
`

export default UPDATE_INSURANCE_PRICING
