import { gql } from '@apollo/client'

const GET_PROFILE_INCOMES = gql`
  query getProfileIncomes($profileIds: [String!]!) {
    getProfileIncomes(profileIds: $profileIds) {
      id
      profileId
      incomeId
      allocation
      isAutoAllocated
    }
  }
`

export default GET_PROFILE_INCOMES
