/* eslint-disable no-unsafe-optional-chaining */
import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import AlertBox from '../../components/AlertBox'
import Header from '../../components/Header'
import Loader from '../../components/Loader'
import PROFILES from '../../gql/queries/Profiles'
import CropView from '../../components/views/profiles/CropView'
import LivestockView from '../../components/views/profiles/LivestockView'

function ProfilesPage() {
  const { id, productionYearId } = useParams()
  const name = Number(productionYearId)
  const { error, loading, data } = useQuery(PROFILES, {
    variables: {
      clientId: id,
      productionYearId: name
    }
  })

  return (
    <>
      <Header>Commodities</Header>
      {loading && !data && <Loader message="Fetching data..." variant="fetchData" />}
      {error && <AlertBox message="Oops! Something went wrong while fetching data." />}
      {data && (
        <>
          <CropView profiles={data?.profiles} />
          <LivestockView profiles={data?.profiles} />
        </>
      )}
    </>
  )
}

export default ProfilesPage
